import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceContract } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ServiceContractsGateway {
  constructor(private readonly apiService: ApiService) {}

  getActiveContractsForShop(companyId: number, shopId: number): Observable<ServiceContract[]> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/pluginservicecontracts`);
  }

  cancelContract(companyId: number, shopId: number, contractId: number): Observable<ServiceContract> {
    return this.apiService.post(
      `/companies/${companyId}/shops/${shopId}/pluginservicecontracts/${contractId}/cancellation`
    );
  }

  revokeContractCancellation(companyId: number, shopId: number, contractId: number): Observable<ServiceContract> {
    return this.apiService.delete(
      `/companies/${companyId}/shops/${shopId}/pluginservicecontracts/${contractId}/cancellation`
    );
  }
}
