import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SupportMatrixEntry } from '../../models/support/support.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class SupportGateway {
  constructor(private readonly apiService: ApiService) {}

  getSupportMatrixForShop(shopId: number, context?: string): Observable<SupportMatrixEntry[]> {
    let params = new HttpParams();
    if (context) {
      params = params.set('context', context);
    }
    return this.apiService.get(`/shops/${shopId}/supportlevels`, params);
  }
}
