import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, Observable } from 'rxjs';

import { AbstractRadioButtonComponent } from './buttons/abstract-radio-button.component';

@Component({
  selector: 'account-enhanced-radio-buttons',
  template: '<ng-content></ng-content>',
  styleUrl: './enhanced-radio-buttons.component.less',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EnhancedRadioButtonsComponent),
    },
  ],
  standalone: true,
})
export class EnhancedRadioButtonsComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input() name: string;
  @ContentChildren(AbstractRadioButtonComponent) protected buttonComponents: QueryList<AbstractRadioButtonComponent>;

  private _value: any;
  private _isDisabled = false;

  private onTouchedCallback: () => null;
  private onChangeCallback: (fn: any) => null;

  ngOnInit(): void {
    if (!this.name) {
      throw new Error('Name is required for radio buttons');
    }
  }

  ngAfterContentInit(): void {
    const selectionChanges: Observable<any>[] = [];
    if (this.buttonComponents.length < 2) {
      throw new Error('Selection for radio buttons should at least have two options');
    }
    for (const buttonComponent of this.buttonComponents) {
      buttonComponent.name = this.name;
      if (this.value === buttonComponent.value) {
        buttonComponent.checked = true;
      }
      selectionChanges.push(buttonComponent.onChange);
      this.setDisabledState(this._isDisabled);
    }
    merge(...selectionChanges).subscribe((selectedValue: any) => {
      if (typeof this.onChangeCallback === 'function') {
        this.value = selectedValue;
        this.onChangeCallback(selectedValue);
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    if (this.buttonComponents) {
      for (const buttonComponent of this.buttonComponents) {
        buttonComponent.disabled = isDisabled;
      }
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      if (typeof this.onChangeCallback === 'function') {
        this.onChangeCallback(value);
      }
    }
  }

  get value(): any {
    return this._value;
  }
}
