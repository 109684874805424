import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { ModalBodyDirective, ModalComponent, ModalFooterDirective } from '../modal.component';
import { ConfirmModalComponent } from './confirm-modal.component';

@Component({
  selector: 'account-confirm-modal',
  templateUrl: './checkbox-confirm-modal.component.html',
  styleUrls: ['../modal.component.less', './checkbox-confirm-modal.component.less'],
  standalone: true,
  imports: [ModalComponent, NgIf, ModalBodyDirective, CheckboxComponent, ModalFooterDirective],
})
export class CheckboxConfirmModalComponent extends ConfirmModalComponent implements OnInit {
  isChecked = false;
  checkBoxText: string = null;

  override ngOnInit(): void {
    super.ngOnInit();
    this.checkBoxText = this.modalData.checkBoxText;
  }

  toggleCheckboxConfirmation(checked: boolean): void {
    this.isChecked = checked;
  }
}
