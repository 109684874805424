import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { TagsInputItemTemplateLoaderComponent } from './item-template-loader.component';

export const TAGS_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsInputComponent),
  multi: true,
};

@Component({
  selector: 'account-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrl: './tags-input.component.less',
  providers: [TAGS_INPUT_VALUE_ACCESSOR],
  standalone: true,
  imports: [NgFor, NgIf, TagsInputItemTemplateLoaderComponent, SwIconComponent, FormsModule, ReactiveFormsModule],
})
export class TagsInputComponent implements OnInit, ControlValueAccessor {
  @Input() items: any[] = [];
  @Input() maxItems: number;
  @Input() placeholder: string;
  @Input() displayProperty: string;
  @Input() minimumCharacters = 3;
  @Input() maximumCharacters: number;

  @Output() readonly search = new EventEmitter<string>();
  @Output() readonly keyAdded = new EventEmitter<void>();

  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('inputFieldElement', { static: true }) searchInputChild: ElementRef;

  focus = false;
  selected: any[] = [];
  inputField = new UntypedFormControl();
  inputInvalid = false;

  private onTouchedCallback: () => {};
  private onChangeCallback: (fn: any) => {};

  private selfClick = false;
  private itemClick = false;
  private documentClickListener: Function;

  private _compareWith: (o1: any, o2: any) => boolean = Object.is;

  constructor(
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.registerOnTouched(() => {});
    this.inputField.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
      if (value.length >= this.minimumCharacters && !this.isMaxItemsReached()) {
        this.bindDocumentClickListener();
        this.search.emit(value);
      } else {
        this.items = [];
      }
    });
  }

  @Input()
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (typeof fn !== 'function') {
      throw new TypeError(`compareWith must be a function, but received ${JSON.stringify(fn)}`);
    }
    this._compareWith = fn;
  }

  @Input()
  set isValid(fn: (input: any) => boolean) {
    if (typeof fn !== 'function') {
      throw new TypeError(`isValid must be a function, but received ${JSON.stringify(fn)}`);
    }
    this._isValid = fn;
  }

  select($event: KeyboardEvent, item: any): void {
    if (this.isSelected(item)) {
      const index = this.selected.findIndex((selectedItem) => this._compareWith(selectedItem, item));
      this.selected.splice(index, 1);
    } else {
      this.selected.push(item);
      this.inputField.patchValue('');
    }
    if (typeof this.onChangeCallback === 'function') {
      this.onChangeCallback(this.selected);
    }
  }

  isSelected(item: any): boolean {
    return !!this.selected.find((selectedItem) => this._compareWith(selectedItem, item));
  }

  onPaste($event: any): void {
    this.inputInvalid = false;

    const text = $event.clipboardData.getData('text');
    if (text.length > 0) {
      const strings = text
        .split(';')
        .join(',')
        .split('\n')
        .join(',')
        .split(',')
        .map((string: string) => string.trim());

      strings.forEach((string: string) => {
        this.processText($event, string);
      });
    }
  }

  onInputKeyDown($event: any): void {
    // backspace key 8
    if (this.inputField.value === '' && $event.keyCode === 8) {
      this.selected.pop();
      if (typeof this.onChangeCallback === 'function') {
        this.onChangeCallback(this.selected);
      }
    }

    this.inputInvalid = false;

    // enter 13
    // comma 188
    if (![13, 188].includes($event.keyCode)) {
      return;
    }

    this.processText($event, $event.target.value);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.selected = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onFocus(): void {
    this.focus = !this.focus;
    this.onTouchedCallback();
  }

  onBlur($event: any): void {
    this.focus = false;
    this.processText($event, $event.target.value);
    this.onTouchedCallback();
  }

  private processText($event: any, text: string): void {
    let item: any = text;
    if (this.displayProperty) {
      item = {};
      item[this.displayProperty] = text;
    }

    if (this.isSelected(item)) {
      this.inputInvalid = true;
      return;
    }

    if (text.trim().length < this.minimumCharacters) {
      this.inputInvalid = true;
      return;
    }

    if (this.maximumCharacters && text.trim().length > this.maximumCharacters) {
      this.inputInvalid = true;
      return;
    }

    if (this.isMaxItemsReached()) {
      this.inputInvalid = true;
      return;
    }

    if (!this._isValid(item)) {
      this.inputInvalid = true;
      return;
    }

    $event.preventDefault();

    this.select($event, item);
    this.keyAdded.emit();
  }

  private isMaxItemsReached(): boolean {
    return this.maxItems && this.maxItems <= this.selected.length;
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.items = [];
          this.unbindDocumentClickListener();
        }

        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  private _isValid: (input: any) => boolean = () => true;
}
