import { createAction, props } from '@ngrx/store';

import { LoginCredentials, LoginResultCredentials, LoginResultToken, SbpException } from '../../models';

export const loginWithCredentials = createAction(
  '[Session] login with credentials',
  props<{ payload: LoginCredentials }>()
);
export const loginWithToken = createAction('[Session] login with token', props<{ payload: string }>());
export const loginWithOryJwtSessionToken = createAction(
  '[Session] login with ory jwt session token',
  props<{ payload: string }>()
);
export const loginWithCredentialsSuccess = createAction(
  '[Session] login with credentials success',
  props<{ payload: LoginResultCredentials }>()
);
export const loginWithTokenSuccess = createAction(
  '[Session] login with token success',
  props<{ payload: LoginResultToken }>()
);
export const loginWithOryJwtSessionTokenSuccess = createAction(
  '[Session] login with ory jwt session token success',
  props<{ payload: { userAccountId: number } }>()
);

export const loginFail = createAction('[Session] login fail', props<{ payload: SbpException }>());

export const logout = createAction('[Session] logout');
export const logoutSuccess = createAction('[Session] logout success');
export const logoutFail = createAction('[Session] logout fail', props<{ payload: SbpException }>());

export const setCompanyId = createAction('[Session] set companyId', props<{ payload: number }>());
export const setPartnerId = createAction('[Session] set partnerId', props<{ payload: number }>());
export const setProducerId = createAction('[Session] set producerId', props<{ payload: number }>());

export const resetCompanyAttributes = createAction('[Session] set reset company attributes');

export const setBypassGTCConfirmation = createAction(
  '[Session] set bypass gtc confirmation',
  props<{ payload: boolean }>()
);
