import { Action, createReducer, on } from '@ngrx/store';

import { CompanyMembershipInvitation } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './invitations.actions';

export interface InvitationsState extends AsyncState {
  invitations: CompanyMembershipInvitation[];
  checkCompleted: boolean;
}

export const initialInvitationsState: InvitationsState = {
  loading: false,
  loaded: false,
  error: undefined,
  invitations: [],
  checkCompleted: false,
};

const invitationsReducerInternal = createReducer(
  initialInvitationsState,

  on(actions.getInvitations, actions.acceptInvitation, actions.declineInvitation, (state: InvitationsState) => ({
    ...state,
    loading: true,
  })),

  on(
    actions.getInvitationsSuccess,
    actions.getInvitationsFail,
    actions.acceptInvitationSuccess,
    actions.acceptInvitationFail,
    actions.declineInvitationSuccess,
    actions.declineInvitationFail,
    (state: InvitationsState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.getInvitationsSuccess, (state: InvitationsState, { payload }) => ({
    ...state,
    invitations: payload,
    error: undefined,
  })),

  on(
    actions.decideLater,
    actions.acceptInvitationSuccess,
    actions.declineInvitationSuccess,
    (state: InvitationsState, { payload }) => ({
      ...state,
      invitations: [
        ...state.invitations.filter((invitation: CompanyMembershipInvitation) => invitation.id !== payload.id),
      ],
      error: undefined,
    })
  ),

  on(actions.invitationCheckCompleted, (state: InvitationsState) => ({
    ...state,
    checkCompleted: true,
  })),

  on(
    actions.getInvitationsFail,
    actions.acceptInvitationFail,
    actions.declineInvitationFail,
    actions.getInvitationsFail,
    actions.acceptInvitationFail,
    actions.declineInvitationFail,
    (state: InvitationsState, { payload }) => ({
      ...state,
      error: payload,
    })
  )
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: InvitationsState | undefined, action: Action): InvitationsState {
  return invitationsReducerInternal(state, action);
}
