import { AbstractControl, ValidatorFn } from '@angular/forms';

import { convertSbpDate } from '../../core/utils/sbp-date-converter.utils';

export class DateValidators {
  static minDate(minDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(control.value['dateObject'])) {
        return null;
      }

      let date: Date;
      const value: string | Date | { dateObject: Date } = control.value;
      if (value) {
        if (value instanceof Date) {
          date = value;
        } else if (typeof value === 'string') {
          date = convertSbpDate(value);
        } else if (Object.prototype.hasOwnProperty.call(value, 'dateObject')) {
          date = (value as { dateObject: Date }).dateObject;
        }
        if (date) {
          // ignore milliseconds
          date.setMilliseconds(minDate.getMilliseconds());
        }

        if (!date || date.getTime() < minDate.getTime()) {
          return { minDate: true };
        }
      }

      return null;
    };
  }

  private static isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
  }
}
