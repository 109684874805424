<div class="avatar" [class.avatar-unknown]="showUnknownAvatar">
  <account-sw-icon *ngIf="showUnknownAvatar; else userAvatar" icon="user" color="white"></account-sw-icon>
  <ng-template #userAvatar>
    <account-avatar [srcUrl]="avatarUrl" [initials]="firstName.charAt(0) + lastName.charAt(0)"></account-avatar>
  </ng-template>
</div>
<div class="user--info">
  <div *ngIf="firstName && lastName; else whoAreYou" class="user--info--username">
    {{ firstName }}&nbsp;{{ lastName }}
  </div>
  <ng-template #whoAreYou>
    <a (click)="goToSettings()">
      <p>{{ 'NAVIGATION.USER_INFO.WHO_ARE_YOU' | translate }}</p>
    </a>
  </ng-template>
  <div class="user--info--additional">{{ email }}</div>
</div>
<account-sw-icon class="user--arrow" icon="chevron-up-s" size="16px" color="#9aa7be"></account-sw-icon>
