import { NgFor, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { uniqueHash } from '../../util';

@Component({
  selector: 'account-incremental-select',
  templateUrl: './incremental-select.component.html',
  styleUrl: './incremental-select.component.less',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IncrementalSelectComponent),
    },
  ],
  imports: [NgFor, NgTemplateOutlet],
})
export class IncrementalSelectComponent implements ControlValueAccessor {
  @Input({ required: true }) options: any[];
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  private _selection: any;
  protected selectedIndex: number;
  protected disabled = false;
  protected inputName = uniqueHash();

  writeValue(selection: any): void {
    this._selection = selection;
    this.selectedIndex = this.options.indexOf(selection);
  }

  onChange(isSelected: boolean, index: number): void {
    if (!isSelected || this.disabled) {
      return;
    }

    this._selection = this.options[index];
    this.selectedIndex = index;
    this.propagateChange(this._selection);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any): void => {};
}
