import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SupportGateway } from '../../gateways/support/support.gateway';
import { SupportMatrixEntry } from '../../models/support/support.model';

@Injectable({
  providedIn: 'root',
})
export class SupportFacade {
  constructor(private readonly supportGateway: SupportGateway) {}

  getSupportMatrixForShop(shopId: number, context?: string): Observable<SupportMatrixEntry[]> {
    return this.supportGateway.getSupportMatrixForShop(shopId, context);
  }
}
