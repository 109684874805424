import { Observable, Subject } from 'rxjs';

export class ComponentOverlayRef {
  componentRef: any;

  private readonly _afterClosed: Subject<any> = new Subject();

  close(result?: any): void {
    this._afterClosed.next(result);
  }

  afterClosed(): Observable<any> {
    return this._afterClosed.asObservable();
  }
}
