import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SwIconComponent } from '../../../../shared/components/sw-icon/sw-icon.component';

@Component({
  selector: 'navigation-expander',
  templateUrl: './navigation-expander.component.html',
  styleUrl: './navigation-expander.component.less',
  imports: [TranslateModule, SwIconComponent],
  standalone: true,
})
export class NavigationExpanderComponent {
  @Output() readonly onToggleNavigation = new EventEmitter<unknown>();

  @HostListener('click') private _onClick(): void {
    this.onToggleNavigation.emit();
  }
}
