import { Environment } from '@account/core/models';

import { defaultVariables } from './defaults';

export const environment: Environment = {
  ...defaultVariables,
  envName: 'live',
  production: true,
  apiUrl: 'https://api.shopware.com',
  packages: {
    protocol: 'https',
    domain: 'packages.shopware.com',
  },
  sessionCookieDomain: '.shopware.com',

  auth_ui_url: 'https://auth.shopware.com',
  auth_api_url: 'https://auth-api.shopware.com',

  heidelpayPublicKey: 'p-pub-2a10jcMkaUuEWaDdW4NyVPTUnuNY9sdz',
  hubspot_environment: '6506294',
  hubspot_cancel_subscription_formId_german: '019aa8d6-6d65-4ab7-a1d4-05b243826716',
  hubspot_cancel_subscription_formId_english: 'f48e11d2-3f51-4e47-917b-3413c5dd2257',
  hubspot_cancel_rent_formId_german: '511e489c-cbeb-45c4-a973-93b3974da198',
  hubspot_cancel_rent_formId_english: 'd033fba2-0f49-4919-884a-10e8221d8e34',
  hubspot_cancel_extension_rent_formId_german: '9e5c6dc7-503b-4187-9007-9988e88e31e8',
  hubspot_cancel_extension_rent_formId_english: '75d58df4-7392-4d35-9e77-8a75c1e3862d',
  hubspot_cancel_contract_trial_formId_german: 'a0955089-9399-4a0e-ab61-567c9bef9430',
  hubspot_cancel_contract_trial_formId_english: '8824722e-bd7c-46ef-8313-7f594d9e35af',
  hubspot_integration_partner_deal_registration_form_url_german: '1E2Sc4UmzRLGS1JYlvqaOJw3vgae',
  hubspot_integration_partner_deal_registration_form_url_english: '1eF5qqAltQTaoV5-Jj1pLnQ3vgae',
  hubspot_technology_partner_deal_registration_form_url_german: '1OmrPEdbkRuarUj0IbtUgVA3vgae',
  hubspot_technology_partner_deal_registration_form_url_english: '1fjNSQjSXQPusGmUkLPKgDA3vgae',

  contentfulEnvironment: 'master',

  friendlyCaptchaSiteKey: 'FCMG59TQ1HEJLM0F',

  googleTagManagerId: 'WJKMNPS',

  storeProtocol: 'http',
  storeIp: 'store.shopware.com',
  storePort: '80',
};
