import { BasketFacade, NavigationFacade, NotificationsFacade } from '@account/core/facades';
import { ModalService, ShoppingCartModalComponent } from '@account/shared/components';
import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ComponentOverlayService } from '../../../shared/components/component-overlay/component-overlay.service';
import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';
import {
  Basket,
  BasketPosition,
  BasketPositionVoucher,
  NotificationItem
} from '../../models';
import { LocalStorageService } from '../../services';
import { convertSbpDate } from '../../utils';
import { BreadcrumbsComponent } from '../breadcrumbs';
import { NotificationCenterComponent } from '../notification-center';
import { ActionBarService } from './action-bar.service';

@Component({
  selector: 'account-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrl: './action-bar.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, AsyncPipe, TranslateModule, BreadcrumbsComponent, SwIconComponent],
})
export class ActionBarComponent implements OnInit {
  @ViewChild('notificationWrap') container: ElementRef;

  notifications$: Observable<NotificationItem[]>;
  basket$: Observable<Basket | null>;
  showNotificationBubble = false;
  isExpanded: boolean;

  actionTemplateRef: null | TemplateRef<Element> = null;

  private notifications: NotificationItem[];

  constructor(
    private readonly navigationFacade: NavigationFacade,
    private readonly notificationsFacade: NotificationsFacade,
    private readonly router: Router,
    private readonly overlayService: ComponentOverlayService,
    private readonly overlay: Overlay,
    private readonly localStorageService: LocalStorageService,
    private readonly actionBarService: ActionBarService,
    private readonly basketFacade: BasketFacade,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.actionBarService.getActionBar().subscribe((actionBar: TemplateRef<Element>) => {
      this.actionTemplateRef = actionBar;
    });

    this.navigationFacade.isExpanded().subscribe((response) => {
      this.isExpanded = response;
    });

    this.notifications$ = timer(0, 600000).pipe(
      switchMap(() =>
        this.notificationsFacade.getNotifications().pipe(
          tap((notifications: NotificationItem[]) => {
            this.notifications = notifications;
            this.checkForNewNotifications();
          })
        )
      )
    );

    this.basket$ = this.basketFacade.getStoredBasket();
  }

  toggleNavigation(): void {
    this.navigationFacade.toggleNavigation();
  }

  goToPortal(): void {
    this.router.navigate(['/portal']);
  }

  openNotificationCenter(): void {
    const remainingNotificationsAfterDeletion$ = new Subject<NotificationItem[]>();

    const notificationCenterRef = this.overlayService.create(
      NotificationCenterComponent,
      {
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.container)
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
            },
          ]),
      },
      {
        notifications: this.notifications,
        onDelete: remainingNotificationsAfterDeletion$,
      }
    );

    remainingNotificationsAfterDeletion$.subscribe((items: NotificationItem[]) => {
      this.notifications = items;
      if (this.notifications.length === 0) {
        this.showNotificationBubble = false;
      }
    });

    notificationCenterRef.afterClosed().subscribe(() => {
      let lastOpened = new Date(+this.localStorageService.getItem('notification.last-opened'));

      this.notifications.forEach((notification: NotificationItem) => {
        const creationDate = convertSbpDate(notification.creationDate);
        if (creationDate > lastOpened) {
          lastOpened = creationDate;
        }
      });

      this.localStorageService.setItem('notification.last-opened', lastOpened.getTime().toString());
      this.checkForNewNotifications();
    });
  }

  openModal(): void {
    this.openNotificationCenter();
  }

  openBasketModal(): void {
    this.modalService.open(ShoppingCartModalComponent);
  }

  getBasketPositionsCount(basket: Basket): number {
    return basket.positions
      .filter((position: BasketPosition) => !this.instanceOfBasketPositionVoucher(position))
      .length;
  }

  private checkForNewNotifications(): void {
    const lastOpened = new Date(+this.localStorageService.getItem('notification.last-opened'));

    this.showNotificationBubble =
      this.notifications.filter((item: NotificationItem) => convertSbpDate(item.creationDate) > lastOpened).length > 0;
  }

  private instanceOfBasketPositionVoucher(position: BasketPosition): position is BasketPositionVoucher {
    return 'voucher' in position;
  }
}
