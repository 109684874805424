import { createSelector } from '@ngrx/store';

import { SbpException } from '../../../models';
import * as fromMeta from '../useraccount-meta.reducer';
import { InvitationsState } from './invitations.reducer';

const invitationsState = createSelector(fromMeta.state, (state: fromMeta.State): InvitationsState => state.invitations);

export const isLoading = createSelector(invitationsState, (state: InvitationsState) => state.loading);

export const isLoaded = createSelector(invitationsState, (state: InvitationsState) => state.loaded);

export const getError = createSelector(invitationsState, (state: InvitationsState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getInvitations = createSelector(invitationsState, (state: InvitationsState) => state.invitations);

export const isCheckCompleted = createSelector(invitationsState, (state: InvitationsState) => state.checkCompleted);
