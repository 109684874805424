import { companyActions, navigationActions } from '@account/core/store/actions';
import { companySelectors } from '@account/core/store/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';

import { ModalService } from '../../../shared/components/modal/modal.service';
import { CompaniesGateway } from '../../gateways/company/companies.gateway';
import {
  Company,
  CompanyAllocations,
  Gtc,
  Language,
  MasterData,
  Partner,
  SbpException,
  SupportTicketUpdates,
} from '../../models';
import { SupportPermissions } from '../../models/support/support.model';
import { LocalStorageService } from '../../services';
import { RootState } from '../../store/root.state';
import { SessionFacade } from '../app';
import { AuthFacade } from '../auth';
import { LanguagesFacade } from '../common';

@Injectable({
  providedIn: 'root',
})
export class CompaniesFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly authFacade: AuthFacade,
    private readonly languagesFacade: LanguagesFacade,
    private readonly sessionFacade: SessionFacade,
    private readonly companiesGateway: CompaniesGateway,
    private readonly localStorageService: LocalStorageService,
    private readonly modalService: ModalService
  ) {}

  /**
   * @summary only for usage where subscription must be alive throughout company change
   * @see getCompanyIdOnce if you want to get the companyId in a component
   */
  getCompanyId(): Observable<number> {
    return this.sessionFacade.getCompanyId().pipe(
      filter((companyId: number | undefined) => undefined !== companyId),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  getCompanyIdOnce(): Observable<number> {
    return this.getCompanyId().pipe(take(1));
  }

  hasCompany(): Observable<boolean> {
    return this.findCompany().pipe(map((company: Company | undefined) => !!company));
  }

  getCompany(): Observable<Company> {
    return this.findCompany().pipe(filter((company: Company) => !!company));
  }

  findCompany(): Observable<Company | undefined> {
    return this.waitUntilCompanyIsLoaded().pipe(
      switchMapTo(
        this.store.select(companySelectors.company.getError).pipe(
          switchMap((error: SbpException | undefined) => {
            if (error) {
              return throwError(() => error);
            }
            return this.store.select(companySelectors.company.getCompany);
          })
        )
      )
    );
  }

  getCurrentPartner(companyId: number): Observable<Partner> {
    return this.companiesGateway.getCurrentPartner(companyId);
  }

  passwordReset(key: string): Observable<any> {
    return this.companiesGateway.passwordReset(key);
  }

  getGtc(): Observable<Gtc> {
    return this.companiesGateway.getGtc().pipe(
      switchMap((gtcDocuments: Gtc[]) =>
        this.languagesFacade.getSelectedLanguage().pipe(
          map((language: Language) => {
            let gtcDocument = gtcDocuments.find((gtcDocument: Gtc) => gtcDocument.locale.startsWith(language.key));
            if (!gtcDocument) {
              gtcDocument = gtcDocuments.find((gtcDocument: Gtc) => gtcDocument.locale.startsWith('en'));
            }
            if (!gtcDocument && gtcDocuments.length === 1) {
              gtcDocument = gtcDocuments[0];
            }
            if (!gtcDocument) {
              throw new Error('No GTC document available');
            }

            return gtcDocument;
          })
        )
      )
    );
  }

  updateApprovedGtc(): Observable<boolean> {
    return this.getCompanyIdOnce().pipe(
      switchMap((companyId: number) =>
        this.companiesGateway.updateApprovedGtc(companyId).pipe(
          tap(() => this.store.dispatch(companyActions.signedGTC.getSignedGTC({ payload: companyId }))),
          switchMapTo(this.store.select(companySelectors.signedGTC.hasSignedLatestGTC)),
          filter((latestGTCSigned: boolean) => latestGTCSigned),
          tap(() => {
            this.authFacade.completeUILogin();
            this.store.dispatch(navigationActions.setNavigationForCompany());
          })
        )
      )
    );
  }

  getAllocations(): Observable<CompanyAllocations> {
    return this.store.select(companySelectors.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(
        this.store.select(companySelectors.allocations.getError).pipe(
          switchMap((error: SbpException | undefined) => {
            if (error) {
              return throwError(() => error);
            }
            return this.store.select(companySelectors.allocations.getAllocations);
          })
        )
      ),
      filter((allocations: CompanyAllocations) => !!allocations),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  refreshAllocations(): Observable<CompanyAllocations> {
    return this.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        this.store.dispatch(companyActions.allocations.refreshCompanyAllocations());
        this.store.dispatch(companyActions.allocations.getCompanyAllocations({ payload: companyId }));
        // wait until allocations have been updated
        return this.getAllocations();
      }),
      take(1)
    );
  }

  getAllocationsForCompany(companyId: number): Observable<CompanyAllocations> {
    return this.companiesGateway.getAllocations(companyId);
  }

  getInfoHasBusinessRelationSince(businessRelationName: string): Observable<string> {
    return this.getCompanyIdOnce().pipe(
      switchMap((currentCompanyId: number) =>
        this.companiesGateway.getInfoHasBusinessRelationSince(currentCompanyId, businessRelationName)
      )
    );
  }

  getMasterData(): Observable<MasterData> {
    return this.getCompany().pipe(
      map((company: Company) => company.masterData),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  updateMasterData(masterData: MasterData): Observable<MasterData> {
    return this.isMasterDataComplete().pipe(
      take(1),
      switchMap((masterDataComplete: boolean) =>
        this.companiesGateway.updateMasterData(masterData).pipe(
          switchMap((updatedMasterData: MasterData) => {
            if (!masterDataComplete && !updatedMasterData.masterDataMissing) {
              this.store.dispatch(companyActions.company.refreshCompany());
              this.store.dispatch(companyActions.company.getCompany({ payload: updatedMasterData.id }));
            } else {
              this.store.dispatch(companyActions.company.updateMasterData({ payload: updatedMasterData }));
            }
            return this.getMasterData();
          })
        )
      ),
      take(1)
    );
  }

  isMasterDataComplete(): Observable<boolean> {
    return this.getMasterData().pipe(map((masterData: MasterData) => !masterData.masterDataMissing));
  }

  hasSupportTicketUpdates(context?: 'shopOwner' | 'partner' | 'producer'): Observable<boolean> {
    return this.hasCompany().pipe(
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of(false);
        }
        return this.store.select(companySelectors.supportTicketUpdates.hasSupportTicketUpdates, { context: context });
      })
    );
  }

  getSupportTicketUpdates(): Observable<SupportTicketUpdates | undefined> {
    return this.hasCompany().pipe(
      switchMap((hasCompany) => {
        if (!hasCompany) {
          return of(null);
        }
        return this.store.select(companySelectors.supportTicketUpdates.isLoaded).pipe(
          filter((loaded: boolean) => loaded),
          switchMapTo(this.store.select(companySelectors.supportTicketUpdates.getSupportTicketUpdates))
        );
      })
    );
  }

  getSupportPermissions(): Observable<SupportPermissions> {
    return this.store.select(companySelectors.supportPermissions.isLoaded).pipe(
      switchMap((isLoaded: boolean) => {
        if (isLoaded) {
          return this.store.select(companySelectors.supportPermissions.getError).pipe(
            switchMap((error: SbpException | undefined) => {
              if (error) {
                return throwError(() => error);
              }
              return this.store.select(companySelectors.supportPermissions.getSupportPermissions);
            })
          );
        }
        return this.getCompanyIdOnce().pipe(
          tap((companyId: number) =>
            this.store.dispatch(companyActions.supportPermissions.getSupportPermissions({ payload: companyId }))
          ),
          switchMapTo(this.store.select(companySelectors.supportPermissions.isLoaded)),
          filter((loaded: boolean) => loaded),
          switchMapTo(this.getSupportPermissions())
        );
      })
    );
  }

  waitUntilCompanyIsLoaded(): Observable<boolean> {
    return this.store.select(companySelectors.isLoaded).pipe(filter((loaded: boolean) => loaded));
  }

  cancelPartnerRelation(companyId: number, partnerId: number): Observable<Partner> {
    return this.companiesGateway.cancelPartnerRelation(companyId, partnerId);
  }

  private canPricingTeaserOpen(force = false): Observable<boolean> {
    return this.hasCompany().pipe(
      filter((hasCompany: boolean) => hasCompany),
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of(false);
        }
        if (force) {
          return of(true);
        }

        return this.authFacade.isLatestGTCSigned().pipe(
          filter((gtcApproved: boolean) => gtcApproved),
          take(1),
          switchMapTo(this.getCompanyIdOnce()),
          switchMap((companyId: number) => {
            const hasTeaserBeenOpened = this.localStorageService.getItem(`pricingTeaserModalOpened_${companyId}`);
            if (hasTeaserBeenOpened) {
              return of(false);
            }
            return this.getAllocations().pipe(
              take(1),
              map((allocations: CompanyAllocations) => allocations.pricingTeaser[0].contentType !== null)
            );
          })
        );
      })
    );
  }
}
