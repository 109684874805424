import { LanguagesFacade, WikiFacade } from '@account/core/facades';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { formatDistanceToNow } from 'date-fns';
import * as locale from 'date-fns/locale';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Language } from '../../../../core/models/common/language.model';
import { WikiArticle } from '../../../../core/models/wiki/wiki.model';
import { convertSbpDate } from '../../../../core/utils/sbp-date-converter.utils';
import { SafePipe } from '../../../pipes';
import { ModalComponent } from '../../modal';
import { MODAL_DATA } from '../../modal/modal-injector';
import { ModalRef } from '../../modal/modal-ref';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';

@Component({
  selector: 'account-wiki-article',
  templateUrl: './wiki-article.component.html',
  styleUrl: './wiki-article.component.less',
  standalone: true,
  imports: [CommonModule, TranslateModule, SwIconComponent, SafePipe, ModalComponent],
})
export class WikiArticleComponent implements OnInit {
  loaded: boolean;
  article: WikiArticle;
  content: string;
  creationDate: string;
  initialLanguage: Language;
  selectedLanguage: Language;

  constructor(
    @Inject(MODAL_DATA) private readonly data: any,
    private readonly modalRef: ModalRef,
    private readonly wikiFacade: WikiFacade,
    private readonly languagesFacade: LanguagesFacade
  ) {}

  ngOnInit(): void {
    this.getArticle();
  }

  getArticle(): void {
    this.getTranslatedArticle().subscribe((article: WikiArticle) => {
      this.article = article;
      this.loaded = true;
      this.content = article.requestedLocalization.requestedVersion.content;
      this.rewriteRelativeUrls();

      this.creationDate = formatDistanceToNow(
        convertSbpDate(this.article.requestedLocalization.requestedVersion.lastChangeDate),
        { addSuffix: true, locale: this.initialLanguage.key === 'de' ? locale.de : locale.enUS }
      );
    });
  }

  getTranslatedArticle(): Observable<WikiArticle> {
    let url = this.data.url;
    if (this.data.url.indexOf('/') === 0) {
      url = url.slice(1);
    }

    if (this.selectedLanguage) {
      return this.wikiFacade.getArticle(url, this.selectedLanguage).pipe(take(1));
    }
    return this.languagesFacade.getSelectedLanguage().pipe(
      take(1),
      switchMap((selectedLanguage: Language) => {
        this.initialLanguage = selectedLanguage;
        this.selectedLanguage = selectedLanguage;
        return this.wikiFacade.getArticle(url, this.selectedLanguage).pipe(take(1)); // last take(1) should be redundand
      })
    );
  }

  rewriteRelativeUrls(): void {
    this.content = this.content.replace(
      /href="(?!http|mailto|tel.*$)(.*)"/gm,
      (m, $1) => `href="https://docs.shopware.com${$1}" target="_blank" rel="noreferrer"`
    );
  }

  close(): void {
    this.modalRef.close();
  }

  switchLanguage(): void {
    if (this.selectedLanguage.key === 'en') {
      this.selectedLanguage = { key: 'de' };
    } else {
      this.selectedLanguage = { key: 'en' };
    }
    this.getArticle();
  }

  getLocalizedSEOUrl(article: WikiArticle): string {
    return `https://docs.shopware.com/${this.selectedLanguage.key}${article.requestedLocalization.seoUrl}`;
  }
}
