import { createSelector } from '@ngrx/store';

import { RootState } from '../root.state';
import { NavigationState } from './navigation.state';

export const navigationState = (state: RootState): NavigationState => state.navigation;

export const getNavigation = createSelector(navigationState, (state: NavigationState) => state);

export const areWorkspacesLoaded = createSelector(getNavigation, (state: NavigationState) => state.workspacesLoaded);

export const getWorkspaces = createSelector(getNavigation, (state: NavigationState) => state.workspaces);

export const getActiveWorkspace = createSelector(getNavigation, (state: NavigationState) => state.activeWorkspace);

export const isActiveWorkspaceSelected = createSelector(
  getNavigation,
  (state: NavigationState) => state.activeWorkspaceSelected
);

export const getItemsByWorkspaceName = (name: string): any =>
  createSelector(getNavigation, (state: NavigationState) => (state as Record<string, any>)[name]);

export const getActiveWorkspaceItem = createSelector(
  getNavigation,
  (state: NavigationState) => state.activeWorkspaceItem
);

export const hasPartnerWorkspace = createSelector(getNavigation, (state: NavigationState) => !!state.partner);

export const hasProducerWorkspace = createSelector(getNavigation, (state: NavigationState) => !!state.producer);

export const hasShopOwnerWorkspace = createSelector(getNavigation, (state: NavigationState) => !!state.shopOwner);

export const hasAcademyWorkspace = createSelector(getNavigation, (state: NavigationState) => !!state.academy);

export const hasCompanyWorkspace = createSelector(getNavigation, (state: NavigationState) => !!state.company);

export const hasUserAccountWorkspace = createSelector(getNavigation, (state: NavigationState) => !!state.userAccount);
