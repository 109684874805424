import { createSelector } from '@ngrx/store';

import { SbpException } from '../../../models';
import { selectSupportPermissionsState } from '../partner-feature.selector';
import { SupportPermissionsState } from './support-permissions.state';

// const subState = createSelector(
//   featureSelector,
//   (state: PartnerFeatureState): SupportPermissionsState => state.supportPermissions
// );

export const selectLoading = createSelector(
  selectSupportPermissionsState,
  (state: SupportPermissionsState) => state.loading
);

export const selectLoaded = createSelector(
  selectSupportPermissionsState,
  (state: SupportPermissionsState) => state.loaded
);

export const selectError = createSelector(
  selectSupportPermissionsState,
  (state: SupportPermissionsState) => state.error
);

export const hasError = createSelector(selectError, (error: SbpException | undefined) => !!error);

export const selectSupportPermissions = createSelector(
  selectSupportPermissionsState,
  (state: SupportPermissionsState) => state.supportPermissions
);
