import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[contenteditable]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContentEditableDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class ContentEditableDirective implements ControlValueAccessor {
  @Input() elementsToRender: string[] = [];
  @HostBinding('style.cursor') styleCursor = 'text';
  @HostBinding('attr.contenteditable') contenteditable = true;

  private disabled = false;

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('blur', ['$event'])
  @HostListener('keyup', ['$event'])
  onEdit(): void {
    if (!this.disabled) {
      this.onValueChange();
    }
  }

  onValueChange(): void {
    if (this.onChange) {
      let text = this.elementRef.nativeElement.innerText;
      // remove iframes to prevent xss
      const patternIframe = /<iframe[^>]*(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
      text = text.replace(patternIframe, '');
      // remove inline javascript to prevent xss
      const patternInlineJavascript = /\bon\w+=\S+(?=.*>)/g;
      text = text.replace(patternInlineJavascript, '');
      this.onChange(text);
    }
  }

  writeValue(value: any): void {
    this.elementRef.nativeElement.innerText = value;
    if (value !== null) {
      if (this.elementsToRender.length === 0) {
        this.elementRef.nativeElement.innerText = value;
      }
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.contenteditable = !this.disabled;
  }

  private onChange = (_: any): void => {};
  private onTouched = (): void => {};
}
