import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { DiscountCampaignsGateway } from '../../gateways/plugins';
import {
  DiscountCampaign,
  DiscountCampaignCreateRequestData,
  DiscountCampaignGetPluginsDuplicatesRequestData,
  DiscountCampaignUpdateRequestData,
  ListResult,
  Producer,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { Plugin } from '../../models/plugin/plugin.model';
import { ProducersFacade } from './producers.facade';

@Injectable({
  providedIn: 'root',
})
export class DiscountCampaignsFacade {
  constructor(
    private readonly discountCampaignsGateway: DiscountCampaignsGateway,
    private readonly producersFacade: ProducersFacade
  ) {}

  addDiscountCampaign(
    discountCampaignCreateRequestData: DiscountCampaignCreateRequestData
  ): Observable<DiscountCampaign> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => {
        discountCampaignCreateRequestData.producerId = producer.id;
        return this.discountCampaignsGateway.addDiscountCampaign(discountCampaignCreateRequestData);
      })
    );
  }

  updateDiscountCampaign(discountCampaign: DiscountCampaignUpdateRequestData): Observable<DiscountCampaign> {
    return this.discountCampaignsGateway.updateDiscountCampaign(discountCampaign);
  }

  abortDiscountCampaign(discountCampaign: DiscountCampaign): Observable<DiscountCampaign> {
    return this.discountCampaignsGateway.abortDiscountCampaign(discountCampaign);
  }

  removeDiscountCampaign(discountCampaign: DiscountCampaign): Observable<any> {
    return this.discountCampaignsGateway.removeDiscountCampaign(discountCampaign);
  }

  getDiscountCampaignList(metaData: RequestMetaData): Observable<ListResult<DiscountCampaign[]>> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.discountCampaignsGateway.getDiscountCampaignList(producer, metaData))
    );
  }

  getDiscountCampaign(id: number): Observable<DiscountCampaign> {
    return this.discountCampaignsGateway.getDiscountCampaign(id);
  }

  getDiscountDuplicates(
    getPluginsDuplicatesRequestData: DiscountCampaignGetPluginsDuplicatesRequestData
  ): Observable<Plugin[]> {
    return this.discountCampaignsGateway.getDiscountDuplicates(getPluginsDuplicatesRequestData);
  }

  private getProducer(): Observable<Producer> {
    return this.producersFacade.getProducer().pipe(take(1));
  }
}
