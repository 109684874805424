import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { PluginBundlesGateway } from '../../gateways/plugins';
import { ListResult, PluginBundle, Producer } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ProducersFacade } from './producers.facade';

@Injectable({
  providedIn: 'root',
})
export class PluginBundlesFacade {
  constructor(
    private readonly pluginBundlesGateway: PluginBundlesGateway,
    private readonly producersFacade: ProducersFacade
  ) {}

  addBundle(bundle: PluginBundle): Observable<PluginBundle> {
    return this.pluginBundlesGateway.addBundle(bundle);
  }

  updateBundle(bundle: PluginBundle): Observable<PluginBundle> {
    return this.pluginBundlesGateway.updateBundle(bundle);
  }

  getBundle(bundleId: number): Observable<PluginBundle> {
    return this.pluginBundlesGateway.getBundle(bundleId);
  }

  getBundleList(metaData: RequestMetaData): Observable<ListResult<PluginBundle[]>> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.pluginBundlesGateway.getBundleList(metaData, producer.id))
    );
  }

  deleteBundle(bundleId: number): Observable<any> {
    return this.pluginBundlesGateway.deleteBundle(bundleId);
  }

  private getProducer(): Observable<Producer> {
    return this.producersFacade.getProducer().pipe(take(1));
  }
}
