import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PartnerFeatureState } from './partner-feature.state';

export const featureName = 'partner';

const selectFeature = createFeatureSelector<PartnerFeatureState>(featureName);

export const selectPartnerState = createSelector(selectFeature, (state: PartnerFeatureState) => state.partner);

export const selectSupportPermissionsState = createSelector(
  selectFeature,
  (state: PartnerFeatureState) => state.supportPermissions
);
