import { NgClass, NgIf } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

import { StatusComponent } from '../status/status.component';

type AlertType =
  | 'default'
  | 'loading'
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'gray_info'
  | 'gray_error'
  | 'gray_minus';

@Component({
  selector: 'account-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, NgIf, StatusComponent],
})
export class AlertComponent {
  /**
   * Defines if the alert shows a status icon.
   */
  @Input() showStatusIcon = true;

  /**
   * Defines the type of the alert that is displayed.
   * @type `AlertType`
   */
  @Input() type: AlertType = 'default';
}
