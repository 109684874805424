import { combineReducers, createFeature } from '@ngrx/store';

import { producerStoreConfig } from './producer';
import { featureName } from './producer-feature.selector';

export const feature = createFeature({
  name: featureName,
  // combine all reducers for feature
  reducer: combineReducers({
    producer: producerStoreConfig.reducer,
  }),
});
