const getTimeZone: (sbpDate: string) => string = (sbpDate: string) => {
  const offset = new Date(sbpDate).getTimezoneOffset();
  const o = Math.abs(offset);
  return `${(offset < 0 ? '+' : '-') + `00${Math.floor(o / 60)}`.slice(-2)}:${`00${o % 60}`.slice(-2)}`;
};

const isSafariBrowser: () => boolean = () =>
  (navigator.userAgent.includes('Safari') || navigator.userAgent.includes('Mobile Safari')) &&
  !navigator.userAgent.includes('Chrome');

const isInternetExplorer: () => boolean = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return true;
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    return true;
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    return true;
  }

  // other browser
  return false;
};

export const convertSbpDate: (sbpDate: string | Date) => Date = (sbpDate: string | Date): Date => {
  if (sbpDate instanceof Date) {
    return sbpDate;
  }
  const isSbpDateFormat = String(sbpDate).includes(' ');
  if (!isSbpDateFormat) {
    return new Date(sbpDate);
  }

  if (isSbpDateFormat && (isSafariBrowser() || isInternetExplorer())) {
    sbpDate = sbpDate.replace(/\s/g, 'T');
    if (sbpDate.length > 10 && !sbpDate.includes('+')) {
      sbpDate += getTimeZone(sbpDate);
    }
  }

  return new Date(sbpDate);
};
