import { createSelector } from '@ngrx/store';

import { Document, SbpException } from '../../../models';
import * as companyRoot from '../company-root.store';
import { SignedGTCState } from './signed-gtc.reducer';

const signedGTCState = createSelector(companyRoot.state, (state: companyRoot.State): SignedGTCState => state.signedGTC);

export const isLoading = createSelector(signedGTCState, (state: SignedGTCState) => state.loading);

export const isLoaded = createSelector(signedGTCState, (state: SignedGTCState) => state.loaded);

export const getError = createSelector(signedGTCState, (state: SignedGTCState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getGTCDocument = createSelector(signedGTCState, (state: SignedGTCState) => state.gtcDocument);

export const hasSignedLatestGTC = createSelector(getGTCDocument, (gtcDocument: Document) =>
  gtcDocument ? gtcDocument.isCurrentVersion : false
);
