import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  validatedDimensions(file: File, width: number, height: number): Observable<boolean> {
    const dimensionsValid$ = new Subject<boolean>();
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const image = new Image();
      image.addEventListener('load', () => {
        if (width !== image.width || height !== image.height) {
          dimensionsValid$.next(false);
        } else {
          dimensionsValid$.next(true);
        }
      });
      image.src = reader.result as string;
    });

    reader.readAsDataURL(file);

    return dimensionsValid$;
  }

  getBase64ImageFromFile(file: File): Observable<string> {
    const blob$ = new Subject<string>();
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      blob$.next(reader.result as string);
    });

    reader.readAsDataURL(file);

    return blob$;
  }
}
