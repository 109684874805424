import { Action, createReducer, on } from '@ngrx/store';

import { SupportTicketUpdates } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './support-ticket-updates.actions';

export interface SupportTicketUpdatesState extends AsyncState {
  supportTicketUpdates: SupportTicketUpdates;
}

const initialSupportTicketUpdatesState: SupportTicketUpdatesState = {
  loading: false,
  loaded: false,
  error: undefined,
  supportTicketUpdates: null,
};

const supportTicketUpdatesReducerInternal = createReducer(
  initialSupportTicketUpdatesState,

  on(actions.resetSupportTicketUpdate, () => ({
    ...initialSupportTicketUpdatesState,
  })),

  on(actions.getSupportTicketUpdates, actions.refreshSupportTicketUpdate, (state: SupportTicketUpdatesState) => ({
    ...state,
    loading: true,
  })),

  on(
    actions.getSupportTicketUpdatesSuccess,
    actions.getSupportTicketUpdatesFail,
    (state: SupportTicketUpdatesState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.getSupportTicketUpdatesSuccess, (state: SupportTicketUpdatesState, { payload }) => ({
    ...state,
    supportTicketUpdates: payload,
  })),

  on(actions.getSupportTicketUpdatesFail, (state: SupportTicketUpdatesState, { payload }) => ({
    ...state,
    error: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: SupportTicketUpdatesState | undefined, action: Action): SupportTicketUpdatesState {
  return supportTicketUpdatesReducerInternal(state, action);
}
