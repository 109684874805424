<div class="rating" [style.gap]="gap" (mouseleave)="resetHovered()" [class.readonly]="readonly || disabled">
  <a
    *ngFor="let star of starsArray; let i = index"
    [attr.tabindex]="disabled ? null : '0'"
    (mouseenter)="setHovered(star)"
    (click)="rate($event, i)"
    (keydown.enter)="rate($event, i)">
    <div class="star-wrapper">
      <account-star
        [size]="size"
        [filled]="!((!value || i >= value) && (null === hovered || i > hovered))"
        [disabled]="readonly || disabled"></account-star>
    </div>
  </a>
</div>
