import { provideEffects } from '@ngrx/effects';

import { StoreConfig } from '../../store-config.model';
import { SupportPermissionsEffects } from './support-permissions.effects';
import { reducer } from './support-permissions.reducer';
import { SupportPermissionsState } from './support-permissions.state';

export { actions as supportPermissionsActions } from './support-permissions.actions';
export * as supportPermissionsSelectors from './support-permissions.selectors';
export { SupportPermissionsState } from './support-permissions.state';

const providers = [provideEffects(SupportPermissionsEffects)];

export const supportPermissionsStoreConfig: StoreConfig<SupportPermissionsState> = {
  reducer: reducer,
  providers: providers,
};
