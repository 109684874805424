import { createSelector } from '@ngrx/store';

import { SbpException } from '../../../models';
import * as companyRoot from '../company-root.store';
import { CompanySupportPermissionsState } from './support-permissions.reducer';

const supportPermissionsState = createSelector(
  companyRoot.state,
  (state: companyRoot.State): CompanySupportPermissionsState => state.supportPermissions
);

export const isLoading = createSelector(
  supportPermissionsState,
  (state: CompanySupportPermissionsState) => state.loading
);

export const isLoaded = createSelector(
  supportPermissionsState,
  (state: CompanySupportPermissionsState) => state.loaded
);

export const getError = createSelector(supportPermissionsState, (state: CompanySupportPermissionsState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getSupportPermissions = createSelector(
  supportPermissionsState,
  (state: CompanySupportPermissionsState) => state.supportPermissions
);
