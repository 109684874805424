// representates a semantic version
export class SemVer {
  constructor(
    private readonly generation: number,
    private readonly major: number,
    private readonly minor: number,
    private readonly patch: number,
    private readonly extra = ''
  ) {}

  isEqual(other: SemVer): boolean {
    return this.isVersionEqual(other);
  }

  isOnlyEarlyAccessDifferent(other: SemVer): boolean {
    return this.isVersionEqual(other) && this.extra.startsWith('EA');
  }

  inc(type: string): SemVer {
    let major = this.major;
    let minor = this.minor;
    let patch = this.patch;
    const extra = this.extra;
    switch (type) {
      case 'major':
        ++major;
        break;
      case 'minor':
        ++minor;
        break;
      case 'patch':
        ++patch;
        break;
      // skip default
    }
    return new SemVer(this.generation, major, minor, patch, extra);
  }

  toString(): string {
    let extra = '';
    if (this.extra) {
      extra = `-${this.extra}`;
    }
    const version = `${this.major}.${this.minor}.${this.patch}${extra}`;

    return -1 !== this.generation ? `${this.generation}.${version}` : version;
  }

  private isVersionEqual(other: SemVer): boolean {
    return (
      this.generation === other.generation &&
      this.major === other.major &&
      this.minor === other.minor &&
      this.patch === other.patch
    );
  }
}
