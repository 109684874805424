import { Action, createReducer, on } from '@ngrx/store';

import { SupportPermissions } from '../../../models/support/support.model';
import { AsyncState } from '../../async.state';
import * as actions from './support-permissions.actions';

export interface CompanySupportPermissionsState extends AsyncState {
  supportPermissions: SupportPermissions | undefined;
}

const initialSupportPermissionsState: CompanySupportPermissionsState = {
  loading: false,
  loaded: false,
  error: undefined,
  supportPermissions: undefined,
};

const companySupportPermissionsReducerInternal = createReducer(
  initialSupportPermissionsState,

  on(actions.getSupportPermissions, (state: CompanySupportPermissionsState) => ({
    ...state,
    loading: true,
  })),

  on(
    actions.getSupportPermissionsSuccess,
    actions.getSupportPermissionsFail,
    (state: CompanySupportPermissionsState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.getSupportPermissionsSuccess, (state: CompanySupportPermissionsState, { payload }) => ({
    ...state,
    supportPermissions: payload,
    error: undefined,
  })),

  on(actions.getSupportPermissionsFail, (state: CompanySupportPermissionsState, { payload }) => ({
    ...state,
    error: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(
  state: CompanySupportPermissionsState | undefined,
  action: Action
): CompanySupportPermissionsState {
  return companySupportPermissionsReducerInternal(state, action);
}
