<label>
  <ng-container *ngIf="label && 'before' === labelPosition">
    <span *ngIf="!labelAsHTML" class="checkbox-label before">{{ label }}</span>
    <span *ngIf="labelAsHTML" class="checkbox-label before" [innerHTML]="label"></span>
  </ng-container>
  <span class="checkbox-wrapper" [class.focused]="focused">
    <input
      type="checkbox"
      [checked]="checked"
      [disabled]="disabled"
      [indeterminate]="indeterminate"
      (change)="onChange($event)"
      (focus)="toggleFocus()"
      (focusout)="toggleFocus()" />
    <i class="input-helper"></i>
  </span>
  <ng-container *ngIf="label && 'after' === labelPosition">
    <span *ngIf="!labelAsHTML" class="checkbox-label after">{{ label }}</span>
    <span *ngIf="labelAsHTML" class="checkbox-label after" [innerHTML]="label"></span>
  </ng-container>
</label>
