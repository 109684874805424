import { Locale } from '@account/core/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'account-locale-switch',
  templateUrl: './locale-switch.component.html',
  styleUrl: './locale-switch.component.less',
  standalone: true,
  imports: [TranslateModule],
})
export class LocaleSwitchComponent {
  @Input() locale: Locale = <Locale>{ name: 'de_DE' };
  @Output() readonly onChangeLocale = new EventEmitter<Locale>();

  onClick(event: any): void {
    // if the flag-image was clicked instead of the language-element, get the parent object
    const isocode = event.target.classList.contains('locale')
      ? event.target.dataset.isocode
      : event.target.parentNode.dataset.isocode;

    if (isocode && this.locale.name !== isocode) {
      this.locale.name = isocode;
      this.onChangeLocale.emit(this.locale);
    }
  }
}
