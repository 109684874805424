import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerDescription',
  standalone: true,
})
export class PartnerDescriptionPipe implements PipeTransform {
  transform(value: string, limit: number = null): string {
    const template = document.createElement('DIV');
    template.innerHTML = value;

    if (limit < template.textContent.length) {
      return `${template.textContent.slice(0, limit).trim()}...`;
    }

    return template.textContent.trim();
  }
}
