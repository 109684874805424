import { provideState } from '@ngrx/store';

import { producerActions, producerSelectors, producerStoreConfig } from './producer';
import { feature } from './producer-feature.feature';

export { ProducerFeatureState } from './producer-feature.state';

// provide access to typed actions and selectors through index file
export const producerStore = {
  actions: {
    producer: producerActions,
  },
  selectors: {
    producer: producerSelectors,
  },
};

export const provideProducerFeature = [provideState(feature), ...producerStoreConfig.providers];
