import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'wizard-intro',
  templateUrl: './intro.component.html',
  styleUrl: './intro.component.less',
  imports: [CommonModule],
  standalone: true,
})
export class IntroComponent {
  @Output() readonly onStarted = new EventEmitter<void>();

  @HostBinding('hidden') hidden = false;

  private _active = false;

  @Input() set onStart(start: boolean) {
    if (start) {
      this._active = false;
      this.onStarted.emit();
    }
  }

  set active(active: boolean) {
    this._active = active;
    this.hidden = !active;
  }

  get active(): boolean {
    return this._active;
  }
}
