import { Action, createReducer, on } from '@ngrx/store';

import { Company, SbpException } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './company.actions';

export interface CompanyState extends AsyncState {
  company?: Company;
}

const initialCompanyState: CompanyState = {
  loading: false,
  loaded: false,
  error: undefined,
  company: undefined,
};

const companyReducerInternal = createReducer(
  initialCompanyState,

  on(actions.getCompany, (state: CompanyState) => ({
    ...state,
    loading: true,
  })),

  on(actions.noCompanyExists, (state: CompanyState) => {
    const noError: undefined | SbpException = undefined;
    const noCompany: undefined | Company = undefined;
    return {
      ...state,
      loaded: true,
      loading: false,
      error: noError,
      company: noCompany,
    };
  }),

  on(actions.refreshCompany, (state: CompanyState) => {
    const noError: undefined | SbpException = undefined;
    return {
      ...state,
      loaded: false,
      error: noError,
    };
  }),

  on(actions.updateMasterData, (state: CompanyState, { payload }) => ({
    ...state,
    company: {
      ...state.company,
      masterData: payload,
    },
  })),

  on(actions.getCompanySuccess, actions.getCompanyFail, (state: CompanyState) => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  on(actions.getCompanySuccess, (state: CompanyState, { payload }) => ({
    ...state,
    company: payload,
    error: undefined,
  })),

  on(actions.getCompanyFail, (state: CompanyState, { payload }) => ({
    ...state,
    error: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: CompanyState | undefined, action: Action): CompanyState {
  return companyReducerInternal(state, action);
}
