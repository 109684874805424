<div class="incremental-select" [class.is_disabled]="disabled">
  <div
    *ngFor="let option of options; let i = index"
    class="incremental-select__option"
    [class.is_before]="i < selectedIndex"
    [class.is_after]="i > selectedIndex"
    [class.is_selected]="i === selectedIndex">
    <div class="incremental-select__selectable" (click)="onChange(true, i)">
      <div class="incremental-select__input">
        <input
          type="radio"
          [name]="inputName"
          [disabled]="disabled"
          [ngValue]="option"
          [checked]="selectedIndex === i"
          (change)="onChange($event.target.checked, i)" />
      </div>
      <div class="incremental-select__label">
        <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: option }"></ng-template>
      </div>
    </div>
  </div>
</div>
