import { Component, Input } from '@angular/core';

@Component({
  selector: 'account-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrl: './ribbon.component.less',
  standalone: true,
})
export class RibbonComponent {
  @Input() text: string;
}
