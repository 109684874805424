import { NgIf } from '@angular/common';
import { Component, ContentChild, Directive, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Shop } from '../../../core/models/shop/shop.model';
import { LocaleCurrencyPipe } from '../../pipes/locale-number.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { OverflowTooltipComponent } from '../overflow-tooltip/overflow-tooltip.component';

@Directive({
  selector: 'cart-info',
  standalone: true,
})
export class CartInfoDirective {}

@Directive({
  selector: 'cart-footer',
  standalone: true,
})
export class CartFooterDirective {}

@Component({
  selector: 'account-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrl: './shopping-cart.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CheckboxComponent, NgIf, OverflowTooltipComponent, TranslateModule, LocaleCurrencyPipe, SafePipe],
})
export class ShoppingCartComponent {
  @Input() accountName: string;
  @Input() accountBalance: number;
  @Input() bookingShop: Shop;
  @Input() outstandingBalance = 0;
  @Input() disabled = false;

  @Output() readonly onClose = new EventEmitter<void>();

  @ContentChild(CartFooterDirective) cartFooter: CartFooterDirective;

  gtcChecked = false;

  toggleGtc(checked: boolean): void {
    this.gtcChecked = checked;
  }
}
