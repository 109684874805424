<div class="entry--group group-version">Shopware Account v{{ VERSION }}</div>

<div *ngIf="activeWorkspace?.hasChangelog" class="entry--group">
  <div class="menu--entry" (click)="showChangelog()">
    {{ 'CHANGELOG.HEADING' | translate: { workspace: '' } }}
  </div>
</div>

<div class="entry--group group--links">
  <ng-template
    [ngTemplateOutlet]="groupLink"
    [ngTemplateOutletContext]="{
      label: 'NAVIGATION.LINKS.STORE.TITLE' | translate,
      link: 'NAVIGATION.LINKS.STORE.LINK' | translate,
    }"></ng-template>
  <ng-template
    [ngTemplateOutlet]="groupLink"
    [ngTemplateOutletContext]="{
      label: 'NAVIGATION.LINKS.WEBSITE.TITLE' | translate,
      link: 'NAVIGATION.LINKS.WEBSITE.LINK' | translate,
    }"></ng-template>
  <ng-template
    [ngTemplateOutlet]="groupLink"
    [ngTemplateOutletContext]="{
      label: 'NAVIGATION.LINKS.WIKI.TITLE' | translate,
      link: 'NAVIGATION.LINKS.WIKI.LINK' | translate,
    }"></ng-template>

  <ng-template let-label="label" let-link="link" #groupLink>
    <a class="menu--entry" [href]="link" target="_blank" rel="noreferrer">
      <img src="/assets/images/shared/shopware-basic.svg" [alt]="label" />
      &nbsp;{{ label }}
    </a>
  </ng-template>
</div>

<div class="entry--group">
  <div class="menu--entry">
    <account-dropdown class="button--lang" [hasArrow]="true">
      <dropdown-active *ngIf="selectedLanguage">
        <ng-template
          [ngTemplateOutlet]="localeFlag"
          [ngTemplateOutletContext]="{ $implicit: selectedLanguage }"></ng-template>
      </dropdown-active>

      <dropdown-entry *ngFor="let language of languages; trackBy: trackByLanguage" [link]="true">
        <a (click)="changeLanguage(language)">
          <ng-template
            [ngTemplateOutlet]="localeFlag"
            [ngTemplateOutletContext]="{ $implicit: language }"></ng-template>
        </a>
      </dropdown-entry>

      <ng-template let-language #localeFlag>
        <img
          class="lang--flag"
          [alt]="language.key"
          [src]="'/assets/images/flags/' + (language.key === 'de' ? 'de_DE.svg' : 'en_GB.svg')" />
        <span class="lang--name">{{ 'LANGUAGE.' + language.key | translate }}</span>
      </ng-template>
    </account-dropdown>
  </div>
</div>

<div class="entry--group">
  <div class="menu--entry entry--separator entry--logout" (click)="logout()">
    <account-sw-icon icon="sign-out" size="14px"></account-sw-icon>
    {{ 'NAVIGATION.LOGOUT' | translate }}
  </div>
</div>
