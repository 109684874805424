import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Toast, ToastEvent } from './toast.model';

@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {
  private static readonly DEFAULT_TIMEOUT = 5000;

  private readonly eventSource: Subject<ToastEvent> = new Subject<ToastEvent>();
  private counter = 0;
  private clearOnNavigationSubscription$: Subscription;

  constructor(private readonly router: Router) {}

  get events$(): Observable<ToastEvent> {
    return this.eventSource.asObservable();
  }

  clearToast(id: number): void {
    this.emitEvent({
      type: 'clear',
      value: id,
    });
  }

  clearPermanentToasts(): void {
    this.emitEvent({
      type: 'clear_permanent',
    });
  }

  success(title: string, message?: string, htmlContent = false, timeout: number = ToastService.DEFAULT_TIMEOUT): void {
    this.add({
      type: 'success',
      title: title,
      message: message,
      timeout: timeout,
      htmlContent: htmlContent,
    });
  }

  error(title: string, message?: string, htmlContent = false): void {
    this.add({
      type: 'error',
      title: title,
      message: message,
      htmlContent: htmlContent,
    });
  }

  info(title: string, message?: string, htmlContent = false): void {
    this.add({
      type: 'info',
      title: title,
      message: message,
      htmlContent: htmlContent,
    });
  }

  enableClearOnNavigation(): void {
    if (this.clearOnNavigationSubscription$) {
      return;
    }
    this.clearOnNavigationSubscription$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.clearPermanentToasts();
      });
  }

  ngOnDestroy(): void {
    if (this.clearOnNavigationSubscription$) {
      this.clearOnNavigationSubscription$.unsubscribe();
    }
  }

  private add(newToast: Toast): void {
    this.counter++;
    const toast: Toast = {
      id: this.counter,
      title: newToast.title,
      message: newToast.message,
      type: newToast.type,
      timeout: newToast.timeout,
      htmlContent: newToast.htmlContent,
    };
    this.emitEvent({
      type: 'add',
      value: toast,
    });
  }

  private emitEvent(event: ToastEvent): void {
    if (this.eventSource) {
      this.eventSource.next(event);
    }
  }
}
