import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PartnerCustomersGateway } from '../../gateways/partner';
import {
  CloudShop,
  Company,
  CompanyToCreate,
  CustomerUserAccountRegistration,
  ListResult,
  OnPremiseShop,
  PackagesToken,
  PartnerCustomer,
  PartnerCustomerMembershipStatus,
  PluginLicense,
  ProductAcceleratorLicense,
  ProductLicense,
  QueryFilter,
  QueryFilterGroup,
  Shop,
  SubshopLicense,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { HttpParamsBuilder } from '../../utils';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerCustomersFacade {
  constructor(
    private readonly partnersFacade: PartnersFacade,
    private readonly partnerCustomersGateway: PartnerCustomersGateway
  ) {}

  getPartnerCustomersList(
    metaData: RequestMetaData,
    filter?: Record<string, any>
  ): Observable<ListResult<PartnerCustomer[]>> {
    return this.partnersFacade.getPartnerIdOnce().pipe(
      switchMap((partnerId: number) =>
        this.partnerCustomersGateway.getPartnerCustomersList(partnerId, metaData, filter)
      ),
      map((partnerCustomerList: ListResult<PartnerCustomer[]>) => partnerCustomerList)
    );
  }

  getCustomerListForSupport(filter?: Record<string, any>): Observable<Pick<Company, 'id' | 'name'>[]> {
    if (!filter) {
      filter = { supportRequest: true };
    } else {
      filter = {
        ...filter,
        supportRequest: true,
      };
    }
    return this.getPartnerCustomersList(HttpParamsBuilder.createMetaData(0, 0, 'name', 'asc'), filter).pipe(
      map((partnerCustomerList: ListResult<PartnerCustomer[]>) =>
        partnerCustomerList.list.map((partnerCustomer: PartnerCustomer) => partnerCustomer.customer)
      )
    );
  }

  getCustomerListForShopManagement(): Observable<Pick<Company, 'id' | 'name'>[]> {
    const filter = { managementAction: true };
    return this.getPartnerCustomersList(HttpParamsBuilder.createMetaData(0, 0, 'name', 'asc'), filter).pipe(
      map((partnerCustomerList: ListResult<PartnerCustomer[]>) =>
        partnerCustomerList.list.map((partnerCustomer: PartnerCustomer) => partnerCustomer.customer)
      )
    );
  }

  inviteAdminUserToCustomer(
    customerId: number,
    registrationData: CustomerUserAccountRegistration
  ): Observable<PartnerCustomer> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.inviteAdminUserToCustomer(partnerId, customerId, registrationData)
        )
      );
  }

  getPartnerCustomer(customerId: number): Observable<PartnerCustomer> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.partnerCustomersGateway.getPartnerCustomer(partnerId, customerId)));
  }

  getPartnerCustomerOnPremiseShops(
    customerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<OnPremiseShop[]>> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerOnPremiseShops(partnerId, customerId, metaData, filter)
        )
      );
  }

  getPartnerCustomerShops(
    customerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<Shop[]>> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerShops(partnerId, customerId, metaData, filter)
        )
      );
  }

  getPartnerCustomerShopsForSupport(
    customerId: number,
    metaData: RequestMetaData
  ): Observable<Pick<OnPremiseShop, 'id' | 'domain'>[]> {
    const filter = { supportRequest: true };
    return this.getPartnerCustomerOnPremiseShops(customerId, metaData, filter).pipe(
      map((partnerCustomerList: ListResult<OnPremiseShop[]>) => partnerCustomerList.list)
    );
  }

  getPartnerCustomerShopsForShopManagement(
    customerId: number,
    metaData: RequestMetaData
  ): Observable<Pick<OnPremiseShop, 'id' | 'domain'>[]> {
    const filter = { managementAction: true };
    return this.getPartnerCustomerOnPremiseShops(customerId, metaData, filter).pipe(
      map((partnerCustomerList: ListResult<OnPremiseShop[]>) => partnerCustomerList.list)
    );
  }

  getPartnerCustomerShopsForPluginMigration(
    customerId: number,
    filter: Record<string, any> = {}
  ): Observable<Pick<OnPremiseShop, 'id' | 'domain'>[]> {
    const filterObject = {
      ...filter,
      pluginMigration: true,
    };
    const metaData = HttpParamsBuilder.createMetaData(0, 0, 'domain', 'asc');
    HttpParamsBuilder.applyFilters(new HttpParams(), filterObject);

    return this.getPartnerCustomerOnPremiseShops(customerId, metaData, filterObject).pipe(
      map((partnerCustomerList: ListResult<OnPremiseShop[]>) => partnerCustomerList.list)
    );
  }

  getPartnerCustomerCloudShops(customerId: number, filter: Record<string, any> = {}): Observable<CloudShop[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerCloudShops(partnerId, customerId, filter)
        )
      );
  }

  getPartnerCustomerOnPremiseShop(customerId: number, shopId: number): Observable<OnPremiseShop> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerOnPremiseShop(partnerId, customerId, shopId)
        )
      );
  }

  getPartnerCustomerShopPluginLicense(
    customerId: number,
    shopId: number,
    licenseId: number
  ): Observable<PluginLicense> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerShopPluginLicense(partnerId, customerId, shopId, licenseId)
        )
      );
  }

  getPartnerCustomerShopPluginLicensesList(
    shop: Shop,
    metaData: RequestMetaData,
    filter: Record<string, any> | QueryFilter[] | QueryFilterGroup[] = null
  ): Observable<PluginLicense[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerShopPluginLicensesList(
            partnerId,
            shop.companyId,
            shop.id,
            metaData,
            filter
          )
        )
      );
  }

  getPartnerCustomerSubshopPluginLicensesList(
    shop: OnPremiseShop,
    requestMetaData: RequestMetaData
  ): Observable<SubshopLicense[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerSubshopPluginLicensesList(
            partnerId,
            shop.companyId,
            shop.id,
            requestMetaData
          )
        )
      );
  }

  getProductAcceleratorLicenses(shop: OnPremiseShop): Observable<ProductAcceleratorLicense[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerProductAcceleratorLicenses(partnerId, shop.companyId, shop.id)
        )
      );
  }

  getPartnerCustomerShopPluginLicensePublishedBinaryVersions(
    customerId: number,
    shopId: number,
    licenseId: number,
    pluginId: number
  ): Observable<string[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerShopPluginLicensePublishedBinaryVersions(
            partnerId,
            customerId,
            shopId,
            licenseId,
            pluginId
          )
        )
      );
  }

  getPartnerCustomerShopProductLicense(customerId: number, shopId: number): Observable<ProductLicense> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerShopProductLicense(partnerId, customerId, shopId)
        )
      );
  }

  getPartnerCustomerShopAcceleratorLicense(
    customerId: number,
    shopId: number,
    acceleratorId: number
  ): Observable<ProductAcceleratorLicense> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPartnerCustomerShopAcceleratorLicense(
            partnerId,
            customerId,
            shopId,
            acceleratorId
          )
        )
      );
  }

  addCustomerForPartner(customer: CompanyToCreate): Observable<PartnerCustomer> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.partnerCustomersGateway.addCustomerForPartner(partnerId, customer)));
  }

  getCustomerMembershipStatus(customerId: number): Observable<PartnerCustomerMembershipStatus> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getCustomerMembershipStatus(partnerId, customerId)
        )
      );
  }

  getPackagesComposerToken(customerId: number, shopId: number): Observable<PackagesToken> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.getPackagesComposerToken(partnerId, customerId, shopId)
        )
      );
  }

  generatePackagesComposerToken(customerId: number, shopId: number): Observable<PackagesToken> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.generatePackagesComposerToken(partnerId, customerId, shopId)
        )
      );
  }

  verifyPackagesComposerToken(customerId: number, shopId: number, token: string): Observable<void> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.verifyPackagesComposerToken(partnerId, customerId, shopId, token)
        )
      );
  }

  revokePackagesComposerToken(customerId: number, shopId: number, token: string): Observable<void> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCustomersGateway.revokePackagesComposerToken(partnerId, customerId, shopId, token)
        )
      );
  }
}
