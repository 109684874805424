import { Action, createReducer, on } from '@ngrx/store';

import { AsyncState } from '../async.state';
import * as sessionActions from './session.actions';

export interface SessionState extends AsyncState {
  loaded: boolean;
  logoutInProgress: boolean;
  companyId: number | undefined;
  partnerId: number | undefined;
  producerId: number | undefined;
  userAccountId: number | undefined;
  bypassGTCConfirmation: boolean;
}

const initialReducerState: SessionState = {
  loading: false,
  loaded: false,
  logoutInProgress: false,
  companyId: undefined,
  partnerId: undefined,
  producerId: undefined,
  userAccountId: undefined,
  bypassGTCConfirmation: false,
  error: undefined,
};

const sessionReducerInternal = createReducer(
  initialReducerState,

  on(
    sessionActions.loginWithCredentials,
    sessionActions.loginWithToken,
    sessionActions.loginWithOryJwtSessionToken,
    sessionActions.logout,
    (state: SessionState) => ({
      ...state,
      loading: true,
      loaded: false,
      error: undefined as undefined,
    })
  ),

  on(
    sessionActions.loginWithCredentialsSuccess,
    sessionActions.loginWithTokenSuccess,
    sessionActions.loginWithOryJwtSessionTokenSuccess,
    (state: SessionState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(sessionActions.loginWithCredentialsSuccess, (state: SessionState, { payload }) => ({
    ...state,
    userAccountId: payload.userAccountId,
    error: undefined,
  })),

  on(sessionActions.loginWithTokenSuccess, (state: SessionState, { payload }) => ({
    ...state,
    userAccountId: payload.userAccountId,
    error: undefined,
  })),

  on(sessionActions.loginWithOryJwtSessionTokenSuccess, (state: SessionState, { payload }) => ({
    ...state,
    userAccountId: payload.userAccountId,
    error: undefined,
  })),

  on(sessionActions.logout, (state: SessionState) => ({
    ...state,
    logoutInProgress: true,
  })),

  on(sessionActions.logoutSuccess, (state: SessionState) => ({
    ...state,
    logoutInProgress: false,
    companyId: undefined as undefined,
    userAccountId: undefined as undefined,
    error: undefined as undefined,
  })),

  on(sessionActions.loginFail, (state: SessionState, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload,
  })),

  on(sessionActions.logoutFail, (state: SessionState, { payload }) => ({
    ...state,
    logoutInProgress: false,
    error: payload,
  })),

  on(sessionActions.setCompanyId, (state: SessionState, { payload }) => ({
    ...state,
    companyId: payload,
  })),

  on(sessionActions.setPartnerId, (state: SessionState, { payload }) => ({
    ...state,
    partnerId: payload,
  })),

  on(sessionActions.setProducerId, (state: SessionState, { payload }) => ({
    ...state,
    producerId: payload,
  })),

  on(sessionActions.resetCompanyAttributes, (state: SessionState) => ({
    ...state,
    companyId: undefined as undefined,
    partnerId: undefined as undefined,
    producerId: undefined as undefined,
  })),

  on(sessionActions.setBypassGTCConfirmation, (state: SessionState, { payload }) => ({
    ...state,
    bypassGTCConfirmation: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: SessionState | undefined, action: Action): SessionState {
  return sessionReducerInternal(state, action);
}
