import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class HtmlValidators {
  static noBase64Image(control: AbstractControl): ValidationErrors | null {
    const hasBase64Image = /(data:(image\/[+A-Za-z]*)?(;charset=utf-8)?;base64,[^"]*)/.test(control.value);
    if (hasBase64Image) {
      return {
        base64ImageFound: true,
      };
    }

    return null;
  }

  static noHttpsSource(control: AbstractControl): ValidationErrors | null {
    const hasHttpSrc = /(src(set)?="http?:)|(url\(["']http?:\/\/)/.test(control.value);
    if (hasHttpSrc) {
      return {
        noHttpsSource: true,
      };
    }

    return null;
  }

  static noInvalidHtmlTags(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const hasValidHtmlTags = /<(?!\/?(a|b|i|em|strong|ul|ol|li|p|br|h2|h3|h4|hr)\b)[^>]+>/gm.test(control.value);
    if (hasValidHtmlTags) {
      return {
        noInvalidHtmlTags: true,
      };
    }

    return null;
  }

  static minLengthWithoutHtml =
    (minLength: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const dummyNode = document.createElement('div');
      dummyNode.innerHTML = control.value;

      const resultText = dummyNode.innerText || dummyNode.textContent;

      const length: number = resultText ? resultText.trim().length : 0;
      return length < minLength ? { minLength: { requiredLength: minLength, actualLength: length } } : null;
    };
}
