export class ObjectComparators {
  static compareById(o1: { id: number }, o2: { id: number }): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  static compareByName(o1: { name: string }, o2: { name: string }): boolean {
    return o1 && o2 ? o1.name === o2.name : o1 === o2;
  }

  static compareByProperty(
    propertyName: string,
    o1: { [key: string]: string },
    o2: { [key: string]: string }
  ): boolean {
    return o1 && o2 ? o1[propertyName] === o2[propertyName] : o1 === o2;
  }
}
