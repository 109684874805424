import { Action, createReducer, on } from '@ngrx/store';

import { CompanyMembershipRole, CompanyMembershipRolePermission } from '../../../models/company/membership.model';
import * as actions from './permissions.actions';

export interface PermissionsState {
  permissions: CompanyMembershipRolePermission[];
}

export const initialPermissionsState: PermissionsState = {
  permissions: [],
};

const permissionsReducerInternal = createReducer(
  initialPermissionsState,

  on(actions.setPermissions, (state: PermissionsState, { payload }) => ({
    ...state,
    permissions: [].concat(...payload.roles.map((role: CompanyMembershipRole) => role.permissions)),
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: PermissionsState | undefined, action: Action): PermissionsState {
  return permissionsReducerInternal(state, action);
}
