import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { CompanyMembershipGateway } from '../../gateways/company/company-membership.gateway';
import {
  CompanyAllocations,
  CompanyMembership,
  CompanyMembershipInvitation,
  CompanyMembershipRole,
  CompanyMembershipRolePermission,
  CompanyMembershipRoleWithMembers,
  ListResult,
  PartialCompanyMembershipInvitation,
  QueryFilter,
  QueryFilterGroup,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { RootState } from '../../store/root.state';
import { CompaniesFacade } from './companies.facade';

@Injectable({
  providedIn: 'root',
})
export class CompanyMembershipFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly companyMembershipGateway: CompanyMembershipGateway,
    private readonly companiesFacade: CompaniesFacade
  ) {}

  getAclPermissions(allocations?: CompanyAllocations): Observable<CompanyMembershipRolePermission[]> {
    return this.companiesFacade.hasCompany().pipe(
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of([]);
        }

        return this.companyMembershipGateway.getAclPermissions().pipe(
          mergeMap((permissions: CompanyMembershipRolePermission[]) => {
            const allocations$ = allocations ? of(allocations) : this.companiesFacade.getAllocations();
            return allocations$.pipe(
              map((allocations: CompanyAllocations) =>
                permissions.filter((permission: CompanyMembershipRolePermission) => {
                  switch (permission.context) {
                    case 'shopOwner':
                      return true;
                    case 'partner':
                      return allocations.isPartner;
                    case 'pluginProducer':
                      return allocations.isProducer;
                    case 'account':
                      return true;
                    default:
                      return true;
                  }
                })
              )
            );
          })
        );
      })
    );
  }

  getRoles(
    metaData: RequestMetaData,
    filter?: Record<string, any> | QueryFilterGroup | QueryFilter[]
  ): Observable<ListResult<CompanyMembershipRole[] | CompanyMembershipRoleWithMembers[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.companyMembershipGateway.getRoles(companyId, metaData, filter)));
  }

  getRole(roleId: number): Observable<CompanyMembershipRole> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.companyMembershipGateway.getRole(companyId, roleId)));
  }

  createRole(role: CompanyMembershipRole): Observable<CompanyMembershipRole> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.companyMembershipGateway.createRole(companyId, role)));
  }

  updateRole(role: CompanyMembershipRole): Observable<CompanyMembershipRole> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.companyMembershipGateway.updateRole(companyId, role)));
  }

  deleteRole(role: CompanyMembershipRole): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.companyMembershipGateway.deleteRole(companyId, role.id)));
  }

  getMembershipList(
    metaData: RequestMetaData,
    filter?: Record<string, any> | QueryFilterGroup | QueryFilter[]
  ): Observable<ListResult<CompanyMembership[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.companyMembershipGateway.getMembershipList(companyId, metaData, filter))
      );
  }

  getMembership(membershipId: number): Observable<CompanyMembership> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.companyMembershipGateway.getMembership(companyId, membershipId)));
  }

  updateMembership(membership: CompanyMembership): Observable<CompanyMembership> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((id: number) => this.companyMembershipGateway.updateMembership(id, membership)));
  }

  removeMembership(membershipId: number): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((id: number) => this.companyMembershipGateway.removeMembership(id, membershipId)));
  }

  inviteMemberIntoCompany(invitation: PartialCompanyMembershipInvitation): Observable<CompanyMembershipInvitation> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((id: number) => this.companyMembershipGateway.inviteMemberIntoCompany(id, invitation)));
  }

  deleteInvitation(invitationId: number): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((id: number) => this.companyMembershipGateway.deleteInvitation(id, invitationId)));
  }

  getPendingInvitations(
    metaData: RequestMetaData,
    filter?: Record<string, any> | QueryFilterGroup | QueryFilter[]
  ): Observable<ListResult<CompanyMembershipInvitation[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((id: number) => this.companyMembershipGateway.getPendingInvitations(id, metaData, filter)));
  }
}
