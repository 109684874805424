import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WidgetInstance } from 'friendly-challenge';

import { ToastService } from '../../../shared/components/toast/toast.service';
import { EnvironmentService } from '../../services';

@Component({
  selector: 'account-friendly-captcha-puzzle',
  templateUrl: './friendly-captcha-puzzle.component.html',
  styleUrl: './friendly-captcha-puzzle.component.less',
  standalone: true,
})
export class FriendlyCaptchaPuzzleComponent implements AfterViewInit {
  @Output() readonly captchaSolved = new EventEmitter<string>();

  @ViewChild('friendlyCaptcha', { static: false }) friendlyCaptcha: ElementRef<HTMLElement>;

  protected friendlyCaptchaSiteKey: string;
  protected friendlyCaptchaPuzzleEndpoints: string;

  protected failedToSolveCaptcha = false;

  private captchaWidget: WidgetInstance;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.friendlyCaptchaSiteKey = this.environmentService.current.friendlyCaptchaSiteKey;
    this.friendlyCaptchaPuzzleEndpoints = this.environmentService.current.friendlyCaptchaPuzzleEndpoints.join(',');
  }

  ngAfterViewInit(): void {
    this.captchaWidget = new WidgetInstance(this.friendlyCaptcha.nativeElement, {
      doneCallback: (captcha: string) => {
        this.captchaSolved.emit(captcha);
      },
      errorCallback: () => {
        /**
         * only error code thrown by friendly captcha is `error_getting_puzzle` which happens if the puzzle endpoint has
         * timeouts and does not answer.
         * https://github.com/FriendlyCaptcha/friendly-challenge/blob/970c8df6ef5d9ad95c141127ea23600be7c4082d/src/captcha.ts#L266
         */
        this.toastService.error(
          this.translateService.instant('COMMON.TOAST.ERROR.TITLE.CAPTCHA_FAILED'),
          this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.CAPTCHA_FAILED'),
          true
        );
        this.failedToSolveCaptcha = true;
        this.changeDetectorRef.detectChanges();
      },
      language: this.translateService.currentLang === 'de' ? 'de' : 'en',
    });
  }

  startCaptcha(): void {
    this.captchaWidget.start();
  }

  resetCaptcha(): void {
    this.captchaWidget.reset();
    this.failedToSolveCaptcha = false;
  }
}
