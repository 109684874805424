import { createAction, props } from '@ngrx/store';

import { SbpException, SupportTicketUpdates } from '../../../models';

export const getSupportTicketUpdates = createAction(
  '[Company][SupportTicketUpdates] get',
  props<{ payload: number }>()
);
export const getSupportTicketUpdatesSuccess = createAction(
  '[Company][SupportTicketUpdates] get success',
  props<{ payload: SupportTicketUpdates }>()
);
export const getSupportTicketUpdatesFail = createAction(
  '[Company][SupportTicketUpdates] get fail',
  props<{ payload: SbpException }>()
);
export const refreshSupportTicketUpdate = createAction(
  '[Company][SupportTicketUpdates] refresh',
  props<{ payload: number }>()
);
export const resetSupportTicketUpdate = createAction('[Company][SupportTicketUpdates] reset');
