import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ProducerSalesGateway } from '../../gateways/plugins';
import { PluginLicense, Producer, SbpException } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import * as fromProducerSelector from '../../store/producer/producer/producer.selectors';
import { RootState } from '../../store/root.state';

@Injectable({
  providedIn: 'root',
})
export class ProducerSalesFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly producerSalesGateway: ProducerSalesGateway
  ) {}

  downloadPluginList(locale: string, metaData: RequestMetaData): Observable<Blob | SbpException> {
    return this.getProducer().pipe(
      take(1),
      switchMap((producer: Producer) => this.producerSalesGateway.downloadPluginList(producer.id, locale, metaData))
    );
  }

  getSalesList(metaData: RequestMetaData): Observable<any> {
    return this.getProducer().pipe(
      take(1),
      switchMap((producer: Producer) => this.producerSalesGateway.getSalesList(producer.id, metaData))
    );
  }

  extendTrialLicenseExpirationDate(licenseId: number): Observable<PluginLicense> {
    return this.getProducer().pipe(
      take(1),
      switchMap((producer: Producer) =>
        this.producerSalesGateway.extendTrialLicenseExpirationDate(producer.id, licenseId)
      )
    );
  }

  private getProducer(): Observable<Producer> {
    return this.store.select(fromProducerSelector.selectProducer);
  }
}
