import { AuthFacade } from '@account/core/facades';
import { Company, CompanyAllocations, MasterData, PartnerStatus } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { NavigationAccessMapper } from '../../components/navigation/navigation-access.mapper';
import { CompanyMembershipRolePermission } from '../../models';
import { SessionCookieService } from '../../services';
import { Permission, PermissionContext } from '../../types/acl.type';
import { StringUtils } from '../../utils';
import { SessionFacade } from '../app';
import { CompaniesFacade } from '../company';
import { PartnersFacade } from '../partner';
import { UserAccountsFacade } from '../user-account';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractAclFacade {
  constructor(
    protected readonly sessionFacade: SessionFacade,
    protected readonly authFacade: AuthFacade,
    protected readonly companiesFacade: CompaniesFacade,
    protected readonly userAccountsFacade: UserAccountsFacade,
    private readonly partnersFacade: PartnersFacade,
    private readonly sessionCookieService: SessionCookieService
  ) {}

  /**
   * @summary Check access by url
   * @description Access check follows following rules:
   * 1. Check access for UserAccount specific urls
   * 2. Check access for Company specific urls
   * 3. Check access by UserAccount permissions in Company
   */
  isAccessGrantedForLocation(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    if (relativeUrl.length === 0 || relativeUrl.includes('denied')) {
      return of(true);
    }
    return this.isUrlAccessGrantedForUserAccount(relativeUrl).pipe(
      switchMap((accessGranted: boolean) => {
        if (!accessGranted) {
          return of(false);
        }
        return this.isUrlAccessGrantedForCompany(relativeUrl).pipe(
          switchMap((accessGranted: boolean) => {
            if (!accessGranted) {
              return of(false);
            }
            return this.isUrlAccessGrantedForCurrentPermissions(relativeUrl);
          })
        );
      })
    );
  }

  protected isUrlAccessGrantedForUserAccount(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    return this.companiesFacade
      .hasCompany()
      .pipe(map((hasCompany) => hasCompany || AbstractAclFacade.isUrlAccessibleForUserAccount(relativeUrl)));
  }

  protected isAccessGrantedForCompanyByGTC(): Observable<boolean> {
    return this.companiesFacade.hasCompany().pipe(
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of(true);
        }
        return this.authFacade.isLatestGTCSigned();
      })
    );
  }

  protected isUrlAccessGrantedForCompanyMasterData(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    return this.companiesFacade
      .getCompany()
      .pipe(
        map(
          (company: Company) =>
            !company.masterData.masterDataMissing ||
            relativeUrl === 'profile/data' ||
            relativeUrl.startsWith('settings')
        )
      );
  }

  protected isUrlAccessGrantedForCompanyAllocations(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    return this.companiesFacade
      .getAllocations()
      .pipe(
        map((allocations: CompanyAllocations) => AbstractAclFacade.isUrlAccessibleForCompany(relativeUrl, allocations))
      );
  }

  protected isUrlAccessGrantedForPartnerStatus(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    if (!relativeUrl.startsWith('partner')) {
      return of(true);
    }
    return this.companiesFacade
      .getAllocations()
      .pipe(
        switchMap((allocations: CompanyAllocations) =>
          of(AbstractAclFacade.isUrlAccessibleForPartnerStatus(relativeUrl, allocations))
        )
      );
  }

  protected isUrlAccessGrantedForCurrentPermissions(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    return this.userAccountsFacade.getPermissionsOfSelectedCompany().pipe(
      map((permissions: CompanyMembershipRolePermission[]) =>
        AbstractAclFacade.isUrlAccessibleForCurrentMembershipPermissions(relativeUrl, permissions)
      ),
      switchMap((hasPermission: boolean) => {
        if (hasPermission === true || url !== 'profile/data') {
          return of(hasPermission);
        }
        return this.isUrlAccessForCompanyProfileIfMasterDataIsMissing();
      })
    );
  }

  private isUrlAccessForCompanyProfileIfMasterDataIsMissing(): Observable<boolean> {
    return this.companiesFacade.hasCompany().pipe(
      switchMap((hasCompany) => {
        if (!hasCompany) {
          return of(false);
        }
        return this.companiesFacade.getMasterData().pipe(
          map((masterData: MasterData) => masterData.masterDataMissing),
          switchMap((masterDataMissing: boolean) => {
            if (!masterDataMissing) {
              return of(false);
            }
            return of(true);
          })
        );
      })
    );
  }

  private isUrlAccessGrantedForCompany(url: string): Observable<boolean> {
    const relativeUrl = StringUtils.trimSlashes(url);
    return this.isAccessGrantedForCompanyByGTC().pipe(
      switchMap((hasSignedGTC: boolean) => {
        if (!hasSignedGTC) {
          return of(false);
        }
        return this.isUrlAccessGrantedForCompanyMasterData(relativeUrl).pipe(
          switchMap((hasCompletedMasterData: boolean) => {
            if (!hasCompletedMasterData) {
              return of(false);
            }
            return this.isUrlAccessGrantedForCompanyAllocations(relativeUrl).pipe(
              switchMap((accessGranted: boolean) => {
                if (!accessGranted) {
                  return of(false);
                }
                return this.isUrlAccessGrantedForPartnerStatus(relativeUrl);
              })
            );
          })
        );
      })
    );
  }

  private static isUrlAccessibleForUserAccount(url: string): boolean {
    const firstUrlPart = url.split('/')[0];
    if ('' === firstUrlPart) {
      return true;
    }
    switch (firstUrlPart) {
      case 'portal':
      case 'settings':
        return true;
      default:
        return false;
    }
  }

  private static isUrlAccessibleForCompany(url: string, allocations: CompanyAllocations): boolean {
    const firstUrlPart = url.split('/')[0];
    if ('' === firstUrlPart) {
      return true;
    }

    switch (firstUrlPart) {
      case 'partner':
        return allocations.isPartner;
      case 'producer':
        return allocations.isProducer;
      case 'shops':
        return allocations.hasShops;
      case 'academy':
        return true;
      case 'profile':
        return true;
      case 'settings':
        return true;
      default:
        return true;
    }
  }

  private static isUrlAccessibleForPartnerStatus(url: string, allocations: CompanyAllocations): boolean {
    const requiredPartnerStatus = NavigationAccessMapper.urlToPartnerStatus(url);
    if (null === requiredPartnerStatus) {
      return true;
    }
    return (
      allocations.isPartner &&
      allocations.partnerStatusList.filter((status: PartnerStatus) => requiredPartnerStatus.includes(status.name))
        .length > 0
    );
  }

  private static isUrlAccessibleForCurrentMembershipPermissions(
    url: string,
    permissionsOfUserAccount: CompanyMembershipRolePermission[]
  ): boolean {
    const requiredPermissions = NavigationAccessMapper.urlToMembershipPermissions(url);
    if (null === requiredPermissions) {
      return true;
    }
    return (
      permissionsOfUserAccount.filter(
        (permission: CompanyMembershipRolePermission) =>
          (requiredPermissions as PermissionContext[]).includes(permission.context) ||
          (requiredPermissions as Permission[]).includes(permission.name)
      ).length > 0
    );
  }
}
