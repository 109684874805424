import { AutomaticBalance } from '@account/core/models';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export const setDefaultPaymentMean =
  (): OperatorFunction<any, any> =>
  // eslint-disable-next-line unused-imports/no-unused-vars
  <T>(source$: Observable<AutomaticBalance>): Observable<AutomaticBalance> =>
    source$.pipe(
      map((automaticBalance: AutomaticBalance) => {
        if (null === automaticBalance.selectedPaymentMean) {
          return automaticBalance;
        }
        for (const paymentMean of automaticBalance.paymentMeans) {
          if (paymentMean.id === automaticBalance.selectedPaymentMean.id) {
            paymentMean.default = true;
            break;
          }
        }
        return automaticBalance;
      })
    );
