export class StringUtils {
  static isEmail(string: string): boolean {
    const emailRegex =
      // eslint-disable-next-line no-control-regex
      /^(?:[\w!#$%&'*+/=?^`{|}~\u00C0-\u017F-]+(?:\.[\w!#$%&'*+/=?^`{|}~\u00C0-\u017F-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z\u00C0-\u017F](?:[\da-z\u00C0-\u017F-]*[\da-z\u00C0-\u017F])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(2(5[0-5]|[0-4]\d)|1\d\d|[1-9]?\d)\.){3}(?:(2(5[0-5]|[0-4]\d)|1\d\d|[1-9]?\d)|[\da-z\u00C0-\u017F-]*[\da-z\u00C0-\u017F]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])$/i;
    return emailRegex.test(string);
  }

  static escape(string: string): string {
    return string.replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
  }

  static trimByChar(string: string, char = ' '): string {
    return string.replace(new RegExp(`^[${char}]+|[${char}]+$`, 'g'), '');
  }

  static trimSlashes(string: string): string {
    return StringUtils.trimByChar(string, '/');
  }

  static getDomainSuffix(domain: string | null, fallbackDomain = '.sw5shop-domain'): string {
    if (!domain) {
      return fallbackDomain;
    }

    let trimmedDomain = StringUtils.trimPrefixes(domain, ['http://', 'https://']);
    trimmedDomain = StringUtils.trimPrefixes(trimmedDomain, ['www.', 'www2.', 'www3.', 'www4.', 'www5.']);

    return `.${trimmedDomain}`;
  }

  static trimPrefixes(subject: string, prefixes: string[]): string {
    for (const prefix of prefixes) {
      if (subject.startsWith(prefix)) {
        return subject.slice(prefix.length);
      }
    }
    return subject;
  }
}
