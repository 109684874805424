import { Changelog } from '@account/core/models';

export const CHANGELOG = {
  key: 'account2-changelog',
  VERSION_PRODUCER: 7,
  VERSION_PARTNER: 3, // increase version number to force a new display for users
  VERSION_SHOPS: 3, // increase version number to force a new display for users
};

export const CHANGELOG_PRODUCER: Changelog[] = [
  {
    versionNumber: '2.0.51',
    releaseDate: '2021-03-03',
    entries: [
      {
        type: 'bugfix',
        textEn:
          'When logging into the account in and entering the wrong password, it could happen after a new, correct login that the account could not be displayed correctly. This is now fixed.',
        textDe:
          'Beim Login den Account und Eingabe des falschen Passwortes konnte es nach erneutem, korrektem Login vorkommen, das der Account nicht korrekt dargestellt werden konnte. Das ist nun behoben.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'The wildcard environment is now also available in the manufacturer area. You can install all licenses of the store on different instances with different Shopware versions and thus test the compatibility of your extensions.',
        textDe:
          'Die Wildcard-Umgebung steht nun auch im Herstellerbereich zur Verfügung. Ihr könnt dort alle Lizenzen des Store auf verschiedenen Instanzen mit unterschiedlichen Shopware Versionen installieren und somit u.a. die Kompatibilität eurer Erweiterungen testen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.50',
    releaseDate: '2021-02-22',
    entries: [
      {
        type: 'feature',
        textEn:
          'On the portal page of the account & on the welcome page in the manufacturer area we now show the open support tickets. So you can see directly which customer requests are still to be answered and you can switch directly to the corresponding ticket. In addition, an icon is displayed in the navigation in the manufacturer area.',
        textDe:
          'Auf der Portal Seite des Accounts & auf der Willkommen Seite im Herstellerbereich zeigen wir nun die offenen Support-Tickets an. Somit seht Ihr direkt, welche Kundenanfragen noch zu beantworten sind und könnt sofort in das entspr. Ticket wechseln. Zudem wird in der Navigation im Herstellerbereich ein Icon angezeigt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.39',
    releaseDate: '2020-09-16',
    entries: [
      {
        type: 'feature',
        textEn:
          'The plugin withdrawal process has been further optimized. For the different application situations (open requests, accepted or rejected requests) you will now receive notifications in the notification center of the account.',
        textDe:
          'Der Rücknahme Prozess für Plugins wurde weiter optimiert. Für die verschiedenen Antragssituationen (offene Anträge, angenommene oder abgelehnte Anträge) erhälst Du nun Benachrichtigungen im Notification Center des Account.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'In the emails for the plugin release workflow, the technical name of the plugin is now also included in the subject.',
        textDe:
          'In den E-Mails zum Plugin-Freigabe Workflow ist nun auch der technische Name des Plugins im Betreff enthalten.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'In the plugin details the option "is responsive" was removed.',
        textDe: 'In den Plugindetails wurde die Option "ist Responsive" entfernt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.38',
    releaseDate: '2020-09-02',
    entries: [
      {
        type: 'feature',
        textEn:
          'Workflow for plugin withdrawal implemented. The process for requests for plugin returns from customers was until today a very manual one. From now on the following workflow is available in the account. The customer contacts our sales department with a cancellation request and receives a form if there is sufficient justification. After he has filled out this form, you will receive a notification by email. You will find open requests in your sales area and can agree to the request for plugin withdrawal as a gesture of goodwill. Afterwards your commission will be cancelled and the customer will be refunded the purchase / rental price.',
        textDe:
          'Workflow für Plugin-Rücknahmen implementiert. Der Prozess zu Anfragen für Plugin-Rücknahmen von Kunden war bis heute ein sehr manueller. Ab jetzt steht folgender Workflow im Account zur Verfügung. Der Kunde meldet sich mit einer Stornierungsanfrage bei unserem Vertrieb und erhält bei ausreichender Begründung ein Formular freigeschaltet. Nachdem er dieses ausgefüllt hat, erhälst Du eine Benachrichtigung per E-Mail. Du findest offene Anträge im Bereich Deiner Verkäufe und kannst der Anfrage auf Pluginrückgabe aus Kulanz zustimmen. Im Anschluss wird Deine Provision storniert und der Kunde erhält den Kauf/Miet-Preis erstattet.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Code review adapted for themes & apps. The code review now checks the manifest.xml file for unopened or unclosed tags.',
        textDe:
          'Codereview für Themes & Apps angepasst. Beim Codereview wird jetzt die manifest.xml Datei auf nicht geöffnete oder geschlossene Tags geprüft.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'From now on you can extend the expiration date of the test licenses of your plugins once for 30 days. In the list of test licenses of your sales there is now a button to extend the expiration date.',
        textDe:
          'Du kannst ab jetzt das Ablaufdatum der Test-Lizenzen Deiner Plugins einmalig um 30 Tage verlängern. In der Liste der Test-Lizenzen bei Deinen Verkäufen gibt es jetzt einen Button zur Verlängerung.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn:
          'A change of the software license (proprietary or open-source) you specified when creating the extension is no longer possible after the first successful code review.',
        textDe:
          'Eine Änderung der von Dir beim Erstellen der Erweiterung festgelegten Software-Lizenz (propritär oder open-source) ist nach dem ersten erfolgreichen Codereview nicht mehr möglich.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.37',
    releaseDate: '2020-08-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'The new review process. To make the approval process of your extensions and themes faster and more transparent, we have optimized and automated the review process. You can upload your plugin, theme, or app as usual through your account and submit it to us for review by clicking the button "Release for review". We have optimized the following in the review process',
        textDe:
          'Neuer automatischer Prüfprozess. Für einen schnelleren und transparenteren Freigabeprozess Deiner Erweiterungen und Themes haben wir den Prüfprozess optimiert und automatisiert. Du kannst Dein Plugin, Dein Theme oder Deine App wie gewohnt über den Account hochladen und jetzt mit dem Button „Für Prüfung freigeben“ bei uns zur Prüfung einreichen. Den darauf folgenden Prozess haben wir optimiert und transparenter gestaltet, sodass Du schneller, einfacher und übersichtlich den aktuellen Stand der Prüfung, den Ablauf sowie die Kommunikation einsehen kannst',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'The navigation for user-specific information has been optimised and this has been integrated into the new menu item “My Data". There you will now find your company memberships and personal user settings.',
        textDe:
          'Die Navigation für die benutzerspezifischen Informationen wurde optimiert und diese im neuen Menüpunkt "Meine Daten" eingegliedert. Dort findest Du in nun Deine Unternehmensmitgliedschaften und persönlichen Nutzereinstellungen.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'The presentation of Gross & Net amounts in the cloud shop segment was standardized. All net amounts are now displayed. ',
        textDe:
          'Die Darstellung der Brutto/Netto-Beträge im Bereich der Cloud-Shops wurde vereinheitlicht. Es werden nun alle Beträge Netto angezeigt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.29',
    releaseDate: '2020-07-08',
    entries: [
      {
        type: 'improvement',
        textEn:
          'Enforced plugin code review checks. Information provided inside the uploaded binary will now be checked against your plugin configuration',
        textDe:
          'Prüfungen im Plugin CodeReview verstärkt erweitert. Informationen, die in einer hochgeladenen Pluginversion hinterlegt sind, werden nun mit den im Plugin definierten Daten gegengeprüft.',
        feedback: false,
      },
      {
        type: 'improvement',
        textEn:
          'The license specified in the plugin is now only distinguished between "proprietary" and " open-source", the actual license must now only be specified in the plugin version',
        textDe:
          'Ber im Plugin angegebenen Lizenz wird nun nur noch zwischen "Proprietär" und "Open-Source" unterschieden, die konkrete Lizenz muss nun nur noch in der Pluginversion hinterlegt werden',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.28',
    releaseDate: '2020-03-18',
    entries: [
      {
        type: 'feature',
        textEn:
          'For support requests some information is very important. Therefore, when creating a new Plugin Support Ticket, an introductory text is now also displayed. This text tells you which information in the ticket is important.',
        textDe:
          'Bei Support Anfragen sind einige Informationen sehr wichtig. Daher wird beim Erstellen eines neuen Plugin-Support Tickets nun auch ein anleitender Text angezeigt. Dieser gibt Aufschluss darüber, welche Informationen im Ticket wichtig sind.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'The support tickets were previously sorted from "new to old". We have now changed this sort order so that you can see the tickets that should be answered first at the top of the list.',
        textDe:
          'Die Support Tickets wurden bisher von "neu zu alt" sortiert. Diese Sortierung haben wir nun geändert, damit Ihr beim Bearbeiten die Tickets, die als erstes Beantwortet werden sollten, an oberster Stelle seht.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Users who have been invited to a company can now leave the company via the "My settings" section.',
        textDe:
          'Benutzer, welche in eine Firma eingeladen wurden, können diese nun über den Bereich "Meine Einstellungen" auch wieder verlassen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.27',
    releaseDate: '2020-02-04',
    entries: [
      {
        type: 'feature',
        textEn:
          'In the Shopware account, the user administration is now available to all users in the "My company" area. In the new user administration it is possible to define different users of the Shopware account. These users can be assigned different roles with corresponding access rights. In this way, different employees of a company only have access to those areas that are relevant for them.',
        textDe:
          'Im Shopware Account steht nun allen Benutzern im Bereich "Meine Firma" ab sofort die Benutzerverwaltung zur Verfügung. In der neuen Benutzerverwaltung ist es möglich verschiedene Nutzer des Shopware Accounts zu definieren. Diesen Nutzern können verschiedene  Rollen mit entsprechenden Zugriffsrechten zugewiesen werden. So erhalten verschiedene Mitarbeiter einer Firma nur noch Zugriff auf die Bereiche, die für sie relevant sind.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.26',
    releaseDate: '2020-01-21',
    entries: [
      {
        type: 'bugfix',
        textEn:
          'In some tables it could happen that the column contents were not shortened correctly. This error is now fixed.',
        textDe:
          'In einigen Tabellen konnte es vorkommen, dass die Spalteninhalte nicht korrekt eingekürzt wurden. Dieser Fehler ist nun behoben.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'The user administration is now available to all plugin manufacturers',
        textDe: 'Die Benutzerverwaltung steht nun allen Plugin Herstellern zur Verfügung.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.25',
    releaseDate: '2020-01-08',
    entries: [
      {
        type: 'feature',
        textEn:
          'The three-month minimum contract period for plugin rentals has been removed. A plugin rental can now be terminated at any time on the next billing day.',
        textDe:
          'Die dreimonatige Mindestvertragslaufzeiten für Pluginmieten wurde entfernt. Eine Miete ist somit jederzeit zum nächsten Abrechnungstag kündbar.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.23',
    releaseDate: '2019-12-01',
    entries: [
      {
        type: 'feature',
        textEn: 'Redesign of the left area of the account.',
        textDe: 'Redesign des linken Bereich des Account.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.21',
    releaseDate: '2019-10-22',
    entries: [
      {
        type: 'feature',
        textEn:
          'The shopware Wiki was integrated in the account and is linked as link "Documentation" in the navigation. Furthermore the FAQ was linked on the detail pages.',
        textDe:
          'Das shopware Wiki wurde im Account integriert und ist als Link "Dokumentation" in der Navigation verlinkt. Weiterhin wurde auf den einzelnen Detailseiten das FAQ verlinkt.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'The file size limit for plugin versions has been increased to 10 MB.',
        textDe: 'Die Dateigrößenbeschränkung für PluginVersionen wurde auf 10 MB angehoben.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.20',
    releaseDate: '2019-08-27',
    entries: [
      {
        type: 'feature',
        textEn: 'Introduction of an export option for bookings, invoices, credit notes and commissions in CSV format.',
        textDe:
          'Einführung einer Export-Möglichkeit für Buchungen, Rechnungen, Gutschriften und Provisionen im CSV-Format.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.19',
    releaseDate: '2019-08-12',
    entries: [
      {
        type: 'feature',
        textEn:
          'Introduction of a Notification Center. We now inform about important events in the account via the bell in the upper left corner.',
        textDe:
          'Einführung eines Notification Center. Über die Glocke im oberen linken Rand informieren wir jetzt über wichtige Ereignisse im Account.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Improved data validation of demo shop URLs.',
        textDe: 'Verbesserung der Datenvalidierung von Demoshop-URLs.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Minor improvements regarding styling, bugfixing and performance improvements.',
        textDe: 'Kleinere Verbesserungen bzgl. Styling, sowie Bugfixing und Performance-Verbesserungen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.18',
    releaseDate: '2019-07-15',
    entries: [
      {
        type: 'feature',
        textEn: 'The billing address can now also be changed after the first booking or invoice.',
        textDe: 'Die Rechnungsadresse ist jetzt auch nach erster Buchung bzw. Rechnung änderbar.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'Various adaptations of the UI, usability improvements and referencing of suitable documentation articles',
        textDe:
          'Diverse Anpassungen der UI, Usability-Verbesserungen und Referenzierung passender Dokumentationsartikel',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.17',
    releaseDate: '2019-07-01',
    entries: [
      {
        type: 'feature',
        textEn: 'The short description of plugins must now be between 150 and 185 characters.',
        textDe: 'Die Kurzbeschreibung von Plugins muss jetzt zwischen 150 und 185 Zeichen betragen.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Various minor adjustments of the UI and bugfixing. The portal is now accessible by clicking on the Shopware logo.',
        textDe:
          'Diverse kleinere Anpassungen der UI und Bugfixing. Das Portal ist jetzt über Click auf das Shopware-Logo erreichbar.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.16',
    releaseDate: '2019-06-17',
    entries: [
      {
        type: 'feature',
        textEn:
          'Renaming of the workspaces. The former plugin management is now the "manufacturer area". The shop management is now called "merchant area".',
        textDe:
          'Umbenennung der Arbeitsbereiche. Das frühere Pluginmanagement ist jetzt der "Herstellerbereich". Die Shopverwaltung heißt jetzt "Shopbetreiberbereich".',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'Plugin listing has been revised, the release month of Shopware 6 plugins is now displayed and the list can be sorted by it.',
        textDe:
          'Listenansicht der Plugins wurde überarbeitet, der Release-Monat von Shopware 6 Plugins wird jetzt angezeigt und die Liste ist danach sortierbar.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'Each plugin can now be enhanced by internationalized frequently asked questions. These are displayed accordingly in the Shopware Store.',
        textDe:
          'Je Plugin können jetzt internationalisiert häufig gestellte Fragen erfasst werden. Diese werden im Shopware Store entsprechend angezeigt.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Various adjustments of the UI and measures to increase the performance of individual views.',
        textDe: 'Diverse Anpassungen der UI und Maßnahmen zur Steigerung der Performance einzelner Ansichten.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.15',
    releaseDate: '2019-05-27',
    entries: [
      {
        type: 'feature',
        textEn:
          'Using a wizard on the plugin detail page, a successor in the Shopware 6 ecosystem can now be defined for each Shopware 5 plugin.',
        textDe:
          'Über einen Wizard auf der Plugin-Detailseite kann jetzt für jedes Shopware 5 Plugin ein Nachfolger im Shopware 6 Ökosystem definiert werden.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'The supported languages for plugins are now also available as armenian, swiss german and turkish.',
        textDe:
          'Als unterstützte Sprache bei Plugins stehen jetzt auch armenisch, schweizerdeutsch und türkisch zur Verfügung.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'The plugin description was extended by the tags <h2>, <h3> and <h4>.',
        textDe: 'Die Plugin-Beschreibung wurde um die Tags <h2>, <h3> und <h4> erweitert.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.14',
    releaseDate: '2019-05-13',
    entries: [
      {
        type: 'feature',
        textEn:
          'Possibility of explicit description of up to 5 highlights and up to 15 features per plugin. These will be highlighted in the Shopware Store.',
        textDe:
          'Möglichkeit der expliziten Beschreibung von bis zu 5 Highlights und bis zu 15 Features je Plugin. Diese werden im Shopware Store besonders hervorgehoben.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'The plugin description is more restrictive, only the HTML tags <a>, <b>, <i>, <em>, <strong>, <ul>, <ol>, <li>, <p> and <br> are allowed. A function has been created for easy conversion of existing texts. Invalid texts do not prevent the storage yet, but are pre-processed in the Shopware Store.',
        textDe:
          'Die Plugin-Beschreibung ist restriktiver, es werden nur noch die HTML-Tags <a>, <b>, <i>, <em>, <strong>, <ul>, <ol>, <li>, <p> und <br> erlaubt. Zur einfachen Konvertierung bestehender Texte wurde eine Funktion erstellt. Invalide Texte verhindern die Speicherung derzeit noch nicht, werden allerdings im Shopware Store entsprechend vor-verarbeitet.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.13',
    releaseDate: '2019-04-02',
    entries: [
      {
        type: 'feature',
        textEn:
          'The maximum number of characters for the plugin short description has been increased to 185. We recommend at least 150 characters.',
        textDe:
          'Die maximale Zeichenzahl für die Plugin-Kurzbeschreibung wurde auf 185 Zeichen erhöht. Wir empfehlen mindestens 150 Zeichen.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'The support module for your plugins shows the plugin version used by the customer now.',
        textDe:
          'Im Support-Modul für Deine Plugins wird jetzt auch die vom Kunden eingesetzte Plugin-Version angezeigt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.12',
    releaseDate: '2019-02-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'The "Account" section has been added. After login you will now be directed to a portal. From here you can enter the areas according to your role.',
        textDe:
          'Bereich "Account" wurde hinzugefügt. Nach Login wirst Du jetzt in ein Portal geleitet. Von hier können die Bereiche entsprechend seiner Rolle betreten werden.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'The billing aspects of the account can be viewed and managed under "Billing". Here you will find your manufacturer commissions, payment methods, invoices and credit notes at a glance.',
        textDe:
          'Unter "Buchhaltung" können die buchhalterischen Aspekte des Account eingesehen und verwaltet werden. Hier findest Du unter anderem deine Hersteller-Provisionen, sowie Zahlungsarten, Rechnungen und Gutschriften übersichtlich auf einen Blick.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.11',
    releaseDate: '2019-02-05',
    entries: [
      {
        type: 'feature',
        textEn: "Possibility of participation in Valentine's Day campaign on 14 February 2019.",
        textDe: 'Möglichkeit der Teilnahme an Valentinstags-Aktion am 14. Februar 2019.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'If the distribution model of a plug-in is changed, the consequences for the manufacturer and the buyer will be pointed out directly in the sales model configuration.',
        textDe:
          'Bei Änderung der Vertriebsmodells eines Plugins wird beim Wegfall der Subscription-Option entsprechend auf die Folgen für Hersteller und Käufer hingewiesen.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'The Academy area has been introduced and is available to all users of the account.',
        textDe: 'Der Academy-Bereich wurde eingeführt und steht allen Nutzern des Account zur Verfügung.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Improvement of various UI components.',
        textDe: 'Verbesserung verschiedener UI-Komponenten.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.10',
    releaseDate: '2019-01-21',
    entries: [
      {
        type: 'feature',
        textEn:
          'Creation of a possibility to revoke individual plugin versions. In the special case of a faulty release of a plugin version, it can now be revoked within the first three days after upload. The version will be completely deleted if it has not yet been downloaded. Otherwise the version will be marked accordingly.',
        textDe:
          'Schaffung einer Möglichkeit zum Zurückziehen einzelner Plugin-Versionen. Im Sonderfall einer fehlerhaften Veröffentlichung einer Plugin-Version kann diese jetzt innerhalb der ersten drei Tage nach Upload zurückgezogen werden. Die Version wird vollständig gelöscht, sofern sie noch nicht heruntergeladen wurde. Andernfalls wird die Version entsprechend markiert.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Customer name and domain are now made transparent in the mail notification when testing plugins.',
        textDe:
          'Kundenname und -domain werden jetzt bei Test-Bezug von Plugins in der Mail-Benachrichtigung transparent gemacht.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Minor improvements to text blocks and visual bug fixes.',
        textDe: 'Kleine Verbesserungen an Textblöcken und Behebung visueller Bugs.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.9',
    releaseDate: '2018-12-18',
    entries: [
      {
        type: 'feature',
        textEn: 'Introduction of a welcome page for direct information from Shopware.',
        textDe: 'Einführung einer Willkommensseite für direkte Infos von Shopware.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'License irregularities are displayed under sales.',
        textDe: 'Lizenzauffälligkeiten werden unter "Verkäufe" angezeigt.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Definition of an individual minimum shopware version per plugin for playout in the store.',
        textDe: 'Definition einer individuellen minimalen Shopware-Version je Plugin für die Ausspielung im Store.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Revision of the English translations',
        textDe: 'Überarbeitung der englischen Übersetzungen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.8',
    releaseDate: '2018-11-19',
    entries: [
      {
        type: 'feature',
        textEn: 'Added functionality to delete development environments.',
        textDe: 'Entwicklungsumgebungen können jetzt auch wieder gelöscht werden.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.7',
    releaseDate: '2018-11-05',
    entries: [
      {
        type: 'feature',
        textEn:
          'From now on, discount campaigns especially for the Black Weekend Sale in the Shopware Store can be added.',
        textDe:
          'Ab sofort können Rabattaktionen speziell für den Black Weekend Sale im Shopware Store eingestellt werden.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.6',
    releaseDate: '2018-10-22',
    entries: [
      {
        type: 'feature',
        textEn:
          'In the new menu item "Development environments" you can define all domains on which you develop your plugins. Your plugins will then be excluded from the license check on these domains.',
        textDe:
          'Im neuen Menüpunkt "Entwicklungsumgebungen" kannst du alle Domains hinterlegen, auf denen Du deine Plugins entwickelst. Deine Plugins werden dann auf diesen Domains von der Lizenzprüfung ausgeschlossen.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.5',
    releaseDate: '2018-10-08',
    entries: [
      {
        type: 'feature',
        textEn:
          'No h1 tags were allowed in the description of a plugin. Accordingly, the editor no longer offers an action for h1.',
        textDe:
          'In der Beschreibung eines Plugins waren keine h1 Tags erlaubt. Entsprechend bietet der Editor nun keine Aktion mehr für h1 an.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'The filter in the sales view now also applies to the export.',
        textDe: 'Der Filter in den Verkäufsübersicht greift nun auch beim Export.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'The description of a bundle with more than 255 characters is now saved correctly.',
        textDe: 'Die Beschreibung eines Bundles mit mehr als 255 Zeichen wird nun korrekt gespeichert.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.4',
    releaseDate: '2018-09-24',
    entries: [
      {
        type: 'feature',
        textEn: 'Possibility to specify the language of Account2 via URL.',
        textDe: 'Möglichkeit die Sprache des Account2 per URL mitzugeben.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Payout export is now also possible filtered by plugin.',
        textDe: 'Der Export ist nun auch nach Plugin gefiltert möglich.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Minor text adjustments and bugfixes for edge cases in wizards.',
        textDe: 'Kleinere Textanpassungen und Bugfixes für Randfälle in Wizards.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.3',
    releaseDate: '2018-09-09',
    entries: [
      {
        type: 'feature',
        textEn: 'In the revenues overview the revenues can now also be filtered and exported by plugin.',
        textDe:
          'In der Provisionsübersicht können die Provisionspositionen jetzt auch nach Plugin gefiltert und exportiert werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'For successful automated Plugin-CodeReviews possible warnings are shown in the details of the respective version.',
        textDe:
          'Bei erfolgreichen automatisierten Plugin-CodeReviews werden eventuelle Warnhinweise in den Details der jeweiligen Version angezeigt.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Added animations for modal windows. Minor usability improvements.',
        textDe: 'Hinzufügen von Animationen für modale Fenster. Kleinere Verbesserungen der Usability.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Bugfix for plugin preview image selection.',
        textDe: 'Bugfix bei Plugin-Vorschaubildauswahl.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.2',
    releaseDate: '2018-08-28',
    entries: [
      {
        type: 'feature',
        textEn:
          'Plugin manufacturers can now respond to closed tickets to send customers a message even after a support case has been completed.',
        textDe:
          'Plugin-Hersteller können jetzt auch auf geschlossene Tickets noch antworten, um Kunden auch nach Abschluss eines Support-Falls noch eine Nachricht zukommen zu lassen.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          '<h1> tags are no longer allowed in the plugin description and installation instructions. When defining the plugin, appropriate hints are given.',
        textDe:
          '<h1>-Tags sind in Plugin-Beschreibung und Installationsanleitung nicht mehr erlaubt. Bei der Plugindefinition werden entsprechende Hinweise gegeben.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'When maintaining the compatibility of plugin versions, notes on the respective PHP compatibility are now given.',
        textDe:
          'Bei der Kompatibilitätspflege von Plugin-Versionen werden jetzt Hinweise zur jeweiligen PHP-Kompatibilität gegeben.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Update of the side navigation including new icons.',
        textDe: 'Update der gesamten Navigation inklusive neuer Icons.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.1',
    releaseDate: '2018-08-13',
    entries: [
      {
        type: 'feature',
        textEn:
          'Removal of the encryption option for plugins compatible with Shopware 5.5.0 or higher. This follows the announcement of the source openness of all plugins in the Shopware Store from Shopware 5.5 on the SCD 2018.',
        textDe:
          'Entfernung der Verschlüsselungsmöglichkeit für Plugins ab einer Kompatibilität mit Shopware 5.5.0. Im Wizard wird der Schritt zur Verschlüsselung ausgespart. Dies folgt der Ankündigung der Quelloffenheit aller Plugins im Shopware Store ab Shopware 5.5 auf dem SCD 2018.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'The installation manual in a plugin is now no longer a mandatory field, but optional.',
        textDe: 'Die Installationsanleitung in einem Plugin ist jetzt kein Pflichtfeld mehr, sondern optional.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'For plugins of the type "Theme" it is now possible to set a test version. For themes a test version is optional, for plugins still obligatory.',
        textDe:
          'Für Plugins des Typs "Theme" ist jetzt das Einstellen einer Testversion möglich. Für Themes ist eine Testversion optional, für Plugins weiterhin verpflichtend.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'For each plugin and language, two YouTube links can now be stored, which are played out in the Shopware Store.',
        textDe:
          'Je Plugin und Sprache können jetzt zwei Youtube-Links hinterlegt werden, die im Shopware Store ausgespielt werden.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Added note when defining plugin demo pages: "Please make sure that your demo shop can be integrated via IFrame. To do this, remove the line \'Header append X-Frame-Options SAMEORIGIN\' from the file .htaccess of your Shopware demo installation.".',
        textDe:
          'Hinweis bei der Definition von Plugin-Demoseiten: "Bitte stell sicher, dass Dein Demoshop per IFrame eingebunden werden kann. Dafür musst Du in der Datei .htaccess Deiner Shopware Demoinstallation die Zeile \'Header append X-Frame-Options SAMEORIGIN\' entfernen."',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Create a way to remove uploads in plugin support that have not yet been sent.',
        textDe: 'Schaffung einer Möglichkeit zum Entfernen von Uploads an noch nicht abgesendeten Support-Antworten.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Improvement of usability in the definition of plugin keywords.',
        textDe: 'Verbesserung der Usability bei der Definition von Plugin-Keywords.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Improvement of the login/logout system. Added more error handling and animations.',
        textDe:
          'Verbesserung der Login-/Logout-Systematik. Hinzufügen und Verbessern weiterer Fehlerbehandlung und Animationen.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Deactivation of incomplete functionalities (search bar). This function is still planned.',
        textDe: 'Deaktivierung unvollständiger Funktionalitäten (Suchleiste). Die Funktion ist weiterhin geplant.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.0',
    releaseDate: '2018-07-24',
    entries: [
      {
        type: 'feature',
        textEn: 'Release of the new plugin management area',
        textDe: 'Veröffentlichung der neuen Plugin-Verwaltung',
        feedback: false,
      },
    ],
  },
];

export const CHANGELOG_PARTNER: Changelog[] = [
  {
    versionNumber: '2.0.51',
    releaseDate: '2021-03-03',
    entries: [
      {
        type: 'bugfix',
        textEn:
          'When logging into the account in and entering the wrong password, it could happen after a new, correct login that the account could not be displayed correctly. This is now fixed.',
        textDe:
          'Beim Login den Account und Eingabe des falschen Passwortes konnte es nach erneutem, korrektem Login vorkommen, das der Account nicht korrekt dargestellt werden konnte. Das ist nun behoben.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.50',
    releaseDate: '2021-02-22',
    entries: [
      {
        type: 'feature',
        textEn: 'When creating developer support tickets, we display a note about valid response times.',
        textDe:
          'Beim Erstellen von Entwickler-Support Tickets zeigen wir einen Hinweis zu den gültigen Reaktionszeiten an.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.38',
    releaseDate: '2020-09-02',
    entries: [
      {
        type: 'feature',
        textEn:
          'In the shop listing, a badge is displayed next to the shop domain if this shop is a client of a shop with an enterprise license. On the detail page of both shops the relationship is shown again and the shops are linked accordingly.',
        textDe:
          'Im Shoplisting wird neben der Shopdomain ein Badge angezeigt, wenn dieser Shop ein Mandant eines Shops mit Enterprise Lizenz ist. Auf der Detailseite beider Shops wird die Beziehung nochmal angezeigt und entsprechend die Shops verlinkt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.37',
    releaseDate: '2020-08-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'The navigation for user-specific information has been optimised and this has been integrated into the new menu item “My Data". There you will now find your company memberships and personal user settings.',
        textDe:
          'Die Navigation für die benutzerspezifischen Informationen wurde optimiert und diese im neuen Menüpunkt "Meine Daten" eingegliedert. Dort findest Du in nun Deine Unternehmensmitgliedschaften und persönlichen Nutzereinstellungen.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'The presentation of Gross & Net amounts in the cloud shop segment was standardized. All net amounts are now displayed. ',
        textDe:
          'Die Darstellung der Brutto/Netto-Beträge im Bereich der Cloud-Shops wurde vereinheitlicht. Es werden nun alle Beträge Netto angezeigt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.29',
    releaseDate: '2020-07-08',
    entries: [
      {
        type: 'feature',
        textEn:
          'Demoshoperstellung für Kunden entfernt, Demoshops können ab jetzt einfach über: https://www.shopware.com/de/demo-testen/ erstellt werden',
        textDe:
          'Demoshop creation for customers has been removed, a Demoshop can now be easily created via: https://www.shopware.com/en/test-demo/',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.28',
    releaseDate: '2020-03-18',
    entries: [
      {
        type: 'feature',
        textEn:
          'For support requests some information is very important. Therefore, when creating a new Plugin Support Ticket, an introductory text is now also displayed. This text tells you which information in the ticket is important.',
        textDe:
          'Bei Support Anfragen sind einige Informationen sehr wichtig. Daher wird beim Erstellen eines neuen Plugin-Support Tickets nun auch ein anleitender Text angezeigt. Dieser gibt Aufschluss darüber, welche Informationen im Ticket wichtig sind.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Users who have been invited to a company can now leave the company via the "My settings" section.',
        textDe:
          'Benutzer, welche in eine Firma eingeladen wurden, können diese nun über den Bereich "Meine Einstellungen" auch wieder verlassen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.27',
    releaseDate: '2020-02-04',
    entries: [
      {
        type: 'bugfix',
        textEn:
          "If a partner has bought a rental plugin in the store for his customer, the customer was shown the partner's rental price in the plugin details. This has been changed, so that the customer is now shown an information that the plugin was licensed by the partner instead of an amount.",
        textDe:
          'Sofern ein Partner ein Miet-Plugin im Store für seinen Kunden gekauft hat, wurde dem Kunden bei dem Plugindetails der Mietpreis des Partners angezeigt. Das wurde geändert, so das der Kunde statt eines Betrages nun eine Information angezeigt bekommt, dass das Plugin durch den Partner lizenziert wurde.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'In the Shopware account, the user administration is now available to all users in the "My company" area. In the new user administration it is possible to define different users of the Shopware account. These users can be assigned different roles with corresponding access rights. In this way, different employees of a company only have access to those areas that are relevant for them.',
        textDe:
          'Im Shopware Account steht nun allen Benutzern im Bereich "Meine Firma" ab sofort die Benutzerverwaltung zur Verfügung. In der neuen Benutzerverwaltung ist es möglich verschiedene Nutzer des Shopware Accounts zu definieren. Diesen Nutzern können verschiedene  Rollen mit entsprechenden Zugriffsrechten zugewiesen werden. So erhalten verschiedene Mitarbeiter einer Firma nur noch Zugriff auf die Bereiche, die für sie relevant sind.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.26',
    releaseDate: '2020-01-21',
    entries: [
      {
        type: 'bugfix',
        textEn:
          'In some tables it could happen that the column contents were not shortened correctly. This error is now fixed.',
        textDe:
          'In einigen Tabellen konnte es vorkommen, dass die Spalteninhalte nicht korrekt eingekürzt wurden. Dieser Fehler ist nun behoben.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Shopware 6 demos can now be created in the lead and customer details.',
        textDe: 'In den Leads- und Kundendetails können ab sofort Shopware 6 Demos erstellt werden.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.25',
    releaseDate: '2020-01-08',
    entries: [
      {
        type: 'feature',
        textEn:
          'The three-month minimum contract period for plugin rentals has been removed. A plugin rental can now be terminated at any time on the next billing day.',
        textDe:
          'Die dreimonatige Mindestvertragslaufzeiten für Pluginmieten wurde entfernt. Eine Miete ist somit jederzeit zum nächsten Abrechnungstag kündbar.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: "The references can now be sorted by drag'n'drop.",
        textDe: "Die Referenzen können jetzt per Drag'n'Drop sortiert werden.",
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'The user administration is now available to all partners.',
        textDe: 'Die Benutzerverwaltung steht nun allen Partnern zur Verfügung.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.23',
    releaseDate: '2019-12-01',
    entries: [
      {
        type: 'feature',
        textEn: 'The Enterprise Search can now be licensed for wildcard instances.',
        textDe: 'Die Enterprise Search ist jetzt für Wildcard Instanzen lizensierbar.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Redesign of the left area of the account.',
        textDe: 'Redesign des linken Bereich des Account.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.21',
    releaseDate: '2019-10-22',
    entries: [
      {
        type: 'feature',
        textEn:
          'The shopware Wiki was integrated in the account and is linked as link "Documentation" in the navigation. Furthermore the FAQ was linked on the detail pages.',
        textDe:
          'Das shopware Wiki wurde im Account integriert und ist als Link "Dokumentation" in der Navigation verlinkt. Weiterhin wurde auf den einzelnen Detailseiten das FAQ verlinkt.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'For the external communication of companies in BeNeLux a trade name can be entered in the partner profile.',
        textDe:
          'Für die Außenkommunikation von Firmen in BeNeLux kann im Partnerprofil ein Handelsname eingetragen werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'If leads are won, the customer created by the lead is directly linked in the lead.',
        textDe: 'Bei gewonnen Leads wird der durch den Lead erstellte Kunde im Lead direkt verlinkt.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.20',
    releaseDate: '2019-08-27',
    entries: [
      {
        type: 'feature',
        textEn: 'Introduction of an export option for bookings, invoices, credit notes and commissions in CSV format.',
        textDe:
          'Einführung einer Export-Möglichkeit für Buchungen, Rechnungen, Gutschriften und Provisionen im CSV-Format.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'Licenses with recurring payments (rentals, paid support) are now displayed as a separate tab in the license overview in the account.',
        textDe:
          'Lizenzen mit wiederkehrender Zahlung (Mieten, kostenpflichtiger Support) werden jetzt als eigener Reiter in der Lizenzübersicht im Account angezeigt.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Removal of subshop functionalities in partner wildcards.',
        textDe: 'Entfernung der Subshop-Funktionalitäten in Partner-Wildcards.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.19',
    releaseDate: '2019-08-12',
    entries: [
      {
        type: 'feature',
        textEn:
          'Wildcards can now also be versioned as Shopware 6. Accordingly, Shopware 6 plugins can be licensed here.',
        textDe:
          'Wildcards können jetzt auch als Shopware 6 versioniert werden. Entsprechend können hier Shopware 6 Plugins lizensiert werden.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'The plugin search in the wildcard instance definition has been improved. Now internationalized description and name, as well as manufacturer and technical name are searched.',
        textDe:
          'Die Plugin-Suche in der Wildcard-Instanz-Definition wurde verbessert. Es werden jetzt internationalisierte Beschreibung und Name, sowie Hersteller- und technischer Name durchsucht.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Introduction of documentation links for each page.',
        textDe: 'Einführung von Dokumentationslinks für jede Seite.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'The lead overview can now be filtered by the "Open" phase.',
        textDe: 'In der Lead-Übersicht kann jetzt nach der Phase "Offen" gefiltert werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'In the lead maintenance, Shopware 6 articles are now available.',
        textDe: 'In der Lead-Pflege stehen jetzt Shopware 6 Artikel zur Verfügung.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'In the lead maintenance, Shopware 6 articles are now available.',
        textDe: 'In der Lead-Pflege stehen jetzt Shopware 6 Artikel zur Verfügung.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Introduction of a Notification Center. We now inform about important events in the account via the bell in the upper left corner.',
        textDe:
          'Einführung eines Notification Center. Über die Glocke im oberen linken Rand informieren wir jetzt über wichtige Ereignisse im Account.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Adjustment of technology profile editing.',
        textDe: 'Anpassung der Einpflege von Technologie-Profilen.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Improvement of data validation in contact and lead maintenance.',
        textDe: 'Verbesserung der Datenvalidierung in Kontakt- und Lead-Pflege.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Minor improvements regarding styling, bugfixing and performance improvements.',
        textDe: 'Kleinere Verbesserungen bzgl. Styling, sowie Bugfixing und Performance-Verbesserungen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.18',
    releaseDate: '2019-07-15',
    entries: [
      {
        type: 'feature',
        textEn:
          'Introduction of the Subscription Assistant. The assistant can be started from the shop details to get an overview of running, expiring and expired plugin subscriptions. Renewable subscriptions can be booked together via a simple shopping cart.',
        textDe:
          'Einführung des Subscription-Assistenten. Aus den Shop-Details kann der Assistent gestartet werden um eine Übersicht über laufende, ablaufende und abgelaufene Plugin-Subscriptions zu bekommen. Verlängerbare Subscriptions können über einen einfachen Warenkorb gemeinsam gebucht werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'The billing address can now be edited at any time.',
        textDe: 'Die Rechnungsadresse ist nun zu jedem Zeitpunkt änderbar.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'Various adaptations of the UI, usability improvements and referencing of suitable documentation articles',
        textDe:
          'Diverse Anpassungen der UI, Usability-Verbesserungen und Referenzierung passender Dokumentationsartikel',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.17',
    releaseDate: '2019-07-01',
    entries: [
      {
        type: 'feature',
        textEn:
          'The go live of a Shopware 6 environment via the migration wizard must now be explicitly confirmed. This process is not reversible.',
        textDe:
          'Der Livegang einer Shopware 6 Umgebung über den Migrationsassistenten muss jetzt explizit bestätigt werden. Dieser Vorgang ist nicht revidierbar.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Go-Live automatically terminates current rentals and support contracts for free plugins on the Shopware 5 environment.',
        textDe:
          'Im Zuge des Livegangs werden laufende Mieten und laufende Support-Verträge für kostenlose Plugins auf der Shopware 5 Umgebung automatisch beendet.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Licensing of test licenses on a Shopware 6 migration environment with a validity of the trial versions until the expiration of the migration environment. This applies regardless of how the trial version is purchased (Shopware Store / Shopware Account).',
        textDe:
          'Die Lizensierung von Testlizenzen auf einer Shopware 6 Migrationsumgebung erfolgt jetzt mit einer Gültigkeit der Testversionen bis zum Ablauf der Migrationsumgebung. Dies gilt unabhängig von der Art des Bezugs der Testversion (Shopware Store / Shopware Account).',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Moving free and trial plugins from one shop to another was made possible.',
        textDe: 'Der Umzug von kostenlosen und Test-Plugins wurde ermöglicht.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'CSV export possibility of bookings within the partner account.',
        textDe: 'CSV-Exportmöglichkeit von Buchungen innerhalb des Partnerkontos.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Various minor adjustments of the UI and bugfixing. The portal is now accessible by clicking on the Shopware logo.',
        textDe:
          'Diverse kleinere Anpassungen der UI und Bugfixing. Das Portal ist jetzt über Click auf das Shopware-Logo erreichbar.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.16',
    releaseDate: '2019-06-17',
    entries: [
      {
        type: 'feature',
        textEn:
          'Renaming of the workspaces. The former plugin management is now the "manufacturer area". The shop management is now called "merchant area".',
        textDe:
          'Umbenennung der Arbeitsbereiche. Das frühere Pluginmanagement ist jetzt der "Herstellerbereich". Die Shopverwaltung heißt jetzt "Shopbetreiberbereich".',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Refactoring of the mask for creating customers. Salutation and language are new mandatory fields.',
        textDe: 'Refaktorierung der Maske zum Erstellen von Kunden. Anrede und Sprache sind neue Pflichtfelder.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Various adjustments of the UI and measures to increase the performance of individual views.',
        textDe: 'Diverse Anpassungen der UI und Maßnahmen zur Steigerung der Performance einzelner Ansichten.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.15',
    releaseDate: '2019-05-27',
    entries: [
      {
        type: 'feature',
        textEn:
          'You can now create Shopware 6 migration environments for your own shops and those of your customers. The migration wizard is used for the license migration of the shop.',
        textDe:
          'Für Deine eigenen Shops und die Shops Deiner Kunden können jetzt Shopware 6 Migrationsumgebungen angelegt werden. Über den Migrationsassistenten wird die lizenztechnische Migration des Shops durchgeführt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.13',
    releaseDate: '2019-04-02',
    entries: [
      {
        type: 'feature',
        textEn:
          'Archiving function for plugin licenses: Unused licenses of your customers can now be archived on the respective shop detail page.',
        textDe:
          'Archivierungsfunktion für Pluginlizenzen: Ungenutzte Lizenzen Deiner Kunden kannst Du jetzt auf der jeweiligen Shop-Detailseite archivieren.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.12',
    releaseDate: '2019-02-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'The "Account" section has been added. After login you will now be directed to a portal. From here you can enter the areas according to your role.',
        textDe:
          'Bereich "Account" wurde hinzugefügt. Nach Login wirst Du jetzt in ein Portal geleitet. Von hier können die Bereiche entsprechend seiner Rolle betreten werden.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'The billing aspects of the account can be viewed and managed under "Billing". Here you will find your partner commissions, payment methods, invoices and credit notes at a glance.',
        textDe:
          'Unter "Buchhaltung" können die buchhalterischen Aspekte des Account eingesehen und verwaltet werden. Hier findest Du unter anderem Deine Partner-Provisionen, sowie Zahlungsarten, Rechnungen und Gutschriften übersichtlich auf einen Blick.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.11',
    releaseDate: '2019-02-05',
    entries: [
      {
        type: 'feature',
        textEn:
          'If a plugin subscription for a customer plugin is no longer offered, instead of extending the plugin subscription, there is now the option to remove it and switch to the lifetime model. This model includes the right to download any compatible version of the plugin.',
        textDe:
          'Falls eine Plugin-Subscription für ein Kundenplugin nicht mehr angeboten wird steht statt der Verlängerung der Plugin-Subscription jetzt die Möglichkeit bereit, diese zu entfernen und in das Lifetime-Modell zu wechseln. Mit diesem Modell geht die Berechtigung auf Download jeglicher kompatibler Version des Plugins einher.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Improvements of shop and license details in enterprise staging environments.',
        textDe: 'Verbesserung der Shop- und Lizenzdetails im Falle von Enterprise-Staging-Umgebungen.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'The Academy area has been introduced and is available to all users of the account.',
        textDe: 'Der Academy-Bereich wurde eingeführt und steht allen Nutzern des Account zur Verfügung.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Improvement of various UI components.',
        textDe: 'Verbesserung verschiedener UI-Komponenten.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.10',
    releaseDate: '2019-01-21',
    entries: [
      {
        type: 'feature',
        textEn:
          'The plugins of customers can now be managed in the partner account. Rentals and paid support for free plugins can be cancelled or reactivated. Plugin subscriptions can now also be extended directly in the partner account.',
        textDe:
          'Die Plugins von Kunden können im Partner-Account jetzt verwaltet werden. Mieten und kostenpflichtiger Support bei kostenlosen Plugins können gekündigt bzw. reaktiviert werden. Auch Plugin-Subscriptions können jetzt direkt im Partneraccount für Kunden verlängert werden.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Possibility of redeeming one-time tickets.',
        textDe: 'Möglichkeit des Einlösens von Einmal-Tickets.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Minor improvements to text blocks and visual bug fixes.',
        textDe: 'Kleine Verbesserungen an Textblöcken und Behebung visueller Bugs.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.9',
    releaseDate: '2018-12-18',
    entries: [
      {
        type: 'feature',
        textEn: 'Wildcard instances can now be deleted',
        textDe: 'Wildcard-Instanzen können jetzt gelöscht werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Administration of the shop subscription for customers possible',
        textDe: 'Verwaltung des Wartungsvertrages für Kunden möglich.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Revision of the English translations',
        textDe: 'Überarbeitung der englischen Übersetzungen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.0',
    releaseDate: '2018-07-24',
    entries: [
      {
        type: 'feature',
        textEn:
          'Release of the new partner area (ALPHA). A changelog is maintained in the course of the final version.',
        textDe:
          'Veröffentlichung des neuen Partner Bereiches (ALPHA). Ein Changelog wird im Zuge der finalen Version gepflegt.',
        feedback: false,
      },
    ],
  },
];

export const CHANGELOG_SHOPS: Changelog[] = [
  {
    versionNumber: '2.0.51',
    releaseDate: '2021-03-03',
    entries: [
      {
        type: 'bugfix',
        textEn:
          'When logging into the account in and entering the wrong password, it could happen after a new, correct login that the account could not be displayed correctly. This is now fixed.',
        textDe:
          'Beim Login den Account und Eingabe des falschen Passwortes konnte es nach erneutem, korrektem Login vorkommen, das der Account nicht korrekt dargestellt werden konnte. Das ist nun behoben.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'In the shop details of a cloud shop, there is now the option to cancel the cloud shop at the next possible time or to delete it directly. All further details are displayed during the process.',
        textDe:
          'In den Shopdetails eines Cloud Shops gibt es nun die Möglichkeit, den Cloud Shop zum nächstmöglichen Zeitpunkt zu Kündigen oder direkt zu Löschen. Alle weiteren Details werden während des Vorgangs angezeigt.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'In the case of submitted ratings for extensions, the subsequent change of the specified name was not saved correctly.',
        textDe:
          'Bei abgegebenen Bewertungen für Erweiterungen wurde das nachträgliche Ändern des angegebenen Namens nicht korrekt gespeichert.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.50',
    releaseDate: '2021-02-22',
    entries: [
      {
        type: 'feature',
        textEn:
          'When verifying the Paypal address as a standard payment method at cloud shops, only 1ct is now debited once. The payment method is then considered verified. For each additional cloud shop, the 1ct booking for this Paypal payment method is no longer performed.',
        textDe:
          'Bei der Verifizierung der Paypal Adresse als Standardzahlungsart bei Cloud Shops wird nun nur noch einmal 1ct abgebucht. Die Zahlart wird dann als Verifiziert betrachtet. Bei jedem weiteren Cloud Shop wird die 1ct Buchung für diese Paypal Zahlart nicht mehr durchgeführt.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'When creating developer support tickets, we display a note about valid response times.',
        textDe:
          'Beim Erstellen von Entwickler-Support Tickets zeigen wir einen Hinweis zu den gültigen Reaktionszeiten an.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.39',
    releaseDate: '2020-09-16',
    entries: [
      {
        type: 'feature',
        textEn:
          'The plugin withdrawal process has been further optimized. For the different application situations (open requests, accepted or rejected requests) you will now receive notifications in the notification center of the account.',
        textDe:
          'Der Rücknahme Prozess für Plugins wurde weiter optimiert. Für die verschiedenen Antragssituationen (offene Anträge, angenommene oder abgelehnte Anträge) erhälst Du nun Benachrichtigungen im Notification Center des Account.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.38',
    releaseDate: '2020-09-02',
    entries: [
      {
        type: 'feature',
        textEn:
          'In the shop listing, a badge is displayed next to the shop domain if this shop is a client of a shop with an enterprise license. On the detail page of both shops the relationship is shown again and the shops are linked accordingly.',
        textDe:
          'Im Shoplisting wird neben der Shopdomain ein Badge angezeigt, wenn dieser Shop ein Mandant eines Shops mit Enterprise Lizenz ist. Auf der Detailseite beider Shops wird die Beziehung nochmal angezeigt und entsprechend die Shops verlinkt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.37',
    releaseDate: '2020-08-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'The navigation for user-specific information has been optimised and this has been integrated into the new menu item “My Data". There you will now find your company memberships and personal user settings.',
        textDe:
          'Die Navigation für die benutzerspezifischen Informationen wurde optimiert und diese im neuen Menüpunkt "Meine Daten" eingegliedert. Dort findest Du in nun Deine Unternehmensmitgliedschaften und persönlichen Nutzereinstellungen.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'The presentation of Gross & Net amounts in the cloud shop segment was standardized. All net amounts are now displayed. ',
        textDe:
          'Die Darstellung der Brutto/Netto-Beträge im Bereich der Cloud-Shops wurde vereinheitlicht. Es werden nun alle Beträge Netto angezeigt.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.28',
    releaseDate: '2020-03-18',
    entries: [
      {
        type: 'feature',
        textEn:
          'For support requests some information is very important. Therefore, when creating a new Plugin Support Ticket, an introductory text is now also displayed. This text tells you which information in the ticket is important.',
        textDe:
          'Bei Support Anfragen sind einige Informationen sehr wichtig. Daher wird beim Erstellen eines neuen Plugin-Support Tickets nun auch ein anleitender Text angezeigt. Dieser gibt Aufschluss darüber, welche Informationen im Ticket wichtig sind.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Users who have been invited to a company can now leave the company via the "My settings" section.',
        textDe:
          'Benutzer, welche in eine Firma eingeladen wurden, können diese nun über den Bereich "Meine Einstellungen" auch wieder verlassen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.27',
    releaseDate: '2020-02-04',
    entries: [
      {
        type: 'feature',
        textEn:
          'In the Shopware account, the user administration is now available to all users in the "My company" area. In the new user administration it is possible to define different users of the Shopware account. These users can be assigned different roles with corresponding access rights. In this way, different employees of a company only have access to those areas that are relevant for them.',
        textDe:
          'Im Shopware Account steht nun allen Benutzern im Bereich "Meine Firma" ab sofort die Benutzerverwaltung zur Verfügung. In der neuen Benutzerverwaltung ist es möglich verschiedene Nutzer des Shopware Accounts zu definieren. Diesen Nutzern können verschiedene  Rollen mit entsprechenden Zugriffsrechten zugewiesen werden. So erhalten verschiedene Mitarbeiter einer Firma nur noch Zugriff auf die Bereiche, die für sie relevant sind.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.26',
    releaseDate: '2020-01-21',
    entries: [
      {
        type: 'bugfix',
        textEn:
          'In some tables it could happen that the column contents were not shortened correctly. This error is now fixed.',
        textDe:
          'In einigen Tabellen konnte es vorkommen, dass die Spalteninhalte nicht korrekt eingekürzt wurden. Dieser Fehler ist nun behoben.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.25',
    releaseDate: '2020-01-08',
    entries: [
      {
        type: 'feature',
        textEn:
          'The three-month minimum contract period for plugin rentals has been removed. A plugin rental can now be terminated at any time on the next billing day.',
        textDe:
          'Die dreimonatige Mindestvertragslaufzeiten für Pluginmieten wurde entfernt. Eine Miete ist somit jederzeit zum nächsten Abrechnungstag kündbar.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.23',
    releaseDate: '2019-12-01',
    entries: [
      {
        type: 'feature',
        textEn:
          'The license transfer assistent can now also be used to transfer a shop including all licenses and the domain itself.',
        textDe:
          'Der Lizenzübertragungs-Assistent kann jetzt auch für den Umzug eines Shops inkl. aller Lizenzen und der Domain selbst genutzt werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Redesign of the left area of the account.',
        textDe: 'Redesign des linken Bereich des Account.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.22',
    releaseDate: '2019-11-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'Implementation of a license transfer assistant. With this wizard you can transfer licenses of your own shops to another shopware account. After checking and approval by shopware, a pending transfer is displayed on the portal page of the license recipient and can be called up and accepted.',
        textDe:
          'Implementierung eines Lizenzumzugsassistenten. Mit diesem können Lizenzen eigener Shops auf einen anderen shopware Account übertragen werden. Nach Prüfung und Freigabe durch shopware wird ein anstehender Umzug auf der Portalseite des Lizenzempfängers angezeigt und kann darüber aufgerufen und angenommen werden.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.21',
    releaseDate: '2019-10-22',
    entries: [
      {
        type: 'feature',
        textEn:
          'The shopware Wiki was integrated in the account and is linked as link "Documentation" in the navigation. Furthermore the FAQ was linked on the detail pages.',
        textDe:
          'Das shopware Wiki wurde im Account integriert und ist als Link "Dokumentation" in der Navigation verlinkt. Weiterhin wurde auf den einzelnen Detailseiten das FAQ verlinkt.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'The credit balance of a shop can now be transferred to other shops of the company in the account details of the corresponding shop.',
        textDe:
          'Das Guthaben eines Shops kann in den Kontodetails des entsprechenden Shops nun auf andere Shops des Unternehmens übertragen werden.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.20',
    releaseDate: '2019-08-27',
    entries: [
      {
        type: 'feature',
        textEn: 'Introduction of an export option for bookings, invoices, credit notes and commissions in CSV format.',
        textDe:
          'Einführung einer Export-Möglichkeit für Buchungen, Rechnungen, Gutschriften und Provisionen im CSV-Format.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn:
          'Licenses with recurring payments (rentals, paid support) are now displayed as a separate tab in the license overview in the account.',
        textDe:
          'Lizenzen mit wiederkehrender Zahlung (Mieten, kostenpflichtiger Support) werden jetzt als eigener Reiter in der Lizenzübersicht im Account angezeigt.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.19',
    releaseDate: '2019-08-12',
    entries: [
      {
        type: 'feature',
        textEn:
          'Introduction of a Notification Center. We now inform about important events in the account via the bell in the upper left corner.',
        textDe:
          'Einführung eines Notification Center. Über die Glocke im oberen linken Rand informieren wir jetzt über wichtige Ereignisse im Account.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Alternative verification procedure when creating shops based on DNS.',
        textDe: 'Alternatives Verifizierungsverfahren bei der Erstellung von Shops auf Grundlage von DNS.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Introduction of documentation links for each page.',
        textDe: 'Einführung von Dokumentationslinks für jede Seite.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Store, wiki and homepage are now referenced in the account in the lower links menu.',
        textDe: 'Store, Wiki und Homepage werden jetzt im Account im Menü unten links referenziert.',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Minor improvements regarding styling, bugfixing and performance improvements.',
        textDe: 'Kleinere Verbesserungen bzgl. Styling, sowie Bugfixing und Performance-Verbesserungen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.18',
    releaseDate: '2019-07-15',
    entries: [
      {
        type: 'feature',
        textEn:
          'Introduction of the Subscription Assistant. The assistant can be started from the shop details to get an overview of running, expiring and expired plugin subscriptions. Renewable subscriptions can be extended together via a simple shopping cart.',
        textDe:
          'Einführung des Subscription-Assistenten. Aus den Shop-Details kann der Assistent gestartet werden um eine Übersicht über laufende, ablaufende und abgelaufene Plugin-Subscriptions zu bekommen. Verlängerbare Subscriptions können über einen einfachen Warenkorb gemeinsam verlängert werden.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'The billing address can now also be changed after the first booking or invoice.',
        textDe: 'Die Rechnungsadresse ist jetzt auch nach erster Buchung bzw. Rechnung änderbar.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn:
          'Various adaptations of the UI, usability improvements and referencing of suitable documentation articles',
        textDe:
          'Diverse Anpassungen der UI, Usability-Verbesserungen und Referenzierung passender Dokumentationsartikel',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.17',
    releaseDate: '2019-07-01',
    entries: [
      {
        type: 'feature',
        textEn:
          'The go-live of a Shopware 6 environment via the migration wizard must now be explicitly confirmed. This process is not revisable.',
        textDe:
          'Der Livegang einer Shopware 6 Umgebung über den Migrationsassistenten muss jetzt explizit bestätigt werden. Dieser Vorgang ist nicht revidierbar.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Go-Live automatically terminates current rentals and support contracts for free plugins on the Shopware 5 environment.',
        textDe:
          'Durch Livegang werden laufende Mieten und laufende Support-Verträge für kostenlose Plugins auf der Shopware 5 Umgebung automatisch beendet.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Licensing of test licenses on a Shopware 6 migration environment with a validity of the trial versions until the expiration of the migration environment. This applies regardless of how the trial version is purchased (Shopware Store / Shopware Account).',
        textDe:
          'Die Lizensierung von Testlizenzen auf einer Shopware 6 Migrationsumgebung erfolgt jetzt mit einer Gültigkeit der Testversionen bis zum Ablauf der Migrationsumgebung. Dies gilt unabhängig von der Art des Bezugs der Testversion (Shopware Store / Shopware Account).',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'Moving free and trial plugins from one shop to another was made possible.',
        textDe: 'Der Umzug von kostenlosen und Test-Plugins wurde ermöglicht.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'CSV export possibility of bookings within individual accounts.',
        textDe: 'CSV-Exportmöglichkeit von Buchungen innerhalb einzelner Konten.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn:
          'Various minor adjustments of the UI and bugfixing. The portal is now accessible by clicking on the Shopware logo.',
        textDe:
          'Diverse kleinere Anpassungen der UI und Bugfixing. Das Portal ist jetzt über Click auf das Shopware-Logo erreichbar.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.16',
    releaseDate: '2019-06-17',
    entries: [
      {
        type: 'feature',
        textEn:
          'Renaming of the workspaces. The former plugin management is now the "manufacturer area". The shop management is now called "merchant area".',
        textDe:
          'Umbenennung der Arbeitsbereiche. Das frühere Pluginmanagement ist jetzt der "Herstellerbereich". Die Shopverwaltung heißt jetzt "Shopbetreiberbereich".',
        feedback: true,
      },
      {
        type: 'bugfix',
        textEn: 'Shop list was revised, the column sizes were adapted.',
        textDe: 'Shop-Liste wurde überarbeitet, die Spaltengrößen wurden angepasst.',
        feedback: false,
      },
      {
        type: 'bugfix',
        textEn: 'Various adjustments of the UI and measures to increase the performance of individual views.',
        textDe: 'Diverse Anpassungen der UI und Maßnahmen zur Steigerung der Performance einzelner Ansichten.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.15',
    releaseDate: '2019-05-27',
    entries: [
      {
        type: 'feature',
        textEn:
          'You can now create a migration environment for each of your shops. The migration wizard is used for the license migration of the shop.',
        textDe:
          'Du kannst jetzt für Deine Shops jeweils eine Migrationsumgebung anlegen. Über den Migrationsassistenten wird die lizenztechnische Migration des Shops durchgeführt.',
        feedback: false,
      },
      {
        type: 'feature',
        textEn: 'In the license details, you now have the option of moving to another shop.',
        textDe: 'In den Lizenzdetails steht Dir jetzt die Möglichkeit des Umzugs auf einen anderen Shop zur Verfügung.',
        feedback: true,
      },
      {
        type: 'feature',
        textEn: 'Error messages and display errors in the loading process were processed.',
        textDe: 'Fehlermeldungen und Anzeigefehler im Auflade-Prozess wurden bearbeitet.',
        feedback: true,
      },
    ],
  },
  {
    versionNumber: '2.0.14',
    releaseDate: '2019-05-13',
    entries: [
      {
        type: 'feature',
        textEn: 'Display of shop relationships, e.g. staging relationships, directly in the shop details.',
        textDe: 'Darstellung von Shop-Beziehungen, beispielweise von Staging-Beziehungen, direkt in den Shop-Details.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.13',
    releaseDate: '2019-04-02',
    entries: [
      {
        type: 'feature',
        textEn:
          'Archiving function for plugin licenses: Unused licenses can now be archived on your shop detail page. These licenses will no longer appear in your Shopware backend. Archived licenses are displayed in the tab "Archive" and can be restored at any time.',
        textDe:
          'Archivierungsfunktion für Pluginlizenzen: Ungenutzte Lizenzen kannst Du jetzt auf Deiner Shop-Detailseite archivieren. Diese Lizenzen tauchen dann nicht mehr in Deinem Shopware Backend auf. Archivierte Lizenzen siehst Du im Tab-Reiter "Archiv" und kannst Sie jederzeit wiederherstellen.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.12',
    releaseDate: '2019-02-19',
    entries: [
      {
        type: 'feature',
        textEn:
          'Official start of the beta phase of the shop administration. The beta is feature-complete and available to all users.',
        textDe:
          'Offizieller Start der Beta-Phase der Shopverwaltung. Die Beta ist feature-complete und steht allen Nutzern zur Verfügung.',
        feedback: false,
      },
    ],
  },
  {
    versionNumber: '2.0.9',
    releaseDate: '2018-12-18',
    entries: [
      {
        type: 'feature',
        textEn:
          'Release of the new shop management area (Alpha). A changelog is maintained in the course of the final version.',
        textDe:
          'Veröffentlichung des neuen Shopbetreiber-Bereiches (Alpha). Ein Changelog wird im Zuge der finalen Version gepflegt.',
        feedback: false,
      },
    ],
  },
];
