<div
  class="avatarContainer"
  (click)="updateImage()"
  [ngStyle]="{
    'min-height': displayHeight + borderSize + 'px',
    'max-width': displayWidth + borderSize + 'px',
    'min-width': displayWidth + borderSize + 'px',
    'max-height': displayHeight + borderSize + 'px',
  }">
  <div class="imageContainer">
    <ng-container *ngIf="src === ''">
      <account-image-placeholder></account-image-placeholder>
    </ng-container>

    <img
      *ngIf="src !== ''"
      [hidden]="!loaded"
      [src]="src"
      alt="avatar"
      class="image"
      (error)="reset()"
      (load)="ready()"
      [width]="displayWidth"
      [height]="displayHeight" />

    <div class="hoverElement">
      <div class="description">
        <account-sw-icon icon="pencil-s" size="16px"></account-sw-icon>
        {{ updateText }}
      </div>
    </div>
  </div>
</div>
