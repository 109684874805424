import {
  DomainVerificationTokenData,
  ListResult,
  OnPremiseShop,
  PackagesToken,
  PluginLicense,
  PluginLicenseMigrationData,
  PluginLicenseMigrationResponse,
  ProductAcceleratorLicense,
  RelatedShops,
  SbpException,
  Shop,
  ShopBooking,
  ShopCreationSkeleton,
  ShopEnvironment,
  ShopMigrationData,
  ShopUpgradeCommercialPlanBookingInformation,
  ShopUpgradeStatusToShopware5,
  Subscription,
} from '@account/core/models';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ShopsGateway {
  constructor(private readonly apiService: ApiService) {}

  getOnPremiseShops(
    companyId: number,
    requestMetaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<OnPremiseShop[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);
    if (requestMetaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, requestMetaData.filter);
    }

    Object.entries(filter).forEach(([key, value]) => {
      params = params.set(key, value);
    });

    return this.apiService.getList(`/companies/${companyId}/onpremiseshops`, params);
  }

  getPluginLicensesByShop(
    shopId: number,
    requestMetaData: RequestMetaData,
    filter: Record<string, any> = null
  ): Observable<ListResult<PluginLicense[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.getList(`/shops/${shopId}/pluginlicenses`, params);
  }

  getProductAcceleratorLicense(shopId: number, licenseId: number): Observable<ProductAcceleratorLicense> {
    return this.apiService.get(`/shops/${shopId}/productacceleratorlicenses/${licenseId}`);
  }

  getPluginLicenseById(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.apiService.get(`/shops/${shopId}/pluginlicenses/${licenseId}`);
  }

  getShop(companyId: number, shopId: number): Observable<OnPremiseShop> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}`);
  }

  getBookings(shopId: number, requestMetaData: RequestMetaData): Observable<ListResult<ShopBooking[]>> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);

    return this.apiService.getList(`/shops/${shopId}/bookings`, params);
  }

  movePluginLicense(
    pluginLicense: PluginLicense,
    newLicenseShop: OnPremiseShop,
    newBookingShop: OnPremiseShop | null
  ): Observable<any> {
    const body: Record<string, number> = {
      targetShopId: newLicenseShop.id,
    };
    if (newBookingShop) {
      body['targetBookingShopId'] = newBookingShop.id;
    }
    return this.apiService.post(`/shops/${pluginLicense.licenseShop.id}/pluginlicenses/${pluginLicense.id}/move`, body);
  }

  migratePluginLicenses(migrationData: PluginLicenseMigrationData[]): Observable<PluginLicenseMigrationResponse[]> {
    return this.apiService.post('/pluginlicensemigrations', migrationData);
  }

  migrateShop(migrationData: ShopMigrationData): Observable<OnPremiseShop | SbpException> {
    return this.apiService.post('/shopmigrations', migrationData);
  }

  validateDomain(companyId: number, domain: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/company/${companyId}/shops/validatedomain`, { domain: domain });
  }

  requestVerificationHashForDomain(companyId: number, domain: string): Observable<DomainVerificationTokenData> {
    return this.apiService.post(`/companies/${companyId}/shops/domainhashes`, { domain: domain });
  }

  verifyDomainVerificationHash(companyId: number, domain: string): Observable<{ success: true }> {
    return this.apiService.post(`/companies/${companyId}/shops/domainverify`, { domain: domain });
  }

  precheckDomain(companyId: number, domain: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/companies/${companyId}/shops/precheckdomain`, { domain });
  }

  getShopEnvironments(): Observable<ShopEnvironment[]> {
    return this.apiService.get('/shopenvironments');
  }

  // verifies domain and adds shop if verification was successful
  addShop(companyId: number, shop: ShopCreationSkeleton): Observable<OnPremiseShop> {
    return this.apiService.post(`/companies/${companyId}/shops/domainverifications`, shop);
  }

  updateShop(companyId: number, shop: OnPremiseShop): Observable<OnPremiseShop> {
    return this.apiService.put(`/companies/${companyId}/shops/${shop.id}`, shop);
  }

  verifyShop(
    companyId: number,
    shop: OnPremiseShop
  ): Observable<'domainVerificationFailed' | 'domainVerificationSuccessFul'> {
    return this.apiService.post(`/companies/${companyId}/shops/${shop.id}/verify`, shop);
  }

  getUpgradeStatusToShopware5(shop: OnPremiseShop, partnerId?: number): Observable<ShopUpgradeStatusToShopware5> {
    let params = new HttpParams();
    if (partnerId) {
      params = params.set('partnerId', partnerId.toString());
    }
    return this.apiService.get(`/companies/${shop.companyId}/shops/${shop.id}/upgradefromshopware4/status`, params);
  }

  performUpgradeToShopware5(shop: OnPremiseShop, partnerId?: number): Observable<{ code: string }[]> {
    return this.apiService.post(`/companies/${shop.companyId}/shops/${shop.id}/upgradefromshopware4`, {
      partnerId: partnerId,
    });
  }

  updateSubscription(shop: OnPremiseShop, subscription: Subscription): Observable<Subscription> {
    return this.apiService.put(`/shops/${shop.id}/subscriptions/${subscription.id}`, subscription);
  }

  getRelatedShops(companyId: number, shopId: number): Observable<RelatedShops> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/relatedshops`);
  }

  downloadBookingsExport(shopId: number, locale: string): Observable<Blob | SbpException> {
    return this.apiService.download(`/shops/${shopId}/bookingsexport/${locale}`);
  }

  transferBalance(companyId: number, shopId: number, targetShopId: number, amount: number): Observable<void> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/transferbalance`, {
      targetShop: { id: targetShopId },
      amount: amount,
    });
  }

  getCloudOrOnPremiseShop(companyId: number, shopId: number): Observable<Shop> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}`);
  }

  getPackagesComposerToken(companyId: number, shopId: number): Observable<PackagesToken> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/packagestoken`);
  }

  generatePackagesComposerToken(companyId: number, shopId: number): Observable<PackagesToken> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/packagestoken`);
  }

  verifyPackagesComposerToken(companyId: number, shopId: number, token: string): Observable<void> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/packagestoken/${token}`);
  }

  revokePackagesComposerToken(companyId: number, shopId: number, token: string): Observable<void> {
    return this.apiService.delete(`/companies/${companyId}/shops/${shopId}/packagestoken/${token}`);
  }

  getShopLicenseAgreement(companyId: number, locale: string): Observable<Blob> {
    return this.apiService.download(`/companies/${companyId}/shopupgrade/downloadlicenseagreement?language=${locale}`);
  }

  getShopLicenseAgreementForCustomer(partnerId: number, customerId: number, locale: string): Observable<Blob> {
    return this.apiService.download(
      `/partners/${partnerId}/customers/${customerId}/shopupgrade/downloadlicenseagreement?language=${locale}`
    );
  }

  getPriceIndicationForCustomer(
    partnerId: number,
    customerId: number,
    shopId: number,
    data: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<Blob> {
    const params = new HttpParams()
      .set('planId', data.planId)
      .set('plannedGmv', data.plannedGmv)
      .set('licenseAgreement', data.licenseAgreement)
      .set('chargingInterval', data.chargingInterval);

    return this.apiService.download(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/priceindication`,
      params
    );
  }

  handleCommercialPlanBooking(
    companyId: number,
    migrationSourceShopId: number,
    data: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<void> {
    return this.apiService.post(
      `/companies/${companyId}/shops/${migrationSourceShopId}/handlecommercialplanbooking`,
      data
    );
  }

  handleCommercialPlanBookingForCustomer(
    partnerId: number,
    companyId: number,
    migrationSourceShopId: number,
    data: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<void> {
    return this.apiService.post(
      `/partners/${partnerId}/customers/${companyId}/shops/${migrationSourceShopId}/handlecommercialplanbooking`,
      data
    );
  }
}
