import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'account-tab',
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.less',
  standalone: true,
})
export class TabComponent {
  @Input() id: number | string;
  @Input() heading: string;
  @Input() icon: string;
  @Input() helptext: string;
  @Input() contentAmount: number;

  @HostBinding('class.has-active') @Input() active: boolean;
  @HostBinding('class.has-border') @Input() border: true;
}
