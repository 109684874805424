export interface PaymentMean {
  id: number;
  uniqueId: string;
  paymentCode: string;
  expireMonth: number;
  expireYear: number;
  accountNumber: string;
  bankNumber: string;
  iban: string;
  bic: string;
  holder: string;
  default: boolean;
  verified: boolean;
  verificationStatus: PaymentMeanVerificationStatus;
}

export interface PaymentMeanVerificationStatus {
  id: number;
  name:
    | typeof PAYMENT_MEAN_VERIFICATION_STATUS_VERIFIED
    | typeof PAYMENT_MEAN_VERIFICATION_STATUS_UNVERIFIED
    | typeof PAYMENT_MEAN_VERIFICATION_STATUS_LATEST_PAYMENT_FAILED
    | typeof PAYMENT_MEAN_VERIFICATION_STATUS_AUTHORIZATION_FAILED;
}

export const PAYMENT_MEAN_VERIFICATION_STATUS_VERIFIED = 'verified';
export const PAYMENT_MEAN_VERIFICATION_STATUS_UNVERIFIED = 'unverified';
export const PAYMENT_MEAN_VERIFICATION_STATUS_LATEST_PAYMENT_FAILED = 'latestPaymentFailed';
export const PAYMENT_MEAN_VERIFICATION_STATUS_AUTHORIZATION_FAILED = 'authorizationFailed';
