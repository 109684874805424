import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MarketDefinition } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class MarketDefinitionsGateway {
  constructor(private readonly apiService: ApiService) {}

  getMarketDefinitions(companyId: number): Observable<MarketDefinition[]> {
    return this.apiService.get(`/companies/${companyId}/marketdefinitions`);
  }
}
