import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WildcardGateway } from '../../gateways/shop/wildcard.gateway';
import { ListResult, PackagesToken, WildcardBase, WildcardInstance, WildcardStatics } from '../../models';
import { Plugin } from '../../models/plugin/plugin.model';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class WildcardFacade {
  constructor(
    private readonly wildcardGateway: WildcardGateway,
    private readonly companiesFacade: CompaniesFacade
  ) {}

  addWildcardBase(wildcardBase: WildcardBase): Observable<WildcardBase> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.addWildcardBase(companyId, wildcardBase)));
  }

  getWildcardBaseList(): Observable<WildcardBase[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.getWildcardBaseList(companyId)));
  }

  getWildcardBase(baseId: number): Observable<WildcardBase> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.getWildcardBase(companyId, baseId)));
  }

  getWildcardInstance(baseId: number, instanceId: number): Observable<WildcardInstance> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.getWildcardInstance(companyId, baseId, instanceId)));
  }

  addWildcardInstance(wildcardInstance: WildcardInstance): Observable<WildcardInstance> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.addWildcardInstance(companyId, wildcardInstance)));
  }

  updateWildcardInstance(wildcardInstance: WildcardInstance): Observable<WildcardInstance> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.updateWildcardInstance(companyId, wildcardInstance)));
  }

  deleteWildcardInstance(wildcardInstance: WildcardInstance): Observable<WildcardInstance> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.deleteWildcardInstance(companyId, wildcardInstance)));
  }

  getDownloadUrlForPlugin(wildcardInstance: WildcardInstance, plugin: Plugin): Observable<any> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.wildcardGateway.getDownloadUrlForPlugin(companyId, wildcardInstance, plugin)
        )
      );
  }

  getWildcardLicensePlugins(
    baseId: number,
    instanceId: number,
    searchTerm: string,
    filter = {}
  ): Observable<ListResult<Plugin[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.wildcardGateway.getWildcardLicensePlugins(companyId, baseId, instanceId, searchTerm, filter)
        )
      );
  }

  getPackagesComposerToken(wildcardInstance: WildcardInstance): Observable<PackagesToken> {
    return this.wildcardGateway.getPackagesComposerToken(wildcardInstance);
  }

  generatePackagesComposerToken(wildcardInstance: WildcardInstance): Observable<PackagesToken> {
    return this.wildcardGateway.generatePackagesComposerToken(wildcardInstance);
  }

  verifyPackagesComposerToken(wildcardInstance: WildcardInstance, token: string): Observable<void> {
    return this.wildcardGateway.verifyPackagesComposerToken(wildcardInstance, token);
  }

  revokePackagesComposerToken(wildcardInstance: WildcardInstance, token: string): Observable<void> {
    return this.wildcardGateway.revokePackagesComposerToken(wildcardInstance, token);
  }

  checkForWildcardEnvironmentsRestrictions(): Observable<{ success: boolean }> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.checkForWildcardEnvironmentsRestrictions(companyId)));
  }

  getStatics(): Observable<WildcardStatics> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.wildcardGateway.getStatics(companyId)));
  }
}
