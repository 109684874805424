<div
  class="banned-container"
  [style.background-color]="'#FF5630'"
  [style.padding]="'2px'"
  [style.color]="'white'"
  [matTooltip]="tooltip | translate"
  matTooltipShowDelay="300">
  <div>
    <account-sw-icon class="banned-icon" size="12px" icon="times-hexagon"></account-sw-icon>
    <span class="banned-attention">{{ 'CORE.BANNED.HEADING' | translate }}:&nbsp;</span>
    <span class="banned-info-text" [innerHTML]="infoText | translate | safe: 'html'"></span>
  </div>
</div>
