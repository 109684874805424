import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Account,
  AutomaticBalance,
  BankAccount,
  Invoice,
  ListResult,
  PartnerPayoutListItem,
  PaymentMean,
  QueryFilter,
  Reversal,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class FinancialGateway {
  constructor(private readonly apiService: ApiService) {}

  /**
   * Bank Accounts & KickbackBankData
   * All requests concerning company accounting
   */
  getBankAccountsList(companyId: number, metaData: RequestMetaData): Observable<ListResult<Account[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }
    return this.apiService.getList(`/companies/${companyId}/accounts`, params);
  }

  getKickbackBankData(companyId: number): Observable<BankAccount[]> {
    return this.apiService.get(`/companies/${companyId}/kickbackbankdata`);
  }

  createKickbackBankData(companyId: number, bankData: BankAccount): Observable<BankAccount> {
    return this.apiService.post(`/companies/${companyId}/kickbackbankdata`, bankData);
  }

  updateKickbackBankData(companyId: number, bankData: BankAccount): Observable<BankAccount> {
    return this.apiService.put(`/companies/${companyId}/kickbackbankdata/${bankData.id}`, bankData);
  }

  registerPaymentMean(
    companyId: number,
    id: string,
    paymentType: string,
    isNewDefault: boolean
  ): Observable<PaymentMean> {
    const data = {
      id: id,
      paymentType: paymentType,
      isNewDefault: isNewDefault,
    };
    return this.apiService.post(`/companies/${companyId}/heidelpayregistration`, data);
  }

  /*
   * AutomaticBalance
   * All requests concerning automatic balance of current company
   */
  getAutomaticBalance(companyId: number): Observable<AutomaticBalance> {
    return this.apiService.get(`/users/${companyId}/automaticBalance`);
  }

  updateAutomaticBalance(companyId: number, automaticBalance: AutomaticBalance): Observable<AutomaticBalance> {
    return this.apiService.put(`/users/${companyId}/automaticBalance`, automaticBalance);
  }

  deletePaymentMean(companyId: number, toDelete: PaymentMean): Observable<void> {
    return this.apiService.delete(`/companies/${companyId}/paymentmeans/${toDelete.id}`);
  }

  /**
   * All requests concerning payouts for partners and producers
   */
  getPartnerPayoutList(companyId: number, metaData: RequestMetaData): Observable<ListResult<PartnerPayoutListItem[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }

    return this.apiService.getList(`/companies/${companyId}/partnerpayouts`, params);
  }

  downloadPartnerPayoutList(companyId: number, locale: string, metaData: RequestMetaData): Observable<Blob> {
    let params = HttpParamsBuilder.applyFilters(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }
    return this.apiService.download(`/companies/${companyId}/partnerpayoutsexport/${locale}`, params);
  }

  /*
   * Invoices & Reversals
   * All requests concerning invoices & reversals
   */
  getInvoices(
    companyId: number,
    requestMetaData: RequestMetaData,
    filter?: QueryFilter[]
  ): Observable<ListResult<Invoice[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }

    return this.apiService.getList(`/users/${companyId}/invoices`, params);
  }

  getReversalList(companyId: number, metaData: RequestMetaData): Observable<ListResult<Reversal[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }

    return this.apiService.getList(`/users/${companyId}/reversals`, params);
  }

  downloadInvoicesExport(companyId: number, locale: string, metaData: RequestMetaData): Observable<Blob> {
    let params = HttpParamsBuilder.applyFilters(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }
    return this.apiService.download(`/users/${companyId}/invoicesexport/${locale}`, params);
  }

  chargeInvoice(invoice: Invoice, paymentMean: PaymentMean): Observable<void> {
    return this.apiService.post(`/invoices/${invoice.id}/charge`, {
      heidelpayUniqueId: paymentMean.uniqueId,
      openAmount: invoice.openAmount,
    });
  }

  downloadInvoiceDocument(invoiceId: number): Observable<Blob> {
    return this.apiService.download(`/invoices/${invoiceId}/document`, new HttpParams(), new HttpHeaders());
  }

  downloadReversalDocument(invoiceId: number, reversalId: number): Observable<Blob> {
    return this.apiService.download(
      `/invoices/${invoiceId}/reversal/${reversalId}/document`,
      new HttpParams(),
      new HttpHeaders()
    );
  }

  /*
   * Payments
   * All requests concerning payments, payment-means, and charges
   */
  getRegistrationRedirectUrl(
    companyId: number,
    id: string,
    paymentType: string,
    isNewDefault: boolean,
    returnUrl: string,
    language: string
  ): Observable<any> {
    const data = {
      id: id,
      paymentType: paymentType,
      returnUrl: returnUrl,
      language: language,
      isNewDefault: isNewDefault,
    };
    return this.apiService.post(`/companies/${companyId}/redirectpaymenturlgeneration`, data);
  }

  chargeShop(
    companyId: number,
    shopId: number,
    paymentMeanId: number,
    amount: number,
    isNewDefault: boolean
  ): Observable<any> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/charge`, {
      paymentMeanId,
      amount,
      isNewDefault,
    });
  }

  getExtensionSaleTaxRate(companyId: number): Observable<number> {
    return this.apiService.get(`/companies/${companyId}/taxrates/extensionsale`);
  }

  getAvailablePaymentTypes(companyId: number): Observable<string[]> {
    return this.apiService.get(`/companies/${companyId}/availablepaymenttypes`);
  }
}
