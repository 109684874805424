import { Component, Inject, OnInit } from '@angular/core';

import { ModalComponent } from '../../modal/';
import { MODAL_DATA } from '../../modal/modal-injector';
import { ModalRef } from '../../modal/modal-ref';

@Component({
  selector: 'account-frame-preview',
  templateUrl: './frame-preview.component.html',
  styleUrl: './frame-preview.component.less',
  standalone: true,
  imports: [ModalComponent],
})
export class FramePreviewComponent implements OnInit {
  name: string;
  link: string;

  constructor(
    private readonly modalRef: ModalRef,
    @Inject(MODAL_DATA) private readonly modalData: any
  ) {}

  ngOnInit(): void {
    this.name = this.modalData.name;
    this.link = this.modalData.link;
  }

  onCloseModal(): void {
    this.modalRef.close();
  }
}
