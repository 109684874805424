import { createSelector } from '@ngrx/store';

import { SbpException, SupportTicketUpdates } from '../../../models';
import * as companyRoot from '../company-root.store';
import { SupportTicketUpdatesState } from './support-ticket-updates.reducer';

const supportTicketUpdatesState = createSelector(
  companyRoot.state,
  (state: companyRoot.State): SupportTicketUpdatesState => state.supportTicketUpdates
);

export const isLoading = createSelector(supportTicketUpdatesState, (state: SupportTicketUpdatesState) => state.loading);

export const isLoaded = createSelector(supportTicketUpdatesState, (state: SupportTicketUpdatesState) => state.loaded);

export const getError = createSelector(supportTicketUpdatesState, (state: SupportTicketUpdatesState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getSupportTicketUpdates = createSelector(
  supportTicketUpdatesState,
  (state: SupportTicketUpdatesState) => state.supportTicketUpdates
);

export const hasSupportTicketUpdates = createSelector(
  getSupportTicketUpdates,
  (supportTicketUpdates: SupportTicketUpdates, props: Record<string, any>) => {
    if (!supportTicketUpdates) {
      return false;
    }
    if (!props.context) {
      return supportTicketUpdates.producer?.overdue.length > 0 || supportTicketUpdates.producer?.open.length > 0;
      // return supportTicketUpdates.shopOwner?.answered.length > 0
      //   || supportTicketUpdates.partner?.answered.length > 0
      //   || supportTicketUpdates.producer?.open.length > 0
      //   || supportTicketUpdates.producer?.overdue.length > 0;
    }
    switch (props.context) {
      case 'shopOwner':
        return supportTicketUpdates.shopOwner?.answered.length > 0;
      case 'partner':
        return supportTicketUpdates.partner?.answered.length > 0;
      case 'producer':
        return supportTicketUpdates.producer?.open.length > 0 || supportTicketUpdates.producer?.overdue.length > 0;
      // skip default
    }
    return false;
  }
);
