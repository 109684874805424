import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { EnvironmentService } from '../environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly environmentService: EnvironmentService
  ) {}

  getToken(): string {
    return this.cookieService.get(this.environmentService.current.sessionCookieName);
  }

  saveToken(token: string): void {
    if (
      token === this.cookieService.get(this.environmentService.current.sessionCookieName) &&
      this.cookieService.get(this.environmentService.current.sessionCookieAccount2BypassGTC)
    ) {
      return;
    }
    if (this.cookieService.get(this.environmentService.current.sessionCookieName)) {
      this.destroyToken();
    }

    this.cookieService.set(
      this.environmentService.current.sessionCookieName,
      token,
      null,
      '/',
      this.environmentService.current.sessionCookieDomain
    );
  }

  destroyToken(): void {
    this.cookieService.delete(
      this.environmentService.current.sessionCookieName,
      '/',
      this.environmentService.current.sessionCookieDomain
    );
  }
}
