import { userAccountActions } from '@account/core/store/actions';
import { userAccountSelectors } from '@account/core/store/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';

import { ToastService } from '../../../shared/components/toast/toast.service';
import { UserAccountsGateway } from '../../gateways/auth';
import {
  Company,
  CompanyMembershipRolePermission,
  CreateCompanyWithBillingCountry,
  ListResult,
  PasswordChange,
  PasswordResetChange,
  PasswordResetRequest,
  SbpException,
  TokenResult,
  UserAccount,
  UserAccountRegistration,
  Verification,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { UserAccessLog } from '../../models/user/user-access-log.model';
import { TokenService } from '../../services';
import { RootState } from '../../store/root.state';
import { NavigationFacade, SessionFacade } from '../app';
import { AuthFacade } from '../auth';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class UserAccountsFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly authFacade: AuthFacade,
    private readonly sessionFacade: SessionFacade,
    private readonly navigationFacade: NavigationFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly userAccountsGateway: UserAccountsGateway,
    private readonly tokenService: TokenService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  getUserAccountId(): Observable<number> {
    return this.sessionFacade.getUserAccountId().pipe(
      filter((userAccountId: number | undefined) => undefined !== userAccountId),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  getUserAccountIdOnce(): Observable<number> {
    return this.getUserAccountId().pipe(take(1));
  }

  verify(verification: Verification): Observable<UserAccount> {
    return this.userAccountsGateway.verify(verification);
  }

  createUserAccount(registrationData: UserAccountRegistration): Observable<void> {
    this.store.dispatch(userAccountActions.createUserAccount({ payload: registrationData }));

    return this.store.select(userAccountSelectors.isLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(userAccountSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          this.handleRegistrationExceptions(error);
          return throwError(error);
        }
        return of(void 0);
      }),
      take(1)
    );
  }

  getUserAccount(): Observable<UserAccount> {
    return this.store.select(userAccountSelectors.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(this.store.select(userAccountSelectors.getUserAccount)),
      catchError((error: SbpException) => throwError(error)),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  getFirstName(): Observable<string> {
    return this.getUserAccount().pipe(
      take(1),
      map((userAccount: UserAccount) => userAccount.personalData.firstName)
    );
  }

  updateUserAccount(updatedUserAccount: UserAccount): Observable<UserAccount> {
    this.store.dispatch(userAccountActions.updateUserAccount({ payload: updatedUserAccount }));

    return this.store.select(userAccountSelectors.isLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(userAccountSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(userAccountSelectors.getUserAccount);
      }),
      take(1)
    );
  }

  createCompany(company: CreateCompanyWithBillingCountry, approveGtc: boolean): Observable<Company> {
    this.store.dispatch(userAccountActions.createCompany({ payload: { company: company, approveGtc: approveGtc } }));
    return this.waitUntilCompanyIsCreated().pipe(
      switchMapTo(this.store.select(userAccountSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.companiesFacade.getCompany();
      }),
      take(1)
    );
  }

  getPermissionsOfSelectedCompany(): Observable<CompanyMembershipRolePermission[]> {
    return this.store.select(userAccountSelectors.getPermissions);
  }

  changePassword(payload: PasswordChange): Observable<TokenResult> {
    return this.getUserAccountIdOnce().pipe(
      switchMap((id: number) => this.userAccountsGateway.changePassword(id, payload)),
      catchError((error) => {
        this.handlePasswordExceptions(error);
        return throwError(error);
      }),
      tap((tokenResult: TokenResult) => this.tokenService.saveToken(tokenResult.token))
    );
  }

  uploadAvatar(id: number, file: File): Observable<string> {
    this.store.dispatch(userAccountActions.uploadAvatar({ payload: { id: id, file: file } }));
    return this.store.select(userAccountSelectors.isLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(userAccountSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(userAccountSelectors.getAvatarUrl);
      }),
      take(1)
    );
  }

  getAvatarUrl(): Observable<string> {
    return this.store.select(userAccountSelectors.isLoaded).pipe(
      filter((isLoaded: boolean) => isLoaded),
      switchMapTo(this.store.select(userAccountSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(userAccountSelectors.getAvatarUrl);
      })
    );
  }

  requestPasswordReset(passwordResetRequest: PasswordResetRequest): Observable<void> {
    return this.userAccountsGateway.requestPasswordReset(passwordResetRequest);
  }

  passwordResetVerify(token: string): Observable<void> {
    return this.userAccountsGateway.passwordResetVerify(token);
  }

  passwordReset(token: string, newPasswords: PasswordResetChange): Observable<void> {
    return this.userAccountsGateway.passwordReset(token, newPasswords).pipe(
      catchError((error) => {
        this.handlePasswordExceptions(error);
        return throwError(error);
      })
    );
  }

  waitUntilCompanyIsCreated(): Observable<boolean> {
    return this.store.select(userAccountSelectors.isLoading).pipe(filter((loading) => !loading));
  }

  getAccessLogsList(metaData: RequestMetaData): Observable<ListResult<UserAccessLog[]>> {
    return this.getUserAccountIdOnce().pipe(
      switchMap((userAccountId: number) => this.userAccountsGateway.getAccessLogs(userAccountId, metaData))
    );
  }

  handlePasswordExceptions(exception: SbpException, throwDefaultError = true): boolean {
    switch (exception.code) {
      case 'UserAccountCredentialsException-1':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_MIN_LENGTH')
        );
        return true;
      case 'UserAccountCredentialsException-2':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_MAX_LENGTH')
        );
        return true;
      case 'UserAccountCredentialsException-3':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_PATTERN_UPPERCASE')
        );
        return true;
      case 'UserAccountCredentialsException-4':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_PATTERN_LOWERCASE')
        );
        return true;
      case 'UserAccountCredentialsException-5':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_PATTERN_NUMBER')
        );
        return true;
      case 'UserAccountCredentialsException-6':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_PATTERN_SPECIAL_CHARACTER')
        );
        return true;
      case 'UsersException-4':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('PROFILE.MASTER_DATA.TOAST.ERROR_MESSAGE_PASSWORD_CHANGE_FAILED')
        );
        return true;
      case 'UsersException-8':
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_MIN_LENGTH')
        );
        return true;
      default:
        if (throwDefaultError) {
          this.toastService.error(
            this.translateService.instant('COMMON.ERROR'),
            this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_GENERIC_ERROR')
          );
          return false;
        }
        return true;
    }
  }

  handleRegistrationExceptions(exception: SbpException): void {
    switch (exception.code) {
      case 'UserAccountException-1':
        this.toastService.error(
          this.translateService.instant('COMMON.REGISTER.ERROR.ACCOUNT_CREATION_FAILED_TITLE'),
          this.translateService.instant('COMMON.REGISTER.ERROR.EMAIL_ALREADY_TAKEN_TITLE')
        );
        break;
      default: {
        const hasPasswordException = this.handlePasswordExceptions(exception, false);
        if (!hasPasswordException) {
          this.toastService.error(
            this.translateService.instant('COMMON.REGISTER.ERROR.ACCOUNT_CREATION_FAILED_TITLE'),
            this.translateService.instant('COMMON.REGISTER.ERROR.ACCOUNT_CREATION_FAILED_MESSAGE')
          );
        }
      }
    }
  }
}
