import { NgFor, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { QueryFilterGroup } from '../../../../../core/models/api/query-filter-group.model';
import { CheckboxComponent } from '../../../checkbox/checkbox.component';
import { SwIconComponent } from '../../../sw-icon/sw-icon.component';

@Component({
  selector: 'sbp-table-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrl: './filter-group.component.less',
  standalone: true,
  imports: [CheckboxComponent, NgTemplateOutlet, SwIconComponent, NgFor],
})
export class SbpTableFilterGroupComponent {
  @Input() resultGroup: QueryFilterGroup;
  @Output() readonly onSetActive = new EventEmitter<boolean>();

  @ContentChildren(TemplateRef) templateList: QueryList<TemplateRef<any>>;

  headingAsReference = false;

  private isActive = false;
  private _formGroup: UntypedFormGroup;
  private _heading: string | TemplateRef<any>;

  @Input() set active(active: boolean) {
    if (this.formGroup) {
      this.formGroup.get('active').patchValue(active);
      this.isActive = active;
      this.onSetActive.emit(active);
    }
  }

  get active(): boolean {
    return this.isActive;
  }

  @Input() set formGroup(formGroup: UntypedFormGroup) {
    if (!formGroup.get('active')) {
      formGroup.addControl('active', new UntypedFormControl(this.active));
    } else {
      this.isActive = formGroup.get('active').value;
    }
    this._formGroup = formGroup;
  }

  get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  @Input() set heading(heading: string | TemplateRef<any>) {
    if (heading instanceof TemplateRef) {
      this.headingAsReference = true;
    }
    this._heading = heading;
  }

  get heading(): string | TemplateRef<any> {
    return this._heading;
  }

  toggleFilterGroup(): void {
    this.active = !this.active;
  }

  groupIsActiveCheckboxChange(event: Event): void {
    event.preventDefault();
  }
}
