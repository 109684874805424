import { createAction, props } from '@ngrx/store';

import { CompanyAllocations, SbpException } from '../../../models';

export const getCompanyAllocations = createAction('[Company][Allocations] get', props<{ payload: number }>());
export const getCompanyAllocationsSuccess = createAction(
  '[Company][Allocations]  get success',
  props<{ payload: CompanyAllocations }>()
);
export const getCompanyAllocationsFail = createAction(
  '[Company][Allocations]  get fail',
  props<{ payload: SbpException }>()
);

export const noCompanyAllocationsExists = createAction('[Company][Allocations] no company exists');
export const refreshCompanyAllocations = createAction('[Company][Allocations] refresh');
