import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LicenseKeyResponse } from '../../models';
import { Shop } from '../../models/shop/shop.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class LicenseKeysGateway {
  constructor(private readonly apiService: ApiService) {}

  getCommercialLicenseKeyForShop(shop: Pick<Shop, 'id'>): Observable<LicenseKeyResponse> {
    return this.apiService.get(`/shops/${shop.id}/commerciallicensekey`);
  }
}
