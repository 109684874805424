import { LanguagesFacade } from '@account/core/facades';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LocaleCurrencyPipe } from '../../../../shared/pipes';
import { Language, PaymentMean } from '../../../models';

@Component({
  selector: 'collection-account',
  templateUrl: './collection-account.component.html',
  styleUrl: './collection-account.component.less',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule, LocaleCurrencyPipe],
})
export class CollectionAccountComponent implements OnInit {
  @Input() paymentMean: PaymentMean;
  @Input() balance: number;
  @Input() hasWritePermissions = false;

  private currentLanguage: Language;

  constructor(
    private readonly router: Router,
    private readonly languagesFacade: LanguagesFacade
  ) {}

  ngOnInit(): void {
    this.languagesFacade.getSelectedLanguage().subscribe((selectedLanguage: Language) => {
      this.currentLanguage = selectedLanguage;
    });
  }

  get amountTypeClass(): string {
    return this.balance > 0 ? 'positive' : this.balance === 0 ? 'neutral' : 'negative';
  }

  open(): void {
    switch (this.currentLanguage.key) {
      case 'de':
        window.open('https://account.shopware.com/#/payment?locale=de_DE', '_blank', 'noopener');
        break;
      case 'en':
      default:
        window.open('https://account.shopware.com/#/payment?locale=en_EN', '_blank', 'noopener');
    }
  }

  get paymentMethod(): string {
    switch (this.paymentMean.paymentCode) {
      case 'DD':
        return 'DD.DB';
      case 'VA':
        return 'VA.DB';
      case 'CC':
        return 'CC.DB';
      default:
        return this.paymentMean.paymentCode;
    }
  }

  goToBilling(): void {
    this.router.navigate(['profile', 'billing']);
  }
}
