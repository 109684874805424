import { Basket } from '@account/core/models';
import { createAction, props } from '@ngrx/store';

export const setBasketForCompanyUserAccount = createAction('[Basket] set useraccount basket');
export const setBasketForCompanyUserAccountSuccess = createAction(
  '[Basket] set useraccount basket success',
  props<{ payload: { basket: Basket | null } }>()
);

export const resetBasket = createAction('[Basket] reset basket');
