<ng-template [ngIf]="item.externalLink" [ngIfThen]="external" [ngIfElse]="internal"></ng-template>

<ng-template #external>
  <a [href]="item.externalLink | translate" target="_blank" rel="noreferrer" class="entry">
    <account-sw-icon class="entry--icon" [icon]="item.icon" [color]="workspace.color"></account-sw-icon>
    <span class="entry--title">{{ item.label | translate }}</span>
    <account-sw-icon
      class="entry--external-icon"
      icon="external-link"
      size="16px"
      color="var(--sw-paragraph)"></account-sw-icon>
  </a>
</ng-template>

<ng-template #internal>
  <ng-template [ngIf]="isWorkspace" [ngIfElse]="menuEntry">
    <a class="entry" [routerLink]="item.path">
      <account-sw-icon class="entry--icon" [icon]="item.icon" [color]="item.color"></account-sw-icon>
      <span class="entry--title">{{ item.label | translate }}</span>
      <div
        *ngIf="item.badge"
        class="entry--badge"
        [class.entry--badge-has-value]="item.badge?.value"
        [ngClass]="item.badge?.cssClasses ? item.badge.cssClasses : undefined">
        <span *ngIf="item.badge?.value">{{ item.badge.value }}</span>
      </div>
    </a>
  </ng-template>
  <ng-template #menuEntry>
    <a [routerLink]="item.path" [class.is--active]="isActive" class="entry">
      <account-sw-icon class="entry--icon" [icon]="item.icon" [color]="workspace.color"></account-sw-icon>
      <span class="entry--title">{{ item.label | translate }}</span>
      <div
        *ngIf="item.badge"
        class="entry--badge"
        [class.entry--badge-has-value]="item.badge?.value"
        [ngClass]="item.badge?.cssClasses ? item.badge.cssClasses : undefined">
        <span *ngIf="item.badge?.value">{{ item.badge.value }}</span>
      </div>
    </a>
  </ng-template>
</ng-template>
