import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductLicensesGateway } from '../../gateways/licenses/product-license.gateway';
import { ProductLicense } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ProductLicensesFacade {
  constructor(private readonly productLicensesGateway: ProductLicensesGateway) {}

  getProductLicense(shopId: number): Observable<ProductLicense> {
    return this.productLicensesGateway.getProductLicense(shopId);
  }
}
