/* eslint-disable quote-props,@typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Contract, Producer, SbpException } from '../../../models';

export const actions = createActionGroup({
  source: 'Producer::Producer',
  events: {
    'Reset Producer': emptyProps(),

    'Create Producer': props<{ payload: number }>(),
    'Create Producer Fail': props<{ payload: SbpException }>(),

    'Get Producer': props<{ payload: number }>(),
    'Get Producer Success': props<{ payload: Producer }>(),
    'Get Producer Fail': props<{ payload: SbpException }>(),

    'Update Producer': props<{ payload: Producer }>(),
    'Update Producer Success': props<{ payload: Producer }>(),
    'Update Producer Fail': props<{ payload: SbpException }>(),

    'Update Icon Url': props<{ payload: File }>(),
    'Update Icon Url Success': props<{ payload: string }>(),
    'Update Icon Url Fail': props<{ payload: SbpException }>(),

    'Get Contract': props<{ payload: number }>(),
    'Get Contract Success': props<{ payload: Contract }>(),
    'Get Contract Fail': props<{ payload: SbpException }>(),

    'Update Contract': props<{ payload: { producerId: number; contract: Contract } }>(),
    'Update Contract Success': props<{ payload: Contract }>(),
    'Update Contract Fail': props<{ payload: SbpException }>(),
  },
});
