/* eslint-disable quote-props,@typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Partner, SbpException } from '../../../models';

export const actions = createActionGroup({
  source: 'Partner::Partner',
  events: {
    'Reset Partner': emptyProps(),

    'Get Partner': props<{ payload: number }>(),
    'Get Partner Success': props<{ payload: Partner }>(),
    'Get Partner Fail': props<{ payload: SbpException }>(),

    'Update Partner': props<{ payload: Partner }>(),
    'Update Partner Success': props<{ payload: Partner }>(),
    'Update Partner Fail': props<{ payload: SbpException }>(),

    'Update Partner Avatar Success': props<{ payload: string }>(),

    'Refresh Partner': emptyProps(),
  },
});
