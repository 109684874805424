<account-modal [heading]="heading" (modalClose)="cancel()" [maxWidth]="maxWidth">
  <modal-body *ngIf="textAsHTML; else plainText">
    <p [innerHTML]="text"></p>
  </modal-body>
  <ng-template #plainText>
    <p>{{ text }}</p>
  </ng-template>
  <modal-footer>
    <button class="a_button is_small" (click)="cancel()">{{ cancelButtonText }}</button>
    <button class="a_button is_primary is_small" type="primary" (click)="confirm()">{{ confirmButtonText }}</button>
  </modal-footer>
</account-modal>
