import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Language } from '../../models';
import { WikiArticle } from '../../models/wiki/wiki.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class WikiGateway {
  constructor(private readonly apiService: ApiService) {}

  getArticle(url: string, language: Language): Observable<WikiArticle> {
    return this.apiService.get(`/wiki/router/${language.key}/${url}`);
  }
}
