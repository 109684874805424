import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { StorageFactory } from '../../utils/storage.factory';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly localStorage;

  constructor() {
    this.localStorage = new StorageFactory(() => localStorage);
  }

  setItem(key: string, value: string): void {
    try {
      this.localStorage.setItem(key, value);
    } catch (error: any) {
      // TODO: watch if this is still necessary
      Sentry.captureMessage(`local storage failed: ${error.toString()}`);
      if (this.isQuotaExceeded(error)) {
        alert('You local storage is full, please clean up.');
      }
      if (this.isStorageCorrupted(error)) {
        alert(
          "You browsers local storage seems corrupted, please clear your storage by going to Tools -> Clear Recent History -> Cookies and set time range to 'Everything'. This will remove the corrupted browser storage across all sites.."
        );
      }
    }
  }

  getItem(key: string): undefined | string {
    return this.localStorage.getItem(key);
  }

  setObject(key: string, value: any): void {
    this.setItem(key, JSON.stringify(value));
  }

  getObject(key: string, defaultValue?: any): any {
    let obj = JSON.parse(this.localStorage.getItem(key));
    if (defaultValue !== undefined && obj === null) {
      obj = defaultValue;
    }
    return obj;
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }

  private isQuotaExceeded(e: any): boolean {
    let quotaExceeded = false;
    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            quotaExceeded = true;
            break;
          case 1014:
            // Firefox
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true;
            }
            break;
          // skip default
        }
      } else if (e.number === -2147024882) {
        // Internet Explorer 8
        quotaExceeded = true;
      }
    }
    return quotaExceeded;
  }

  private isStorageCorrupted(e: any): boolean {
    let isStorageCorrupted = false;
    switch (e.name) {
      // Firefox (see https://stackoverflow.com/questions/18877643/error-in-local-storage-ns-error-file-corrupted-firefox)
      case 'NS_ERROR_FILE_CORRUPTED':
        isStorageCorrupted = true;
        break;
      // skip default
    }

    return isStorageCorrupted;
  }
}
