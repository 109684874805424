import { NgIf } from '@angular/common';
import { Component, ContentChild, Directive, ElementRef, Input, ViewEncapsulation } from '@angular/core';

import { SwIconComponent } from '../sw-icon/sw-icon.component';

@Directive({
  selector: 'card-header',
  standalone: true,
})
export class CollapsibleCardHeaderDirective {}

@Directive({
  selector: 'card-body',
  standalone: true,
})
export class CollapsibleCardBodyDirective {}

@Directive({
  selector: 'card-additional',
  standalone: true,
})
export class CollapsibleCardAdditionalDirective {}

@Component({
  selector: 'account-collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrl: './collapsible-card.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, SwIconComponent],
})
export class CollapsibleCardComponent {
  @ContentChild(CollapsibleCardBodyDirective, { read: ElementRef }) bodyElement: ElementRef;
  @Input() collapsible = true;
  @Input() headerFullyLinked = false;
  @Input() showToggleLeft = true;

  open = false;

  toggleOnFullHeaderClick(): void {
    if (this.headerFullyLinked) {
      this.toggle();
    }
  }

  toggle(): void {
    if (!this.collapsible) {
      return;
    }
    this.open = !this.open;
  }
}
