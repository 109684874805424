import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ListResult,
  Locale,
  OnPremiseShop,
  Partner,
  PartnerAvatar,
  PartnerBranch,
  PartnerContact,
  PartnerOperatingCountry,
  PartnerProjectType,
  PartnerResource,
} from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PartnersGateway {
  constructor(private readonly apiService: ApiService) {}

  getPartner(companyId: number): Observable<Partner> {
    return this.apiService.get(`/partners/${companyId}`);
  }

  updatePartner(partner: Partner): Observable<Partner> {
    return this.apiService.put(`/partners/${partner.id}`, partner);
  }

  getPartnerShop(partnerId: number): Observable<OnPremiseShop> {
    return this.apiService.get(`/partners/${partnerId}/partnershop`);
  }

  updatePartnerResources(partner: Partner, resources: PartnerResource[]): Observable<PartnerResource[]> {
    return this.apiService.post(`/partners/${partner.id}/resources`, resources);
  }

  updatePartnerContactChannels(partner: Partner, contacts: PartnerContact[]): Observable<PartnerContact[]> {
    return this.apiService.post(`/partners/${partner.partnerId}/contactchannels`, contacts);
  }

  getPartnerLanguages(): Observable<ListResult<Locale[]>> {
    return this.apiService.getList('/partnerlanguages');
  }

  getPartnerBranches(): Observable<PartnerBranch[]> {
    return this.apiService.get('/partnerbranches');
  }

  getPartnerResources(partner: Partner): Observable<ListResult<PartnerResource[]>> {
    return this.apiService.getList(`/partners/${partner.id}/resources`);
  }

  getPossiblePartnerProjectTypes(): Observable<PartnerProjectType[]> {
    return this.apiService.get('/partnerprojecttypes');
  }

  uploadPartnerAvatar(partner: Partner, avatar: File): Observable<PartnerAvatar> {
    return this.apiService.upload(`/partners/${partner.id}/icon`, avatar, avatar.name);
  }

  addPartnerOperatingCountry(partner: Partner, country: PartnerOperatingCountry): Observable<PartnerOperatingCountry> {
    return this.apiService.post(`/partners/${partner.id}/operatingcountries`, country.country);
  }

  addPartnerLanguage(partner: Partner, language: Locale): Observable<Locale> {
    return this.apiService.post(`/partners/${partner.id}/languages`, language);
  }

  addPartnerBranch(partner: Partner, branch: PartnerBranch): Observable<PartnerBranch> {
    return this.apiService.post(`/partners/${partner.id}/branches`, branch);
  }

  deletePartnerOperatingCountry(partner: Partner, country: PartnerOperatingCountry): Observable<void> {
    return this.apiService.delete(`/partners/${partner.id}/operatingcountries/${country.id}`);
  }

  deletePartnerLanguage(partner: Partner, language: Locale): Observable<void> {
    return this.apiService.delete(`/partners/${partner.id}/languages/${language.id}`);
  }

  deletePartnerBranch(partner: Partner, branch: PartnerBranch): Observable<void> {
    return this.apiService.delete(`/partners/${partner.id}/branches/${branch.id}`);
  }
}
