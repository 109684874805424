<div class="links">
  <a [href]="'FOOTER.LINK_GTC' | translate" target="_blank">{{ 'COMMON.GTC' | translate }}</a>
  |
  <a [href]="'FOOTER.LINK_PRIVACY' | translate" target="_blank">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a>
  |
  <a [href]="'FOOTER.LINK_LEGAL' | translate" target="_blank">{{ 'COMMON.LEGAL_NOTICE' | translate }}</a>
  |
  <a href="javascript:void(0)" (click)="openUsercentricsModal()">{{ 'COMMON.COOKIE_SETTINGS' | translate }}</a>
</div>
© shopware AG - All rights reserved
