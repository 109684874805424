import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'wizard-steps',
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.less',
  standalone: true,
})
export class StepsComponent {
  @HostBinding('hidden') hidden = false;

  @Input()
  set showSteps(visible: boolean) {
    this.hidden = !visible;
  }
}
