import { createAction, props } from '@ngrx/store';

import { Company, MasterData, SbpException } from '../../../models';

export const getCompany = createAction('[Company] get', props<{ payload: number }>());
export const getCompanySuccess = createAction('[Company] get success', props<{ payload: Company }>());
export const getCompanyFail = createAction('[Company] get fail', props<{ payload: SbpException }>());

export const noCompanyExists = createAction('[Company] not exists');

export const refreshCompany = createAction('[Company] refresh');

export const updateMasterData = createAction('[Company] update master data', props<{ payload: MasterData }>());
