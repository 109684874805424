interface DatepickerDateFormat {
  [label: string]: string;
}
interface DatepickerLocalization {
  [label: string]: {
    firstDayOfWeek: number;
    dayNames: string[];
    dayNamesShort: string[];
    dayNamesMin: string[];
    monthNames: string[];
    monthNamesShort: string[];
  };
}

export class DatepickerStatics {
  static readonly DATE_FORMAT_DEFAULT: DatepickerDateFormat = {
    en: 'dd/mm/y',
    de: 'dd.mm.yy',
  };

  static readonly DATE_FORMAT_FULL: DatepickerDateFormat = {
    en: 'dddd, MM dd yy',
    de: 'dddd, dd MM yy',
  };

  static readonly DATE_FORMAT_MONTH: DatepickerDateFormat = {
    en: 'mm/y',
    de: 'mm.yy',
  };

  static readonly DATE_FORMAT_MONTH_FULL: DatepickerDateFormat = {
    en: 'MM, yy',
    de: 'MM, yy',
  };

  static readonly LOCALIZATION: DatepickerLocalization = {
    en: {
      firstDayOfWeek: 0,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },

    de: {
      firstDayOfWeek: 1,
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    },
  };
}
