import { PluginLocale, Tag } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PluginTagsGateway } from '../../gateways/plugins';
import { ListResult } from '../../models';
import { ProducersFacade } from './producers.facade';

@Injectable({
  providedIn: 'root',
})
export class PluginTagsFacade {
  constructor(
    private readonly pluginTagsGateway: PluginTagsGateway,
    private readonly producersFacade: ProducersFacade
  ) {}

  getPluginTags(locale: PluginLocale, searchString: string): Observable<Tag[]> {
    return this.pluginTagsGateway
      .getPluginTags(locale, searchString)
      .pipe(map((result: ListResult<Tag[]>) => result.list));
  }

  getTagSuggestions(
    pluginId: number,
    locale: string,
    pluginDescription: string,
    pluginTags: string
  ): Observable<string> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(
        switchMap((producerId: number) =>
          this.pluginTagsGateway.getTagSuggestions(pluginId, producerId, locale, pluginDescription, pluginTags)
        )
      );
  }
}
