import { ActionReducerMap } from '@ngrx/store';

import { RootState } from '../root.state';
import { reducer as allocationsReducer } from './allocations/allocations.reducer';
import { reducer as companyReducer } from './company/company.reducer';
import { State } from './company-root.state';
import { reducer as signedGTCReducer } from './signed-gtc/signed-gtc.reducer';
import { reducer as supportPermissionsReducer } from './support-permissions/support-permissions.reducer';
import { reducer as supportTicketUpdatesReducer } from './support-ticket-updates/support-ticket-updates.reducer';

export { State } from './company-root.state';

export const reducers: ActionReducerMap<State> = {
  allocations: allocationsReducer,
  company: companyReducer,
  supportPermissions: supportPermissionsReducer,
  supportTicketUpdates: supportTicketUpdatesReducer,
  signedGTC: signedGTCReducer,
};

export const state = (state: RootState): State => state.company;
