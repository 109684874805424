import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionBarService {
  private readonly actionBar = new Subject<TemplateRef<Element>>();

  setActionBar(actionBar: TemplateRef<Element>): void {
    this.actionBar.next(actionBar);
  }

  getActionBar(): Subject<TemplateRef<Element>> {
    return this.actionBar;
  }
}
