import { FeatureFlagService } from '@account/core/services';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FeatureFlagPipe } from '../../../../shared/pipes';
import { Workspace, WorkspaceItem } from '../../../models';
import { NavigationEntryComponent } from './navigation-entry/navigation-entry.component';

@Component({
  selector: 'navigation-workspace',
  templateUrl: './navigation-workspace.component.html',
  styleUrl: './navigation-workspace.component.less',
  standalone: true,
  imports: [CommonModule, NavigationEntryComponent, FeatureFlagPipe],
})
export class NavigationWorkspaceComponent {
  @Input() workspace: Workspace;
  @Input() workspaceItems: WorkspaceItem[];
  @Input() activeWorkspaceItem: WorkspaceItem;

  constructor(private readonly featureFlagService: FeatureFlagService) {}

  showNavigationEntry(workspace: WorkspaceItem): boolean {
    if (workspace.featureFlag) {
      return this.featureFlagService.flagActivated(workspace.featureFlag);
    }

    if (workspace.whenFeatureFlagDeactivated) {
      return !this.featureFlagService.flagActivated(workspace.whenFeatureFlagDeactivated);
    }

    return true;
  }

  trackByWorkspaceItem(index: number, item: WorkspaceItem): string {
    return item.label;
  }
}
