import { NgIf } from '@angular/common';
import { Component, ContentChild, Directive, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { OverflowTooltipComponent } from '../../overflow-tooltip/overflow-tooltip.component';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';

@Directive({
  selector: 'cart-item-content',
  standalone: true,
})
export class CartItemContentDirective {}

@Directive({
  selector: 'cart-item-config',
  standalone: true,
})
export class CartItemConfigDirective {}

@Component({
  selector: 'account-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrl: './shopping-cart-item.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, OverflowTooltipComponent, SwIconComponent],
})
export class ShoppingCartItemComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconUrl: string;

  @Output() readonly onRemove = new EventEmitter<void>();

  @ContentChild(CartItemContentDirective) cartItemContent: CartItemContentDirective;
  @ContentChild(CartItemConfigDirective) cartItemConfig: CartItemConfigDirective;
}
