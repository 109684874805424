import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

import { CheckboxConfirmModalComponent } from './confirm-modal/checkbox-confirm-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ModalConfig } from './modal-config.model';
import { ModalInjector } from './modal-injector';
import { ModalRef } from './modal-ref';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private componentRef: ComponentRef<any>;
  private ticked = false;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector
  ) {}

  open(component: Type<any>, modalConfig?: ModalConfig): ModalRef {
    const defaultModalConfig = new ModalConfig();
    modalConfig = Object.assign(defaultModalConfig, modalConfig);

    return this.spawn(component, modalConfig);
  }

  simpleConfirm(text: string, textAsHTML = false, maxWidth: string = null): ModalRef {
    const modalConfig = new ModalConfig();
    modalConfig.data = {
      text: text,
      textAsHTML: textAsHTML,
      maxWidth: maxWidth,
    };

    return this.spawn(ConfirmModalComponent, modalConfig);
  }

  confirm(
    heading: string,
    text: string,
    cancelButtonText: string = null,
    confirmButtonText: string = null,
    textAsHTML = false,
    maxWidth: string = null
  ): ModalRef {
    const modalConfig = new ModalConfig();
    modalConfig.data = {
      heading: heading,
      text: text,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      textAsHTML: textAsHTML,
      maxWidth: maxWidth,
    };

    return this.spawn(ConfirmModalComponent, modalConfig);
  }

  checkboxConfirm(
    heading: string,
    text: string,
    checkBoxText: string,
    cancelButtonText: string = null,
    confirmButtonText: string = null,
    textAsHTML = false,
    maxWidth: string = null
  ): ModalRef {
    const modalConfig = new ModalConfig();
    modalConfig.data = {
      heading: heading,
      text: text,
      checkBoxText: checkBoxText,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      textAsHTML: textAsHTML,
      maxWidth: maxWidth,
    };

    return this.spawn(CheckboxConfirmModalComponent, modalConfig);
  }

  info(heading: string, text: string, textAsHTML = false, maxWidth: string = null): ModalRef {
    const modalConfig = new ModalConfig();
    modalConfig.data = {
      heading: heading,
      text: text,
      textAsHTML: textAsHTML,
      maxWidth: maxWidth,
    };
    return this.spawn(InfoModalComponent, modalConfig);
  }

  private spawn(component: Type<any>, modalConfig?: ModalConfig): ModalRef {
    if (this.componentRef) {
      this.ticked = true;
      this.componentRef.destroy();
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const injector = this.injector;
    const modalRef = new ModalRef();
    const tabInjector = new ModalInjector(injector, modalRef, modalConfig.data || null);

    this.componentRef = componentFactory.create(tabInjector);
    modalRef.componentRef = this.componentRef;

    const applicationRef = this.injector.get(ApplicationRef);
    applicationRef.attachView(this.componentRef.hostView);

    const domElement = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElement);

    modalRef.afterClosed().subscribe(() => {
      applicationRef.detachView(this.componentRef.hostView);
      // if (!this.ticked) {
      //   applicationRef.tick();
      //   this.ticked = true;
      // }
      this.componentRef.destroy();
    });

    return modalRef;
  }
}
