import { AuthFacade, ProducersFacade } from '@account/core/facades';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Producer } from '../models';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class ProducerGuard {
  constructor(
    private readonly authGuard: AuthGuard,
    private readonly authFacade: AuthFacade,
    private readonly producersFacade: ProducersFacade,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authGuard.canActivate(route, state).pipe(
      switchMap((result: boolean | UrlTree) => {
        if (!result || result instanceof UrlTree) {
          return of(result);
        }
        return this.producersFacade.getProducer().pipe(
          map((producer: Producer) => {
            if (producer.cancelledContract && producer.cancelledContract.status.name === 'completed') {
              return this.router.parseUrl('producercontractcancelled');
            }
            return true;
          })
        );
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
