<div class="progress-circle" [class.has-round-edges]="hasRoundEdges">
  <span class="progress-done">
    {{ labelConfig?.formattedProgressDone ?? progressDone }}
  </span>
  <span *ngIf="labelConfig && !labelConfig?.hideTotal" class="progress-open">
    /&nbsp;{{ labelConfig.formattedProgressTotal ?? progressTotal }}
  </span>
</div>
<div
  class="progress-circle-background"
  [class.has-custom-color]="!!progressOpenColor"
  [class.has-opacity]="!!progressOpenOpacity"></div>
