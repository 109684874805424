import {
  CompaniesFacade,
  LanguagesFacade,
  NavigationFacade,
  UserAccountMembershipsFacade,
  UserAccountsFacade,
} from '@account/core/facades';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Configuration, FrontendApi, LogoutFlow } from '@ory/client';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalService } from '../../../shared/components/modal/modal.service';
import { FeatureFlagPipe } from '../../../shared/pipes';
import { AuthFacade } from '../../facades/auth';
import { CompanyMembership, Language, UserAccount, Workspace, WorkspaceItem } from '../../models';
import { ChangelogService, EnvironmentService, FeatureFlagService } from '../../services';
import { MenuOverlayComponent } from './menu-overlay/menu-overlay.component';
import { NavigationExpanderComponent } from './navigation-expander/navigation-expander.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { NavigationEntryComponent } from './navigation-workspace/navigation-entry/navigation-entry.component';
import { NavigationWorkspaceComponent } from './navigation-workspace/navigation-workspace.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { WorkspacesSelectorComponent } from './workspaces-selector/workspaces-selector.component';

@Component({
  selector: 'account-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MenuOverlayComponent,
    NavigationHeaderComponent,
    NavigationWorkspaceComponent,
    NavigationEntryComponent,
    NavigationExpanderComponent,
    UserInfoComponent,
    WorkspacesSelectorComponent,
    FeatureFlagPipe,
  ],
})
export class NavigationComponent implements OnInit {
  @HostBinding('class.is--collapsed') isCollapsed = true;

  languages: Language[];
  activeUser$: Observable<UserAccount>;
  selectedLanguage$: Observable<Language>;

  workspaces$: Observable<Workspace[]>;
  activeWorkspace$: Observable<null | Workspace>;
  activeWorkspaceItems$: Observable<WorkspaceItem[]>;
  activeWorkspaceItem$: Observable<null | WorkspaceItem>;

  isOverlayMenuOpen = false;
  isMobileViewport: boolean;

  constructor(
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly location: Location,
    private readonly navigationFacade: NavigationFacade,
    private readonly authFacade: AuthFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly userAccountsFacade: UserAccountsFacade,
    private readonly userAccountMembershipsFacade: UserAccountMembershipsFacade,
    private readonly languagesFacade: LanguagesFacade,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly environmentService: EnvironmentService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly changelogService: ChangelogService
  ) {
    // get current expanded value for HostBinding
    this.isCollapsed = !this.navigationFacade.expanded;
  }

  ngOnInit(): void {
    this.languages = this.languagesFacade.getAvailableLanguages();
    this.activeUser$ = this.userAccountsFacade.getUserAccount();
    this.selectedLanguage$ = this.languagesFacade.getSelectedLanguage();

    this.workspaces$ = this.navigationFacade.getWorkspaces();
    this.activeWorkspace$ = this.navigationFacade.getActiveWorkspace();
    this.activeWorkspaceItems$ = this.navigationFacade.getActiveWorkspaceItems();
    this.activeWorkspaceItem$ = this.navigationFacade.getActiveWorkspaceItem();

    const navigationExpanded$ = this.navigationFacade
      .isExpanded()
      .pipe(tap((isExpanded: boolean) => (this.isCollapsed = !isExpanded)));

    const toggleNavigationUponViewportChange$ = this.breakpointObserver.observe(['(max-width: 1024px)']).pipe(
      tap((viewport: BreakpointState) => {
        if (viewport.matches && !this.navigationFacade.expanded) {
          this.toggleNavigation();
        }
      })
    );

    const routeChange$ = this.navigationFacade.onRouteChange();

    merge(navigationExpanded$, routeChange$, toggleNavigationUponViewportChange$).subscribe();
  }

  selectWorkspace(workspace: Workspace): void {
    this.router.navigate([workspace.path]);
  }

  changeSelectedMembership(membership: CompanyMembership): void {
    this.userAccountMembershipsFacade.changeSelectedMembership(membership).subscribe(() => {
      this.router.navigate(['changecompany'], { skipLocationChange: true });
    });
  }

  logout(): void {
    if (this.featureFlagService.flagActivated('ory')) {
      const basePath = this.environmentService.current.auth_api_url;
      const ory = new FrontendApi(
        new Configuration({
          basePath,
          baseOptions: {
            withCredentials: true,
          },
        })
      );

      const returnTo = `${window.location.protocol}//${window.location.host}`;
      ory
        .createBrowserLogoutFlow({
          returnTo: returnTo,
        })
        .then((response) => response.data)
        .then((logoutFlow: LogoutFlow) => {
          window.location.href = logoutFlow.logout_url;
        });

      return;
    }

    this.authFacade.logout().subscribe(() => {
      // explicitly clear redirect url, if user has logged in via deeplink and than logs out and in again he should not be redirected to the original deeplink url
      this.authFacade.setRedirectUrl();
      this.router.navigate(['front']);
    });
  }

  toggleNavigation(): void {
    this.navigationFacade.toggleNavigation();
  }

  changeLanguage(language: Language): void {
    this.toggleOverlayMenu();
    this.languagesFacade.selectLanguage(language);
  }

  toggleOverlayMenu(): void {
    this.isOverlayMenuOpen = !this.isOverlayMenuOpen;
  }

  openChangelogModal(force = false): void {
    if (this.isOverlayMenuOpen) {
      this.toggleOverlayMenu();
    }
    this.changelogService.openChangelogModal(force);
  }

  goToPortal(): void {
    this.router.navigate(['/portal']);
  }

  goToUserAccountSettings(): void {
    this.router.navigate(['/settings']).then(() => {
      this.toggleOverlayMenu();
    });
  }

  trackByWorkspace(index: number, item: Workspace): string {
    return item.name;
  }
}
