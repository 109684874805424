import { ActionReducerMap } from '@ngrx/store';

import { RootState } from '../root.state';
import * as fromInvitations from './invitations/invitations.reducer';
import * as fromMemberships from './memberships/memberships.reducer';
import * as fromPermissions from './permissions/permissions.reducer';
import * as fromUserAccount from './useraccount/useraccount.reducer';

export const state = (state: RootState): State => state.userAccount;

export interface State {
  invitations: fromInvitations.InvitationsState;
  memberships: fromMemberships.MembershipsState;
  permissions: fromPermissions.PermissionsState;
  userAccount: fromUserAccount.UserAccountState;
}

export const reducers: ActionReducerMap<State> = {
  invitations: fromInvitations.reducer,
  memberships: fromMemberships.reducer,
  permissions: fromPermissions.reducer,
  userAccount: fromUserAccount.reducer,
};
