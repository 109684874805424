import { NgFor, NgIf } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';

import { HelptextComponent } from '../helptext/helptext.component';
import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { TabComponent } from '../tabs/tab/tab.component';

@Component({
  selector: 'account-navbar-tabs',
  templateUrl: './navbar-tabs.component.html',
  styleUrl: './navbar-tabs.component.less',
  standalone: true,
  imports: [SwIconComponent, NgFor, NgIf, HelptextComponent],
})
export class NavbarTabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Input() isCardNavigation: boolean;
  @Input() stickyHeader = false;

  @Output() readonly onTabChanged = new EventEmitter<TabComponent>();

  ngAfterContentInit(): void {
    this.setDefaultActivatedTab();
  }

  setDefaultActivatedTab(): void {
    if (this.tabs.filter((tab) => tab.active).length === 0) {
      this.tabs.first.active = true;
    }
  }

  activateTab(tab: TabComponent): void {
    this.tabs.forEach((currentTab) => {
      currentTab.active = false;
    });
    tab.active = true;
    this.onTabChanged.emit(tab);
  }

  nextTab(): void {
    let nextTabShouldBeActive = false;

    this.tabs.forEach((currentTab) => {
      if (nextTabShouldBeActive) {
        currentTab.active = true;
        this.onTabChanged.emit(currentTab);
        nextTabShouldBeActive = false;
      } else if (currentTab.active && this.tabs.last !== currentTab) {
        nextTabShouldBeActive = true;
        currentTab.active = false;
      }
    });
  }

  previousTab(): void {
    let nextTabShouldBeActive = false;

    this.tabs
      .toArray()
      .reverse()
      .forEach((currentTab) => {
        if (nextTabShouldBeActive) {
          currentTab.active = true;
          this.onTabChanged.emit(currentTab);
          nextTabShouldBeActive = false;
        } else if (currentTab.active && this.tabs.first !== currentTab) {
          nextTabShouldBeActive = true;
          currentTab.active = false;
        }
      });
  }
}
