import { ShopSuccessorGateway } from '@account/core/gateways';
import { OnPremiseShop, SwPlatformShopPluginMigration } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShopSuccessorFacade {
  constructor(private readonly shopSuccessorGateway: ShopSuccessorGateway) {}

  createShopForSwPlatform(shop: OnPremiseShop, migrationShopDomain: string): Observable<OnPremiseShop> {
    return this.shopSuccessorGateway.createShopForSwPlatform(shop.companyId, shop.id, migrationShopDomain);
  }

  getSwPlatformSuccessorShopByPredecessor(predecessorShop: OnPremiseShop): Observable<OnPremiseShop | null> {
    return this.shopSuccessorGateway.getSwPlatformSuccessorShopByPredecessor(
      predecessorShop.companyId,
      predecessorShop.id
    );
  }

  getPluginMigrationListForSWPlatformShop(
    successorShopId: number,
    mode: 'free' | 'commercial'
  ): Observable<SwPlatformShopPluginMigration[]> {
    return this.shopSuccessorGateway.getPluginMigrationListForSWPlatformShop(successorShopId, mode);
  }

  bookPluginSuccessorsForSWPlatformShop(
    successorShopId: number,
    mode: 'free' | 'commercial',
    migrations: SwPlatformShopPluginMigration[]
  ): Observable<SwPlatformShopPluginMigration[]> {
    return this.shopSuccessorGateway.bookPluginSuccessorsForSWPlatformShop(successorShopId, mode, migrations);
  }

  releaseSwPlatformShop(shop: OnPremiseShop): Observable<OnPremiseShop> {
    return this.shopSuccessorGateway.releaseSwPlatformShop(shop.companyId, shop.id);
  }
}
