import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResult, PluginBundle } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PluginBundlesGateway {
  constructor(private readonly apiService: ApiService) {}

  addBundle(pluginBundle: PluginBundle): Observable<PluginBundle> {
    return this.apiService.post('/pluginbundles', pluginBundle);
  }

  updateBundle(pluginBundle: PluginBundle): Observable<PluginBundle> {
    return this.apiService.put(`/pluginbundles/${pluginBundle.id}`, pluginBundle);
  }

  getBundle(bundleId: number): Observable<PluginBundle> {
    return this.apiService.get(`/pluginbundles/${bundleId}`);
  }

  getBundleList(metaData: RequestMetaData, producerId: number): Observable<ListResult<PluginBundle[]>> {
    const params = new HttpParams()
      .set('limit', metaData.limit.toString())
      .set('offset', metaData.offset.toString())
      .set('orderBy', metaData.orderBy)
      .set('orderSequence', metaData.orderSequence)
      .set('search', metaData.search)
      .set('producerId', producerId.toString());

    return this.apiService.getList('/pluginbundles', params);
  }

  deleteBundle(bundleId: number): Observable<any> {
    return this.apiService.delete(`/pluginbundles/${bundleId}`);
  }
}
