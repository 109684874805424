import { combineReducers, createFeature } from '@ngrx/store';

import { partnerStoreConfig } from './partner';
import { featureName } from './partner-feature.selector';
import { supportPermissionsStoreConfig } from './support-permissions';

export const feature = createFeature({
  name: featureName,
  // combine all reducers for feature
  reducer: combineReducers({
    partner: partnerStoreConfig.reducer,
    supportPermissions: supportPermissionsStoreConfig.reducer,
  }),
});
