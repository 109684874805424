import { CloudShopSandbox, CloudShopSandboxResponse } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class CloudShopSandboxGateway {
  constructor(private readonly apiService: ApiService) {}

  getCloudShopSandbox(): Observable<CloudShopSandbox | null> {
    return this.apiService.get('/cloudshop/sandbox').pipe(
      switchMap((cloudShopSandboxResponse: CloudShopSandboxResponse) => {
        if (cloudShopSandboxResponse.id) {
          return of(cloudShopSandboxResponse as CloudShopSandbox);
        } else {
          return of(null);
        }
      })
    );
  }
  createCloudShopSandbox(): Observable<CloudShopSandbox> {
    return this.apiService.post('/cloudshop/sandbox');
  }
}
