import { NgIf } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'account-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class ContactComponent {
  /**
   * Defines whether or not the contact telephone number is displayed.
   */
  @Input() showDirectPhoneContact = false;

  @HostBinding('class') get classes(): string {
    return 'account-contact';
  }
}
