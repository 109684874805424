import { Address, Country, Locale } from '../common';

export const integrationPartnerStatusList = ['bronze', 'silver', 'gold', 'platinum', 'reseller'];
export const techPartnerStatusList = ['stpsilver', 'stpgold', 'stpplatinum'];

export type PartnerStatusName =
  | 'consultant'
  | 'sar'
  | 'sbp'
  | 'sep'
  | 'ssp'
  | 'stp'
  | 'stpSilver'
  | 'stpGold'
  | 'stpPlatinum'
  | 'reseller'
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'platinum';

export interface Partner {
  id: number;
  partnerId: number;
  companyId: number;
  name: string;
  descriptions: PartnerDescription[];
  languages: Locale[];
  branches: PartnerBranch[];
  operatingCountries: PartnerOperatingCountry[];
  minProjectVolume: number;
  freeResources: PartnerResource[];
  certifications: PartnerCertification[];
  companyName: string;
  companySizeRange: PartnerCompanySizeRange;
  consumeCreditVoucherWithPartnerAccount: boolean;
  contactChannels: PartnerContact[];
  customerNumber: string;
  iconUrl: string;
  isHidden: boolean;
  isIconSet: boolean;
  partnerSince: string | null;
  priority: number;
  userId: number;
  statusList: PartnerStatus[];
  affiliateKey?: string;
  projectTypes: PartnerProjectType[];
  status?: PartnerStatus;
}

export interface PartnerDescription {
  id: number;
  locale: Locale;
  content: string;
}

export interface PartnerOperatingCountry {
  id: number;
  country: Country;
}

export interface PartnerCompanySizeRange {
  id: number;
  name: string;
  min: number;
  max: number;
  description: string;
}

export interface PartnerResource {
  id: number;
  name: string;
  description: string;
  isFree: boolean | null;
  orderVal: number;
  public: boolean;
}

export interface PartnerCertification {
  active: boolean;
  article: {} | null; // TODO
  attendanceDate: string;
  certificate: PartnerCertificate;
  expirationDate: string;
  id: number | null;
  participant: string;
  passed: boolean;
  status: PartnerStatus;
  statusList: PartnerStatus[];
}

export interface PartnerCertificate {
  name: string;
  description: string;
}

export interface PartnerContact extends Address {
  id: number;
  email: string;
  fax: string;
  name: string;
  phone: string;
  website: string;
}

export interface PartnerStatus {
  id: number | null;
  name: PartnerStatusName;
  description: string;
  discount: number;
  dispo: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  prio_sort: number;
}

export interface PartnerAvatar {
  icon: string;
}

export interface PartnerCustomerMembershipStatus {
  hasMemberships: boolean;
  hasInvitations: boolean;
  adminUsers: string[];
  invitations: string[];
}

export interface PartnerBranch {
  id: number;
  name: string;
  label: string;
}

export interface PartnerProjectType {
  id: number;
  name: string;
}
