import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

import { StatusComponent } from '../../status/status.component';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { Toast } from '../toast.model';

@Component({
  selector: 'account-toast-item',
  templateUrl: './toast-item.component.html',
  styleUrl: './toast-item.component.less',
  standalone: true,
  imports: [StatusComponent, NgIf, SwIconComponent],
})
export class ToastItemComponent implements AfterViewInit {
  @Input() toast: Toast;
  @Output() readonly closeToast = new EventEmitter();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.renderer.addClass(this.elementRef.nativeElement, 'is--active');
      });
    }, 20);
  }

  close(): void {
    this.closeToast.emit(this.toast);
  }
}
