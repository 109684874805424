import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
} from '@angular/core';

import { DatepickerComponent } from '../datepicker/datepicker.component';

@Component({
  selector: 'account-daterangepicker',
  template: '<ng-content></ng-content>',
  standalone: true,
})
export class DaterangepickerComponent implements AfterContentInit {
  @Input() startDateChangedIncreaseEndDateByHours = 24;

  @ContentChild('startDate') startDateElement: ElementRef;
  @ContentChild('endDate') endDateElement: ElementRef;

  @ContentChildren(DatepickerComponent, { descendants: true }) datepickerComponents: QueryList<DatepickerComponent>;

  datePickerStartDate: DatepickerComponent;
  datePickerEndDate: DatepickerComponent;

  ngAfterContentInit(): void {
    // daterangepicker must have to datepickers
    if (2 !== this.datepickerComponents.length) {
      throw new Error('Daterangepicker must be provided with 2 datepickers');
    }

    this.datepickerComponents.forEach((datepicker: DatepickerComponent) => {
      const nativeElement = datepicker.elementRef.nativeElement;
      const startDatePickerElement = this.startDateElement.nativeElement.querySelector('account-datepicker');
      const endDatePickerElement = this.endDateElement.nativeElement.querySelector('account-datepicker');

      switch (nativeElement) {
        case startDatePickerElement:
          this.datePickerStartDate = datepicker;
          break;
        case endDatePickerElement:
          this.datePickerEndDate = datepicker;
          break;
        // skip default
      }
    });
    // throw exception if any of the pickers could not be identified
    if (!this.datePickerStartDate || !this.datePickerEndDate) {
      throw new Error('Datepickers must be wrapped by a reference element with #startDate respectively #endDate');
    }

    let minEndDate: Date = this.datePickerStartDate.minDate;
    if (!minEndDate) {
      minEndDate = this.datePickerStartDate.internalDate;
    }
    this.datePickerEndDate.minDate = new Date(minEndDate.getTime() + 3600000);

    this.datePickerStartDate.change.subscribe((startDate: { dateObject: Date; dateString: string }) => {
      // update endDate current-date and min-date if start changes and is greater than current endDate
      if (
        startDate &&
        startDate.dateObject &&
        startDate.dateObject.getTime() >= this.datePickerEndDate.internalDate.getTime()
      ) {
        const endDate = new Date(
          startDate.dateObject.getTime() + this.startDateChangedIncreaseEndDateByHours * 3600000
        );
        const newMinEndDate = new Date(startDate.dateObject.getTime() + 3600000);

        this.datePickerEndDate.writeValue(endDate);
        this.datePickerEndDate.minDate = newMinEndDate;
        this.datePickerEndDate.onChange(endDate);
      }
    });
  }
}
