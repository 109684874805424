import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SbpException, SbpExceptionContext } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ExceptionFormatterService {
  constructor(private readonly translateService: TranslateService) {}

  /**
   * Formats the context of a SbpException as an HTML list
   * Translates the property of the context as label for the message
   * - use default context for generic exception messages
   * - apply explicit context for domain-specific exception messages
   * - explicitly apply `null` if you want to omit the property because it does not match your form field (e.g. file-upload where the property is always `file`)
   */
  getMessageByContext(
    exception: SbpException,
    translationContextProperty: string | null = 'COMMON.TOAST.ERROR.TITLE.PROPERTY'
  ): string {
    const formattedErrors = exception.context
      .map((context: SbpExceptionContext) => {
        if (!translationContextProperty) {
          return `<li>${context.errorMessage}</li>`;
        }

        const property = context.property
          .slice(context.property.lastIndexOf('.') + 1)
          .replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)
          .toUpperCase();
        const translationKey = `${translationContextProperty}.${property}`;
        const errorProperty = this.translateService.instant(translationKey);
        return `<li>${errorProperty}: ${context.errorMessage}</li>`;
      })
      .join('\r\n');

    return `<ul>${formattedErrors}</ul>`;
  }
}
