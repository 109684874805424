import * as allocationsActions from './allocations/allocations.actions';
import * as allocationsSelectors from './allocations/allocations.selectors';
import * as companyActions from './company/company.actions';
import * as companySelectors from './company/company.selectors';
import * as signedGTCActions from './signed-gtc/signed-gtc.actions';
import * as signedGTCSelectors from './signed-gtc/signed-gtc.selectors';
import * as supportPermissionsActions from './support-permissions/support-permissions.actions';
import * as supportPermissionsSelectors from './support-permissions/support-permissions.selectors';
import * as supportTicketUpdatesActions from './support-ticket-updates/support-ticket-updates.actions';
import * as supportTicketUpdatesSelectors from './support-ticket-updates/support-ticket-updates.selectors';

export const actions = {
  allocations: allocationsActions,
  company: companyActions,
  supportPermissions: supportPermissionsActions,
  supportTicketUpdates: supportTicketUpdatesActions,
  signedGTC: signedGTCActions,
};

export const selectors = {
  allocations: allocationsSelectors,
  company: companySelectors,
  supportPermissions: supportPermissionsSelectors,
  supportTicketUpdates: supportTicketUpdatesSelectors,
  signedGTC: signedGTCSelectors,
};
