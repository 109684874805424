import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { AcademyGateway } from '../../gateways/academy/academy.gateway';
import {
  AcademyBooking,
  AcademyCertification,
  Appointment,
  Attendee,
  Examination,
  ListResult,
  SbpException,
  ShopwareCertification,
  Training,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { RootState } from '../../store/root.state';
import { AuthFacade } from '../auth';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class AcademyFacade {
  private readonly academyUrls = {
    de: 'https://www.shopware.com/de/academy/',
    en: 'https://www.shopware.com/en/academy/',
  };

  private readonly badgeFileMap = {
    certifiedDeveloper: 'shopware-certified-developer',
    certifiedDeveloperAdvanced: 'shopware-certified-developer-adv',
    certifiedPartner: 'shopware-certified-partner',
    certifiedTemplateDesigner: 'shopware-certified-template-designer',
    certifiedTemplateDeveloper: 'shopware-certified-template-developer',
    sw6CertifiedDeveloper: 'shopware6-certified-developer',
    sw6CertifiedTemplate: 'shopware6-certified-template-designer',
    sw6CertifiedDeveloperAdvanced: 'shopware6-certified-developer-adv',
    sw6CertifiedTemplateAdvanced: 'shopware6-certified-template-developer-adv',
    sw6CertifiedSolutionsSpecialist: 'shopware6-certified-solutions-specialist',
    sw6CertifiedPaasSpecialist: 'shopware6-certified-paas-specialist',
  };

  constructor(
    private readonly store: Store<RootState>,
    private readonly translateService: TranslateService,
    private readonly authFacade: AuthFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly academyGateway: AcademyGateway
  ) {}

  getCertificationList(metaData: RequestMetaData): Observable<ListResult<any[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.academyGateway.getAcademyCertificatesForCompany(companyId, metaData))
      );
  }

  getAppointments(metaData: RequestMetaData): Observable<Appointment[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.academyGateway.getAppointmentsForCompany(companyId, metaData)));
  }

  getBookings(metaData: RequestMetaData): Observable<ListResult<AcademyBooking[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.academyGateway.getBookingsForCompany(companyId, metaData)));
  }

  getExaminationList(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<Examination[]>> {
    return this.academyGateway.getExaminationList(metaData, filter);
  }

  getTrainingList(metaData: RequestMetaData, filter: Record<string, any> = {}): Observable<ListResult<Training[]>> {
    return this.academyGateway.getTrainingList(metaData, filter);
  }

  updateAppointmentAttendee(appointment: Appointment, attendee: Attendee): Observable<Attendee> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.academyGateway.updateAppointmentAttendee(companyId, appointment, attendee)
        )
      );
  }

  getOnlineCertificationsForCompany(metaData: RequestMetaData): Observable<AcademyCertification[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.academyGateway.getOnlineCertificationsForCompany(companyId, metaData))
      );
  }

  resendOnlineCertificationAccessDataMail(certification: AcademyCertification): Observable<void> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      take(1),
      switchMap((companyId: number) =>
        this.academyGateway.resendOnlineCertificationAccessDataMail(companyId, certification)
      )
    );
  }

  downloadLegacyCertificatePdf(
    certification: ShopwareCertification,
    locale = 'en_GB'
  ): Observable<Blob | SbpException> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.academyGateway.downloadLegacyCertificatePdf(companyId, certification, locale)
        )
      );
  }

  downloadBadgePicture(certification: ShopwareCertification): void {
    const dl = document.createElement('a');
    dl.href = this.getBadgeUrl(certification);
    dl.download = '';
    dl.click();
    dl.remove();
  }

  getBadgeUrl(certification: ShopwareCertification): string {
    const fileName = (this.badgeFileMap as Record<string, string>)[certification.certificate.name];
    return `/assets/images/academy/badges/${fileName}.png`;
  }

  getAcademyUrl(): Observable<string> {
    return this.translateService.onLangChange.pipe(
      startWith({ lang: this.translateService.currentLang }),
      map((event: LangChangeEvent) => this.academyUrls[event.lang as 'de' | 'en'] || this.academyUrls['en'])
    );
  }
}
