import { createAction, props } from '@ngrx/store';

import { CompanyMembership, CompanyMembershipRolePermission } from '../../../models';

// payload is the id of the UserAccount
export const setPermissions = createAction(
  '[UserAccount][CompanyPermissions] set current permissions',
  props<{ payload: CompanyMembership }>()
);
export const setPermissionsSuccess = createAction(
  '[UserAccount][CompanyPermissions] set current permissions success',
  props<{ payload: CompanyMembershipRolePermission[] }>()
);
