import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AvatarComponent } from '../../../../shared/components/avatar/avatar/avatar.component';
import { SwIconComponent } from '../../../../shared/components/sw-icon/sw-icon.component';

@Component({
  selector: 'navigation-user-info',
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.less',
  standalone: true,
  imports: [CommonModule, TranslateModule, SwIconComponent, AvatarComponent],
})
export class UserInfoComponent implements OnChanges {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() avatarUrl: string;

  @Output() readonly onClick = new EventEmitter<unknown>();
  @Output() readonly onGoToSettings = new EventEmitter<unknown>();

  showUnknownAvatar = true;

  ngOnChanges(changes: SimpleChanges): void {
    this.showUnknownAvatar = this.isUnknownAvatar(
      changes.firstName.currentValue,
      changes.lastName.currentValue,
      changes.avatarUrl.currentValue
    );
  }

  goToSettings(): void {
    this.onGoToSettings.emit();
  }

  @HostListener('click')
  private _onClick(): void {
    this.onClick.emit();
  }

  private isUnknownAvatar(firstName: string, lastName: string, avatarUrl: string): boolean {
    // show custom avatar, if one exists
    if (avatarUrl) {
      return false;
    }

    // show UnknownAvatar when neither a first name, nor a second name is given
    if (!firstName && !lastName) {
      return true;
    }

    // show UnknownAvatar when both first name and second name is given
    return false;
  }
}
