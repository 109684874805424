import { createReducer, on } from '@ngrx/store';

import { actions } from './producer.actions';
import { ProducerState } from './producer.state';

const initialProducerState: ProducerState = {
  loading: false,
  loaded: false,
  error: undefined,
  contractLoaded: false,
  producer: undefined,
};

export const reducer = createReducer(
  initialProducerState,

  on(actions.resetProducer, () => ({
    ...initialProducerState,
  })),

  on(
    actions.createProducer,
    actions.getProducer,
    actions.updateProducer,
    actions.updateContract,
    actions.updateIconUrl,
    (state: ProducerState) => ({
      ...state,
      loading: true,
    })
  ),

  on(
    actions.getProducerSuccess,
    actions.getProducerFail,
    actions.updateProducerSuccess,
    actions.updateProducerFail,
    actions.updateIconUrlSuccess,
    actions.updateIconUrlFail,
    (state: ProducerState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.getProducerSuccess, (state: ProducerState, { payload }) => ({
    ...state,
    error: undefined,
    producer: { ...payload },
  })),

  on(actions.updateProducerSuccess, (state: ProducerState, { payload }) => ({
    ...state,
    error: undefined,
    producer: {
      ...payload,
      contract: { ...state.producer.contract },
    },
  })),

  on(actions.updateIconUrlSuccess, (state: ProducerState, { payload }) => ({
    ...state,
    error: undefined,
    producer: {
      ...state.producer,
      iconUrl: payload,
    },
  })),

  on(
    actions.getContractSuccess,
    actions.updateContractSuccess,
    actions.updateProducerFail,
    actions.updateIconUrlFail,
    (state: ProducerState) => ({
      ...state,
      loading: false,
      contractLoaded: true,
    })
  ),

  on(actions.getContractSuccess, actions.updateContractSuccess, (state: ProducerState, { payload }) => ({
    ...state,
    producer: {
      ...state.producer,
      contract: { ...payload },
    },
    error: undefined,
  })),

  on(
    actions.getProducerFail,
    actions.updateProducerFail,
    actions.updateIconUrlFail,
    (state: ProducerState, { payload }) => ({
      ...state,
      error: payload,
    })
  )
);
