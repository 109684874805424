<div class="modal-backdrop" [@modalBackdrop]="state"></div>
<div class="modal" [class.is_fullscreen]="fullScreen" [style.padding-top]="paddingTop">
  <div class="modal-content" [@modalContent]="state" [style.max-width]="maxWidth" [style.min-height]="minHeight">
    <div class="is_loading modal-loading" *ngIf="loading">
      <div *ngIf="loadingText" class="text">{{ loadingText }}</div>
    </div>
    <div *ngIf="showHeader" class="modal-header">
      <h3>{{ heading || 'Modal' }}</h3>
      <div class="modal-controls">
        <ng-content select="modal-controls"></ng-content>
        <button *ngIf="closable" type="button" class="a_button is_small modal-close" (click)="closeModal($event)">
          <account-sw-icon icon="times-s" size="12px" color="var(--sw-paragraph)"></account-sw-icon>
        </button>
      </div>
    </div>
    <div class="modal-body-wrapper" [ngClass]="' overflow-' + overflowBody">
      <div class="modal-body" [ngClass]="paddingBody">
        <ng-content select="modal-body"></ng-content>
      </div>
    </div>
    <div
      *ngIf="footer"
      class="modal-footer"
      [class.content-left]="footerContentLeft"
      data-padding-left
      data-padding-right>
      <ng-content select="modal-footer"></ng-content>
    </div>
  </div>
</div>
