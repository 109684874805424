<div class="tabs-header" [class.sticky-header]="stickyHeader" [class.tabs-cardnav]="isCardNavigation">
  <div class="header-button button-prev" (click)="previousTab()" [class.disabled]="tabs.first.active">
    <account-sw-icon size="12px" icon="chevron-left-s"></account-sw-icon>
  </div>
  <div class="header-wrapper">
    <div class="header-inner">
      <a *ngFor="let tab of tabs" class="tab" [class.active]="tab.active" (click)="activateTab(tab, $event)">
        {{ tab.heading }}
        <account-helptext class="helptext" *ngIf="tab.helptext" [content]="tab.helptext"></account-helptext>
      </a>
    </div>
  </div>
  <div class="header-button button-next" (click)="nextTab()" [class.disabled]="tabs.last.active">
    <account-sw-icon size="12px" icon="chevron-right-s"></account-sw-icon>
  </div>
</div>
<div class="tab-content">
  <ng-content></ng-content>
</div>
