import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StringUtils } from '../utils';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard {
  constructor(
    private readonly router: Router,
    private readonly authGuard: AuthGuard
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const relativeUrl = StringUtils.trimSlashes(state.url);

    return this.authGuard.canActivateByUrl(relativeUrl).pipe(
      switchMap((canActivate: boolean | UrlTree) => {
        if (canActivate === true) {
          return of(true);
        }

        const noAccessRedirect = canActivate as UrlTree;
        if (!relativeUrl.startsWith('profile/data')) {
          return of(noAccessRedirect);
        }
        /**
         * automatic redirect is happening here therefore check access following the navigation order
         * and access the last available item or show no access component
         */
        return this.authGuard.canActivateByUrl('profile/billing').pipe(
          switchMap((canActivate: boolean | UrlTree) => {
            if (canActivate === true) {
              return of(this.router.parseUrl('profile/billing'));
            }
            return this.authGuard.canActivateByUrl('profile/memberships').pipe(
              map((canActivate: boolean | UrlTree) => {
                if (canActivate === true) {
                  return this.router.parseUrl('profile/memberships');
                }
                return noAccessRedirect;
              })
            );
          })
        );
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
