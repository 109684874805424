<div class="mobile--trigger" (click)="toggle()"></div>
<ul class="menubar">
  <li *ngFor="let item of items">
    <a
      [routerLink]="baseUrl"
      [fragment]="item.refId"
      [class.active]="item.active"
      [class.first]="item.first"
      [class.last]="item.last">
      <account-sw-icon
        [matTooltip]="item.title | translate"
        [matTooltipShowDelay]="250"
        matTooltipPosition="right"
        [icon]="item.icon"></account-sw-icon>
    </a>
  </li>
</ul>
