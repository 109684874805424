import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { StarComponent } from '../star/star.component';

@Component({
  selector: 'account-rating',
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.less',
  imports: [CommonModule, StarComponent],
  standalone: true,
})
export class RatingComponent implements OnInit {
  readonly loopingArray = new Array(5);

  @Input() disabled = false;
  @Input() amount: number;
  @Input() maximum = 5;

  normalizedAmount: number;
  showHalfStar: boolean;

  ngOnInit(): void {
    if (5 !== this.maximum) {
      if (0 !== this.maximum % 5) {
        throw new Error('invalid maximum');
      }
      const modifier = this.maximum / 5;
      this.normalizedAmount = this.amount / modifier;
    } else {
      this.normalizedAmount = this.amount;
    }
    if (this.normalizedAmount !== Number.parseInt(this.normalizedAmount.toString(), 10)) {
      this.showHalfStar = true;
    }
  }

  renderEmptyStar(index: number): boolean {
    if (!this.showHalfStar) {
      return index + 1 > this.normalizedAmount;
    } else {
      return index + 1 > this.normalizedAmount + 0.5;
    }
  }
}
