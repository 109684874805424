import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ProgressBarComponent } from '../../progress-bar/progress-bar.component';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { StepComponent } from '../step/step.component';

@Component({
  selector: 'wizard-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.less',
  imports: [CommonModule, TranslateModule, SwIconComponent, ProgressBarComponent],
  standalone: true,
})
export class PaginationComponent {
  @Input() wizardStarted = false;
  @Input() showButtonLabels = true;
  @Input() showProgressBar = true;
  @Input() labelCompleteButton: string;

  @Output() readonly onNext = new EventEmitter<any>();
  @Output() readonly onPrev = new EventEmitter<any>();
  @Output() readonly onComplete = new EventEmitter<any>();
  @Output() readonly onStepChanged: EventEmitter<StepComponent> = new EventEmitter<StepComponent>();

  stepsImpactingProgress: StepComponent[];

  private _steps: StepComponent[];

  @Input()
  set steps(steps: StepComponent[]) {
    this._steps = steps;
    this.stepsImpactingProgress = steps.filter((step: StepComponent) => step.impactsProgress);
  }

  get steps(): StepComponent[] {
    return this._steps;
  }

  set activeStep(step: StepComponent) {
    if (step !== this.activeStep) {
      this.activeStep.active = false;
      step.active = true;
      this.onStepChanged.emit(step);
    }
  }

  get activeStep(): StepComponent {
    return this.steps.find((step) => step.active);
  }

  get activeStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  get hasNextStep(): boolean {
    return this.activeStepIndex < this.steps.length - 1;
  }

  get hasPreviousStep(): boolean {
    return this.activeStepIndex > 0;
  }

  get canGoToPreviousStep(): boolean {
    return this.activeStep.canGoToPreviousStep;
  }

  next(): void {
    this.onNext.emit();
  }

  previous(): void {
    this.onPrev.emit();
  }

  complete(): void {
    this.onComplete.emit();
  }
}
