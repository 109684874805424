import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SbpTableContainerIntl {
  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  readonly changes: Subject<void> = new Subject<void>();

  ofLabel = 'of';

  entriesLabel = 'Entries';

  searchLabel = 'Filter by search term...';

  resetFilterLabel = 'Reset';

  applyFilterLabel = 'Apply';
}

export const SBP_TABLE_INTL_PROVIDER_FACTORY = (parentIntl: SbpTableContainerIntl): SbpTableContainerIntl =>
  parentIntl || new SbpTableContainerIntl();

export const SBP_TABLE_INTL_PROVIDER = {
  // If there is already an SbpTableContainerIntl available, use that. Otherwise, provide a new one.
  provide: SbpTableContainerIntl,
  deps: [[new Optional(), new SkipSelf(), SbpTableContainerIntl]],
  useFactory: SBP_TABLE_INTL_PROVIDER_FACTORY,
};
