import { provideState } from '@ngrx/store';

import { partnerActions, partnerSelectors, partnerStoreConfig } from './partner';
import { feature } from './partner-feature.feature';
import {
  supportPermissionsActions,
  supportPermissionsSelectors,
  supportPermissionsStoreConfig,
} from './support-permissions';

export { PartnerFeatureState } from './partner-feature.state';

// provide access to typed actions and selectors through index file
export const partnerStore = {
  actions: {
    partner: partnerActions,
    supportPermissions: supportPermissionsActions,
  },
  selectors: {
    partner: partnerSelectors,
    supportPermissions: supportPermissionsSelectors,
  },
};

// provide all providers for feature
export const providePartnerFeature = [
  provideState(feature),
  // provide all providers for sub features
  ...partnerStoreConfig.providers,
  ...supportPermissionsStoreConfig.providers,
];
