<div class="collapsible-heading" (click)="collapsible && toggle()">
  <h4>{{ heading }}</h4>
  <account-sw-icon
    [attr.data-hidden]="!collapsible ? '' : null"
    icon="chevron-up-s"
    size="16px"
    [class.is-reversed]="!open"></account-sw-icon>
</div>
<div class="collapsible-body" [class.is-closed]="!open">
  <ng-content></ng-content>
</div>
