import { FinancialFacade } from '@account/core/facades';
import { ModalComponent } from '@account/shared/components';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MODAL_DATA } from '../../../../../shared/components/modal/modal-injector';
import { ModalRef } from '../../../../../shared/components/modal/modal-ref';
import { ToastService } from '../../../../../shared/components/toast/toast.service';
import { cloneObject, objectIsEqual } from '../../../../../shared/util';
import { FinanceValidators } from '../../../../../shared/validators/finance.validators';
import { BankAccount, SbpException } from '../../../../models';

@Component({
  selector: 'account-bank-account-modal',
  templateUrl: './bank-account-modal.component.html',
  styleUrl: './bank-account-modal.component.less',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, ModalComponent],
})
export class BankAccountModalComponent implements OnInit {
  form: UntypedFormGroup;

  heading: string;
  infoText: string;
  textAsHTML = false;

  private bankData: BankAccount;
  private originalFormValues: Record<string, any>;

  constructor(
    private readonly modalRef: ModalRef,
    @Inject(MODAL_DATA) private readonly modalData: any,
    private readonly financialFacade: FinancialFacade,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.heading = this.modalData.heading;
    this.infoText = this.modalData.infoText;
    // if textAsHTML has been injected use the injected data
    if (
      undefined !== this.modalData.textAsHTML &&
      null !== this.modalData.textAsHTML &&
      typeof this.modalData.textAsHTML === typeof true
    ) {
      this.textAsHTML = this.modalData.textAsHTML;
    }
    if (this.modalData.bankAccount && Object.keys(this.modalData.bankAccount).length >= 4) {
      this.bankData = this.modalData.bankAccount;
      this.createForm(this.bankData);
      this.originalFormValues = cloneObject(this.form.getRawValue());
    } else {
      this.financialFacade.getKickbackBankData().subscribe(
        (bankData: BankAccount) => {
          this.bankData = bankData;
          this.createForm(this.bankData);
        },
        (error: SbpException) => {
          // prevent exception toast if bankData was not found
          if (error.code !== 'KickbackBankDataException-2') {
            this.toastService.error(
              this.translateService.instant('COMMON.TOAST.ERROR.TITLE.REQUEST_FAILED'),
              this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.REQUEST_FAILED.GET')
            );
          } else {
            this.bankData = { type: { name: 'direct_debit' } } as BankAccount;
            this.createForm(this.bankData);
          }
        }
      );
    }
  }

  createForm(bankAccount: BankAccount): void {
    this.form = this.formBuilder.group({
      accountHolder: [bankAccount.accountHolder, [Validators.required, Validators.minLength(1)]],
      bankName: [bankAccount.bankName, [Validators.required, Validators.minLength(1)]],
      iban: [bankAccount.iban, [Validators.required, FinanceValidators.iban]],
      bic: [bankAccount.bic, [Validators.required, FinanceValidators.bic]],
      type: this.formBuilder.group({
        name: [bankAccount.type.name, [Validators.required]],
      }),
    });

    this.originalFormValues = cloneObject(this.form.getRawValue());
  }

  hasPendingChanges(): boolean {
    return !objectIsEqual(this.originalFormValues, this.form.getRawValue());
  }

  save(): void {
    this.bankData = Object.assign(this.bankData, this.form.getRawValue());
    if (!this.bankData.id) {
      this.financialFacade.createKickbackBankData(this.bankData).subscribe(
        (storedBankData: BankAccount) => {
          this.bankData = storedBankData;
          this.toastService.success(
            this.translateService.instant('COMMON.TOAST.SUCCESS.TITLE.DEFINED'),
            this.translateService.instant('ACCOUNTING.BANK_ACCOUNT.MODAL.TOAST.SUCCESS.CREATE')
          );
          this.modalRef.close(this.bankData);
        },
        () => {
          this.toastService.error(
            this.translateService.instant('COMMON.TOAST.ERROR.TITLE.REQUEST_FAILED'),
            this.translateService.instant('ACCOUNTING.BANK_ACCOUNT.MODAL.TOAST.ERROR.CREATE')
          );
        }
      );
    } else {
      this.financialFacade.updateKickbackBankData(this.bankData).subscribe(
        (storedBankData: BankAccount) => {
          this.bankData = storedBankData;
          this.toastService.success(
            this.translateService.instant('COMMON.TOAST.SUCCESS.TITLE.UPDATE'),
            this.translateService.instant('ACCOUNTING.BANK_ACCOUNT.MODAL.TOAST.SUCCESS.UPDATE')
          );
          this.modalRef.close(this.bankData);
        },
        () => {
          this.toastService.error(
            this.translateService.instant('COMMON.TOAST.ERROR.TITLE.REQUEST_FAILED'),
            this.translateService.instant('ACCOUNTING.BANK_ACCOUNT.MODAL.TOAST.ERROR.UPDATE')
          );
        }
      );
    }
  }

  closeModal(): void {
    this.modalRef.close();
  }
}
