import { NgIf } from '@angular/common';
import { Component, Directive, forwardRef, Input, ViewEncapsulation } from '@angular/core';

import { AbstractListItemComponent } from '../abstract-list-item.component';
import { CollapsibleContainerComponent } from '../shared/collapsible-container/collapsible-container.component';

@Directive({
  selector: 'simple-list-item-visible',
  standalone: true,
})
export class SimpleListItemVisibleDirective {}

@Directive({
  selector: 'simple-list-item-collapsible',
  standalone: true,
})
export class SimpleListItemCollapsibleDirective {}

@Component({
  selector: 'simple-list-item',
  templateUrl: './simple-list-item.component.html',
  styleUrl: './simple-list-item.component.less',
  providers: [{ provide: AbstractListItemComponent, useExisting: forwardRef(() => SimpleListItemComponent) }],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, CollapsibleContainerComponent],
})
export class SimpleListItemComponent extends AbstractListItemComponent {
  @Input() collapsible = false;

  collapsed = true;

  constructor() {
    super();
  }

  toggleCollapsible(): void {
    this.collapsed = !this.collapsed;
  }
}
