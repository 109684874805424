<div class="sbp-table-header__info">
  <h3>{{ headline }}</h3>
  <span *ngIf="showTotal && total >= 0">&nbsp;({{ total }})</span>
</div>
<!-- @todo SWPLAT-21645 spot for the sorting select -->
<!-- <div *ngIf="isMobile" class="sbp-table-header__sort">
  Sortieren nach:
</div> -->
<div class="sbp-table-header__controls">
  <ng-content></ng-content>
</div>
