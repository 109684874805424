import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CountriesGateway } from '../../gateways/common';
import { Country, TimeZone } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CountriesFacade {
  constructor(private readonly countriesGateway: CountriesGateway) {}

  getCountries(): Observable<Country[]> {
    return this.countriesGateway.getCountries().pipe(map((countries: Country[]) => this.sortCountries(countries)));
  }

  getTimeZones(): Observable<TimeZone[]> {
    return this.countriesGateway.getTimeZones();
  }

  private sortCountries(countries: Country[]): Country[] {
    countries.sort((a, b): number => {
      if (a.iso === 'DE') {
        return -1;
      }
      return a.name < b.name ? -1 : 1;
    });

    return countries;
  }
}
