import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginCredentials, LoginResultCredentials, LoginResultToken, TokenResult } from '../../models';
import { ApiService, TokenService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AccessTokensGateway {
  constructor(
    private readonly apiService: ApiService,
    private readonly tokenService: TokenService
  ) {}

  login(credentials: LoginCredentials): Observable<LoginResultCredentials> {
    return this.apiService.post('/account/login', credentials).pipe(
      map((data: LoginResultCredentials) => {
        this.setAuth(data);
        return data;
      })
    );
  }

  loginWithToken(token: string): Observable<LoginResultToken> {
    return this.apiService.get(`/accesstokens/${token}`).pipe(
      map((data: LoginResultToken) => {
        const loginResult: LoginResultToken & { token: string } = {
          ...data,
          token: token,
        };
        this.setAuth(loginResult);
        return loginResult;
      })
    );
  }

  logout(): Observable<void> {
    const token = this.tokenService.getToken();

    return token ? this.apiService.delete(`/accesstokens/${this.tokenService.getToken()}`) : of(null);
  }

  setAuth(data: Pick<TokenResult, 'token'>): void {
    this.tokenService.saveToken(data.token);
  }
}
