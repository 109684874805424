import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Company,
  CompanyAllocations,
  Gtc,
  LastApprovedGtc,
  MasterData,
  Partner,
  SupportTicketUpdates,
} from '../../models';
import { SupportPermissions } from '../../models/support/support.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class CompaniesGateway {
  constructor(private readonly apiService: ApiService) {}

  getCompany(companyId: number): Observable<Company> {
    return this.apiService.get(`/companies/${companyId}`);
  }

  getSupportTicketUpdates(companyId: number): Observable<SupportTicketUpdates> {
    return this.apiService.get(`/companies/${companyId}/supportticketupdates`);
  }

  getCurrentPartner(companyId: number): Observable<Partner> {
    return this.apiService.get(`/companies/${companyId}/partner`);
  }

  passwordReset(key: string): Observable<any> {
    return this.apiService.get(`/passwordresets/${key}`);
  }

  getGtc(): Observable<Gtc[]> {
    return this.apiService.get('/gtc');
  }

  updateApprovedGtc(companyId: number): Observable<void> {
    return this.apiService.put(`/companies/${companyId}/lastApprovedGtc`);
  }

  getLastApprovedGTC(companyId: number): Observable<null | LastApprovedGtc> {
    return this.apiService.get(`/companies/${companyId}/lastApprovedGtc`).pipe(
      map((lastApprovedGTC: LastApprovedGtc | []) => {
        if (Array.isArray(lastApprovedGTC)) {
          return null;
        }
        return lastApprovedGTC;
      })
    );
  }

  updateMasterData(masterData: MasterData): Observable<MasterData> {
    return this.apiService.put(`/companies/${masterData.id}/masterdata`, masterData);
  }

  getAllocations(companyId: number): Observable<CompanyAllocations> {
    return this.apiService.get(`/companies/${companyId}/allocations`);
  }

  getInfoHasBusinessRelationSince(companyId: number, businessRelationName: string): Observable<string> {
    return this.apiService.get(`/companies/${companyId}/businessrelations/${businessRelationName}/since`);
  }

  getSupportPermissions(companyId: number): Observable<SupportPermissions> {
    return this.apiService.get(`/companies/${companyId}/supportpermissions`);
  }

  // TODO: CRM-643 connect API endpoint
  cancelPartnerRelation(companyId: number, partnerId: number): Observable<Partner> {
    return this.apiService.post(`/companies/${companyId}/cancelpartnerrelation`, partnerId);
  }
}
