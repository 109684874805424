import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PartnerCommissionGateway } from '../../gateways';
import { ListResult, PartnerCommission, PartnerCommissionAction, QueryFilter } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerCommissionFacade {
  constructor(
    private readonly partnerCommissionGateway: PartnerCommissionGateway,
    private readonly partnersFacade: PartnersFacade
  ) {}

  getPartnerCommissionList(
    requestMetaData: RequestMetaData,
    filter?: QueryFilter[]
  ): Observable<ListResult<PartnerCommission[]>> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCommissionGateway.getPartnerCommissionList(partnerId, requestMetaData, filter)
        )
      );
  }

  createPartnerCommission(partnerCommission: PartnerCommission): Observable<PartnerCommission> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnerCommissionGateway.createPartnerCommission(partnerId, partnerCommission)
        )
      );
  }

  getPartnerCommissionActions(): Observable<PartnerCommissionAction[]> {
    return this.partnerCommissionGateway.getPartnerCommissionActions();
  }
}
