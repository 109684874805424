/** Container for MatSortables to manage the sort state and provide default sort parameters. */
import { Directive } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Directive({
  selector: '[sbpSort]',
  exportAs: 'sbpSort',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['disabled: sbpSortDisabled'],
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SbpSort extends MatSort {}
