import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';

import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { MultiSelectItemTemplateLoaderComponent } from './item-template-loader.component';
import { TreeViewComponent } from './tree-view/tree-view.component';

export const MULTI_SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true,
};

@Component({
  selector: 'account-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.less',
  providers: [MULTI_SELECT_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MultiSelectItemTemplateLoaderComponent,
    SwIconComponent,
    FormsModule,
    ReactiveFormsModule,
    TreeViewComponent,
    TranslateModule,
  ],
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {
  @Input() items: any[] = [];
  @Input() placeholder: string;
  @Input() selectableMode: 'all' | 'leaf' = 'all';
  @Input() selectableAttributeName: string;
  @Input() alternativeSelectionLabels: string[];
  @Input() maxItems: number | null;
  @Output() readonly search = new EventEmitter<string>();

  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('searchInputChild', { static: true }) searchInputChild: ElementRef;

  selected: any[] = [];
  open = false;
  searchInput = new UntypedFormControl();
  disabled = false;
  _compareWith: (o1: any, o2: any) => boolean = Object.is;

  private onTouchedCallback: () => {};
  private onChangeCallback: (fn: any) => {};
  private focus = false;

  private selfClick = false;
  private itemClick = false;
  private documentClickListener: Function;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.searchInput.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
      this.search.emit(value);
    });
  }

  @Input()
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (typeof fn !== 'function') {
      throw new TypeError(`compareWith must be a function, but received ${JSON.stringify(fn)}`);
    }
    this._compareWith = fn;
  }

  deselect($event: KeyboardEvent, item: any): void {
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }

    if (this.isSelected(item)) {
      this.selected = this.selected.filter((selectedItem) => !this._compareWith(selectedItem, item));
    }
    if (typeof this.onChangeCallback === 'function') {
      this.onChangeCallback(this.selected);
    }
  }

  updateSelectedNodes(items: any[]): void {
    this.selected = items;
    if (typeof this.onChangeCallback === 'function') {
      this.onChangeCallback(this.selected);
    }
  }

  onDropdownContentClick(): void {
    this.itemClick = true;
  }

  toggle(): void {
    if (this.disabled) {
      this.open = false;
      return;
    }
    this.open = !this.open;
    if (this.open) {
      this.bindDocumentClickListener();
      this.selfClick = true;
      this.searchInput.patchValue('');
      setTimeout(() => {
        this.searchInputChild.nativeElement.focus();
      });
    }
  }

  isSelected(item: any): boolean {
    return !!this.selected.find((selectedItem) => this._compareWith(selectedItem, item));
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.selected = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocus(): void {
    this.focus = true;
  }

  onInputBlur(): void {
    this.focus = false;
    this.onTouchedCallback();
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.open = false;
          this.unbindDocumentClickListener();
        }

        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
