<account-modal
  [heading]="'COMMON.BASKET.MODAL.TITLE' | translate"
  (modalClose)="closeModal()"
  paddingBody="padding_none"
  maxWidth="1200px">
  <modal-body>
    <account-shopping-cart
      *ngIf="basket$ | async as basket"
      (onClose)="checkoutBasket()"
      [accountBalance]="bookingShopBalance"
      [accountName]="
        bookingShopDomain !== 'www.shopware-ag.de' ? bookingShopDomain : ('COMMON.PARTNER_ACCOUNT' | translate)
      "
      [formGroup]="form"
      [disabled]="form.invalid || isEditingPosition"
      [class.is_loading]="isLoading">
      <cart-info>
        <div class="summary">
          <strong>{{ 'COMMON.BASKET.MODAL.SUMMARY' | translate }}</strong>
          <span></span>
          @for (position of sortBasketPositions(basket); track position) {
            <span>
              @if (instanceOfBasketPositionExamination(position)) {
                {{ position.examination.name }}
              } @else if (instanceOfBasketPositionAppointment(position)) {
                {{ position.appointment.training.name }}
              } @else if (instanceOfBasketPositionVoucher(position)) {
                {{ 'COMMON.BASKET.MODAL.VOUCHER.POSITION_NAME' | translate: { voucherValue: position.voucher.value } }}
              }
            </span>
            <span class="text-right">
              {{ position.netPrice | localeCurrency }}
              @if (position.taxValue > 0) {
                *
              }
            </span>
          }
          <strong>{{ 'COMMON.BASKET.MODAL.SUB_TOTAL' | translate }}</strong>
          <span class="text-right">{{ basket.totalNetPrice | localeCurrency }} *</span>
          <span>{{ 'COMMON.BASKET.MODAL.VAT' | translate: { taxRate: basket.taxRate } }}</span>
          <span class="text-right">{{ basket.totalTax | localeCurrency }}</span>
          <strong>{{ 'COMMON.BASKET.MODAL.TOTAL' | translate }}</strong>
          <strong class="text-right">{{ basket.totalPrice | localeCurrency }}</strong>
        </div>

        <ng-container class="grey-background">
          <div class="grey-background redeem-voucher a_control">
            <span class="control_description">{{ 'COMMON.BASKET.MODAL.VOUCHER.REDEEM' | translate }}</span>
            <div class="control_content redeem-voucher-control">
              <input
                type="text"
                formControlName="voucher"
                placeholder="{{ 'COMMON.BASKET.MODAL.VOUCHER.LABEL' | translate }}" />
              <span></span>
              <button
                class="a_button is_small item-button"
                (click)="validateVoucher()"
                [disabled]="reedemVoucherDisabled || form.get('voucher').value.length === 0"
                [matTooltip]="
                  reedemVoucherDisabled ? ('COMMON.BASKET.MODAL.VOUCHER.REDEEM_ONLY_ONE_VOUCHER' | translate) : ''
                ">
                <account-sw-icon icon="checkmark-s" size="10px"><i class="mi-regular-checkmark-s"></i></account-sw-icon>
              </button>
            </div>
            <div class="control_help">
              @if (redeemVoucherSuccess === false) {
                <span class="control_error">
                  {{ 'COMMON.BASKET.MODAL.VOUCHER.ERROR' | translate }}
                </span>
              } @else if (redeemVoucherSuccess) {
                <span>{{ 'COMMON.BASKET.MODAL.VOUCHER.SUCCESS' | translate }}</span>
              }
            </div>
          </div>
          <div *ngIf="shops$ | async as shops; else shopSelectLoading" class="grey-background booking-shop-selection">
            <account-alert *ngIf="shops.length === 0" type="error">
              {{ 'COMMON.BASKET.MODAL.NO_BOOKING_SHOP_ALERT' | translate }}
              <a href="#" (click)="goToBilling($event)">
                @if (preferredBillingShop) {
                  {{
                    'COMMON.BASKET.MODAL.OPEN_DETAILS'
                      | translate
                        : {
                            account:
                              preferredBillingShop.domain !== 'www.shopware-ag.de'
                                ? preferredBillingShop.domain
                                : ('COMMON.PARTNER_ACCOUNT' | translate),
                          }
                  }}
                } @else {
                  {{ 'NAVIGATION.WORKSPACE.ACCOUNT.BILLING' | translate }}
                }
              </a>
            </account-alert>
            <div class="a_control" [class.is_disabled]="form.get('shop').disabled">
              <div class="control_description">
                <strong>{{ 'COMMON.BASKET.MODAL.BOOKING_SHOP' | translate }}</strong>
              </div>
              <div class="control_content a_select" [class.is_disabled]="shops.length === 0">
                <select class="a_select" formControlName="shop">
                  <option *ngFor="let selectableShop of shops" [ngValue]="selectableShop">
                    {{
                      selectableShop.domain !== 'www.shopware-ag.de'
                        ? selectableShop.domain
                        : ('COMMON.PARTNER_ACCOUNT' | translate)
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <ng-template #shopSelectLoading>
            <div class="grey-background booking-shop-selection is_loading"></div>
          </ng-template>
        </ng-container>
      </cart-info>

      <div formArrayName="requirements" class="basket-items">
        <ng-container *ngFor="let position of basket.positions">
          <div
            *ngIf="instanceOfBasketPositionExamination(position) || instanceOfBasketPositionAppointment(position)"
            class="basket-position"
            [formGroupName]="position.orderNumber">
            <div class="basket-item">
              @if (instanceOfBasketPositionExamination(position)) {
                <div class="item-image" [style.background-image]="'url(' + position.examination.iconUrl + ')'"></div>
              } @else if (instanceOfBasketPositionAppointment(position)) {
                <div
                  class="item-image"
                  [style.background-image]="'url(' + position.appointment.training.iconUrl + ')'"></div>
              }
              <div class="item-description">
                @if (instanceOfBasketPositionExamination(position)) {
                  <div class="item-title">{{ position.examination.name }}</div>
                  <div class="item-subtitle">{{ 'ACADEMY.BOOKINGS.ONLINE_CERTIFICATION' | translate }}</div>
                } @else if (instanceOfBasketPositionAppointment(position)) {
                  <div class="item-title">{{ position.appointment.training.name }}</div>
                  <div class="item-subtitle">{{ 'ACADEMY.BOOKINGS.ON_SITE_TRAINING' | translate }}</div>
                }
              </div>
              <div class="item-price">{{ position.netPrice | localeCurrency }} *</div>
              <button
                class="a_button is_small item-button"
                (click)="removePosition(position)"
                [disabled]="isEditingPosition">
                <account-sw-icon icon="times-s" size="10px"></account-sw-icon>
              </button>
            </div>

            @if (instanceOfBasketPositionExamination(position)) {
              <div class="basket-notice">
                {{ 'COMMON.BASKET.MODAL.ATTENDEE_NOTICE' | translate }}
              </div>
            }

            <div class="basket-attendees">
              <span class="basket-attendees__header">{{ 'COMMON.FIRSTNAME' | translate }}</span>
              <span class="basket-attendees__header">{{ 'COMMON.LASTNAME' | translate }}</span>
              <span class="basket-attendees__header">{{ 'COMMON.EMAIL' | translate }}</span>
              <span class="basket-attendees__header"></span>
              <span class="basket-attendees__header"></span>
              <ng-container
                *ngFor="let attendee of form.get('requirements').get(position.orderNumber).controls; let i = index"
                [formGroupName]="i">
                <input
                  class="form-control"
                  type="text"
                  formControlName="firstName"
                  [placeholder]="'COMMON.FIRSTNAME' | translate" />
                <input
                  class="form-control"
                  type="text"
                  formControlName="lastName"
                  [placeholder]="'COMMON.LASTNAME' | translate" />
                <input
                  class="form-control"
                  type="text"
                  formControlName="eMail"
                  [placeholder]="'COMMON.MAIL' | translate" />
                <!-- edit attendee -->
                <button
                  *ngIf="!isRowEditable(position, i)"
                  class="a_button is_small item-button item-edit"
                  (click)="editRow(position, i)"
                  [disabled]="isEditingPosition">
                  <account-sw-icon icon="pencil-s" size="10px"></account-sw-icon>
                  <span>{{ 'COMMON.BASKET.MODAL.PARTICIPANT_EDIT' | translate }}</span>
                </button>
                <!-- save attendee -->
                <button
                  *ngIf="isRowEditable(position, i)"
                  class="a_button is_small item-button item-edit"
                  (click)="updatePosition(position)"
                  [disabled]="
                    form.get('requirements').get(position.orderNumber).invalid ||
                    !form.get('requirements').get(position.orderNumber).dirty
                  ">
                  <account-sw-icon icon="checkmark-s" size="10px"></account-sw-icon>
                  <span>{{ 'COMMON.BASKET.MODAL.PARTICIPANT_SAVE' | translate }}</span>
                </button>
                <!-- remove attendee -->
                <button
                  *ngIf="!isRowEditable(position, i)"
                  class="a_button is_small item-button"
                  (click)="removeAttendee(position, i)"
                  [disabled]="
                    form.get('requirements').get(position.orderNumber).value.length <= 1 || isEditingPosition
                  ">
                  <account-sw-icon icon="times-s" size="10px"></account-sw-icon>
                </button>
                <!-- cancel edit attendee -->
                <button
                  *ngIf="isRowEditable(position, i)"
                  class="a_button is_small item-button"
                  (click)="resetRequirements()">
                  <account-sw-icon icon="undo-s" size="10px"></account-sw-icon>
                </button>
              </ng-container>
            </div>
            <button
              class="a_button is_small add-attendee"
              (click)="addAttendee(position)"
              [disabled]="isEditingPosition || isAttendeeLimitReached(position)">
              {{ 'COMMON.BASKET.MODAL.PARTICIPANT_ADD' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </account-shopping-cart>
  </modal-body>
</account-modal>
