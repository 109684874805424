import {
  ActivationStatus,
  Addon,
  ApprovalStatus,
  BinaryStatus,
  DemoType,
  Info,
  License,
  LifecycleStatus,
  Localization,
  PluginLocale,
  PluginPriceModel,
  ProductType,
  SoftwareVersion,
  StoreAvailability,
} from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PluginStaticsGateway } from '../../gateways/plugins';
import { Category, QueryFilter, Statics } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class PluginStaticsFacade {
  constructor(private readonly pluginStaticsGateway: PluginStaticsGateway) {}

  getPluginStatics(
    key = 'all',
    filter: QueryFilter[] = null
  ): Observable<
    | Statics
    | ActivationStatus[]
    | Addon[]
    | ApprovalStatus[]
    | BinaryStatus[]
    | Category[]
    | DemoType[]
    | Info[]
    | License[]
    | LifecycleStatus[]
    | PluginLocale[]
    | Localization[]
    | PluginPriceModel[]
    | SoftwareVersion[]
    | StoreAvailability[]
    | ProductType[]
  > {
    return this.pluginStaticsGateway.getPluginStatics(key, filter);
  }
}
