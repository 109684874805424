import { Locale } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DescriptionAssistantType, PartnersAiGateway } from '../../gateways/partner/partners-ai.gateway';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnersAiFacade {
  constructor(
    private readonly partnersAiGateway: PartnersAiGateway,
    private readonly partnersFacade: PartnersFacade
  ) {}

  getAiGeneratedPartnerDescription(
    locale: Locale,
    keywords: string,
    type: DescriptionAssistantType
  ): Observable<string> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.partnersAiGateway.getAiGeneratedPartnerDescription(partnerId, locale, keywords, type)
        )
      );
  }
}
