import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sbp-table-header',
  templateUrl: './header.html',
  styleUrl: './header.less',
  standalone: true,
  imports: [NgIf],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SbpTableHeader {
  @HostBinding('class.is-mobile') @Input() isMobile = false;
  @Input() headline: string;
  @Input() total: number;
  @Input() showTotal = true;
}
