import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, mapTo } from 'rxjs/operators';

import { Breadcrumb } from './breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  breadcrumbs$: Observable<Breadcrumb[]>;

  private readonly _dynamicLabel = new Subject<string>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    const navigationChange$ = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      mapTo(null)
    );

    const activeLabelChange$ = this._dynamicLabel.asObservable();

    this.breadcrumbs$ = merge(navigationChange$, activeLabelChange$).pipe(
      debounceTime(0),
      map((label: null | string) => this.getBreadcrumbs(this.activatedRoute, label))
    );
  }

  setDynamicLabel(label: string): void {
    this._dynamicLabel.next(label);
  }

  private getBreadcrumbs(activatedRoute: ActivatedRoute, pageLabel: string): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];
    let currentRoute = activatedRoute.root;
    let url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      childrenRoutes.forEach((route) => {
        if (route.outlet === PRIMARY_OUTLET) {
          const routeSnapshot = route.snapshot;
          if (routeSnapshot.url.length > 0) {
            url += `/${routeSnapshot.url
              .map((segment) => segment.path)
              .filter((path) => path !== '')
              .join('/')}`;
            breadcrumbs.push({
              label: route.snapshot.data['breadcrumb'],
              url: url,
            });
          }
          currentRoute = route;
        }
      });
    } while (currentRoute);

    if (null !== pageLabel) {
      breadcrumbs[breadcrumbs.length - 1].label = pageLabel;
    }

    {
      const scope = Sentry.getCurrentScope();
      const breadcrumbAsString = breadcrumbs.map((breadcrumb) => breadcrumb.label).join(' >> ');
      scope.setExtra('breadcrumb', breadcrumbAsString);
    }

    return breadcrumbs;
  }
}
