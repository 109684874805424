import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'simple-radio-button',
  templateUrl: './simple-radio-button.component.html',
  styleUrl: './simple-radio-button.component.less',
  standalone: true,
})
export class SimpleRadioButtonComponent {
  @Input() name: string;
  @Input() value: any;
  @Input() checked: boolean;
  @Input() disabled: boolean;

  @Output() readonly onChange = new EventEmitter<any>();

  change(selectedElement: EventTarget): void {
    this.onChange.emit((selectedElement as HTMLInputElement).value);
  }
}
