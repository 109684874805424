import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsGuard {
  constructor(private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const forbiddenQueryParams: { [key: string]: string[] } = route.data.forbiddenQueryParams || {};
    const queryParams = route.queryParams;

    for (const param in forbiddenQueryParams) {
      if (
        Object.prototype.hasOwnProperty.call(queryParams, param) &&
        forbiddenQueryParams[param].includes(queryParams[param])
      ) {
        return this.router.parseUrl('404');
      }
    }

    return true;
  }
}
