<a class="notification-inner" (click)="clickNotification($event)" [class.has--bubble]="showBubble">
  <div *ngIf="showBubble" class="notification-bubble"></div>

  <div class="notification-icon">
    <account-sw-icon
      [icon]="notification.type.icon"
      [color]="notification.type.iconColor"
      size="16px"></account-sw-icon>
  </div>

  <div class="notification-content">
    <h4>
      {{ 'NOTIFICATION.' + notification.type.name.toUpperCase() + '.HEADLINE' | translate: notification.payload }}
    </h4>
    <p
      [innerHTML]="
        'NOTIFICATION.' + notification.type.name.toUpperCase() + '.DESCRIPTION' | translate: notification.payload
      "></p>
    <span class="muted">{{ creationDate }}</span>
  </div>

  <div class="notification-close">
    <account-sw-icon (click)="deleteNotification($event)" icon="times-s" size="16px"></account-sw-icon>
  </div>
</a>
