import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    const wordEnd = value.slice(1);
    return value.slice(0, 1).toUpperCase() + wordEnd;
  }
}
