/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { SbpException } from '../../../models';
import { SupportPermissions } from '../../../models/support/support.model';

export const getSupportPermissions = createAction('[Partner][Support] get permissions', props<{ payload: number }>());
export const getSupportPermissionsSuccess = createAction(
  '[Partner][Support] get permissions success',
  props<{ payload: SupportPermissions | null }>()
);
export const getSupportPermissionsFail = createAction(
  '[Partner][Support] get permissions fail',
  props<{ payload: SbpException }>()
);

export const resetSupportPermissions = createAction('[Partner][Support] reset permissions');

export const actions = createActionGroup({
  source: 'Partner::Support',
  events: {
    'Get Permissions': props<{ payload: number }>(),
    'Get Permissions Success': props<{ payload: SupportPermissions | null }>(),
    'Get Permissions Fail': props<{ payload: SbpException }>(),

    'Reset Permissions': emptyProps(),
  },
});
