import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'dropdown-entry, [dropdownEntry]',
  standalone: true,
})
export class DropdownEntryDirective {
  @Input()
  @HostBinding('class.is_disabled')
  disabled = false;

  @Input()
  @HostBinding('class.is_link')
  link = false;

  @HostBinding('class.content--entry') classContentEntry = true;
}
