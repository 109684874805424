import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Plugin } from '../../../core/models/plugin/plugin.model';
import { PluginsService } from '../../services/plugins.service';

@Pipe({
  name: 'pluginPrice',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class PluginPricePipe implements PipeTransform {
  constructor(private readonly pluginsService: PluginsService) {}

  transform(plugin: Plugin, type = 'buy', duration: number = null): number {
    return this.pluginsService.getPluginPrice(plugin, type, duration);
  }
}
