<div class="sbp-table-paginator__pages">
  <button
    type="button"
    class="a_button is_small padding_small"
    [disabled]="!hasPreviousPage()"
    (click)="loadPreviousPage()">
    <account-sw-icon icon="chevron-left-s" size="12px"></account-sw-icon>
  </button>
  <span class="sbp-table-paginator__pages-counter">{{getCurrentPage()}} {{intl.ofLabel}} {{getPageCount()}}</span>
  <button type="button" class="a_button is_small padding_small" [disabled]="!hasNextPage()" (click)="loadNextPage()">
    <account-sw-icon icon="chevron-right-s" size="12px"></account-sw-icon>
  </button>
</div>

<div class="a_select is_small">
  <select [ngModel]="entriesPerPage" (change)="changeItemsPerPage($event.target.value)">
    <option [value]="5">5 {{intl.entriesLabel}}</option>
    <option [value]="10">10 {{intl.entriesLabel}}</option>
    <option [value]="25">25 {{intl.entriesLabel}}</option>
    <option [value]="50">50 {{intl.entriesLabel}}</option>
    <option [value]="100">100 {{intl.entriesLabel}}</option>
  </select>
</div>

<button *ngIf="showRefresh" type="button" class="a_button is_small padding_small" (click)="reloadList()">
  <account-sw-icon icon="redo" size="12px"></account-sw-icon>
</button>
