import { NgIf } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import {
  PasswordHelpTextsComponent,
  PasswordValidationRequirement,
} from './password-help-texts/password-help-texts.component';
import { PasswordValidationModel } from './password-validation.model';
import { ToggleablePasswordComponent } from './toggleable-password/toggleable-password.component';

type VisibleStatus = 'helpAndProgressBar' | 'onlyProgressBar' | 'none';

@Component({
  selector: 'account-password-mask',
  templateUrl: './password-mask.component.html',
  styleUrl: './password-mask.component.less',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordMaskComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, ProgressBarComponent, ToggleablePasswordComponent, PasswordHelpTextsComponent, TranslateModule],
})
export class PasswordMaskComponent implements OnInit, ControlValueAccessor {
  @Input() passwordString = 'COMMON.PASSWORD';

  @Input() visibleChecks: PasswordValidationRequirement[] = [
    'characterLength',
    'hasNumber',
    'hasLowerAndUppercaseLetter',
    'hasSpecialChar',
  ];

  control = new UntypedFormControl();
  progressBarValue = 0;
  isHelpShown: VisibleStatus = 'none';
  passwordValidation: PasswordValidationModel;

  ngOnInit(): void {
    this.control.valueChanges.subscribe((value: any) => {
      this.propagateChange(value);
    });
  }

  @Input() set errors(errors: ValidationErrors) {
    const isEqualToOldPassword = this.visibleChecks.includes('isNotEqualToOldPassword')
      ? !(
          errors &&
          Object.prototype.hasOwnProperty.call(errors, 'equalsWithOtherControl') &&
          errors.equalsWithOtherControl === true
        )
      : false;
    let passwordValidation: PasswordValidationModel = {
      characterLength: !errors?.minlength || errors.minlength.actualLength >= errors.minlength.requiredLength,
      hasNumber: !errors?.number,
      hasLowerAndUppercaseLetter: !errors?.lowerAndUpperLetter,
      hasSpecialChar: !errors?.specialCharacter,
      isNotEqualToOldPassword: isEqualToOldPassword,
    };

    if (errors?.required === true) {
      // if error required is set, set the ValidationModel to completely invalid, because field is empty
      passwordValidation = {
        characterLength: false,
        hasNumber: false,
        hasLowerAndUppercaseLetter: false,
        hasSpecialChar: false,
        isNotEqualToOldPassword: false,
      };
    }

    this.calculateProgressBar(errors, passwordValidation);
    this.passwordValidation = passwordValidation;
  }

  showHelp(): void {
    this.isHelpShown = 'helpAndProgressBar';
  }

  hideHelp(): void {
    this.isHelpShown = 'onlyProgressBar';
  }

  calculateProgressBar(errors: ValidationErrors, passwordValidationModel: PasswordValidationModel): void {
    if (errors?.required === true) {
      this.progressBarValue = 0;
      return;
    }
    if (!errors) {
      this.progressBarValue = this.visibleChecks.length;
      return;
    }

    // counts 'trues' in object
    this.progressBarValue = Object.values(passwordValidationModel).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  writeValue(obj: any): void {
    this.control.patchValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled === true ? this.control.disable() : this.control.enable();
  }

  private propagateChange = (_: any): void => {};

  private propagateTouch = (_: any): void => {};
}
