import { NoAccessContext } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NavigationAccessMapper } from '../../components/navigation/navigation-access.mapper';
import { LocalStorageService, SessionCookieService } from '../../services';
import { SessionFacade } from '../app';
import { AuthFacade } from '../auth';
import { CompaniesFacade } from '../company';
import { PartnersFacade } from '../partner';
import { UserAccountsFacade } from '../user-account';
import { AbstractAclFacade } from './abstract-acl.facade';

@Injectable({
  providedIn: 'root',
})
export class GuardsAclFacade extends AbstractAclFacade {
  constructor(
    sessionFacade: SessionFacade,
    authFacade: AuthFacade,
    companiesFacade: CompaniesFacade,
    userAccountsFacade: UserAccountsFacade,
    partnersFacade: PartnersFacade,
    sessionCookieService: SessionCookieService,
    private readonly localStorageService: LocalStorageService
  ) {
    super(sessionFacade, authFacade, companiesFacade, userAccountsFacade, partnersFacade, sessionCookieService);
  }

  isAccessGrantedForUserAccount(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForUserAccount(url);
  }

  isAccessGrantedForCompanyGTC(): Observable<boolean> {
    return this.isAccessGrantedForCompanyByGTC();
  }

  isAccessGrantedForCompanyMasterData(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForCompanyMasterData(url);
  }

  isAccessGrantedForCompanyAllocations(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForCompanyAllocations(url);
  }

  isAccessGrantedForPartnerStatus(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForPartnerStatus(url);
  }

  isAccessGrantedForCurrentPermissions(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForCurrentPermissions(url);
  }

  setNoAccessTranslationKey(url: string, context: NoAccessContext): void {
    let message: null | string;
    switch (context) {
      case 'partnerStatus':
        message = NavigationAccessMapper.urlToPermissionDeniedTranslationKeyForPartnerStatus(url);
        break;
      case 'permissions':
        message = NavigationAccessMapper.urlToPermissionDeniedTranslationKey(url);
        break;
      default:
        message = null;
    }
    this.localStorageService.setItem('no-access-message', message);
  }
}
