import { Observable, Subject } from 'rxjs';

export class ModalRef {
  componentRef: any;

  private readonly _changeEmitted: Subject<any> = new Subject();

  private readonly _afterClosed: Subject<any> = new Subject();

  // private animationInProgress = false;

  emitChange(result?: any): void {
    this._changeEmitted.next(result);
  }

  changeEmitted(): Observable<any> {
    return this._changeEmitted.asObservable();
  }

  close(result?: any): void {
    // if (this.animationInProgress) {
    //   return;
    // }

    // disable for now due to heavy change detection bugs
    // if (this.componentRef) {
    // const element = this.componentRef.location.nativeElement;
    // if (element && 'animate' in element) {
    //   this.animationInProgress = true;
    //   const timingOptions = {
    //     duration: 400,
    //     easing: 'cubic-bezier(.68, -0.55, .26, 1.55)',
    //     iterations: 1,
    //     fill: 'forwards',
    //   };
    //
    //   const modalContent = element.querySelector('.modal-content');
    //
    //   if (modalContent) {
    //     const animation = modalContent.animate([
    //       // keyframes
    //       { opacity: 1, transform: 'scale(1)' },
    //       { opacity: 0, transform: 'scale(0.8)' }
    //     ], timingOptions);
    //
    //     animation.onfinish = () => {
    //       this._afterClosed.next(result);
    //     };
    //   }
    //
    //   const modalBackdrop = element.querySelector('.modal-backdrop');
    //
    //   if (modalBackdrop) {
    //     const backDropAnimation = modalBackdrop.animate([
    //       // keyframes
    //       { opacity: 1 },
    //       { opacity: 0 }
    //     ], timingOptions);
    //   }
    //
    //   if (!modalContent && !modalBackdrop) {
    //     this._afterClosed.next(result);
    //   }
    //
    // } else {
    //   this._afterClosed.next(result);
    // }
    //
    // return;
    // }

    this._afterClosed.next(result);
  }

  afterClosed(): Observable<any> {
    // this.animationInProgress = false;
    return this._afterClosed.asObservable();
  }
}
