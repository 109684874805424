import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LicenseKeysGateway } from '../../gateways/licenses';
import { LicenseKeyResponse } from '../../models';
import { Shop } from '../../models/shop/shop.model';

@Injectable({
  providedIn: 'root',
})
export class LicenseKeysFacade {
  constructor(private readonly licenseKeysGateway: LicenseKeysGateway) {}

  getCommercialLicenseKeyForShop(shop: Pick<Shop, 'id'>): Observable<LicenseKeyResponse> {
    return this.licenseKeysGateway.getCommercialLicenseKeyForShop(shop);
  }
}
