import { Country } from '@account/core/models';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'countryName',
  pure: false,
  standalone: true,
})
@Injectable()
export class CountryNamePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(country: Country): string {
    try {
      const locale = this.translateService.currentLang;
      const displayNames = new Intl.DisplayNames([locale], { type: 'region' });

      return displayNames.of(country.iso) ?? country.name;
    } catch {
      // for very old browsers that don't support Intl.DisplayNames
      // https://caniuse.com/mdn-javascript_builtins_intl_displaynames
      return country.name;
    }
  }
}
