<a
  class="dropdown--button a_button"
  [class.is_disabled]="disabled"
  (click)="onOpen()"
  [class.has--arrow]="hasArrow"
  [class.padding_small]="smallPadding"
  [class.is--active]="open"
  data-float-left>
  <ng-content select="dropdown-active"></ng-content>
</a>

<div class="dropdown--content" [class.is--active]="open">
  <ng-content select="dropdown-entry, [dropdownEntry]"></ng-content>
</div>
