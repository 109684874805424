import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ReturnActionService {
  /**
   * implement actions to execute on returning to the account after redirect happened (e.g. return from payment provider)
   */
  executeActionForReturnParam(paramMap: ParamMap): void {
    let found = false;
    for (const key of paramMap.keys) {
      switch (key) {
        case 'paramInUrlWhichExecutesReturnAction':
          found = true;
          // do something
          break;
        // skip default
      }
      if (found) {
        break;
      }
    }
  }
}
