import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ServiceContractsGateway } from '../../gateways/shop';
import { ServiceContract } from '../../models';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class ServiceContractsFacade {
  constructor(
    private readonly companiesFacade: CompaniesFacade,
    private readonly serviceContractGateway: ServiceContractsGateway
  ) {}

  getActiveContractsForShop(shopId: number): Observable<ServiceContract[]> {
    return this.companiesFacade
      .getCompanyId()
      .pipe(switchMap((companyId: number) => this.serviceContractGateway.getActiveContractsForShop(companyId, shopId)));
  }

  cancelContract(shopId: number, contractId: number): Observable<ServiceContract> {
    return this.companiesFacade
      .getCompanyId()
      .pipe(
        switchMap((companyId: number) => this.serviceContractGateway.cancelContract(companyId, shopId, contractId))
      );
  }

  revokeContractCancellation(shopId: number, contractId: number): Observable<ServiceContract> {
    return this.companiesFacade
      .getCompanyId()
      .pipe(
        switchMap((companyId: number) =>
          this.serviceContractGateway.revokeContractCancellation(companyId, shopId, contractId)
        )
      );
  }
}
