import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'wizard-step',
  templateUrl: './step.component.html',
  styleUrl: './step.component.less',
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true,
})
export class StepComponent {
  @Input() hidden = false;
  @Input() impactsProgress = true;
  @Input() formGroup: UntypedFormGroup | FormGroup;
  @Input() nextStepLabel: string;
  @Input() previousStepLabel: string;
  @Input() canGoToPreviousStep = true;
  @Input() canGoToNextStepIfFormIsDisabled = true;

  @Input() nextStepAsyncConfirm$: Observable<boolean>;
  @Input() previousStepAsyncConfirm$: Observable<boolean>;

  @Output() readonly onInit = new EventEmitter<void>();
  @Output() readonly onNext = new EventEmitter<any>();
  @Output() readonly onPrev = new EventEmitter<any>();
  @Output() readonly onComplete = new EventEmitter<any>();

  @HostBinding('class.active') active = false;

  stepHasMargin = false;

  @Input()
  set skipStepIf(condition: boolean) {
    this.hidden = condition;
    if (this.formGroup && condition) {
      this.formGroup.markAsUntouched();
    }
  }

  /**
   * if the complete formGroup is disabled all controls have been disabled and the values have been set implicitly
   * therefore we can assume that the step is valid
   *
   * @returns {boolean}
   */
  get isValid(): boolean {
    if (this.canGoToNextStepIfFormIsDisabled) {
      return !this.formGroup ? true : this.formGroup.valid || this.formGroup.disabled;
    }
    return !this.formGroup ? true : this.formGroup.valid;
  }
}
