import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'progress-bar-label',
  templateUrl: './label.component.html',
  styleUrl: './label.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass],
})
export class LabelComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() value: number;
  @Input() maxValue = 100;
  // max value is only visible if current value is also visible
  @Input() showMaxValue = false;
  @Input() unit = '%';
  @Input() showUnit = true;
  @Input() height: number;
  @Input() txtColor: string;
  @Input() valueAlignment = 'center'; // must be one of: left/right or center
  @Input() isInlineLabel = false;
  @Input() minMaxValueSeparator = '/';
  @Input() separateMinMaxValuesWithWhitespace = true;
  @Input() separateValueAndUnitWithWhitespace = false;

  valueAsLabel: string;
  labelPadding = 0;
  fontSize = 12;

  ngOnChanges(): void {
    this.valueAsLabel = this.value.toString();
    if (this.showMaxValue) {
      if (this.separateMinMaxValuesWithWhitespace) {
        this.valueAsLabel += ` ${this.minMaxValueSeparator} `;
      } else {
        this.valueAsLabel += this.minMaxValueSeparator;
      }
      this.valueAsLabel += this.maxValue.toString();
    }
    if (this.showUnit) {
      if (this.separateValueAndUnitWithWhitespace) {
        this.valueAsLabel += ' ';
      }
      this.valueAsLabel += this.unit;
    }
  }

  ngOnInit(): void {
    if (this.height && this.height < 12) {
      this.labelPadding = 1;
    }
    if (!this.label && this.isInlineLabel && this.height) {
      this.fontSize = this.height - 2 - 2 * this.labelPadding;
    }
  }
}
