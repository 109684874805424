import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { EnvironmentService } from '../environment';

@Injectable({
  providedIn: 'root',
})
export class SessionCookieService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly environmentService: EnvironmentService
  ) {}

  hasGTCBypassCookie(): boolean {
    return 1 === Number(this.cookieService.get(this.environmentService.current.sessionCookieAccount2BypassGTC));
  }

  destroyGTCBypassCookie(): void {
    this.cookieService.delete(
      this.environmentService.current.sessionCookieAccount2BypassGTC,
      undefined,
      this.environmentService.current.sessionCookieDomain
    );
  }
}
