import { Directive, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { ActionBarService } from '../action-bar.service';

@Directive({
  selector: '[accountActionBarContent]',
  standalone: true,
})
export class ActionBarContentDirective implements OnInit, OnDestroy {
  constructor(
    private readonly actionBarService: ActionBarService,
    private readonly templateRef: TemplateRef<Element>
  ) {}

  ngOnInit(): void {
    this.actionBarService.setActionBar(this.templateRef);
  }

  ngOnDestroy(): void {
    this.actionBarService.setActionBar(null);
  }
}
