import { createAction, props } from '@ngrx/store';

import { LastApprovedGtc, SbpException } from '../../../models';

export const getSignedGTC = createAction('[Company][GTC] get latest signed document', props<{ payload: number }>());
export const getSignedGTCSuccess = createAction(
  '[Company][GTC] get latest signed document success',
  props<{ payload: LastApprovedGtc | null }>()
);
export const getSignedGTCFail = createAction(
  '[Company][GTC] get latest signed document fail',
  props<{ payload: SbpException }>()
);

export const noGTCExists = createAction('[Company][GTC] not exists');

export const refreshSignedGTC = createAction('[Company][GTC] refresh');
