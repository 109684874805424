import { Component } from '@angular/core';

import { SwIconComponent } from '../sw-icon/sw-icon.component';

@Component({
  selector: 'account-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrl: './image-placeholder.component.less',
  standalone: true,
  imports: [SwIconComponent],
})
export class ImagePlaceholderComponent {}
