import { ContractCancellation } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ContractCancellationGateway {
  constructor(private readonly apiService: ApiService) {}

  cancelContract(commercialContractId: number): Observable<ContractCancellation> {
    return this.apiService.post(`/commercialcontracts/${commercialContractId}/cancellations`);
  }
}
