import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  HostBinding,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AbstractListItemComponent } from './items/abstract-list-item.component';
import { SimpleListItemComponent } from './items/simple-list-item/simple-list-item.component';

@Directive({
  selector: 'list-header',
  standalone: true,
})
export class ListHeaderDirective {}

@Directive({
  selector: 'list-header-actions',
  standalone: true,
})
export class ListHeaderActionsDirective {}

@Directive({
  selector: 'list-footer',
  standalone: true,
})
export class ListFooterDirective {
  @Input() isSticky = false;
  @Input() spaceToViewportEnd = 0;
}

@Directive({
  selector: 'list-empty-state',
  standalone: true,
})
export class ListEmptyStateDirective {}

@Component({
  selector: 'account-list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, SimpleListItemComponent, AsyncPipe],
})
export class ListComponent implements AfterContentInit {
  @Input() showHeader = true;
  @Input() showItemCount = true;
  @Input() heading: string;
  @Input() emptyStateText: string;

  @Input()
  @HostBinding('class.is-embedded')
  isEmbedded = false;

  hasCustomHeader = false;
  hasCustomHeaderActions = false;
  hasCustomFooter = false;
  hasCustomEmptyState = false;

  isFooterSticky = false;
  negativeSpaceToViewportEnd = 0;

  countItems$: Observable<number>;
  hasItems$: Observable<boolean>;

  @HostBinding('class.initializing') isContentLoading = true;

  @ContentChild(ListHeaderDirective) protected listHeader: ListHeaderDirective;
  @ContentChild(ListHeaderActionsDirective) protected listHeaderActions: ListHeaderActionsDirective;
  @ContentChild(ListFooterDirective) protected listFooter: ListFooterDirective;
  @ContentChild(ListEmptyStateDirective) protected listEmptyState: ListFooterDirective;

  @ContentChildren(AbstractListItemComponent) protected listItems: QueryList<AbstractListItemComponent>;

  ngAfterContentInit(): void {
    this.hasCustomHeader = undefined !== this.listHeader;
    this.hasCustomHeaderActions = undefined !== this.listHeaderActions;
    this.hasCustomFooter = undefined !== this.listFooter;
    this.hasCustomEmptyState = undefined !== this.listEmptyState;

    if (this.hasCustomFooter) {
      this.isFooterSticky = this.listFooter.isSticky;
      this.negativeSpaceToViewportEnd = -this.listFooter.spaceToViewportEnd;
    }

    if (this.listItems) {
      this.countItems$ = this.listItems.changes.pipe(
        startWith(this.listItems),
        map((changeState: QueryList<AbstractListItemComponent>) => changeState.length)
      );

      this.hasItems$ = this.countItems$.pipe(map((numberOfItems: number) => numberOfItems > 0));
    }
    this.isContentLoading = false;
  }
}
