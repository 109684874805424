import { FeedItem, ShopCampaignPopUpData, SupportFeedItem } from '@account/core/models';
import { Injectable } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { createClient, EntryCollection } from 'contentful';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { FeedItemType } from '../../types/feed-types.type';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private readonly client = createClient({
    space: environment.contentfulSpaceId,
    accessToken: environment.contentfulApiKey,
    environment: environment.contentfulEnvironment,
  });

  getAccountFeedItems(feedType: FeedItemType, language: string): Observable<FeedItem[]> {
    const items$ = from(
      this.client.getEntries(Object.assign({
        'fields.publishedWorkspaces[in]': feedType,
        content_type: environment.contentfulFeedContentTypeId,
        order: '-fields.releaseDate' as any,
        limit: 10,
        skip: 0,
        locale: `${language}-DE`,
      }))
    );

    return items$.pipe(
      map((entries: EntryCollection<any>) =>
        entries.items
          .filter((entry: any) => entry.fields.publishedLocales.includes(language.toUpperCase()))
          .map(
            (entry: any) =>
              ({
                title: entry.fields.title,
                releaseDate: entry.fields.releaseDate,
                image: entry.fields.image,
                content: documentToHtmlString(entry.fields.content),
                publishedWorkspaces: entry.fields.publishedWorkspaces,
                publishedLocales: entry.fields.publishedLocales,
                linkText: entry.fields.linkText,
                linkUrl: entry.fields.linkUrl,
                campaign: entry.fields.campaign?.fields?.slug,
              }) as FeedItem
          )
      )
    );
  }

  getSupportFeedItems(language: string): Observable<SupportFeedItem[]> {
    const items$ = from(
      this.client.getEntries(Object.assign({
        content_type: environment.contentfulSupportContentTypeId,
        limit: 10,
        skip: 0,
        locale: `${language}-DE`,
      }))
    );

    return items$.pipe(
      map((entries: EntryCollection<any>) =>
        entries.items
          .filter((entry: any) => entry.fields.publishedLocales.includes(language.toUpperCase()))
          .map(
            (entry: any) =>
              ({
                title: entry.fields.title,
                content: documentToHtmlString(entry.fields.content),
              }) as SupportFeedItem
          )
      )
    );
  }

  getPopUpModalEntriesForCampaignType(language: string): Observable<ShopCampaignPopUpData[]> {
    const items$ = from(
      this.client.getEntries(Object.assign({
        content_type: environment.contentfulPopUpModalContentTypeId,
        limit: 10,
        locale: `${language}-DE`,
      }))
    )

    return items$
      .pipe(
        map((entries: EntryCollection<any>) =>
          entries.items.map(
              (entry: any) =>
                ({
                  id: entry.sys.id,
                  title: entry.fields.title,
                  imageUrl: entry.fields.image.fields.file.url,
                  content: documentToHtmlString(entry.fields.content),
                  buttonText: entry.fields.buttonText,
                  targetGroups: entry.fields.targetGroups,
                }) as ShopCampaignPopUpData
        )
        ),
  );
  }
}
