import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class IntegrationPartnerCommissionsGateway {
  constructor(private readonly apiService: ApiService) {}

  getIntegrationPartnerCommissionBalance(companyId: number): Observable<number> {
    return this.apiService
      .get(`/partners/${companyId}/integrationpartnercommissionbalance`)
      .pipe(map((data: { amount: number }) => data.amount));
  }

  getIntegrationPartnerCommissionList(companyId: number, metaData: RequestMetaData): Observable<ListResult<any>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }
    return this.apiService.getList(`/partners/${companyId}/integrationpartnercommissions`, params);
  }

  downloadIntegrationPartnerCommissionExport(
    companyId: number,
    locale: string,
    metaData: RequestMetaData
  ): Observable<Blob> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData).set('limit', '0');
    return this.apiService.download(`/partners/${companyId}/integrationpartnercommissionsexport/${locale}`, params);
  }
}
