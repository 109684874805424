<form *ngIf="form" [formGroup]="form">
  <account-modal
    paddingBody="padding_none"
    [heading]="'PRODUCER_MAIL.MODAL.HEADING' | translate"
    (modalClose)="closeModal()">
    <modal-body>
      <div class="a_row">
        <div class="a_column-12 a_column-l-6" data-border-right="l,x">
          <div>
            <div class="a_control is_required">
              <div class="control_description">
                <strong>{{ 'PRODUCER_MAIL.MODAL.SUPPORT_MAIL' | translate }}</strong>
              </div>
              <div class="control_content">
                <input type="text" formControlName="supportMail" />
              </div>
            </div>
            <div class="a_control is_required">
              <div class="control_description">
                <strong>{{ 'PRODUCER_MAIL.MODAL.RATING_MAIL' | translate }}</strong>
              </div>
              <div class="control_content">
                <input type="text" formControlName="ratingMail" />
              </div>
            </div>
            <div class="a_control is_required">
              <div class="control_description">
                <strong>{{ 'PRODUCER_MAIL.MODAL.SALE_MAIL' | translate }}</strong>
              </div>
              <div class="control_content">
                <input type="text" formControlName="saleMail" />
              </div>
            </div>
          </div>
        </div>
        <div class="a_column-12 a_column-l-6">
          <h3>{{ 'PRODUCER_MAIL.MODAL.INFO_TEXT_HEADING' | translate }}</h3>
          <p>{{ 'PRODUCER_MAIL.MODAL.INFO_TEXT_PART_1' | translate }}</p>
          <p>{{ 'PRODUCER_MAIL.MODAL.INFO_TEXT_PART_2' | translate }}</p>
        </div>
      </div>
    </modal-body>
    <modal-footer>
      <button
        class="a_button is_primary is_small"
        type="button"
        [disabled]="form.invalid || !formHasPendingChanges"
        [class.is_disabled]="form.invalid || !formHasPendingChanges"
        (click)="save()">
        {{ 'COMMON.SAVE' | translate }}
      </button>
    </modal-footer>
  </account-modal>
</form>
