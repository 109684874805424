import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'account-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.less',
  standalone: true,
  imports: [TranslateModule],
})
export class FooterComponent {
  openUsercentricsModal(): void {
    if (Object.prototype.hasOwnProperty.call(window, 'UC_UI')) {
      (window as Record<string, any>)['UC_UI'].showSecondLayer();
    }
  }
}
