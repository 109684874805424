import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { PluginsGateway } from '../../gateways/plugins';
import {
  CreateBinaryCommand,
  ListResult,
  MarketingCampaigns,
  PluginCertification,
  Producer,
  ProductType,
  UpdateExistingBinaryCommand,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import {
  Binary,
  BinaryCheckResult,
  BinaryReview,
  LicenseCheckSnippet,
  Plugin,
  PluginGeneration,
  PluginPicture,
  PluginPreview,
  PluginStatus,
  PluginSuccessorDefinition,
} from '../../models/plugin/plugin.model';
import { ProducersFacade } from './producers.facade';

@Injectable({
  providedIn: 'root',
})
export class PluginsFacade {
  constructor(
    private readonly pluginsGateway: PluginsGateway,
    private readonly producersFacade: ProducersFacade
  ) {}

  getPlugin(id: number): Observable<Plugin> {
    const plugin$ = this.pluginsGateway.getPlugin(id).pipe(
      map((plugin: Plugin) => {
        if (!plugin.certification) {
          plugin.certification = null;
        }
        return plugin;
      })
    );

    return combineLatest(
      plugin$,
      this.getPluginPictures(id),
      this.getPluginBinaries(id),
      this.getBinaryReviews(id)
    ).pipe(
      map(([plugin, pictures, binaries, reviews]: [Plugin, PluginPicture[], Binary[], BinaryReview[]]) => {
        plugin.pictures = pictures;
        plugin.binaries = binaries;
        plugin.reviews = reviews;
        return plugin;
      })
    );
  }

  getPluginStatus(id: number): Observable<PluginStatus> {
    return this.pluginsGateway.getPlugin(id).pipe(
      map((plugin: Plugin) => ({
        lifecycleStatus: plugin.lifecycleStatus,
        approvalStatus: plugin.approvalStatus,
        activationStatus: plugin.activationStatus,
        status: plugin.status,
      }))
    );
  }

  getPluginList(metaData: RequestMetaData, filter: Record<string, any> = {}): Observable<ListResult<Plugin[]>> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.pluginsGateway.getPluginList(producer.id, metaData, filter))
    );
  }

  getPluginListWithSimpleData(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<Plugin[]>> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.pluginsGateway.getPluginListWithSimpleData(producer.id, metaData, filter))
    );
  }

  getPluginListForVersionCompatibilityAssistant(): Observable<Plugin[]> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.pluginsGateway.getPluginListForVersionCompatibilityAssistant(producer.id)),
      map((listResult: ListResult<Plugin[]>) =>
        listResult.list.filter((plugin: Plugin) => null !== plugin.latestBinary)
      )
    );
  }

  createNewPlugin(generation: PluginGeneration, productType?: ProductType): Observable<Plugin> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.pluginsGateway.createNewPlugin(generation, producer, productType))
    );
  }

  updatePlugin(pluginToUpdate: Plugin): Observable<Plugin> {
    const plugin = { ...pluginToUpdate, ...{ binaries: undefined, reviews: undefined } };
    const plugin$ = this.pluginsGateway.updatePlugin(plugin).pipe(
      map((plugin: Plugin) => {
        if (!plugin.certification) {
          plugin.certification = null;
        }
        return plugin;
      })
    );

    return combineLatest(plugin$, this.getPluginPictures(plugin.id), this.getPluginBinaries(plugin.id)).pipe(
      map(([plugin, pictures, binaries]: [Plugin, PluginPicture[], Binary[]]) => {
        plugin.pictures = pictures;
        plugin.binaries = binaries;
        return plugin;
      })
    );
  }

  updatePluginAutomaticBugfixVersionCompatibility(plugin: Plugin): Observable<Plugin> {
    return this.pluginsGateway.updatePluginAutomaticBugfixVersionCompatibility(plugin);
  }

  uploadPicture(pluginId: number, file: any): Observable<PluginPicture> {
    return this.pluginsGateway
      .uploadPluginPicture(pluginId, file)
      .pipe(map((pictures: PluginPicture[]) => pictures[0]));
  }

  updatePluginPicture(pluginId: number, picture: PluginPicture): Observable<PluginPicture> {
    return this.pluginsGateway.updatePluginPicture(pluginId, picture);
  }

  deletePluginPicture(pluginId: number, pictureId: number): Observable<void> {
    return this.pluginsGateway.deletePluginPicture(pluginId, pictureId);
  }

  uploadIcon(pluginId: number, file: any): Observable<{ icon: string }> {
    return this.pluginsGateway.uploadIcon(pluginId, file);
  }

  createBinary(pluginId: number, binary: CreateBinaryCommand): Observable<Binary> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.pluginsGateway.createBinary(producerId, pluginId, binary))
    );
  }

  validateBinaryFile(pluginId: number, file: any): Observable<Binary> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.pluginsGateway.validateBinaryFile(producerId, pluginId, file))
    );
  }

  uploadBinaryFile(pluginId: number, binaryId: number, file: File): Observable<Binary> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.pluginsGateway.uploadBinaryFile(producerId, pluginId, binaryId, file))
    );
  }

  updateBinary(pluginId: number, binaryId: number, binary: UpdateExistingBinaryCommand): Observable<Binary> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.pluginsGateway.updateBinary(producerId, pluginId, binaryId, binary))
    );
  }

  revokeBinary(pluginId: number, binaryId: number): Observable<null | Binary> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.pluginsGateway.revokeBinary(producerId, pluginId, binaryId))
    );
  }

  getBinaryFileUrl(
    pluginId: number,
    binaryId: number,
    shopwareMajorVersion: number | null,
    shopId: number = null
  ): Observable<{ url: string }> {
    return this.pluginsGateway.getBinaryFileUrl(pluginId, binaryId, shopwareMajorVersion, shopId);
  }

  downloadBinaryFile(url: string): Observable<Blob> {
    return this.pluginsGateway.downloadBinaryFile(url);
  }

  deletePlugin(pluginId: number): Observable<''> {
    return this.pluginsGateway.deletePlugin(pluginId);
  }

  requestBinaryReview(plugin: Plugin): Observable<any> {
    return this.pluginsGateway.requestBinaryReview(plugin);
  }

  getBinaryReviews(pluginId: number): Observable<BinaryReview[]> {
    return this.pluginsGateway.getBinaryReviews(pluginId);
  }

  getPluginBinaries(pluginId: number): Observable<Binary[]> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.pluginsGateway.getPluginBinaries(producerId, pluginId))
    );
  }

  getLicenseCheckSnippet(plugin: Plugin): Observable<LicenseCheckSnippet> {
    return this.pluginsGateway.getLicenseCheckSnippet(plugin);
  }

  getPluginBinaryCheckResults(pluginId: number, binaryId: number): Observable<BinaryCheckResult[]> {
    return this.pluginsGateway.getPluginBinaryCheckResults(pluginId, binaryId).pipe(
      map((checkResults: BinaryCheckResult[]) => {
        checkResults = checkResults.sort((a: BinaryCheckResult, b: BinaryCheckResult) => b.id - a.id);
        return checkResults;
      })
    );
  }

  validateCertification(plugin: Plugin): Observable<PluginCertification> {
    return this.pluginsGateway.validateCertification(plugin);
  }

  createPluginSuccessorDefinition(
    pluginId: number,
    definition: PluginSuccessorDefinition
  ): Observable<PluginSuccessorDefinition> {
    return this.pluginsGateway.createPluginSuccessorDefinition(pluginId, definition);
  }

  updatePluginSuccessorDefinition(
    pluginId: number,
    definition: PluginSuccessorDefinition
  ): Observable<PluginSuccessorDefinition> {
    return this.pluginsGateway.updatePluginSuccessorDefinition(pluginId, definition);
  }

  getPluginSuccessorDefinition(pluginId: number): Observable<PluginSuccessorDefinition> {
    return this.pluginsGateway.getPluginSuccessorDefinition(pluginId);
  }

  getActiveMarketingCampaigns(plugin: Plugin): Observable<MarketingCampaigns> {
    return this.pluginsGateway.getActiveMarketingCampaigns(plugin);
  }

  getPluginPreview(plugin: Plugin): Observable<PluginPreview> {
    return this.pluginsGateway.getPluginPreview(plugin);
  }

  updatePluginPreviewStatus(plugin: Plugin, pluginPreview: PluginPreview): Observable<PluginPreview> {
    return this.pluginsGateway.updatePluginPreviewStatus(plugin, pluginPreview);
  }

  private getProducerIdOnce(): Observable<number> {
    return this.producersFacade.getProducerIdOnce();
  }

  private getProducer(): Observable<Producer> {
    return this.producersFacade.getProducer().pipe(take(1));
  }

  private getPluginPictures(id: number): Observable<PluginPicture[]> {
    return this.pluginsGateway.getPluginPictures(id).pipe(map((pictures: PluginPicture[]) => pictures));
  }
}
