export class StorageFactory {
  private inMemoryStorage: { [key: string]: string } = {};
  private readonly getStorage: () => Storage;

  /**
   * to retrieve localStorage or sessionStorage
   * new StorageFactory(() => localStorage);
   * new StorageFactory(() => sessionStorage);
   */
  constructor(getStorage: () => Storage) {
    this.getStorage = getStorage;
  }

  clear(): void {
    if (this.isSupported()) {
      this.getStorage().clear();
    } else {
      this.inMemoryStorage = {};
    }
  }

  getItem(name: string): string | undefined {
    if (this.isSupported()) {
      return this.getStorage().getItem(name);
    }
    if (Object.prototype.hasOwnProperty.call(this.inMemoryStorage, name)) {
      return this.inMemoryStorage[name];
    }
    return undefined;
  }

  setItem(name: string, value: string | number): void {
    const stringValue = value.toString();
    if (this.isSupported()) {
      this.getStorage().setItem(name, stringValue);
    } else {
      this.inMemoryStorage[name] = stringValue;
    }
  }

  removeItem(name: string): void {
    if (this.isSupported()) {
      this.getStorage().removeItem(name);
    } else {
      delete this.inMemoryStorage[name];
    }
  }

  key(index: number): string | undefined {
    if (this.isSupported()) {
      return this.getStorage().key(index);
    }
    return Object.keys(this.inMemoryStorage)[index] || undefined;
  }

  length(): number {
    if (this.isSupported()) {
      return this.getStorage().length;
    }
    return Object.keys(this.inMemoryStorage).length;
  }

  private isSupported(): boolean {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      this.getStorage().setItem(testKey, testKey);
      this.getStorage().removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  }
}
