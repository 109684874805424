import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ArrayValidators {
  static minLength(min: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length < min) {
        return { minLengthArray: true };
      }

      return null;
    };
  }

  static unique(control: AbstractControl): ValidationErrors {
    if (new Set(control.value).size !== control.value.length) {
      return { notUnique: true };
    }
    return null;
  }
}
