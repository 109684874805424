import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Category } from '../../core/models/plugin/category.model';
import { PluginsService } from '../services/plugins.service';

@Pipe({
  name: 'pluginCategory',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class PluginCategoryPipe implements PipeTransform {
  constructor(private readonly pluginsService: PluginsService) {}

  transform(pluginCategory: Category): any {
    return this.pluginsService.getLocalizedCategoryName(pluginCategory);
  }
}
