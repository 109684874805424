import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PartnerMarketingGateway } from '../../gateways/partner/partner-marketing.gateway';
import {
  ListResult,
  MarketingBudget,
  MarketingBudgetRequest,
  MarketingDocument,
  MarketingPrintBrochures,
  ValuePoints,
  ValuePointsCreditableAction,
  ValuePointsCreditRequest,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade } from '../company';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerMarketingFacade {
  constructor(
    private readonly partnersFacade: PartnersFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly marketingGateway: PartnerMarketingGateway
  ) {}

  getMarketingMaterials(): Observable<ListResult<MarketingDocument[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.marketingGateway.getMarketingMaterials(companyId)));
  }

  requestPrintBrochures(printBrochures: MarketingPrintBrochures): Observable<MarketingPrintBrochures> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        printBrochures.user = { id: companyId };
        return this.marketingGateway.requestPrintBrochures(printBrochures);
      })
    );
  }

  getValuePoints(year: number): Observable<null | ValuePoints> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.getValuePoints(partnerId, year)));
  }

  getCreditableActions(): Observable<ValuePointsCreditableAction[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.getCreditableActions(partnerId)));
  }

  getCreditRequestList(metaData: RequestMetaData, year: number): Observable<ListResult<ValuePointsCreditRequest[]>> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.getCreditRequestList(partnerId, metaData, { year: year })
        )
      );
  }

  submitCreditRequest(creditRequest: ValuePointsCreditRequest): Observable<void> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.submitCreditRequest(partnerId, creditRequest)));
  }

  deleteCreditRequest(creditRequestId: number): Observable<void> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.deleteCreditRequest(partnerId, creditRequestId)));
  }

  getMarketingBudget(year: number): Observable<null | MarketingBudget> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.getMarketingBudget(partnerId, year)));
  }

  getBudgetRequestList(metaData: RequestMetaData, year: number): Observable<ListResult<MarketingBudgetRequest[]>> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.getBudgetRequestList(partnerId, metaData, { year: year })
        )
      );
  }

  submitBudgetRequest(budgetRequest: MarketingBudgetRequest): Observable<MarketingBudgetRequest> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.submitBudgetRequest(partnerId, budgetRequest)));
  }

  updateBudgetRequest(
    budgetRequest: MarketingBudgetRequest,
    budgetRequestId: number
  ): Observable<MarketingBudgetRequest> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.updateBudgetRequest(partnerId, budgetRequest, budgetRequestId)
        )
      );
  }

  deleteBudgetRequest(budgetRequestId: number): Observable<void> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.deleteBudgetRequest(partnerId, budgetRequestId)));
  }

  uploadBudgetRequestAttachments(budgetRequestId: number, attachments: File[]): Observable<MarketingBudgetRequest> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.uploadBudgetRequestAttachments(partnerId, budgetRequestId, attachments)
        )
      );
  }

  deleteBudgetRequestAttachment(budgetRequestId: number, attachmentId: number): Observable<MarketingBudgetRequest> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.deleteBudgetRequestAttachment(partnerId, budgetRequestId, attachmentId)
        )
      );
  }

  uploadBudgetRequestInvoice(budgetRequestId: number, invoice: File): Observable<MarketingBudgetRequest> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.uploadBudgetRequestInvoice(partnerId, budgetRequestId, invoice)
        )
      );
  }

  getBudgetRequestAttachmentDownloadUrl(
    budgetRequestId: number,
    attachmentId: number
  ): Observable<{ downloadUrl: string }> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.marketingGateway.getBudgetRequestAttachmentDownloadUrl(partnerId, budgetRequestId, attachmentId)
        )
      );
  }

  deleteBudgetRequestInvoice(budgetRequestId: number): Observable<MarketingBudgetRequest> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) => this.marketingGateway.deleteBudgetRequestInvoice(partnerId, budgetRequestId))
      );
  }

  getBudgetRequestActivities(): Observable<string[]> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.marketingGateway.getBudgetActivities(partnerId)));
  }
}
