import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CloudShop,
  CloudShopReductionReason,
  CloudShopReductionReasonRequest,
  NotYetInvoicedRevenueShareCostsForPlan,
  QueryFilter,
  RevenueShareBookingDetails,
} from '../../models';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CloudShopsGateway {
  constructor(private readonly apiService: ApiService) {}

  getCloudShopsForCompany(companyId: number, filter: QueryFilter[]): Observable<CloudShop[]> {
    const params = HttpParamsBuilder.applyFilters(new HttpParams(), filter);
    return this.apiService.get(`/companies/${companyId}/cloudshops`, params);
  }

  changeShopOwner(companyId: number, cloudShopId: number, userAccountEmail: string): Observable<CloudShop> {
    return this.apiService.post(`/companies/${companyId}/cloudshops/${cloudShopId}/owner`, {
      userAccountMail: userAccountEmail,
    });
  }

  getCloudShopRevenueShareDetailsForBooking(
    companyId: number,
    cloudShopId: number,
    bookingId: number
  ): Observable<RevenueShareBookingDetails> {
    return this.apiService.get(`/companies/${companyId}/cloudshops/${cloudShopId}/bookings/${bookingId}`);
  }

  getNotYetInvoicedRevenueShareCosts(
    companyId: number,
    cloudShopId: number
  ): Observable<NotYetInvoicedRevenueShareCostsForPlan[]> {
    return this.apiService.get(`/companies/${companyId}/cloudshops/${cloudShopId}/revenuesharecosts`);
  }

  updateCloudShop(companyId: number, cloudShop: CloudShop): Observable<CloudShop> {
    return this.apiService.put(`/companies/${companyId}/cloudshops/${cloudShop.id}`, cloudShop);
  }

  getCloudShopCancellationsReasons(): Observable<CloudShopReductionReason[]> {
    return this.apiService.get('/cloudshops/cancellationreasons');
  }

  performCloudShopCancellation(
    cloudShopId: number,
    reasons: CloudShopReductionReasonRequest[],
    cancelInstant: boolean
  ): Observable<CloudShop> {
    return this.apiService.post(`/cloudshops/${cloudShopId}/cancellation`, {
      reasons: reasons,
      cancelInstant: cancelInstant,
    });
  }

  revokeCloudShopCancellation(cloudShopId: number): Observable<void> {
    return this.apiService.delete(`/cloudshops/${cloudShopId}/cancellation`);
  }
}
