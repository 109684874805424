import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { PartnerSearchResult } from '../../../../core/models/company/partner-search-result';
import { OVERLAY_DATA } from '../../component-overlay/component-overlay-injector';
import { ComponentOverlayRef } from '../../component-overlay/component-overlay-ref';
import { EmptyStateComponent } from '../../empty-state/empty-state.component';

interface ModalData {
  options: Observable<PartnerSearchResult[]>;
}

@Component({
  selector: 'account-search-options-overlay',
  templateUrl: './search-options-overlay.component.html',
  styleUrl: './search-options-overlay.component.less',
  standalone: true,
  imports: [NgIf, NgFor, EmptyStateComponent, AsyncPipe, TranslateModule],
})
export class SearchOptionsOverlayComponent implements OnInit {
  constructor(
    @Inject(OVERLAY_DATA) public data: ModalData,
    private readonly overlayRef: ComponentOverlayRef
  ) {}

  @Output() readonly onSelect = new EventEmitter<any>();

  partnerSearchResults$: Observable<PartnerSearchResult[]>;

  ngOnInit(): void {
    this.partnerSearchResults$ = this.data.options;
  }

  close(data: any): void {
    this.overlayRef.close(data);
  }

  select(partner: PartnerSearchResult): void {
    this.close(partner);
  }
}
