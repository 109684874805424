import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CloudShopsGateway } from '../../gateways/shop/cloud-shops.gateway';
import {
  CloudShop,
  CloudShopReductionReason,
  CloudShopReductionReasonRequest,
  NotYetInvoicedRevenueShareCostsForPlan,
  QueryFilter,
  RevenueShareBookingDetails,
} from '../../models';
import { RootState } from '../../store/root.state';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class CloudShopsFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly cloudShopsGateway: CloudShopsGateway,
    private readonly companiesFacade: CompaniesFacade
  ) {}

  getCloudShopsForCompany(filter: QueryFilter[] = []): Observable<CloudShop[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.cloudShopsGateway.getCloudShopsForCompany(companyId, filter)));
  }

  changeShopOwner(companyId: number, cloudShopId: number, userAccountEmail: string): Observable<CloudShop> {
    return this.cloudShopsGateway.changeShopOwner(companyId, cloudShopId, userAccountEmail);
  }

  getCloudShopRevenueShareDetailsForBooking(
    cloudShopId: number,
    bookingId: number
  ): Observable<RevenueShareBookingDetails> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.cloudShopsGateway.getCloudShopRevenueShareDetailsForBooking(companyId, cloudShopId, bookingId)
        )
      );
  }

  getNotYetInvoicedRevenueShareCosts(cloudShopId: number): Observable<NotYetInvoicedRevenueShareCostsForPlan[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.cloudShopsGateway.getNotYetInvoicedRevenueShareCosts(companyId, cloudShopId)
        )
      );
  }

  updateCloudShop(cloudShop: CloudShop): Observable<CloudShop> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.cloudShopsGateway.updateCloudShop(companyId, cloudShop)));
  }

  getCloudShopCancellationsReasons(): Observable<CloudShopReductionReason[]> {
    return this.cloudShopsGateway.getCloudShopCancellationsReasons();
  }

  performCloudShopCancellation(
    cloudShopId: number,
    reasons: CloudShopReductionReasonRequest[],
    cancelInstant: boolean
  ): Observable<CloudShop> {
    return this.cloudShopsGateway.performCloudShopCancellation(cloudShopId, reasons, cancelInstant);
  }

  revokeCloudShopCancellation(cloudShopId: number): Observable<void> {
    return this.cloudShopsGateway.revokeCloudShopCancellation(cloudShopId);
  }
}
