import { Action, createReducer, on } from '@ngrx/store';

import { Document } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './signed-gtc.actions';

export interface SignedGTCState extends AsyncState {
  gtcDocument: Document | undefined;
  signedOn: string;
}

const initialSignedGTCState: SignedGTCState = {
  loading: false,
  loaded: false,
  error: undefined,
  gtcDocument: undefined,
  signedOn: undefined,
};

const signedGTCReducerInternal = createReducer(
  initialSignedGTCState,

  on(actions.getSignedGTC, (state: SignedGTCState) => ({
    ...state,
    loading: true,
  })),

  on(actions.noGTCExists, (state: SignedGTCState) => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined as undefined,
    gtcDocument: undefined as undefined,
    signedOn: undefined as undefined,
  })),

  on(actions.refreshSignedGTC, (state: SignedGTCState) => ({
    ...state,
    loaded: false,
    error: undefined as undefined,
  })),

  on(actions.getSignedGTCSuccess, actions.getSignedGTCFail, (state: SignedGTCState) => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  on(actions.getSignedGTCSuccess, (state: SignedGTCState, { payload }) => ({
    ...state,
    gtcDocument: payload?.document,
    signedOn: payload?.creationDate,
    error: undefined,
  })),

  on(actions.getSignedGTCFail, (state: SignedGTCState, { payload }) => ({
    ...state,
    error: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: SignedGTCState | undefined, action: Action): SignedGTCState {
  return signedGTCReducerInternal(state, action);
}
