import { createSelector } from '@ngrx/store';

import { SbpException } from '../../../models';
import * as fromMeta from '../useraccount-meta.reducer';
import { MembershipsState } from './memberships.reducer';

const membershipsState = createSelector(fromMeta.state, (state: fromMeta.State): MembershipsState => state.memberships);

export const isLoading = createSelector(membershipsState, (state: MembershipsState) => state.loading);

export const isLoaded = createSelector(membershipsState, (state: MembershipsState) => state.loaded);

export const getError = createSelector(membershipsState, (state: MembershipsState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getWriteError = createSelector(membershipsState, (state: MembershipsState) => state.writeError);

export const getMemberships = createSelector(membershipsState, (state: MembershipsState) => state.memberships);

export const isSelectionInProgress = createSelector(
  membershipsState,
  (state: MembershipsState) => state.selectionInProgress
);
