<div class="clickable">
  <span>
    <ng-content></ng-content>
  </span>

  <ng-container *ngIf="_isSorted()">
    <account-sw-icon
      *ngIf="_arrowDirection === 'asc'; else ascOrder"
      icon="chevron-down-s"
      size="16px"></account-sw-icon>
    <ng-template #ascOrder>
      <account-sw-icon icon="chevron-up-s" size="16px"></account-sw-icon>
    </ng-template>
  </ng-container>
</div>
