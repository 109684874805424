import { createAction, props } from '@ngrx/store';

import { SbpException } from '../../../models';
import { SupportPermissions } from '../../../models/support/support.model';

export const getSupportPermissions = createAction('[Company][Support] get permissions', props<{ payload: number }>());
export const getSupportPermissionsSuccess = createAction(
  '[Company][Support] get permissions success',
  props<{ payload: SupportPermissions | null }>()
);
export const getSupportPermissionsFail = createAction(
  '[Company][Support] get permissions fail',
  props<{ payload: SbpException }>()
);
