import { Action, createReducer, on } from '@ngrx/store';

import { CompanyMembership, SbpException } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './memberships.actions';

export interface MembershipsState extends AsyncState {
  writeError: undefined | SbpException;
  memberships: CompanyMembership[];
  selectionInProgress: boolean;
}

export const initialMembershipsState: MembershipsState = {
  loading: false,
  loaded: false,
  error: undefined,
  writeError: undefined,
  selectionInProgress: false,
  memberships: [],
};

const membershipsReducerInternal = createReducer(
  initialMembershipsState,

  on(actions.getMemberships, actions.terminateMembership, (state: MembershipsState) => ({
    ...state,
    loading: true,
  })),

  on(
    actions.getMembershipsSuccess,
    actions.getMembershipsFail,
    actions.terminateMembershipSuccess,
    actions.terminateMembershipFail,
    (state: MembershipsState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.getMembershipsSuccess, (state: MembershipsState, { payload }) => ({
    ...state,
    memberships: payload,
    error: undefined,
  })),

  on(actions.terminateMembershipSuccess, (state: MembershipsState, { payload }) => ({
    ...state,
    memberships: state.memberships.filter((membership: CompanyMembership) => membership.id !== payload),
    writeError: undefined,
  })),

  on(actions.refreshMemberships, (state: MembershipsState) => ({
    ...state,
    loaded: false,
  })),

  on(actions.selectMembership, (state: MembershipsState) => ({
    ...state,
    selectionInProgress: true,
  })),

  on(actions.selectMembershipSuccess, actions.selectMembershipFail, (state: MembershipsState) => ({
    ...state,
    selectionInProgress: false,
  })),

  on(actions.getMembershipsFail, actions.selectMembershipFail, (state: MembershipsState, { payload }) => ({
    ...state,
    error: payload,
  })),

  on(actions.selectMembershipFail, actions.terminateMembershipFail, (state: MembershipsState, { payload }) => ({
    ...state,
    writeError: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: MembershipsState | undefined, action: Action): MembershipsState {
  return membershipsReducerInternal(state, action);
}
