import { DropdownComponent, SwIconComponent } from '@account/shared/components';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../../../../../environments/environment';
import { Language, Workspace } from '../../../models';

@Component({
  selector: 'navigation-menu-overlay',
  templateUrl: './menu-overlay.component.html',
  styleUrl: './menu-overlay.component.less',
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, TranslateModule, SwIconComponent, DropdownComponent],
  standalone: true,
})
export class MenuOverlayComponent {
  @Input() selectedLanguage: Language;
  @Input() languages: Language[];
  @Input() activeWorkspace: Workspace;

  @Output() readonly onLanguageChange = new EventEmitter<Language>();
  @Output() readonly onLogout = new EventEmitter<undefined>();
  @Output() readonly onShowChangelog = new EventEmitter<undefined>();
  @Output() readonly onCloseOverlay = new EventEmitter<undefined>();

  readonly VERSION = environment.version.split('@')[0];
  hasChangelogEntry: boolean;

  private _open = false;

  constructor(private readonly elementRef: ElementRef) {}

  changeLanguage(language: Language): void {
    this.onLanguageChange.emit(language);
  }

  logout(): void {
    this.onLogout.emit();
  }

  showChangelog(): void {
    this.onShowChangelog.emit();
  }

  trackByLanguage(index: number, language: Language): string {
    return language.key;
  }

  @HostListener('document:click', ['$event.target'])
  private onMenuClick(target: HTMLElement): void {
    if (this._open && !target.closest(this.elementRef.nativeElement.tagName.toLowerCase())) {
      this._open = !this._open;
      this.onCloseOverlay.emit();
      return;
    }
    if (!this._open) {
      this._open = true;
    }
  }
}
