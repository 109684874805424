import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { NodeComponent } from './node/node.component';

@Component({
  selector: 'tree-view',
  templateUrl: './tree-view.component.html',
  styleUrl: './tree-view.component.less',
  standalone: true,
  imports: [NgFor, NodeComponent],
})
export class TreeViewComponent {
  @Input() nodes: any[] = [];
  @Input() selectedNodes: any[] = [];
  @Input() disabled = false;

  // name of the element attribute which should be rendered
  @Input() nodeValueAttributeName = 'name';
  // name of the element attribute which contains the children
  @Input() childNodesAttributeName = 'children';
  // name of the element attribute which determines if the node is selectable
  @Input() selectableAttributeName: string;
  // the different types of selectability
  @Input() selectableOption: 'all' | 'leaf' = 'all';

  @Input() template: TemplateRef<any>;

  @Output() readonly onUpdateSelectedNodes = new EventEmitter<any[]>();

  backgroundColor = '#ffffff';
  private _compareWith: (o1: any, o2: any) => boolean = Object.is;

  @Input()
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (typeof fn !== 'function') {
      throw new TypeError(`compareWith must be a function, but received ${JSON.stringify(fn)}`);
    }
    this._compareWith = fn;
  }

  onNodeClick(event: MouseEvent, node: any): void {
    if (this.isSelected(node)) {
      this.propagateDown(node, false);
    } else {
      if (!this.disabled) {
        this.propagateDown(node, true);
      }
    }

    this.onUpdateSelectedNodes.emit(this.selectedNodes);
  }

  isSelected(node: any): boolean {
    const directlySelected = this.selectedNodes.findIndex((selectedNode) => this._compareWith(selectedNode, node)) > -1;
    if (directlySelected) {
      return true;
    }

    if (node[this.childNodesAttributeName] && node[this.childNodesAttributeName].length > 0) {
      let childrenSelected = 0;
      for (const child of node[this.childNodesAttributeName]) {
        if (this.isSelected(child)) {
          childrenSelected++;
        }
      }
      if (childrenSelected === node[this.childNodesAttributeName].length) {
        return true;
      }
    }

    return false;
  }

  isPartialSelected(node: any): boolean {
    const directlySelected = this.selectedNodes.findIndex((selectedNode) => this._compareWith(selectedNode, node)) > -1;
    if (directlySelected) {
      return true;
    }

    if (node[this.childNodesAttributeName] && node[this.childNodesAttributeName].length > 0) {
      for (const child of node[this.childNodesAttributeName]) {
        if (this.isPartialSelected(child)) {
          return true;
        }
      }
    }
    return false;
  }

  private propagateDown(node: any, select: boolean): void {
    const index = this.selectedNodes.findIndex((selectedNode) => this._compareWith(selectedNode, node));

    if (!this.selectableAttributeName || (this.selectableAttributeName && node[this.selectableAttributeName])) {
      if (select && index === -1) {
        this.selectedNodes = [...(this.selectedNodes || []), node];
      } else if (!select && index > -1) {
        this.selectedNodes = this.selectedNodes.filter((selectedNode) => !this._compareWith(selectedNode, node));
      }
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        this.propagateDown(child, select);
      }
    }
  }
}
