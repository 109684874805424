import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ListResult,
  Partner,
  PartnerReference,
  PartnerReferenceFacet,
  PartnerReferenceFacetPicture,
  PartnerReferenceFacetStatics,
  PartnerReferenceHistoryEntry,
  PartnerReferenceSkeleton,
  PartnerReferencesStatics,
  PartnerReferenceStatusName,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PartnerReferencesGateway {
  constructor(private readonly apiService: ApiService) {}

  getReferenceList(partner: Partner, metaData: RequestMetaData): Observable<ListResult<PartnerReference[]>> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    return this.apiService.getList(`/partners/${partner.partnerId}/newreferences`, params);
  }

  getReference(partner: Partner, id: number): Observable<PartnerReference> {
    return this.apiService.get(`/partners/${partner.partnerId}/newreferences/${id.toString()}`);
  }

  addReference(partner: Partner, newReference: PartnerReferenceSkeleton): Observable<PartnerReference> {
    return this.apiService.post(`/partners/${partner.partnerId}/newreferences`, newReference);
  }

  updateReference(partner: Partner, updatedReference: PartnerReference): Observable<PartnerReference> {
    return this.apiService.put(`/partners/${partner.partnerId}/newreferences/${updatedReference.id}`, updatedReference);
  }

  deleteReference(partner: Partner, deletableReference: PartnerReference): Observable<null> {
    return this.apiService.delete(`/partners/${partner.partnerId}/newreferences/${deletableReference.id}`);
  }

  submitReferenceForNextWorkflowStep(
    partner: Partner,
    referenceId: number,
    newStatus: PartnerReferenceStatusName
  ): Observable<PartnerReferenceHistoryEntry> {
    const payload = {
      newStatus: { name: newStatus },
    };
    return this.apiService.post(`/partners/${partner.partnerId}/newreferences/${referenceId}/status`, payload);
  }

  getPartnerReferencesStatics(): Observable<PartnerReferencesStatics> {
    return this.apiService.get('/partnerReferencesStatics');
  }

  getPartnerReferenceFacetList(
    partner: Partner,
    referenceId: number,
    metaData: RequestMetaData,
    filter?: Object
  ): Observable<ListResult<PartnerReferenceFacet[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.getList(`/partners/${partner.partnerId}/newreferences/${referenceId}/facets`, params);
  }

  addPartnerReferenceFacet(
    partner: Partner,
    referenceId: number,
    facet: PartnerReferenceFacet
  ): Observable<PartnerReferenceFacet> {
    return this.apiService.post(`/partners/${partner.partnerId}/newreferences/${referenceId}/facets`, facet);
  }

  updatePartnerReferenceFacet(
    partner: Partner,
    referenceId: number,
    facet: PartnerReferenceFacet
  ): Observable<PartnerReferenceFacet> {
    return this.apiService.put(`/partners/${partner.partnerId}/newreferences/${referenceId}/facets/${facet.id}`, facet);
  }

  deletePartnerReferenceFacet(partner: Partner, referenceId: number, facet: PartnerReferenceFacet): Observable<void> {
    return this.apiService.delete(`/partners/${partner.partnerId}/newreferences/${referenceId}/facets/${facet.id}`);
  }

  addPartnerReferenceFacetPicture(
    partner: Partner,
    referenceId: number,
    facetId: number,
    picture: File
  ): Observable<PartnerReferenceFacetPicture> {
    const path = `/partners/${partner.partnerId}/newreferences/${referenceId}/facets/${facetId}/pictures`;
    return this.apiService.upload(path, picture);
  }

  deletePartnerReferenceFacetPicture(
    partner: Partner,
    referenceId: number,
    facetId: number,
    pictureId: number
  ): Observable<any> {
    const path = `/partners/${partner.partnerId}/newreferences/${referenceId}/facets/${facetId}/pictures/${pictureId}`;
    return this.apiService.delete(path);
  }

  setPartnerReferenceFacetPreviewPicture(
    partner: Partner,
    referenceId: number,
    facetId: number,
    pictureId: number
  ): Observable<PartnerReferenceFacetPicture> {
    const path = `/partners/${partner.partnerId}/newreferences/${referenceId}/facets/${facetId}/pictures/${pictureId}`;
    return this.apiService.put(path);
  }

  updateReferenceOrder(partner: Partner, updatedOrder: { id: number; priority: number }[]): Observable<void> {
    return this.apiService.put(`/partners/${partner.partnerId}/updatedReferenceOrder`, {
      updatedReferenceOrder: updatedOrder,
    });
  }

  getPartnerReferenceFacetStatics(): Observable<PartnerReferenceFacetStatics> {
    return this.apiService.get('/partnerReferenceFacetStatics');
  }
}
