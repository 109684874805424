import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class KeysPipe implements PipeTransform {
  transform(value: Record<string, any>): any {
    const keys = [];
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        keys.push({ key, value: value[key] });
      }
    }
    return keys;
  }
}
