import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Locale } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class LocalesGateway {
  constructor(private readonly apiService: ApiService) {}

  getLocales(): Observable<Locale[]> {
    return this.apiService.get('/locales');
  }
}
