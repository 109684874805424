/**
 * @link https://github.com/robisim74/angular-l10n/blob/master/src/pipes/locale-date.pipe.ts
 */
import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { convertSbpDate } from '../../core/utils/sbp-date-converter.utils';

@Pipe({
  name: 'localeDate',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: any, pattern = 'mediumDate', timezone: string = null): string {
    if (!value) {
      return value;
    }
    const langKey = this.translateService.currentLang;

    if (pattern === 'dayAndMonth') {
      pattern = langKey === 'de' ? 'd. MMM' : 'MMM d.';
    }

    // HACK: safari browser and internet explorer do not like sbp date strings
    const sbpDate = convertSbpDate(value);

    const localeDate: DatePipe = new DatePipe(langKey);
    return localeDate.transform(sbpDate, pattern, timezone);
  }
}
