import { CommonModule } from '@angular/common';
import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wizard-summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class SummaryComponent {
  @HostBinding('hidden') hidden = false;

  private _active = false;

  set active(active: boolean) {
    this._active = active;
    this.hidden = !active;
  }

  get active(): boolean {
    return this._active;
  }
}
