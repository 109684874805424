import { companySelectors } from '@account/core/store/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';

import { ProducersGateway } from '../../gateways/plugins';
import {
  Contract,
  Document,
  Locale,
  Plugin,
  Producer,
  ProducerContractLifecycleStatus,
  ProducerCustomerValidationResult,
  ProducerGivenExtensionLicenseRequestData,
  SbpException,
  SupportLanguage,
} from '../../models';
import { producerStore } from '../../store/producer';
import { RootState } from '../../store/root.state';
import { SessionFacade } from '../app';
import { AuthFacade } from '../auth';
import { DocumentsFacade } from '../common';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class ProducersFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly authFacade: AuthFacade,
    private readonly sessionFacade: SessionFacade,
    private readonly documentsFacade: DocumentsFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly producersGateway: ProducersGateway
  ) {}

  hasProducer(): Observable<boolean> {
    return this.companiesFacade.hasCompany().pipe(
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of(false);
        }
        return this.findProducer().pipe(map((producer: Producer | undefined) => !!producer));
      })
    );
  }

  getProducer(): Observable<Producer> {
    return this.findProducer().pipe(filter((producer: Producer) => !!producer));
  }

  getProducerIdOnce(): Observable<number> {
    return this.getProducerId().pipe(take(1));
  }

  createProducer(): Observable<Producer> {
    return this.sessionFacade.getCompanyId().pipe(
      switchMap((companyId: number) => {
        this.store.dispatch(producerStore.actions.producer.createProducer({ payload: companyId }));
        return this.store.select(producerStore.selectors.producer.selectLoading);
      }),
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(producerStore.selectors.producer.selectError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(producerStore.selectors.producer.selectProducer);
      }),
      take(1)
    );
  }

  downloadContract(locale: Locale): Observable<Blob | SbpException> {
    return this.producersGateway.downloadContract(locale);
  }

  getCurrentContract(): Observable<Document> {
    return this.documentsFacade.getDocumentByVersion('producerContract', 'current');
  }

  updateProducer(producer: Producer): Observable<Producer> {
    this.store.dispatch(producerStore.actions.producer.updateProducer({ payload: producer }));
    return this.store.select(producerStore.selectors.producer.selectLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(producerStore.selectors.producer.selectError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(producerStore.selectors.producer.selectProducer);
      }),
      take(1)
    );
  }

  updateContract(producerId: number, contract: Contract): Observable<Contract> {
    this.store.dispatch(
      producerStore.actions.producer.updateContract({ payload: { producerId: producerId, contract: contract } })
    );
    return this.store.select(producerStore.selectors.producer.selectLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(producerStore.selectors.producer.selectError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(producerStore.selectors.producer.selectContract);
      }),
      take(1)
    );
  }

  uploadIcon(file: File): Observable<string> {
    this.store.dispatch(producerStore.actions.producer.updateIconUrl({ payload: file }));
    return this.store.select(producerStore.selectors.producer.selectLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(producerStore.selectors.producer.selectError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.store.select(producerStore.selectors.producer.selectIconUrl);
      }),
      take(1)
    );
  }

  getProducerContractLifecycleStatus(): Observable<ProducerContractLifecycleStatus> {
    return this.getProducer().pipe(
      take(1),
      map((producer: Producer) => this.getContractLifecycleStatusForProducer(producer))
    );
  }

  getProducerSupportLanguages(): Observable<SupportLanguage[]> {
    return this.producersGateway.getProducerSupportLanguages();
  }

  isAllowedToAddPlugin(contractStatus?: ProducerContractLifecycleStatus): Observable<boolean> {
    return this.getProducerContractLifecycleStatus$(contractStatus).pipe(
      map(
        (status: ProducerContractLifecycleStatus) =>
          !status.contractCancellationInProgress && status.approvedByShopware && status.newestApprovedByProducer
      )
    );
  }

  isAllowedToAddMarketingOptions(contractStatus?: ProducerContractLifecycleStatus): Observable<boolean> {
    return this.getProducerContractLifecycleStatus$(contractStatus).pipe(
      map(
        (status: ProducerContractLifecycleStatus) =>
          !status.contractCancellationInProgress && status.approvedByShopware && status.newestApprovedByProducer
      )
    );
  }

  isAllowedToEditMarketingOptions(contractStatus?: ProducerContractLifecycleStatus): Observable<boolean> {
    return this.getProducerContractLifecycleStatus$(contractStatus).pipe(
      map(
        (status: ProducerContractLifecycleStatus) =>
          !status.contractCancellationInProgress && status.approvedByShopware && status.approvedByProducer
      )
    );
  }

  isAllowedToCommentPluginReviews(contractStatus?: ProducerContractLifecycleStatus): Observable<boolean> {
    return this.getProducerContractLifecycleStatus$(contractStatus).pipe(
      map(
        (status: ProducerContractLifecycleStatus) => status.approvedByShopware && !status.contractCancellationInProgress
      )
    );
  }

  isAllowedToAddOrEditDeveloperEnvironments(contractStatus?: ProducerContractLifecycleStatus): Observable<boolean> {
    return this.getProducerContractLifecycleStatus$(contractStatus).pipe(
      map(
        (status: ProducerContractLifecycleStatus) =>
          !status.contractCancellationInProgress && status.approvedByShopware && status.newestApprovedByProducer
      )
    );
  }

  isContractCancellationInProgress(contractStatus?: ProducerContractLifecycleStatus): Observable<boolean> {
    return this.getProducerContractLifecycleStatus$(contractStatus).pipe(
      map((status: ProducerContractLifecycleStatus) => status.contractCancellationInProgress)
    );
  }

  isCustomerAndShopValid(customerNumber: string, shopDomain: string): Observable<ProducerCustomerValidationResult> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) =>
        this.producersGateway.isCustomerAndShopValid(producerId, customerNumber, shopDomain)
      )
    );
  }

  getProvisionPreviewForPlugin(plugin: Plugin): Observable<number> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.producersGateway.getProvisionPreviewForPlugin(producerId, plugin)),
      switchMap((response: { provisionAmount: number }) => of(response.provisionAmount))
    );
  }

  createProducerGivenLicense(requestData: ProducerGivenExtensionLicenseRequestData): Observable<void> {
    return this.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.producersGateway.createProducerGivenLicense(producerId, requestData))
    );
  }

  private getProducerContractLifecycleStatus$(
    status?: ProducerContractLifecycleStatus
  ): Observable<ProducerContractLifecycleStatus> {
    let status$: Observable<ProducerContractLifecycleStatus>;
    if (status) {
      status$ = of(status);
    } else {
      status$ = this.getProducerContractLifecycleStatus();
    }
    return status$;
  }

  private getContractLifecycleStatusForProducer(producer: Producer): ProducerContractLifecycleStatus {
    const producerContractLifecycleStatus: ProducerContractLifecycleStatus = {
      approvedByShopware: false,
      approvedByProducer: false,
      newestApprovedByProducer: false,
      contractCancellationInProgress: false,
      contractCancellationCompleted: false,
    };
    if (producer) {
      if (producer.cancelledContract) {
        if (producer.cancelledContract.status.name === 'completed') {
          producerContractLifecycleStatus.contractCancellationCompleted = true;
        } else if (producer.cancelledContract.status.name === 'inProgress') {
          producerContractLifecycleStatus.contractCancellationInProgress = true;
        }
      }

      if (producer.contract.shopwareApproved) {
        producerContractLifecycleStatus.approvedByShopware = true;
      }

      if (producer.contract.producerApproved) {
        producerContractLifecycleStatus.newestApprovedByProducer = true;
      }
      if (producer.contract.signedDocument) {
        producerContractLifecycleStatus.approvedByProducer = true;
      }
    }
    return producerContractLifecycleStatus;
  }

  private findProducer(): Observable<Producer | undefined> {
    return this.store.select(producerStore.selectors.producer.selectLoaded).pipe(
      switchMap((loaded: boolean) => {
        if (loaded) {
          return this.store.select(producerStore.selectors.producer.selectProducer);
        }
        return this.sessionFacade.getCompanyId().pipe(
          tap((companyId: number) => {
            this.store.dispatch(producerStore.actions.producer.getProducer({ payload: companyId }));
          }),
          switchMapTo(this.waitUntilProducerIsLoaded()),
          switchMapTo(this.findProducer())
        );
      }),
      catchError((error: SbpException) => throwError(error)),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  private waitUntilProducerIsLoaded(): Observable<boolean> {
    return this.store.select(producerStore.selectors.producer.selectLoaded).pipe(filter((loaded) => loaded));
  }

  private getProducerId(): Observable<number> {
    return this.store.select(companySelectors.getProducerId);
  }
}
