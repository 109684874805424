import { createReducer, on } from '@ngrx/store';

import { actions } from './support-permissions.actions';
import { SupportPermissionsState } from './support-permissions.state';

const initialState: SupportPermissionsState = {
  loading: false,
  loaded: false,
  error: undefined,
  supportPermissions: undefined,
};

export const reducer = createReducer(
  initialState,

  on(actions.resetPermissions, () => ({
    ...initialState,
  })),

  on(actions.getPermissions, (state: SupportPermissionsState) => ({
    ...state,
    loading: true,
  })),

  on(actions.getPermissionsSuccess, actions.getPermissionsFail, (state: SupportPermissionsState) => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  on(actions.getPermissionsSuccess, (state: SupportPermissionsState, { payload }) => ({
    ...state,
    supportPermissions: payload,
    error: undefined,
  })),

  on(actions.getPermissionsFail, (state: SupportPermissionsState, { payload }) => ({
    ...state,
    error: payload,
  }))
);
