<div class="tabs-header" [class.has-background]="hasBackground" [class.has-bottom-margin]="hasBottomMargin">
  <div class="header-wrapper" [class.tabs-center]="tabsCenter">
    <div
      class="header-inner"
      [class.no-side-padding]="noSidePadding"
      [ngStyle]="{ width: headerWidth, 'grid-template-columns': 'repeat(' + tabs.length + ', max-content)' }">
      <a *ngFor="let tab of tabs" class="tab" [class.active]="tab.active" (click)="activateTab(tab)">
        {{ tab.heading }}
        <span *ngIf="tab.contentAmount">&nbsp;({{ tab.contentAmount }})</span>
        <account-helptext class="helptext" *ngIf="tab.helptext" [content]="tab.helptext"></account-helptext>
      </a>
    </div>
  </div>
</div>
<div class="tab-content">
  <ng-content></ng-content>
</div>
