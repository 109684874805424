import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Locale } from '../../models';
import { ApiService } from '../../services';

export type DescriptionAssistantType = 'profile' | 'reference';

@Injectable({
  providedIn: 'root',
})
export class PartnersAiGateway {
  constructor(private readonly apiService: ApiService) {}

  getAiGeneratedPartnerDescription(
    partnerId: number,
    locale: Locale,
    keywords: string,
    type: DescriptionAssistantType
  ): Observable<string> {
    const params = new HttpParams().set('language', locale.name).set('keywords', keywords).set('type', type);
    return this.apiService.get(`/partners/${partnerId}/profile/ai/description`, params);
  }
}
