/* eslint-disable @typescript-eslint/no-require-imports */
import { Environment } from '@account/core/models';

export const defaultVariables: Environment = {
  envName: 'dev',
  version: '2.0.51@4ba3f572f4d83b2485dc449cb8c3eee5441157e3',
  production: false,
  hmr: false,
  apiUrl: 'https://sbp-next-core.shopware.in',
  packages: {
    protocol: 'https',
    domain: 'sbp-staging-packages.shopware.in',
  },
  sessionCookieDomain: 'localhost',
  sessionCookieName: 'shopware_sbp_sessionToken',
  sessionCookieAccount2BypassGTC: 'shopware_account_bypass_gtc',

  auth_ui_url: 'https://auth.shopware.in',
  auth_api_url: 'https://auth-api.shopware.in',

  heidelpayPublicKey: 's-pub-2a10zqs4RKf3bDuWq5Qz23Z47rZhR7qU',
  hubspot_environment: '7299116',
  hubspot_cancel_subscription_formId_german: '8f67f03c-490c-41b0-9f58-ebcdcbc5b7f3',
  hubspot_cancel_subscription_formId_english: 'bfade7f1-12cf-43af-9e21-c832202c120b',
  hubspot_cancel_rent_formId_german: '0d43cbc3-8d50-47a2-8ca8-2cb78ba371c6',
  hubspot_cancel_rent_formId_english: '898b8580-8ea6-479d-8c86-03842a43ebba',
  hubspot_cancel_extension_rent_formId_german: '2ad93c70-dc40-4fcd-b9af-298cd79f6f6c',
  hubspot_cancel_extension_rent_formId_english: '309628a4-a165-4402-bbc3-cce5ecebab03',
  hubspot_cancel_contract_trial_formId_german: '60488fc5-a1b3-4298-9539-e4524aff2dd0',
  hubspot_cancel_contract_trial_formId_english: 'da234504-ff61-4312-92f6-0f63f3b22249',
  hubspot_forms_base_url: 'https://share.hsforms.com/',
  hubspot_integration_partner_deal_registration_form_url_german: '12m0QRCI-ROSDjV07KyonAw4cg18',
  hubspot_integration_partner_deal_registration_form_url_english: '1eJfhXou5Tkmimol69gVsIA4cg18',
  hubspot_technology_partner_deal_registration_form_url_german: '1C9PxHwFYT12Nwb5fgiXkTQ4cg18',
  hubspot_technology_partner_deal_registration_form_url_english: '19LfgQqwtQwamhntcwiCvLg4cg18',

  contentfulApiKey: 'OzuBwaSAoTg0hj_X_fOcpc1DYb1vBuanOHEVFExadwU',
  contentfulSpaceId: 'nqzs8zsepqpi',
  contentfulFeedContentTypeId: 'shopwareAccountFeed',
  contentfulSupportContentTypeId: 'shopwareAccountSupportFeed',
  contentfulPopUpModalContentTypeId: 'shopwareAccountPopUpModal',
  contentfulEnvironment: 'sandbox',

  friendlyCaptchaSiteKey: 'FCMG59TQ1GCAA8C2',
  friendlyCaptchaPuzzleEndpoints: [
    'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
    'https://api.friendlycaptcha.com/api/v1/puzzle',
  ],

  googleTagManagerId: '',

  storeProtocol: 'https',
  storeIp: 'store.sbp-staging.shopware.com',
  storePort: '443',
};
