<div class="menu-button" (click)="toggleNavigation()">
  <account-sw-icon icon="bars" size="24px"></account-sw-icon>
</div>

<div class="info--icon" (click)="goToPortal()"></div>

<div class="action-bar-content" [class.has-actions]="!!actionTemplateRef">
  <account-breadcrumbs></account-breadcrumbs>

  <div *ngIf="!!actionTemplateRef" class="action-bar-content--actions">
    <ng-container [ngTemplateOutlet]="actionTemplateRef" />
  </div>

  <div class="action-bar-content--defaults">
    <ng-container *ngIf="basket$ | async as basket">
      <button
        *ngIf="basket.positions && basket.positions.length > 0"
        class="a_button basket-button"
        (click)="openBasketModal()">
        <account-sw-icon icon="shopping-cart" size="16px"></account-sw-icon>
        <span>
          {{ 'COMMON.BASKET.LABEL' | translate }}
          <span class="basket-button__count">({{ getBasketPositionsCount(basket) }})</span>
        </span>
      </button>
    </ng-container>

    <div *ngIf="notifications$ | async" class="notification-wrapper" [ngClass]="{ 'is-expanded': isExpanded }">
      <div class="notification-wrapper-inner">
        <div #notificationWrap class="notification-trigger" role="button" (click)="openModal()">
          <account-sw-icon icon="bell" size="20px"></account-sw-icon>
          <div *ngIf="showNotificationBubble" class="notification-bubble"></div>
        </div>
      </div>
    </div>
  </div>
</div>
