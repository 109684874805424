<ng-container sbpColumnDef>
  <sbp-header-cell *sbpHeaderCellDef>
    <account-checkbox
      (change)="$event ? masterToggle() : null"
      [checked]="selection.hasValue() && isAllSelected()"
      [indeterminate]="selection.hasValue() && !isAllSelected()"></account-checkbox>
  </sbp-header-cell>
  <sbp-cell *sbpCellDef="let row">
    <account-checkbox
      (change)="$event ? selection.toggle(row) : null"
      [checked]="selection.isSelected(row)"></account-checkbox>
  </sbp-cell>
</ng-container>
