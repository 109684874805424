import { TypeOfObjectProperty } from '../models/extension.types';

export type TreeEntry<T> = DefaultTreeEntry<T> & {
  [Property in keyof T]: TypeOfObjectProperty<T, Property>;
};

interface DefaultTreeEntry<T> {
  id?: number | string;
  parent?: 0 | number | string;
  children?: TreeEntry<T>[];
  [key: string]: number | string | TypeOfObjectProperty<T, keyof T> | TreeEntry<T>[];
}

export interface TreeConvertOptions {
  idKey?: 'id' | string;
  parentKey?: 'parent' | string;
  childrenKey?: 'children' | string;
}

export class ArrayUtils {
  // converts a flat array to a tree
  static toTree<T>(array: T[], options: TreeConvertOptions): TreeEntry<T>[] {
    options = options || {};
    const ID_KEY = options.idKey || 'id';
    const PARENT_KEY = options.parentKey || 'parent';
    const CHILDREN_KEY = options.childrenKey || 'children';

    const tree: TreeEntry<T>[] = [];
    const childrenOf: any = {};

    for (let i = 0, length = array.length; i < length; i++) {
      const element: Record<string, any> = array[i];
      if (!Object.prototype.hasOwnProperty.call(element, ID_KEY)) {
        throw new Error('Implementation failure, expected object identifier to be set');
      }
      const id = element[ID_KEY];
      const parentId = element[PARENT_KEY] || 0;

      // every item may have children
      childrenOf[id] = childrenOf[id] || [];

      // init its children
      const item: TreeEntry<T> = {
        ...array[i],
        [CHILDREN_KEY]: childrenOf[id],
      };

      if (parentId !== 0) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }
    return tree;
  }
}
