import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
// DONT CHANGE! circular dependency caused by this --- TODO: navigation.facade should not be an facade it should be a service
import { filter } from 'rxjs/operators';

import { ModalService } from '../../../shared/components/modal/modal.service';
// do not shorten this import otherwise it will create a circular reference
import { ChangelogModalComponent } from '../../components/changelog-modal/changelog-modal.component';
import { CHANGELOG } from '../../components/changelog-modal/changelogs';
import { NavigationFacade } from '../../facades/app/navigation.facade';
import { Workspace } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ChangelogService {
  private subscription$: Subscription;

  constructor(
    private readonly cookieService: CookieService,
    private readonly modalService: ModalService,
    private readonly navigationFacade: NavigationFacade
  ) {}

  openChangelogModal(force = false): void {
    this.subscription$ = this.navigationFacade
      .getActiveWorkspace()
      .pipe(filter((workspace: Workspace) => !!workspace))
      .subscribe((workspace: Workspace) => {
        if (!workspace.hasChangelog) {
          return;
        }

        const key = `${CHANGELOG.key}-${workspace.name}`;
        let version;
        switch (key) {
          case 'account2-changelog-producer':
            version = CHANGELOG.VERSION_PRODUCER;
            break;
          case 'account2-changelog-partner':
            version = CHANGELOG.VERSION_PARTNER;
            break;
          case 'account2-changelog-shopOwner':
            version = CHANGELOG.VERSION_SHOPS;
            break;
          // skip default
        }

        const value = this.cookieService.get(key);
        if (force || !value || version > +value) {
          this.modalService.open(ChangelogModalComponent, { data: workspace });
          const expireDate = new Date();
          expireDate.setFullYear(expireDate.getFullYear() + 1);
          this.cookieService.set(key, version.toString(), expireDate);
        }
      });

    this.subscription$.unsubscribe();
  }
}
