import { createSelector } from '@ngrx/store';

import { Company, SbpException } from '../../../models';
import * as companyRoot from '../company-root.store';
import { CompanyState } from './company.reducer';

const companyState = createSelector(companyRoot.state, (state: companyRoot.State): CompanyState => state.company);

export const isLoading = createSelector(companyState, (state: CompanyState) => state.loading);
export const isLoaded = createSelector(companyState, (state: CompanyState) => state.loaded);

export const getError = createSelector(companyState, (state: CompanyState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getCompany = createSelector(companyState, (state: CompanyState) => state.company);

export const isBanned = createSelector(getCompany, (company: Company) => (company ? company.isBanned : false));

export const isSoftBanned = createSelector(getCompany, (company: Company) => (company ? company.isSoftBanned : false));
