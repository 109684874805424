import { PaymentMean } from '@account/core/models';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private readonly translateService: TranslateService) {}

  getOption(paymentMean: PaymentMean): string {
    switch (this.convertPaymentCode(paymentMean)) {
      case 'DD.DB': {
        let value = this.translateService.instant('ACCOUNTING.PAYMENT_MEAN.TEMPLATE.DD_DB', {
          iban: this.convertIban(paymentMean.iban),
        });
        if (paymentMean.bic) {
          value += ` / ${this.translateService.instant('ACCOUNTING.PAYMENT_MEAN.TEMPLATE.DD_DB_BIC', { bic: paymentMean.bic })}`;
        }
        return value;
      }
      case 'DD.BLZ':
        return this.translateService.instant('ACCOUNTING.PAYMENT_MEAN.TEMPLATE.DD_BLZ', {
          number: paymentMean.accountNumber,
          blz: paymentMean.bankNumber,
        });
      case 'VA.DB':
        if (!paymentMean.accountNumber) {
          return this.translateService.instant('ACCOUNTING.PAYMENT_MEAN.LABEL.PAYPAL');
        }
        return this.translateService.instant('ACCOUNTING.PAYMENT_MEAN.TEMPLATE.VA_DB', {
          eMail: paymentMean.accountNumber,
        });
      case 'CC.DB':
        return this.translateService.instant('ACCOUNTING.PAYMENT_MEAN.TEMPLATE.CC_DB', {
          number: paymentMean.accountNumber,
          month: paymentMean.expireMonth,
          year: paymentMean.expireYear,
        });
      // skip default
    }

    return paymentMean.paymentCode;
  }

  convertPaymentCode(paymentMean: PaymentMean): string {
    if (paymentMean.paymentCode === 'DD' || paymentMean.paymentCode === 'DD.DB') {
      if (!paymentMean.iban) {
        return 'DD.BLZ';
      }
    }

    switch (paymentMean.paymentCode) {
      case 'DD':
        return 'DD.DB'; // Bank account
      case 'VA':
        return 'VA.DB'; // PayPal
      case 'CC':
        return 'CC.DB'; // Credit card
      default:
        return paymentMean.paymentCode;
    }
  }

  convertIban(iban: string): string {
    const NON_ALPHANUM = /[^\d*A-Za-z]/g;
    const EVERY_FOUR_CHARS = /(.{4})(?!$)/g;
    return iban
      .replace(NON_ALPHANUM, '')
      .toUpperCase()
      .replace(EVERY_FOUR_CHARS, '$1' + ' ');
  }
}
