import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';
import { SafePipe } from '../../../shared/pipes';

@Component({
  selector: 'account-banned-banner',
  templateUrl: './banned-banner.component.html',
  styleUrl: './banned-banner.component.less',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule, RouterModule, SwIconComponent, SafePipe],
})
export class BannedBannerComponent implements OnInit {
  @Input() isBanned: boolean;
  @Input() isSoftBanned: boolean;
  tooltip: string;
  infoText: string;

  ngOnInit(): void {
    this.tooltip = 'CORE.BANNED.TOOLTIP';
    this.infoText = this.isBanned && this.isSoftBanned ? 'CORE.BANNED.SOFT_TEXT' : 'CORE.BANNED.TEXT';
  }
}
