import { OnPremiseShop, SubscriptionBasketItem } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SubscriptionAssistantGateway } from '../../gateways/shop';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionAssistantFacade {
  constructor(private readonly subscriptionAssistantGateway: SubscriptionAssistantGateway) {}

  getSubscriptionBasket(shopId: number, partnerId: number = null): Observable<SubscriptionBasketItem[]> {
    return this.subscriptionAssistantGateway.getSubscriptionBasket(shopId, partnerId).pipe(catchError(() => of([])));
  }

  proceedSubscriptionBasket(
    shop: OnPremiseShop,
    basket: SubscriptionBasketItem[],
    totalAmount: number,
    partnerId: number = null
  ): Observable<void> {
    const payload = {
      subscriptionBasket: basket,
      totalAmount: totalAmount,
      domain: shop.domain,
      bookingDomain: shop.domain,
      partnerId: partnerId,
    };

    if (partnerId) {
      payload.bookingDomain = 'www.shopware-ag.de';
    }

    return this.subscriptionAssistantGateway.proceedSubscriptionBasket(shop.id, payload);
  }
}
