import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResult, PackagesToken, WildcardBase, WildcardInstance, WildcardStatics } from '../../models';
import { Plugin } from '../../models/plugin/plugin.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class WildcardGateway {
  constructor(private readonly apiService: ApiService) {}

  addWildcardBase(companyId: number, wildcardBase: WildcardBase): Observable<WildcardBase> {
    return this.apiService.post(`/companies/${companyId}/wildcardlicenses`, wildcardBase);
  }

  getWildcardBaseList(companyId: number): Observable<WildcardBase[]> {
    const params = new HttpParams();
    return this.apiService.get(`/companies/${companyId}/wildcardlicenses`, params);
  }

  getWildcardBase(companyId: number, baseId: number): Observable<WildcardBase> {
    return this.apiService.get(`/companies/${companyId}/wildcardlicenses/${baseId}`);
  }

  getWildcardInstance(companyId: number, baseId: number, instanceId: number): Observable<WildcardInstance> {
    return this.apiService.get(`/companies/${companyId}/wildcardlicenses/${baseId}/instances/${instanceId}`);
  }

  addWildcardInstance(companyId: number, wildcardInstance: WildcardInstance): Observable<WildcardInstance> {
    return this.apiService.post(
      `/companies/${companyId}/wildcardlicenses/${wildcardInstance.baseId}/instances`,
      wildcardInstance
    );
  }

  updateWildcardInstance(companyId: number, wildcardInstance: WildcardInstance): Observable<WildcardInstance> {
    return this.apiService.put(
      `/companies/${companyId}/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}`,
      wildcardInstance
    );
  }

  deleteWildcardInstance(companyId: number, wildcardInstance: WildcardInstance): Observable<WildcardInstance> {
    return this.apiService.delete(
      `/companies/${companyId}/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}`
    );
  }

  getDownloadUrlForPlugin(companyId: number, wildcardInstance: WildcardInstance, plugin: Plugin): Observable<any> {
    const params = new HttpParams().set('json', 'true');
    return this.apiService.get(
      `/companies/${companyId}/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}/plugins/${plugin.code}/downloads/${wildcardInstance.shopwareVersion.name}`,
      params
    );
  }

  getWildcardLicensePlugins(
    companyId: number,
    baseId: number,
    instanceId: number,
    searchTerm: string,
    filter = {}
  ): Observable<ListResult<Plugin[]>> {
    let params = new HttpParams().set('search', searchTerm);
    params = HttpParamsBuilder.applyFilters(params, filter);
    return this.apiService.getList(
      `/companies/${companyId}/wildcardlicenses/${baseId}/instances/${instanceId}/plugins`,
      params
    );
  }

  getPackagesComposerToken(wildcardInstance: WildcardInstance): Observable<PackagesToken> {
    return this.apiService.get(
      `/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}/packagestoken`
    );
  }

  generatePackagesComposerToken(wildcardInstance: WildcardInstance): Observable<PackagesToken> {
    return this.apiService.post(
      `/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}/packagestoken`
    );
  }

  verifyPackagesComposerToken(wildcardInstance: WildcardInstance, token: string): Observable<void> {
    return this.apiService.post(
      `/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}/packagestoken/${token}`
    );
  }

  revokePackagesComposerToken(wildcardInstance: WildcardInstance, token: string): Observable<void> {
    return this.apiService.delete(
      `/wildcardlicenses/${wildcardInstance.baseId}/instances/${wildcardInstance.id}/packagestoken/${token}`
    );
  }

  checkForWildcardEnvironmentsRestrictions(companyId: number): Observable<{ success: boolean }> {
    return this.apiService.get(`/companies/${companyId}/wildcardlicenses/access`);
  }

  getStatics(companyId: number): Observable<WildcardStatics> {
    return this.apiService.get(`/companies/${companyId}/wildcardlicenses/statics`);
  }
}
