import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { StringUtils } from '../../core';
import { isUrl } from '../util';

export class StringValidators {
  static equals =
    (value: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const currentValue = control.value;

      return value !== currentValue ? { equal: { expected: value, actual: currentValue } } : null;
    };

  static notEquals =
    (value: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const currentValue = control.value;
      return value === currentValue ? { notEquals: { message: 'Value is equal', value: value } } : null;
    };

  static notEqualsWithOtherControl =
    (otherValue: AbstractControl): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const currentValue = control.value;
      const value = otherValue.value;

      if (value === currentValue) {
        return { equalsWithOtherControl: true };
      }

      return null;
    };

  static notInList =
    (values: any[], compareBy?: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const currentValue = control.value;
      if (!compareBy) {
        return values.includes(currentValue)
          ? { notInList: { message: 'Value exists in list', values: values } }
          : null;
      }

      const mappedValues = values.map((value: any) => value[compareBy]);
      return mappedValues.includes(currentValue[compareBy])
        ? { notInList: { message: 'Value exists in list', values: values } }
        : null;
    };

  static noWhitespace(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      if (value && value.includes(' ')) {
        return { noWhitespace: true };
      }
    }

    return null;
  }

  static notTrimmed =
    (character = ' '): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (control) {
        const value: string = control.value;
        if (value.startsWith(character) || value.endsWith(character)) {
          return { notTrimmed: true };
        }
      }
      return null;
    };

  static email(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      const regexDoubleAt = /@.*@/;

      if (value && regexDoubleAt.test(value)) {
        return { email: true };
      }

      if (value && !StringUtils.isEmail(value)) {
        return { email: true };
      }
    }

    return null;
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      const regex = /^(\+?[\d\s()/-]+)$/;
      if (value && !regex.test(value)) {
        return { phone: true };
      }
    }
    return null;
  }

  static urlWithoutProtocol(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      if (!isUrl(value)) {
        return { url: true, httpPrefix: /^https?(.*)$/i.test(value) };
      }
    }

    return null;
  }

  static urlWithProtocol(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      if (!isUrl(value, true)) {
        return { url: true };
      }
    }

    return null;
  }

  static uuid(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      const regex = /^[\da-f]{0,8}-?[\da-f]{0,4}-?([1-5][\da-f]{0,3})?-?([89ab][\da-f]{0,3})?-?[\da-f]{0,12}$/i;
      if (value && !regex.test(value)) {
        return { uuidStructure: true };
      }

      if (value.length !== 36) {
        return { uuid: true };
      }
    }

    return null;
  }

  static wildcardInstance(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      const regex = /^[\dA-Za-z][\d.A-Za-z-]*[\dA-Za-z]$/;
      if (value && !regex.test(value)) {
        return { wildcardInstance: true };
      }
    }

    return null;
  }

  static maxLines =
    (limit: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      const numberOfLines = (value.match(/\n/g) || '').length + 1;
      return numberOfLines > limit ? { maxLines: { limit: limit, numberOfLines: numberOfLines } } : null;
    };

  static hasUpperCaseLetter(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      if (value && value === value.toUpperCase()) {
        return { upperCase: true };
      }
    }

    return null;
  }

  static hasLowerCaseLetter(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      if (value && value === value.toLowerCase()) {
        return { lowerCase: true };
      }
    }

    return null;
  }

  static hasUpperAndLowerCaseLetter(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      const hasLower = /[a-z]/.test(value);
      const hasUpper = /[A-Z]/.test(value);

      if (value && (!hasLower || !hasUpper)) {
        return { lowerAndUpperLetter: true };
      }
    }

    return null;
  }

  static hasNumber(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      const regex = /\d/;

      if (value && !regex.test(value)) {
        return { number: true };
      }
    }

    return null;
  }

  static hasSpecialCharacter(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      const regex = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;

      if (value && !regex.test(value)) {
        return { specialCharacter: true };
      }
    }

    return null;
  }

  static path(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;

      const regex = /^(.+)\/$/;

      if (value && regex.test(value)) {
        return { path: true };
      }
    }

    return null;
  }

  static trimmedMinLength =
    (minLength: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (!(typeof control.value === 'string')) {
        throw new Error('trimmedMinLength is used on a non-string control');
      }

      const value: string = control.value.trim();
      return value.length < minLength ? { trimmedMinLength: true } : null;
    };
}
