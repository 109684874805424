import { companyActions } from '@account/core/store/actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, mergeMap, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { ToastService } from '../../../shared/components/toast/toast.service';
import { SupportTicketGateway } from '../../gateways/support/support-ticket-gateway';
import {
  ListResult,
  SbpException,
  SupportTicket,
  SupportTicketAnswer,
  SupportTicketAnswerRating,
  SupportTicketInternalComment,
  SupportTicketStatus,
  SupportTicketWithBasicInformation,
  TimeZone,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { Plugin } from '../../models/plugin/plugin.model';
import { SupportMatrixEntry, SupportTicketAttachment } from '../../models/support/support.model';
import { ExceptionFormatterService } from '../../services';
import { SupportTicketLocalizer } from '../../services/data-localizer/support-ticket.localizer';
import { RootState } from '../../store/root.state';
import { CompaniesFacade } from '../company';
import { ProducersFacade } from '../plugins';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketFacade {
  constructor(
    private readonly store: Store<RootState>,
    private readonly companiesFacade: CompaniesFacade,
    private readonly producersFacade: ProducersFacade,
    private readonly supportTicketGateway: SupportTicketGateway,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly exceptionFormatterService: ExceptionFormatterService,
    private readonly supportTicketLocalizer: SupportTicketLocalizer
  ) {}

  getSupportTicketListByCompany(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SupportTicketWithBasicInformation[]>> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) =>
        this.supportTicketGateway.getSupportTicketListByCompany(companyId, metaData, filter)
      ),
      map((result: ListResult<SupportTicketWithBasicInformation[]>) => ({
        ...result,
        list: result.list.map((ticket: SupportTicketWithBasicInformation) =>
          this.supportTicketLocalizer.localizeSupportTicketWithBasicInformation(ticket)
        ),
      }))
    );
  }

  getSupportTicketListByProducer(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SupportTicketWithBasicInformation[]>> {
    return this.producersFacade.getProducerIdOnce().pipe(
      switchMap((producerId: number) =>
        this.supportTicketGateway.getSupportTicketListByProducer(metaData, producerId, filter)
      ),
      map((result: ListResult<SupportTicket[]>) => ({
        ...result,
        list: result.list.map((ticket: SupportTicket) =>
          this.supportTicketLocalizer.localizeSupportTicketWithBasicInformation(ticket)
        ),
      }))
    );
  }

  getSupportTicketAsProducer(ticketId: number): Observable<SupportTicket> {
    return this.producersFacade.getProducerIdOnce().pipe(
      switchMap((producerId: number) => this.supportTicketGateway.getSupportTicketAsProducer(producerId, ticketId)),
      map((ticket: SupportTicket) => this.supportTicketLocalizer.localizeSupportTicket(ticket))
    );
  }

  getSupportTicket(ticketId: number): Observable<SupportTicket> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.supportTicketGateway.getSupportTicket(companyId, ticketId)),
      map((ticket: SupportTicket) => this.supportTicketLocalizer.localizeSupportTicket(ticket))
    );
  }

  updateSupportTicketStatus(ticketId: number, data: any): Observable<SupportTicketStatus> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.supportTicketGateway.updateSupportTicketStatus(companyId, ticketId, data))
      );
  }

  updateSupportTicketStatusAsProducer(ticketId: number, data: any): Observable<SupportTicketStatus> {
    return this.producersFacade.getProducerIdOnce().pipe(
      switchMap((producerId: number) =>
        this.supportTicketGateway.updateSupportTicketStatusAsProducer(producerId, ticketId, data).pipe(
          mergeMap((status: SupportTicketStatus) =>
            this.companiesFacade.getCompanyIdOnce().pipe(
              tap((companyId: number) =>
                this.store.dispatch(
                  companyActions.supportTicketUpdates.refreshSupportTicketUpdate({ payload: companyId })
                )
              ),
              mapTo(status)
            )
          )
        )
      )
    );
  }

  validateAttachments(files: File[]): Observable<File[]> {
    const validationRequests$ = files.map((file: File) => this.validateAttachment(file));
    return forkJoin(validationRequests$).pipe(
      map((files: (null | File)[]) => files.filter((file: null | File) => !!file))
    );
  }

  uploadAttachments(files: File[]): Observable<SupportTicketAttachment[]> {
    const attachmentRequests$ = files.map((file: File) => this.uploadAttachment(file));
    return forkJoin(attachmentRequests$).pipe(
      map((files: (null | SupportTicketAttachment)[]) => files.filter((file: null | SupportTicketAttachment) => !!file))
    );
  }

  downloadAttachment(ticket: SupportTicket, attachment: SupportTicketAttachment): Observable<Blob | SbpException> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) =>
        this.supportTicketGateway.getDownloadUrlForAttachment(ticket, attachment, companyId)
      ),
      switchMap((data: SupportTicketAttachment) => this.supportTicketGateway.downloadAttachmentFile(data.remoteLink))
    );
  }

  getDownloadUrlForAttachment(ticket: SupportTicket, attachment: SupportTicketAttachment): Observable<string> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.supportTicketGateway
            .getDownloadUrlForAttachment(ticket, attachment, companyId)
            .pipe(map((response: SupportTicketAttachment) => response.remoteLink))
        )
      );
  }

  addAnswer(ticket: SupportTicket, data: SupportTicketAnswer): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.supportTicketGateway.addAnswer(ticket, companyId, data)));
  }

  addAnswerAsProducer(ticket: SupportTicket, data: SupportTicketAnswer): Observable<void> {
    return this.producersFacade.getProducerIdOnce().pipe(
      switchMap((producerId: number) =>
        this.supportTicketGateway.addAnswerAsProducer(ticket, producerId, data).pipe(
          switchMapTo(this.companiesFacade.getCompanyIdOnce()),
          tap((companyId: number) =>
            this.store.dispatch(companyActions.supportTicketUpdates.refreshSupportTicketUpdate({ payload: companyId }))
          ),
          mapTo(void 0)
        )
      )
    );
  }

  addInternalComment(ticket: SupportTicket, data: SupportTicketInternalComment): Observable<void> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(switchMap((producerId: number) => this.supportTicketGateway.addInternalComment(ticket, producerId, data)));
  }

  addTicketAnswerRating(
    ticket: SupportTicket,
    answer: SupportTicketAnswer,
    data: any
  ): Observable<SupportTicketAnswerRating> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.supportTicketGateway.addTicketAnswerRating(companyId, ticket, answer, data)
        )
      );
  }

  createTicket(companyId: number, ticket: any): Observable<SupportTicketWithBasicInformation> {
    return this.supportTicketGateway.createTicket(companyId, ticket);
  }

  getEstimatedDeadline(
    typeName: string,
    entry?: SupportMatrixEntry,
    plugin?: Plugin,
    timeZone?: TimeZone
  ): Observable<string> {
    return this.supportTicketGateway.getEstimatedDeadline(typeName, entry, plugin, timeZone);
  }

  private validateAttachment(file: File): Observable<null | File> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.supportTicketGateway.validateAttachment(companyId, file)),
      map(() => file),
      catchError((exception: SbpException) => {
        this.toastService.error(
          this.translateService.instant('COMMON.TOAST.ERROR.TITLE.UPLOAD_FAILED'),
          this.exceptionFormatterService.getMessageByContext(exception, null),
          true
        );
        return of(null);
      })
    );
  }

  private uploadAttachment(file: File): Observable<SupportTicketAttachment> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.supportTicketGateway.uploadAttachment(companyId, file)),
      catchError((exception: SbpException) => {
        this.toastService.error(
          this.translateService.instant('COMMON.TOAST.ERROR.TITLE.UPLOAD_FAILED'),
          this.exceptionFormatterService.getMessageByContext(exception, null),
          true
        );
        return of(null);
      })
    );
  }
}
