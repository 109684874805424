import { Component, Inject, OnInit } from '@angular/core';

import { ModalComponent } from '../../modal';
import { MODAL_DATA } from '../../modal/modal-injector';
import { ModalRef } from '../../modal/modal-ref';

@Component({
  selector: 'account-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrl: './attachment-preview.component.less',
  standalone: true,
  imports: [ModalComponent],
})
export class AttachmentPreviewComponent implements OnInit {
  name: string;
  imageSource: string;

  constructor(
    private readonly modalRef: ModalRef,
    @Inject(MODAL_DATA) private readonly modalData: any
  ) {}

  ngOnInit(): void {
    this.name = this.modalData.name;
    this.imageSource = this.modalData.imageSource;
  }

  onCloseModal(): void {
    this.modalRef.close();
  }
}
