import { PaymentMean } from '@account/core/models';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { PaymentService } from '../services/payment.service';

@Pipe({
  name: 'paymentMeanOption',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class PaymentMeanOptionPipe implements PipeTransform {
  constructor(private readonly paymentService: PaymentService) {}

  transform(paymentMean: PaymentMean): string {
    return this.paymentService.getOption(paymentMean);
  }
}
