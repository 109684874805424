import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { QueryFilter } from '../../../../core/models/api/query-filter.model';
import { QueryFilterGroup } from '../../../../core/models/api/query-filter-group.model';

@Injectable({
  providedIn: 'root',
})
export class SbpTableFilterService {
  getActiveFilterGroups(formGroup: UntypedFormGroup): QueryFilterGroup[] {
    const filterGroups: QueryFilterGroup[] = [];
    if (!formGroup) {
      return filterGroups;
    }

    const formValues = formGroup.value;
    for (const key in formValues) {
      if (Object.prototype.hasOwnProperty.call(formValues, key)) {
        if ('search' !== key) {
          let formGroupActive = false;
          let containsInvalidValues = false;
          const formGroupValues = formValues[key];
          const filterItems: QueryFilter[] = [];
          for (const formGroupKey in formGroupValues) {
            if (Object.prototype.hasOwnProperty.call(formGroupValues, formGroupKey)) {
              if ('active' === formGroupKey) {
                formGroupActive = formGroupValues[formGroupKey];
              } else {
                const value = formGroupValues[formGroupKey];
                if ([null, undefined].includes(value)) {
                  containsInvalidValues = true;
                }
                filterItems.push({ property: formGroupKey, value: value });
              }
            }
          }
          if (!containsInvalidValues) {
            filterGroups.push({ active: formGroupActive, items: filterItems });
          }
        }
      }
    }

    return filterGroups.filter((group) => group.active === true);
  }
}
