import { NgIf } from '@angular/common';
import { Component, ContentChild, Directive, HostBinding, Input, Provider, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: 'message-header',
  standalone: true,
})
export class MessageHeaderDirective {}

@Directive({
  selector: 'message-body',
  standalone: true,
})
export class MessageBodyDirective {}

@Directive({
  selector: 'message-footer',
  standalone: true,
})
export class MessageFooterDirective {}

@Component({
  selector: 'account-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf],
})
export class MessageComponent {
  @HostBinding('class.is-primary') @Input() isUserMessage: boolean;
  @Input() isMarkdown = false;
  @Input() footerPadding = '16px';
  @ContentChild(MessageFooterDirective) footer: MessageFooterDirective;
}

export const messageBarrel: Provider[] = [
  MessageHeaderDirective,
  MessageBodyDirective,
  MessageFooterDirective,
  MessageComponent,
];
