import { AbstractControl, ValidationErrors } from '@angular/forms';

export class RegistrationValidators {
  static matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password').value;
    const passwordRepeat = control.get('passwordRepeat').value;
    if (password !== passwordRepeat) {
      return { passwordMismatch: true };
    }
    return null;
  }

  static matchEmail(control: AbstractControl): ValidationErrors | null {
    const email = control.get('email').value;
    const emailRepeat = control.get('emailRepeat').value;
    if (email !== emailRepeat) {
      return { emailMismatch: true };
    }
    return null;
  }
}
