import { LanguagesFacade } from '@account/core/facades';
import { AsyncPipe } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isDate, parseISO } from 'date-fns';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { Observable, Subscription } from 'rxjs';

import { Language } from '../../../../core/models/common/language.model';
import { dateToDateString } from '../../../util';
import { DatepickerStatics } from './datepicker-statics';

@Component({
  selector: 'account-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.less',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CalendarModule, FormsModule, AsyncPipe],
})
export class DatepickerComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit, ControlValueAccessor {
  /**
   * http://www.primefaces.org/primeng/#/calendar
   */
  @ViewChild(Calendar, { static: true }) calendarComponent: Calendar;

  @Input() date: Date | string = null;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() placeholder: string;
  @Input() readonlyInput = false;
  @Input() disabled = false;
  @Input() showTime = false;
  @Input() timeOnly = false;
  @Input() formatDateFull = false;
  @Input() yearRange: string;
  @Input() returnType: 'both' | 'dateObject' | 'string' = 'both';
  @Input() view: 'date' | 'month' = 'date';
  @Output() readonly change = new EventEmitter<any>();

  readonly LOCALIZATION = DatepickerStatics.LOCALIZATION;

  internalDate: Date = null;
  currentLanguage$: Observable<Language>;

  private languageSubscription: Subscription;

  constructor(
    public elementRef: ElementRef,
    private readonly ngZone: NgZone,
    private readonly languagesFacade: LanguagesFacade
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      Object.prototype.hasOwnProperty.call(changes, 'date') &&
      changes['date']['previousValue'] !== changes['date']['currentValue']
    ) {
      const currentValue = changes['date']['currentValue'];
      if (null !== currentValue && undefined !== currentValue) {
        this.internalDate = parseISO(changes['date']['currentValue']);
      }
    }
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.languagesFacade.getSelectedLanguage();

    if (null !== this.date && undefined !== this.date) {
      this.internalDate = parseISO(this.date as string);
    }
  }

  writeValue(value: any): void {
    let year = new Date().getFullYear();

    if (null !== value && undefined !== value) {
      if (isDate(value)) {
        this.internalDate = value;
      } else {
        this.internalDate = parseISO(value);
      }
      year = this.internalDate.getFullYear();
    } else {
      this.internalDate = null;
    }

    this.yearRange = `${year}:${year + 2}`;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: (changedData: any) => any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.propagateTouched = fn;
  }

  onBlur(): void {
    this.propagateTouched();
  }

  onChange(date: Date): void {
    /**
     * if updated value is lower than minDate the datepicker sets the date for the change-event to the minDate
     * therefore if the incoming date is the same as the minDate the user has set a value lower than the minDate
     *
     * => this means we need to check if time of the day of the internalDate (date before change) is before the minDate
     * if this is the case we need to update the value and the UI
     */

    if (date && this.minDate && this.internalDate && date.getTime() === this.minDate.getTime()) {
      const hoursInternal = this.internalDate.getHours();
      const minutesInternal = this.internalDate.getMinutes();
      const secondsInternal = this.internalDate.getSeconds();
      const hoursMin = this.minDate.getHours();
      const minutesMin = this.minDate.getMinutes();
      const secondsMin = this.minDate.getSeconds();

      let updateValue = false;
      if (hoursInternal < hoursMin) {
        updateValue = true;
      } else {
        if (minutesInternal < minutesMin) {
          updateValue = true;
        } else {
          if (secondsInternal < secondsMin) {
            updateValue = true;
          }
        }
      }

      if (updateValue) {
        this.writeValue(this.minDate);
        this.calendarComponent.updateTime();
      }
    }

    this.change.emit(this.getCallbackData(date));
    this.propagateChange(this.getCallbackData(date));
  }

  ngAfterContentInit(): void {
    this.languageSubscription = this.currentLanguage$.subscribe((language: Language) => {
      if (this.formatDateFull) {
        this.calendarComponent.dateFormat =
          'date' === this.view
            ? DatepickerStatics.DATE_FORMAT_FULL[language.key]
            : DatepickerStatics.DATE_FORMAT_MONTH_FULL[language.key];
      } else {
        this.calendarComponent.dateFormat =
          'date' === this.view
            ? DatepickerStatics.DATE_FORMAT_DEFAULT[language.key]
            : DatepickerStatics.DATE_FORMAT_MONTH[language.key];
      }

      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          // need to wait one tick so we can guarantee that the dateFormat has been updated
          this.calendarComponent.updateInputfield();
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }

  private getCallbackData(date: Date): any {
    if (this.returnType === 'string') {
      if (!date) {
        return '';
      }
      return dateToDateString(date, this.showTime, this.timeOnly);
    }
    if (this.returnType === 'dateObject') {
      if (!date) {
        return null;
      }
      // does not make form dirty if dateObject has changed, if you are having trouble use returnType "string"
      return date;
    }

    if (!date) {
      return {
        dateObject: null,
        dateString: '',
      };
    }

    return {
      dateObject: date,
      dateString: dateToDateString(date, this.showTime, this.timeOnly),
    };
  }

  private propagateChange = (_: any): void => {};

  private propagateTouched = (): void => {};
}
