import * as Sentry from '@sentry/browser';

import { Company, UserAccount } from '../../models';

interface LoggingContext extends Record<string, unknown> {
  company?: {
    id: number;
    customerNumber: string;
    name: string;
  };
  userAccount?: {
    id: number;
    email: string;
  };
}

export class LoggingService {
  static setCompanyContextForErrorHandler(company: Company): void {
    {
      const scope = Sentry.getCurrentScope();
      scope.setUser({
        id: company.id.toString(),
      });
      const context: LoggingContext = {
        company: {
          id: company.id,
          customerNumber: company.customerNumber,
          name: company.name,
        },
      };
      scope.setExtras(context);
    }
  }

  static setUserAccountContextForErrorHandler(userAccount: UserAccount): void {
    {
      const scope = Sentry.getCurrentScope();
      scope.setUser({
        id: '-1',
        email: userAccount.email,
        username: userAccount.personalData
          ? `${userAccount.personalData.firstName} ${userAccount.personalData.lastName}`
          : userAccount.email,
      });
      const context: LoggingContext = {
        userAccount: {
          id: userAccount.id,
          email: userAccount.email,
        },
      };
      scope.setExtras(context);
    }
  }
}
