import { NgIf } from '@angular/common';
import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'account-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.less',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
  standalone: true,
  imports: [NgIf],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() labelAsHTML = false;
  @Input() labelPosition = 'after';
  @Input() indeterminate = false;
  @Input() @HostBinding('class.disabled') disabled = false;
  @Input() @HostBinding('style.align-items') alignItems = 'center';

  _checked = false;
  focused = false;

  @Input()
  set checked(checked: boolean) {
    if (checked !== this._checked) {
      this._checked = checked;
      this.propagateChange(checked);
    }
  }

  get checked(): boolean {
    return this._checked;
  }

  writeValue(checked: boolean): void {
    if (checked !== this._checked) {
      this._checked = checked;
    }
  }

  onChange(event: any): void {
    if (!this.disabled) {
      this.checked = event.target.checked;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_: any): void {
    // implemented due to interface
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleFocus(): void {
    this.focused = !this.focused;
  }

  private propagateChange = (_: any): void => {};
}
