import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { SwIconComponent } from '../../../../sw-icon/sw-icon.component';

@Component({
  selector: 'list-item-shared-collapsible-container',
  templateUrl: './collapsible-container.component.html',
  styleUrl: './collapsible-container.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, SwIconComponent],
})
export class CollapsibleContainerComponent implements OnInit {
  @Input() showToggle = true;
  @Input() @HostBinding('class.collapsed') collapsed = false;
  @Input() @HostBinding('class.collapsible') collapsible = false;

  @Output() readonly onToggleCollapsible = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.collapsible) {
      this.showToggle = false;
    }
  }

  toggleCollapsible(): void {
    this.onToggleCollapsible.emit();
  }
}
