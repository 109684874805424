import { NgIf } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FigureComponent } from '../../../figure/figure.component';
import { SwIconComponent } from '../../../sw-icon/sw-icon.component';
import { AbstractRadioButtonComponent } from '../abstract-radio-button.component';
import { SimpleRadioButtonComponent } from '../simple-radio-button/simple-radio-button.component';

@Component({
  selector: 'enhanced-radio-button',
  templateUrl: './enhanced-radio-button.component.html',
  styleUrl: './enhanced-radio-button.component.less',
  providers: [{ provide: AbstractRadioButtonComponent, useExisting: forwardRef(() => EnhancedRadioButtonComponent) }],
  standalone: true,
  imports: [MatTooltipModule, NgIf, FigureComponent, SwIconComponent, SimpleRadioButtonComponent],
})
export class EnhancedRadioButtonComponent extends AbstractRadioButtonComponent implements OnInit {
  @Input() label: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() iconAssetPath: string;

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.icon && !this.iconAssetPath) {
      throw new Error('Icon or asset path is required for radio button');
    }
  }
}
