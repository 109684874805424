import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SanitizedContent } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class SanitizeGateway {
  constructor(private readonly apiService: ApiService) {}

  sanitizeHTML(content: string): Observable<SanitizedContent> {
    const payload = {
      content: content,
    };
    return this.apiService.post('/sanitizehtml', payload);
  }
}
