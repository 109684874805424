import { Pipe, PipeTransform } from '@angular/core';

import { PluginForStoreAvailability } from '../../../core/models/plugin/plugin.model';
import { PluginsService } from '../../services/plugins.service';

@Pipe({
  name: 'pluginStoreLink',
  standalone: true,
})
export class PluginStoreLinkPipe implements PipeTransform {
  constructor(private readonly pluginsService: PluginsService) {}

  transform(plugin: PluginForStoreAvailability): any {
    return this.pluginsService.getStoreLinkForPlugin(plugin);
  }
}
