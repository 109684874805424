import { Directive, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Directive({ standalone: true })
export abstract class AbstractRadioButtonComponent implements OnInit {
  @Input() value: any;
  @Output() readonly onChange = new EventEmitter<any>();

  @HostBinding('class.radio-button') cssClass = true;

  name: string;
  checked = false;
  disabled = false;

  ngOnInit(): void {
    if (!this.value) {
      throw new Error('Value is required for radio button');
    }
  }

  change(selectedValue: any): void {
    this.onChange.emit(selectedValue);
  }
}
