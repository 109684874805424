import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ModalService } from '../modal/modal.service';
import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { WikiArticleComponent } from './wiki-article/wiki-article.component';

@Component({
  selector: 'account-wiki-modal',
  templateUrl: './wiki-modal.component.html',
  styleUrl: './wiki-modal.component.less',
  standalone: true,
  imports: [TranslateModule, SwIconComponent],
})
export class WikiModalComponent {
  @Input() url: string;

  constructor(private readonly modalService: ModalService) {}

  onOpenModal(): void {
    this.modalService.open(WikiArticleComponent, {
      data: { url: this.url },
    });
  }
}
