import { Workspace, WorkspaceItem } from '@account/core/models';
import { createAction, props } from '@ngrx/store';

import { NavigationState } from './navigation.state';

export const setNavigationForUserAccount = createAction('[Navigation] set useraccount navigation');
export const setNavigationForUserAccountSuccess = createAction(
  '[Navigation] set useraccount navigation success',
  props<{ payload: { hasCompany: boolean; navigation: WorkspaceItem[] } }>()
);

export const setNavigationForCompany = createAction('[Navigation] set company navigation');
export const setNavigationForCompanySuccess = createAction(
  '[Navigation] set company navigation success',
  props<{ payload: { navigation: Partial<NavigationState> } }>()
);

export const resetNavigation = createAction('[Navigation] reset');

export const setWorkspaces = createAction('[Navigation] set workspaces');
export const setWorkspacesSuccess = createAction(
  '[Navigation] set workspaces success',
  props<{ payload: { workspaces: Workspace[] } }>()
);

export const setActiveWorkspace = createAction(
  '[Navigation] set active workspace',
  props<{ payload: { name: string } }>()
);

export const setActiveWorkspaceItem = createAction(
  '[Navigation] set active workspace item',
  props<{ payload: { item: WorkspaceItem } }>()
);
