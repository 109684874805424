import { createSelector } from '@ngrx/store';

import { selectProducerState } from '../producer-feature.selector';
import { ProducerState } from './producer.state';

export const selectLoading = createSelector(selectProducerState, (state: ProducerState) => state.loading);

export const selectLoaded = createSelector(selectProducerState, (state: ProducerState) => state.loaded);

export const selectContractLoaded = createSelector(selectProducerState, (state: ProducerState) => state.contractLoaded);

export const selectError = createSelector(selectProducerState, (state: ProducerState) => state.error);

export const selectProducer = createSelector(selectProducerState, (state: ProducerState) => state.producer);

export const selectContract = createSelector(selectProducerState, (state: ProducerState) => state.producer.contract);

export const selectIconUrl = createSelector(selectProducerState, (state: ProducerState) => state.producer.iconUrl);
