import { NgIf } from '@angular/common';
import { AfterContentInit, Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';

export interface ProgressCircleLabel {
  formattedProgressDone?: string;
  formattedProgressTotal?: string;
  hideTotal?: boolean;
}

@Component({
  selector: 'account-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrl: './progress-circle.component.less',
  standalone: true,
  imports: [NgIf],
})
export class ProgressCircleComponent implements OnInit, AfterContentInit {
  @Input()
  @HostBinding('style.--color')
  color: string;

  @Input()
  @HostBinding('style.--width')
  width: string;

  @Input()
  @HostBinding('style.--circle-size')
  progressCircleSize: string;

  @Input()
  @HostBinding('style.--progress-open-color')
  progressOpenColor: string;

  @Input()
  @HostBinding('style.--progress-open-opacity')
  progressOpenOpacity: number;

  @Input()
  @HostBinding('class.progress-decreasing')
  decreasing = false;

  @Input() hasRoundEdges = false;

  @Input() labelConfig: ProgressCircleLabel;
  @Input() content: TemplateRef<any>;

  @HostBinding('style.--progress')
  private progress: number;

  private _progressDone: number;
  private _progressTotal = 100;

  @Input()
  set progressDone(progressDone: number) {
    this._progressDone = progressDone;
    this.updateProgress();
  }

  get progressDone(): number {
    return this._progressDone;
  }

  @Input()
  set progressTotal(progressTotal: number) {
    this._progressTotal = progressTotal;
    if (this._progressDone) {
      this.updateProgress();
    }
  }

  get progressTotal(): number {
    return this._progressTotal;
  }

  ngOnInit(): void {
    if (this.hasRoundEdges && !this.width) {
      throw new Error('A fixed width must be supplied when using round edges');
    }
  }

  ngAfterContentInit(): void {
    if (this.labelConfig && this.content) {
      throw new Error('Either a label or a custom template must be provided');
    }
  }

  private updateProgress(): void {
    this.progress = (this._progressDone / this._progressTotal) * 100;
  }
}
