import { Action, createReducer, on } from '@ngrx/store';

import * as actions from './basket.actions';
import { BasketState } from './basket.state';

export const initialBasketState: BasketState = {
  basket: null,
};

const basketReducerInternal = createReducer(
  initialBasketState,

  on(actions.setBasketForCompanyUserAccountSuccess, (state: BasketState, { payload }) => ({
    ...state,
    basket: payload.basket,
  })),

  on(actions.resetBasket, () => ({
    ...initialBasketState,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: BasketState | undefined, action: Action): BasketState {
  return basketReducerInternal(state, action);
}
