<div class="locale_wrapper">
  <div class="locale locale_de" [class.active]="locale.name === 'de_DE'" data-isocode="de_DE" (click)="onClick($event)">
    <img src="/assets/images/flags/de_DE.svg" />
    {{ 'COMMON.LOCALE.DE_DE' | translate }}
  </div>
  <div class="locale locale_en" [class.active]="locale.name === 'en_GB'" data-isocode="en_GB" (click)="onClick($event)">
    <img src="/assets/images/flags/en_GB.svg" />
    {{ 'COMMON.LOCALE.EN_GB' | translate }}
  </div>
</div>
