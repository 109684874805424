import { convertSbpDate } from '../core';

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */

const typeCache: { [label: string]: boolean } = {};
export const type = <T>(label: T | ''): T => {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unqiue"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
};

export const textToNumber = (text: string): number => Number.parseFloat(text.toString().replace(',', '.'));

export const round = (value: number, decimal = 0): number => {
  let multiplier = 10 * decimal;
  if (0 === multiplier) {
    multiplier = 1;
  }

  return Math.round(value * multiplier) / multiplier;
};

export const dateToDateString = (date: Date, showTime = true, timeOnly = false): string => {
  let dateString = '';
  if (!timeOnly) {
    dateString += `${date.getFullYear()}-${
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  }

  if (showTime) {
    dateString += ` ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }:${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`;
  }

  return dateString.trim();
};

export const isDateExpired = (expirationDate: string | Date): boolean => {
  const date = expirationDate instanceof Date ? expirationDate : convertSbpDate(expirationDate);
  return date.getTime() < Date.now();
};

export const cloneObject = (object: Record<string, any>): Record<string, any> | Record<string, any>[] => {
  let clone: Record<string, any> = {};
  if (Array.isArray(object)) {
    clone = [];
  }
  for (const key in object) {
    if (object[key] != null && typeof object[key] === 'object') {
      clone[key] = cloneObject(object[key]);
    } else {
      clone[key] = object[key];
    }
  }
  return clone;
};

export const objectIsEqual = (objectA: Record<string, any>, objectB: Record<string, any>): boolean => {
  if ((objectA === null || objectB === null || objectA === undefined || objectB === undefined) && objectA !== objectB) {
    return false;
  }

  for (const property in objectA) {
    // Check property exists on both objects
    if ((typeof objectA[property] !== 'undefined') !== (typeof objectB[property] !== 'undefined')) {
      return false;
    }
    switch (typeof objectA[property]) {
      // Deep compare objects
      case 'object':
        if (false === objectIsEqual(objectA[property], objectB[property])) {
          return false;
        }
        break;
      // Compare function code
      case 'function':
        if (
          typeof objectB[property] === 'undefined' ||
          (property !== 'compare' && objectA[property].toString() !== objectB[property].toString())
        ) {
          return false;
        }
        break;
      // Compare values
      default:
        if (objectA[property] !== objectB[property]) {
          return false;
        }
    }
  }

  // Check object 2 for any extra properties
  for (const property in objectB) {
    if (typeof objectA[property] === 'undefined' && typeof objectB[property] !== 'undefined') {
      return false;
    }
  }
  return true;
};

export const uniqueHash = (): string => Math.random().toString(36).slice(2, 11);

export const isEmpty = (array: any[]): boolean => Array.isArray(array) && array.length === 0;

export const isUrl = (value: string, allowProtocol = false): boolean => {
  let regex = /^[\da-zäöü-]+([.-][\da-zäöü-]+)*\.[a-z]{2,18}(:\d{1,5})?(\/.*)?$/i;
  if (allowProtocol) {
    regex = /^(https?:\/\/)?[\da-zäöü-]+([.-][\da-zäöü-]+)*\.[a-z]{2,18}(:\d{1,5})?(\/.*)?$/i;
  }
  return !(value && !regex.test(value));
};
