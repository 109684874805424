<account-card [heading]="heading" [contentHasPadding]="false">
  <card-controls>
    <ng-content select="detail-panel-controls"></ng-content>
  </card-controls>
  <card-body>
    <account-well background="white" class="flex-container flex-row">
      <account-figure *ngIf="swIconName" class="panel-image-wrapper" [size]="mobileViewport ? '72px' : '90px'">
        <account-sw-icon
          class="panel-icon"
          icon="{{ swIconName }}"
          [size]="mobileViewport ? '28px' : '36px'"
          color="white"></account-sw-icon>
      </account-figure>

      <account-frame
        *ngIf="imageFrameUrl"
        class="panel-image-wrapper"
        [height]="mobileOrTabletViewport ? '72px' : '90px'"
        [width]="mobileViewport ? '72px' : '90px'"
        [link]="imageFrameUrl"></account-frame>

      <div class="information">
        <ng-content select="detail-panel-info"></ng-content>
      </div>
    </account-well>

    <account-well *ngIf="showContent" data-border-top class="a_row bottom">
      <ng-content select="detail-panel-content"></ng-content>
    </account-well>
  </card-body>
</account-card>
