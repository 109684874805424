<account-modal
  [heading]="'COMPANY.CREATION.MODAL.HEADING' | translate"
  [closeableOnEscape]="true"
  maxWidth="600px"
  paddingBody="padding_large"
  (modalClose)="close()">
  <modal-body>
    <div class="creation-modal-content" [formGroup]="form">
      <h2>{{ 'COMPANY.CREATION.MODAL.INFO.HEADING' | translate }}</h2>
      <p class="additional-info">{{ 'COMPANY.CREATION.MODAL.INFO.PART_1' | translate }}</p>
      <div class="a_control is_required" [class.is_error]="this.form.get('name').errors?.notTrimmed">
        <div class="control_content">
          <input
            type="text"
            formControlName="name"
            [placeholder]="'COMPANY.CREATION.MODAL.FORM.COMPANY_NAME.PLACEHOLDER' | translate" />
        </div>
        <div *ngIf="this.form.get('name').errors?.notTrimmed" class="control_help">
          {{ 'COMPANY.CREATION.MODAL.FORM.COMPANY_NAME.ERROR_NOT_TRIMMED' | translate }}
        </div>
      </div>
      <p class="additional-info">{{ 'COMPANY.CREATION.MODAL.INFO.PART_2' | translate }}</p>
      <div class="a_control is_required">
        <div class="control_content">
          <div class="a_select">
            <select formControlName="billingCountry" [compareWith]="compareByName">
              <option disabled [ngValue]="null" style="display: none">
                {{ 'COMMON.COMPANY_LOCATION' | translate }}
              </option>
              <option *ngFor="let country of countries$ | async" [ngValue]="country">{{ country.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <button
        type="button"
        (click)="createCompany()"
        variant="primary"
        class="a_button is_primary"
        [disabled]="form.invalid"
        [class.is_disabled]="form.invalid">
        {{ 'COMPANY.CREATION.MODAL.ACTION.CREATE.LABEL' | translate }}
      </button>
    </div>
  </modal-body>
</account-modal>
