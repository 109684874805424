import {
  CommercialContract,
  SelfServiceContractPriceInfos,
  ShopUpgradeCommercialPlanBookingInformation,
  ShopUpgradeSalesInformation,
} from '@account/core/models';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class UpgradeToCommercialPlanGateway {
  constructor(private readonly apiService: ApiService) {}

  calculateSubscriptionRefund(companyId: number, shopId: number): Observable<{ refund: number }> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/shopupgrade/calculaterefund`);
  }

  isTrialPhaseOfferedInPlanBooking(companyId: number, shopId: number): Observable<{ result: boolean }> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/shopupgrade/trialoffered`);
  }

  isTrialPhaseOfferedInPlanBookingForCustomer(
    partnerId: number,
    customerId: number,
    shopId: number
  ): Observable<{ result: boolean }> {
    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/shopupgrade/trialoffered`
    );
  }

  isTrialPhaseOfferedInPlanBookingForNewShop(): Observable<{ result: boolean }> {
    return this.apiService.get('/shopupgrade/trialoffered');
  }

  calculateSubscriptionRefundForCustomer(
    partnerId: number,
    companyId: number,
    shopId: number
  ): Observable<{ refund: number }> {
    return this.apiService.get(
      `/partners/${partnerId}/customers/${companyId}/shops/${shopId}/shopupgrade/calculaterefund`
    );
  }

  calculateShopPriceAndDiscount(
    companyId: number,
    planId: number,
    chargingInterval: number,
    plannedGmv: number
  ): Observable<SelfServiceContractPriceInfos> {
    const params = new HttpParams().set('chargingInterval', chargingInterval).set('plannedGmv', plannedGmv);
    return this.apiService.get(
      `/companies/${companyId}/shopupgrade/commercialplan/${planId}/calculatepriceanddiscount`,
      params
    );
  }

  calculateShopPriceAndDiscountForCustomer(
    partnerId: number,
    customerId: number,
    planId: number,
    chargingInterval: number,
    plannedGmv: number
  ): Observable<SelfServiceContractPriceInfos> {
    const params = new HttpParams().set('chargingInterval', chargingInterval).set('plannedGmv', plannedGmv);
    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shopupgrade/commercialplan/${planId}/calculatepriceanddiscount`,
      params
    );
  }

  handleUpgrade(
    companyId: number,
    shopId: number,
    planBookingInformation: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<CommercialContract> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/handleupgrade`, planBookingInformation);
  }

  handleUpgradeForCustomer(
    partnerId: number,
    customerId: number,
    shopId: number,
    planBookingInformation: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<CommercialContract> {
    return this.apiService.post(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/handleupgrade`,
      planBookingInformation
    );
  }

  contactSalesWithPlanInformation(
    companyId: number,
    selectedPlanInformation: ShopUpgradeSalesInformation
  ): Observable<void> {
    return this.apiService.post(`/companies/${companyId}/contactsales`, selectedPlanInformation);
  }

  contactSalesWithPlanInformationForCustomer(
    partnerId: number,
    customerId: number,
    selectedPlanInformation: ShopUpgradeSalesInformation
  ): Observable<void> {
    return this.apiService.post(`/partners/${partnerId}/customers/${customerId}/contactsales`, selectedPlanInformation);
  }
}
