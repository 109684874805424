import { NgIf, NgStyle } from '@angular/common';
import { AfterContentInit, Component, ContentChild, Directive, DoCheck, ElementRef, Input } from '@angular/core';

@Directive({
  selector: 'card-controls',
  standalone: true,
})
export class CardControlsDirective {}

@Directive({
  selector: 'card-body',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.flex]': '"1"',
  },
  standalone: true,
})
export class CardBodyDirective {}

@Directive({
  selector: 'card-footer',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"row"',
    '[style.flex]': '"1"',
  },
  standalone: true,
})
export class CardFooterDirective {}

/**
 * The card component is used to separate content on a page.
 * It allows you to specify items that are displayed in the `controls`, `body` and `footer` slots.
 */
@Component({
  selector: 'account-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.less',
  standalone: true,
  imports: [NgStyle, NgIf],
})
export class CardComponent implements AfterContentInit, DoCheck {
  /**
   * Defines the icon displayed next to the card label.
   */
  @Input() icon: string = null;

  /**
   * Defines the heading of the card.
   */
  @Input() heading: string = null;

  /**
   * Defines if the card header is displayed.
   */
  @Input() showHeader = true;

  /**
   * No purpose.
   */
  @Input() documentationPath: string = null;

  /**
   * Defines if the card content is scrollable.
   */
  @Input() contentIsScrollable = false;

  /**
   * Defines if the card content has padding.
   */
  @Input() contentHasPadding = true;

  /**
   * Defines if the card content has smaller padding.
   */
  @Input() hasSmallPadding = false;

  /**
   * Defines the background color of the card.
   */
  @Input() backgroundColor = '#FFFFFF';

  /**
   * @internal
   */
  showControls = false;

  /**
   * @internal
   */
  showBody = false;

  /**
   * @internal
   */
  showFooter = false;

  /**
   * `<card-controls>` slot that allows you to place content in the card header.
   */
  @ContentChild(CardControlsDirective) private readonly controls: CardControlsDirective;

  /**
   * `<card-body>` slot that allows you to place content in the card body.
   */
  @ContentChild(CardBodyDirective) private readonly body: CardBodyDirective;

  /**
   * `<card-footer>` element that allows you to place content in the card footer.
   */
  @ContentChild(CardFooterDirective, { read: ElementRef }) private readonly footer: ElementRef;

  ngDoCheck(): void {
    this.showFooter = undefined !== this.footer;
    if (
      this.showFooter &&
      this.footer.nativeElement.children.length === 1 &&
      this.footer.nativeElement.hasAttribute('hidden')
    ) {
      this.showFooter = false;
    }
  }

  ngAfterContentInit(): void {
    this.showControls = undefined !== this.controls;
    this.showBody = undefined !== this.body;
    this.showFooter = undefined !== this.footer;
  }
}
