import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkColumnDef } from '@angular/cdk/table';
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { MatSortHeader, MatSortHeaderIntl } from '@angular/material/sort';

import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { SbpSort } from './sort';

@Component({
  selector: '[sbp-sort-header]',
  exportAs: 'sbpSortHeader',
  templateUrl: './sort-header.html',
  styleUrl: './sort-header.less',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(click)': '_handleClick()',
    '(mouseenter)': '_setIndicatorHintVisible(true)',
    '(longpress)': '_setIndicatorHintVisible(true)',
    '(mouseleave)': '_setIndicatorHintVisible(false)',
    '[class.mat-sort-header-disabled]': '_isDisabled()',
  },
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['disabled'],
  animations: [
    // matSortAnimations.indicator,
    // matSortAnimations.leftPointer,
    // matSortAnimations.rightPointer,
    // matSortAnimations.arrowOpacity,
    // matSortAnimations.arrowPosition,
    // matSortAnimations.allowChildren,
  ],
  standalone: true,
  imports: [NgIf, SwIconComponent],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SbpSortHeader extends MatSortHeader {
  @Input('sbp-sort-header') override id: string;

  constructor(
    _intl: MatSortHeaderIntl,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() _sort: SbpSort,
    @Optional() _cdkColumnDef: CdkColumnDef,
    focusMonitor: FocusMonitor,
    elementRef: ElementRef
  ) {
    super(_intl, changeDetectorRef, _sort, _cdkColumnDef, focusMonitor, elementRef);
  }
}
