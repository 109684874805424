import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'account-auth-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class AuthBadgeComponent {
  @HostBinding('class.iconOnly') @Input() iconOnly = false;
}
