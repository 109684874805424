<div class="card_content" [ngStyle]="{ background: backgroundColor }">
  <div *ngIf="showHeader" class="card_header">
    <h3 *ngIf="heading" class="heading">{{ heading }}</h3>
    <div *ngIf="showControls">
      <ng-content select="card-controls"></ng-content>
    </div>
  </div>
  <div
    class="card_body"
    *ngIf="showBody"
    [class.has-padding]="contentHasPadding"
    [class.padding-small]="hasSmallPadding"
    [class.overflow-auto]="contentIsScrollable">
    <ng-content select="card-body"></ng-content>
  </div>
  <div *ngIf="showFooter" class="card_footer" data-border-top>
    <ng-content select="card-footer"></ng-content>
  </div>
</div>
