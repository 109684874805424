import { LanguagesFacade } from '@account/core/facades';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { BadgeComponent } from '../../../../shared/components/badge/badge.component';
import { ChangelogEntry, Language } from '../../../models';

@Component({
  selector: 'account-changelog-entry',
  templateUrl: './changelog-entry.component.html',
  styleUrl: './changelog-entry.component.less',
  standalone: true,
  imports: [CommonModule, TranslateModule, BadgeComponent],
})
export class ChangelogEntryComponent implements OnInit {
  @Input() entry: ChangelogEntry;

  shownText: string;

  constructor(private readonly languagesFacade: LanguagesFacade) {}

  ngOnInit(): void {
    this.languagesFacade
      .getSelectedLanguage()
      .pipe(take(1))
      .subscribe((language: Language) => {
        this.shownText = this.entry.textEn;
        if (language.key === 'de') {
          this.shownText = this.entry.textDe;
        }
      });
  }
}
