import { createSelector } from '@ngrx/store';

import { CompanyAllocations, SbpException } from '../../../models';
import * as companyRoot from '../company-root.store';
import { AllocationsState } from './allocations.reducer';

const allocationsState = createSelector(
  companyRoot.state,
  (state: companyRoot.State): AllocationsState => state.allocations
);

export const isLoading = createSelector(allocationsState, (state: AllocationsState) => state.loading);

export const isLoaded = createSelector(allocationsState, (state: AllocationsState) => state.loaded);

export const getError = createSelector(allocationsState, (state: AllocationsState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getAllocations = createSelector(allocationsState, (state: AllocationsState) => state.allocations);

export const getPartnerId = createSelector(getAllocations, (allocations: CompanyAllocations) =>
  allocations ? allocations.partnerId : undefined
);

export const getProducerId = createSelector(getAllocations, (allocations: CompanyAllocations) =>
  allocations ? allocations.producerId : undefined
);
