<div *ngIf="workspaces" class="navigation--workspaces" [class.is--collapsed]="!isExpanded">
  <div class="select--wrapper">
    <div (click)="toggle()" [ngClass]="{ select: true, 'is-disabled': !selected }">
      <ng-container *ngIf="!selected; else workspaceSelected">
        <account-sw-icon
          *ngIf="!isExpanded"
          class="hide-in-mobile-state"
          [icon]="'chevron-down-s'"
          size="12px"
          color="var(--sw-gray-200)"></account-sw-icon>
        <span class="select--preview">{{ 'NAVIGATION.WORKSPACE.SELECT_WORKSPACE' | translate }}</span>
      </ng-container>
      <ng-template #workspaceSelected>
        <account-sw-icon [icon]="selected.icon" [color]="selected.color"></account-sw-icon>
        <span class="select--preview" [ngClass]="selected.name">{{ selected.label | translate }}</span>
      </ng-template>
    </div>

    <div class="content" [class.is--active]="open">
      <ng-template ngFor [ngForOf]="workspaces" let-workspace>
        <a class="content--entry" [ngClass]="workspace.name" (click)="selectWorkspace(workspace)">
          <account-sw-icon [icon]="workspace.icon" [color]="workspace.color"></account-sw-icon>
          {{ workspace.label | translate }}
        </a>
      </ng-template>
    </div>
  </div>
</div>
