import { NotificationsFacade } from '@account/core/facades';
import { CardComponent, ComponentOverlayRef, EmptyStateComponent, SwIconComponent } from '@account/shared/components';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { OVERLAY_DATA } from '../../../shared/components/component-overlay/component-overlay-injector';
import { NotificationItem } from '../../models';
import { NotificationItemComponent } from './notification-item/notification-item.component';

interface ModalData {
  notifications: NotificationItem[];
  onDelete: Subject<NotificationItem[]>;
}

@Component({
  selector: 'account-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrl: './notification-center.component.less',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NotificationItemComponent,
    SwIconComponent,
    EmptyStateComponent,
    CardComponent,
  ],
})
export class NotificationCenterComponent {
  constructor(
    @Inject(OVERLAY_DATA) public data: ModalData,
    private readonly overlayRef: ComponentOverlayRef,
    private readonly notificationsFacade: NotificationsFacade,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  deleteNotification(notification: NotificationItem): void {
    this.notificationsFacade.deleteNotifications(notification.id).subscribe(() => {
      this.data.notifications = this.data.notifications.filter((item: NotificationItem) => item.id !== notification.id);
      this.data.onDelete.next(this.data.notifications);
    });
  }

  deleteNotifications(): void {
    if (this.data.notifications.length > 0) {
      this.notificationsFacade.deleteNotifications().subscribe(() => {
        this.data.notifications = [];
        this.data.onDelete.next(this.data.notifications);
      });
    }
  }

  clickNotification(notification: NotificationItem): void {
    this.overlayRef.close();
    if (!notification.link.startsWith('http')) {
      this.router.navigateByUrl(notification.link);
    } else {
      if (notification.link.includes(',')) {
        const links = notification.link.split(',');
        const lang = this.translateService.currentLang;
        if (links[0].includes(`/${lang}/`)) {
          notification.link = links[0];
        } else {
          notification.link = links[1];
        }
      }
      window.open(notification.link, '_blank', 'noopener');
    }
    this.deleteNotification(notification);
  }
}
