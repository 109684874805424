import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { StatusComponent } from '../../status/status.component';
import { StatusStyle } from '../../status/status.model';
import { PasswordValidationModel } from '../password-validation.model';

export type PasswordValidationRequirement =
  | 'characterLength'
  | 'hasNumber'
  | 'hasLowerAndUppercaseLetter'
  | 'hasSpecialChar'
  | 'isNotEqualToOldPassword';

@Component({
  selector: 'account-password-help-texts',
  templateUrl: './password-help-texts.component.html',
  styleUrl: './password-help-texts.component.less',
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
  standalone: true,
  imports: [NgIf, StatusComponent, TranslateModule],
})
export class PasswordHelpTextsComponent {
  @Input() passwordValidation: PasswordValidationModel;
  @Input() visibleChecks: PasswordValidationRequirement[];

  // is needed to provide :leave animation, normal ngIf from parent component doesn't work
  @Input() showHelpTexts: boolean;

  getTypeForFulfilledRequirement(requirement: PasswordValidationRequirement): StatusStyle {
    if (!this.passwordValidation) {
      return 'gray_minus';
    }

    return this.passwordValidation[requirement] ? 'success' : 'gray_minus';
  }

  isVisible(requirement: PasswordValidationRequirement): boolean {
    return this.visibleChecks.includes(requirement);
  }
}
