import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { SwIconComponent } from '../sw-icon/sw-icon.component';

@Component({
  selector: 'account-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SwIconComponent],
})
export class SidebarComponent {
  @Output() readonly onClose = new EventEmitter();

  @HostBinding('class.is-open') @Input() open = false;
  @Input() heading: string;
  @Input() contentHasPadding = true;

  close(): void {
    this.onClose.emit(null);
    this.open = false;
  }
}
