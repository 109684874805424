import { ActionReducer, ActionReducerMap, combineReducers, compose, MetaReducer } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import * as fromBasket from './basket/basket.reducer';
import * as companyStore from './company/company-root.store';
import * as fromNavigation from './navigation';
import * as rootActions from './root.actions';
import * as rootSelectors from './root.selectors';
import { RootState } from './root.state';
import * as fromSession from './session/session.reducer';
import * as fromUserAccount from './useraccount/useraccount-meta.reducer';

export const actions = rootActions;
export const selectors = rootSelectors;

export const reducers: ActionReducerMap<RootState> = {
  session: fromSession.reducer,
  company: combineReducers(companyStore.reducers),
  userAccount: combineReducers(fromUserAccount.reducers),
  navigation: fromNavigation.reducer,
  basket: fromBasket.reducer,
};

const developmentReducer = compose(combineReducers)(reducers);

const productionReducer = compose(combineReducers)(reducers);

// TODO: @Knusper was is damit, is das Kunst oder kann das weg
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: any, action: any): RootState {
  if (environment.production) {
    return productionReducer(state, action);
  }
  return developmentReducer(state, action);
}

const storeReset = (resetAction: any) => (reducer: any) => (state: any, action: any) => {
  if (action.type === resetAction.type) {
    state = undefined;
  }
  return reducer(state, action);
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function storeResetMetaReducer(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return storeReset(actions.rootActions.logout)(reducer);
}

export const metaReducers: MetaReducer<RootState>[] = !environment.production
  ? [storeResetMetaReducer]
  : [storeResetMetaReducer];
