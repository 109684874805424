import { EmbeddedViewRef, Injectable, ViewContainerRef } from '@angular/core';

import { FriendlyCaptchaPuzzleComponent } from '../../components';

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {
  createCaptchaWidget(
    viewContainerRef: ViewContainerRef,
    parentHtmlContainer: HTMLElement
  ): FriendlyCaptchaPuzzleComponent {
    const captchaComponentRef = viewContainerRef.createComponent(FriendlyCaptchaPuzzleComponent);
    const overlayRoot = (captchaComponentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    parentHtmlContainer.appendChild(overlayRoot);

    return captchaComponentRef.instance;
  }
}
