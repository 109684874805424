import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { BadgeStyle } from './badge.model';

/**
 * The badge component displays a text + icon combination inside a colored badge.
 */
@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, MatTooltipModule, SwIconComponent],
})
export class BadgeComponent implements OnInit, OnChanges {
  /**
   * Defines if the badge should be displayed if the value is zero.
   */
  @Input() hideIfZero = false;

  /**
   * Defines what color the badge is displayed in.
   */
  @Input() badgeColor: BadgeStyle = 'default';

  /**
   * Defines the value displayed inside the badge.
   */
  @Input() value: null | number | string;

  /**
   * Defines the tooltip displayed when hovering the element.
   */
  @Input() tooltip: null | string;

  /**
   * Defines the icon displayed next to the badge description.
   */
  @Input() icon: string;

  /**
   * @internal
   */
  hideBadge = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hideIfZero) {
      const valueChangeState = changes['value'];
      this.hideBadge = true === valueChangeState.currentValue;
    }
  }

  ngOnInit(): void {
    this.determineBatchIsHidden();
  }

  private determineBatchIsHidden(): void {
    if (this.hideIfZero && 0 === this.value) {
      this.hideBadge = true;
    }
  }
}
