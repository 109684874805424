<account-empty-state
  icon="lock"
  [heading]="'CORE.NO_ACCESS.HEADING.DEFAULT' | translate"
  [subheading]="subheading | translate">
  <a class="a_button is_primary is_small" *ngIf="returnUrl" [routerLink]="'/' + returnUrl">
    {{ 'CORE.NO_ACCESS.HEADING.RETURN_TO_PREVIOUS_PAGE' | translate }}
  </a>
  <a class="a_button is_primary is_small" *ngIf="goToPortal" routerLink="/portal">
    {{ 'CORE.NO_ACCESS.HEADING.RETURN_TO_PORTAL' | translate }}
  </a>
</account-empty-state>
