import { AuthFacade, CompaniesFacade, GuardsAclFacade } from '@account/core/facades';
import { NoAccessContext } from '@account/core/models';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { RootState } from '../store/root.state';
import { StringUtils } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store<RootState>,
    private readonly authFacade: AuthFacade,
    private readonly aclFacade: GuardsAclFacade,
    private readonly companiesFacade: CompaniesFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivateByUrl(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  /**
   * @summary Check access by url
   * @description Access check follows following rules:
   * 1. Check if LoggedIn
   * 2. Check can bypass LegacyUserWall
   * 3. Check access for UserAccount specific urls
   * 4. If not hasCompany grand access otherwise do Company check
   * 5. Check has Company signed GTC
   * 6. Check has Company complete master data
   * 7. Check access for Partner specific urls
   * 8. Check access by UserAccount permissions in Company
   */
  canActivateByUrl(url: string): Observable<boolean | UrlTree> {
    const relativeUrl = StringUtils.trimSlashes(url);
    return this.canActivateIfLoggedIn(relativeUrl).pipe(
      switchMap((result: boolean | UrlTree) => {
        if (!result || result instanceof UrlTree) {
          return of(result);
        }
        return this.authFacade.isLoggedIn().pipe(
          switchMap((loggedIn: boolean) => {
            if (!loggedIn) {
              return of(this.router.parseUrl('front'));
            }

            return this.canActivateIfAccessibleByUserAccount(relativeUrl).pipe(
              switchMap((result: boolean | UrlTree) => {
                if (!result || result instanceof UrlTree) {
                  return of(result);
                }
                return this.companiesFacade.hasCompany().pipe(
                  switchMap((hasCompany: boolean) => {
                    if (!hasCompany) {
                      // if user has no company authorize
                      return of(true);
                    }
                    // do all permission checks for company
                    return this.canActivateIfAccessibleByGTC().pipe(
                      switchMap((result: boolean | UrlTree) => {
                        if (!result || result instanceof UrlTree) {
                          return of(result);
                        }
                        return this.canActivateIfAccessibleByCompanyMasterData(url).pipe(
                          switchMap((result: boolean | UrlTree) => {
                            if (!result || result instanceof UrlTree) {
                              return of(result);
                            }
                            return this.canActivateIfAccessibleByCompanyAllocations(relativeUrl).pipe(
                              switchMap((result: boolean | UrlTree) => {
                                if (!result || result instanceof UrlTree) {
                                  return of(result);
                                }
                                return this.canActivateIfAccessibleByPartnerStatus(relativeUrl).pipe(
                                  switchMap((result: boolean | UrlTree) => {
                                    if (!result || result instanceof UrlTree) {
                                      return of(result);
                                    }
                                    return this.canActivateIfAccessibleByCurrentPermissions(relativeUrl);
                                  })
                                );
                              })
                            );
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  private canActivateIfLoggedIn(url: string): Observable<boolean | UrlTree> {
    // this.authFacade.isLoggedIn().subscribe((respose) => console.log(respose))
    return this.authFacade.isLoggedIn().pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return true;
        }
        // is not logged in set redirect url for re-login and navigate to login-page which will trigger a logout
        this.authFacade.setRedirectUrl(url);
        return this.router.parseUrl('front');
      })
    );
  }

  private canActivateIfAccessibleByUserAccount(url: string): Observable<boolean | UrlTree> {
    return this.aclFacade.isAccessGrantedForUserAccount(url).pipe(
      map((accessGranted: boolean) => {
        if (accessGranted) {
          return true;
        }
        // user has no company and wants to access a workspace requiring a company therefore navigate to portal
        return this.router.parseUrl('portal');
      })
    );
  }

  private canActivateIfAccessibleByGTC(): Observable<boolean | UrlTree> {
    return this.aclFacade.isAccessGrantedForCompanyGTC().pipe(
      map((accessGranted: boolean) => {
        if (accessGranted) {
          return true;
        }
        // company has not signed latest GTC, therefore navigate to terms
        return this.router.parseUrl('terms');
      })
    );
  }

  private canActivateIfAccessibleByCompanyMasterData(url: string): Observable<boolean | UrlTree> {
    return this.aclFacade.isAccessGrantedForCompanyMasterData(url).pipe(
      map((accessGranted: boolean) => {
        if (accessGranted) {
          return true;
        }
        // company has not completed the master data, therefore navigate to master data form
        return this.router.parseUrl('profile/data');
      })
    );
  }

  private canActivateIfAccessibleByCompanyAllocations(url: string): Observable<boolean | UrlTree> {
    return this.aclFacade.isAccessGrantedForCompanyAllocations(url).pipe(
      map((accessGranted: boolean) => {
        if (accessGranted) {
          return true;
        }
        // company has not the required allocations, therefore navigate to portal
        return this.router.parseUrl('portal');
      })
    );
  }

  private canActivateIfAccessibleByPartnerStatus(url: string): Observable<boolean | UrlTree> {
    return this.aclFacade.isAccessGrantedForPartnerStatus(url).pipe(
      switchMap((accessGranted: boolean) => {
        if (accessGranted) {
          return of(true);
        }
        // company has not the required partner status, therefore navigate to no access component
        return this.navigateToNoAccess(url, 'partnerStatus');
      })
    );
  }

  private canActivateIfAccessibleByCurrentPermissions(url: string): Observable<boolean | UrlTree> {
    return this.aclFacade.isAccessGrantedForCurrentPermissions(url).pipe(
      switchMap((accessGranted: boolean) => {
        if (accessGranted) {
          return of(true);
        }
        // user has not the required permissions inside the company, therefore navigate to no access component
        return this.navigateToNoAccess(url, 'permissions');
      })
    );
  }

  private navigateToNoAccess(url: string, noAccessContext: NoAccessContext): Observable<UrlTree> {
    const parsedUrl = this.router.parseUrl(url);
    const queryParams = parsedUrl.queryParams;

    return this.getRoutingForNoAccessLayer(url).pipe(
      map((parentLayerUrl: string) => {
        if (parentLayerUrl === '') {
          queryParams['toPortal'] = true;
        }
        this.aclFacade.setNoAccessTranslationKey(parentLayerUrl !== '' ? url : url.split('/')[0], noAccessContext);
        const urlTree = this.router.parseUrl(`${parentLayerUrl}/denied`);
        urlTree.queryParams = queryParams;
        return urlTree;
      })
    );
  }

  private getRoutingForNoAccessLayer(url: string): Observable<string> {
    const urlAsArray = url.split('/');
    const parentLayerUrl = urlAsArray.slice(0, -1).join('/');

    return this.aclFacade
      .isAccessGrantedForLocation(parentLayerUrl)
      .pipe(
        switchMap((hasAccess: boolean) =>
          hasAccess ? of(`${parentLayerUrl}`) : this.getRoutingForNoAccessLayer(parentLayerUrl)
        )
      );
  }
}
