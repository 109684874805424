import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PartnersGateway } from '../../gateways/partner';
import { ListResult, OnPremiseShop, SbpException, ShopMigrationData } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade } from '../company';
import { ShopsFacade } from '../shop';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerShopsFacade {
  constructor(
    private readonly partnersFacade: PartnersFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly shopsFacade: ShopsFacade,
    private readonly partnersGateway: PartnersGateway
  ) {}

  getShop(): Observable<OnPremiseShop> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.partnersGateway.getPartnerShop(partnerId)));
  }

  getPartnerShops(
    requestMetaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<OnPremiseShop[]>> {
    return this.shopsFacade.getOnPremiseShops(requestMetaData, filter).pipe(
      map((shopList: ListResult<OnPremiseShop[]>) => {
        shopList.list = shopList.list.filter((shop: OnPremiseShop) => shop.domain !== 'www.shopware-ag.de');
        shopList.total--;

        return shopList;
      })
    );
  }

  migrateShop(migrationData: ShopMigrationData): Observable<OnPremiseShop | SbpException> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        migrationData.oldOwnerId = companyId;
        return this.shopsFacade.migrateShop(migrationData);
      })
    );
  }

  renameShopDomain(migrationData: ShopMigrationData): Observable<OnPremiseShop | SbpException> {
    return this.shopsFacade.migrateShop(migrationData);
  }
}
