<div
  class="card-header"
  [class.is--reversed]="showToggleLeft"
  data-padding="s"
  [class.clickable]="headerFullyLinked"
  (click)="toggleOnFullHeaderClick()">
  <ng-content select="card-header"></ng-content>
  <div class="header-toggle" *ngIf="collapsible" (click)="!headerFullyLinked && toggle()">
    <account-sw-icon [class.is-reversed]="open" icon="chevron-down-xs" size="10px"></account-sw-icon>
  </div>
</div>
<div class="card-body" [class.is-open]="open">
  <ng-content select="card-body"></ng-content>
</div>
<ng-content select="card-additional"></ng-content>
