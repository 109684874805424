import { provideEffects } from '@ngrx/effects';

import { StoreConfig } from '../../store-config.model';
import { PartnerEffects } from './partner.effects';
import { reducer } from './partner.reducer';
import { PartnerState } from './partner.state';

export { actions as partnerActions } from './partner.actions';
export * as partnerSelectors from './partner.selectors';
export { PartnerState } from './partner.state';

const providers = [provideEffects(PartnerEffects)];

export const partnerStoreConfig: StoreConfig<PartnerState> = {
  reducer: reducer,
  providers: providers,
};
