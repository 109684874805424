<account-modal [heading]="heading" (modalClose)="cancel()">
  <modal-body *ngIf="textAsHTML; else plainText" [innerHTML]="text">
    <account-checkbox
      [label]="checkBoxText"
      [checked]="false"
      [labelAsHTML]="false"
      (change)="toggleCheckboxConfirmation($event.target.checked)"></account-checkbox>
  </modal-body>
  <ng-template #plainText>
    {{ text }}
    <account-checkbox
      [label]="checkBoxText"
      [checked]="false"
      [labelAsHTML]="false"
      (change)="toggleCheckboxConfirmation($event.target.checked)"></account-checkbox>
  </ng-template>
  <modal-footer>
    <button class="a_button is_small" (click)="cancel()">{{ cancelButtonText }}</button>
    <button class="a_button is_primary is_small" type="primary" (click)="confirm()" [disabled]="!isChecked">
      {{ confirmButtonText }}
    </button>
  </modal-footer>
</account-modal>
