import { BadgeStyle } from '../../shared/components/badge/badge.model';

export const getBadgeColorByTicketStatus: (ticketStatus: string) => BadgeStyle = (ticketStatus: string) => {
  switch (ticketStatus) {
    case 'open':
    case 'offen':
      return 'success';
    case 'in Bearbeitung':
    case 'inProgress':
      return 'default';
    case 'answered':
    case 'beantwortet':
      return 'info';
    case 'closed':
    case 'closedByCreator':
    case 'closedByProducer':
    case 'Abgeschlossen':
      return 'special';
    default:
      return 'error';
  }
};
