import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, mapTo, share, switchMap, switchMapTo, take } from 'rxjs/operators';

import { Workspace, WorkspaceItem } from '../../models';
import { ReturnActionService } from '../../services';
import { actions, selectors } from '../../store';
import { RootState } from '../../store/root.state';
import { RouterFacade } from './router.facade';

@Injectable({
  providedIn: 'root',
})
export class NavigationFacade {
  private readonly navigationExpanded = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly store: Store<RootState>,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly routerFacade: RouterFacade,
    private readonly returnActionService: ReturnActionService
  ) {}

  isExpanded(): Observable<boolean> {
    return this.navigationExpanded.asObservable();
  }

  get expanded(): boolean {
    return this.navigationExpanded.value;
  }

  getWorkspaces(): Observable<Workspace[]> {
    return this.waitUntilWorkspacesAreLoaded().pipe(
      switchMapTo(this.store.select(selectors.navigationSelectors.getWorkspaces))
    );
  }

  getActiveWorkspace(): Observable<Workspace> {
    return this.store.select(selectors.navigationSelectors.getActiveWorkspace);
  }

  getActiveWorkspaceItems(): Observable<WorkspaceItem[]> {
    return this.getActiveWorkspace().pipe(
      switchMap((workspace: Workspace) => (workspace ? this.getItemsByWorkspaceName(workspace.name) : of([]))),
      share()
    );
  }

  getActiveWorkspaceItem(): Observable<WorkspaceItem> {
    return this.store.select(selectors.navigationSelectors.getActiveWorkspaceItem).pipe(share());
  }

  toggleNavigation(): void {
    this.navigationExpanded.next(!this.navigationExpanded.value);
  }

  selectWorkspace(workspace: Workspace): void {
    this.store.dispatch(
      actions.navigationActions.setActiveWorkspace({ payload: workspace ? { name: workspace.name } : null })
    );
  }

  selectWorkspaceItem(item: WorkspaceItem): void {
    this.store.dispatch(actions.navigationActions.setActiveWorkspaceItem({ payload: { item: item } }));
  }

  selectActiveWorkspaceByPath(path: string, onlyIfNotAlreadyActive = true): Observable<boolean> {
    return this.getWorkspaces().pipe(
      switchMap((workspaces: Workspace[]) => {
        const normalizedPath = path.startsWith('/') ? path.slice(1) : path;
        const workspaceToSelect = workspaces.find((availableWorkspace: Workspace) =>
          normalizedPath.startsWith(availableWorkspace.path)
        );
        // make sure the active workspace has been stored into store
        const workspaceSelectionCompleted$ = this.store
          .select(selectors.navigationSelectors.isActiveWorkspaceSelected)
          .pipe(
            filter((selected) => selected),
            take(1)
          );

        if (!onlyIfNotAlreadyActive) {
          this.selectWorkspace(workspaceToSelect);
          return workspaceSelectionCompleted$;
        }
        return this.getActiveWorkspace().pipe(
          take(1),
          switchMap((activeWorkspace: Workspace) => {
            if (activeWorkspace !== workspaceToSelect || activeWorkspace?.path !== workspaceToSelect?.path) {
              this.selectWorkspace(workspaceToSelect);
              return workspaceSelectionCompleted$;
            }
            return of(true);
          })
        );
      }),
      switchMapTo(this.selectActiveWorkspaceItemByPath(path, onlyIfNotAlreadyActive))
    );
  }

  selectActiveWorkspaceItemByPath(path: string, onlyIfNotAlreadyActive = true): Observable<boolean> {
    return this.getActiveWorkspaceItemByPath(path).pipe(
      switchMap((item: WorkspaceItem) => {
        if (!onlyIfNotAlreadyActive) {
          this.selectWorkspaceItem(item);
          return of(true);
        }
        return this.getActiveWorkspaceItem().pipe(
          take(1),
          switchMap((activeItem: WorkspaceItem) => {
            if (activeItem !== item || activeItem?.path !== item?.path) {
              this.selectWorkspaceItem(item);
            }
            return of(true);
          })
        );
      })
    );
  }

  onRouteChange(): Observable<string> {
    return this.routerFacade.registerRoutingChangesIfLoggedIn().pipe(
      switchMap((path: string) => {
        if (
          Object.prototype.hasOwnProperty.call(this.route.snapshot.queryParams, 'returnUrl') &&
          !this.route.snapshot.queryParams.returnUrl.startsWith('http')
        ) {
          const parsedUrl = this.router.parseUrl(decodeURIComponent(this.route.snapshot.queryParams.returnUrl));
          const navigationCommands = parsedUrl.root.children[PRIMARY_OUTLET].segments.map(
            (segment: UrlSegment) => segment.path
          );
          if (navigationCommands.length !== 1 || navigationCommands[0] !== 'portal') {
            this.router.navigate(navigationCommands, { queryParams: {}, replaceUrl: true });
          }
          this.returnActionService.executeActionForReturnParam(parsedUrl.queryParamMap);
          return of(path);
        }
        return this.selectActiveWorkspaceByPath(path).pipe(mapTo(path));
      }),
      share()
    );
  }

  private getActiveWorkspaceItemByPath(path: string): Observable<WorkspaceItem> {
    return this.getActiveWorkspaceItems().pipe(
      take(1),
      map((workspaceItems: WorkspaceItem[]) =>
        workspaceItems.find((navItem: WorkspaceItem) => path.includes(navItem.path))
      )
    );
  }

  private getItemsByWorkspaceName(name: string): Observable<WorkspaceItem[]> {
    return this.store.select(selectors.navigationSelectors.getItemsByWorkspaceName(name));
  }

  private waitUntilWorkspacesAreLoaded(): Observable<boolean> {
    return this.store
      .select(selectors.navigationSelectors.areWorkspacesLoaded)
      .pipe(filter((loaded: boolean) => loaded));
  }
}
