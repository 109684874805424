<button
  type="button"
  class="a_button"
  [class]="buttonSize"
  [class.is_disabled]="disabled"
  [disabled]="isUploading"
  (click)="fileInput.click()">
  <div data-hidden="s">{{ label || (multiple ? 'Dateien hochladen' : 'Datei hochladen') }}</div>
  <div class="loading-spinner" [hidden]="!isUploading"></div>
</button>
<input
  [hidden]="true"
  #fileInput
  type="file"
  [accept]="accept"
  [multiple]="multiple"
  [disabled]="disabled"
  (change)="onFileSelect($event)" />
