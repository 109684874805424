import { Partner } from './partner.model';

export type MarketingMaterialAreaName = 'partner' | 'producer';

export const OTHERS: string = 'others';

export interface MarketingDocument {
  name: string | undefined;
  creationDate: Date;
  lastModified: string | undefined;
  areas: MarketingMaterialArea[];
  documents: MarketingDocumentDownload[];
}

export interface MarketingMaterialArea {
  id: number;
  name: MarketingMaterialAreaName;
}

export interface MarketingMaterialLocale {
  id: number;
  name: string;
  description: string;
}

export interface MarketingDocumentDownload {
  creationDate: string;
  lastModified: string;
  locale: MarketingMaterialLocale;
  id: number;
  name: string;
  remotePath: string;
}

export interface MarketingContract {
  date: string;
  name: string;
  downloadUrl: string;
}

export interface MarketingPrintBrochures {
  user: { id: number };
  recipient: string;
  company: string;
  address: string;
  zip: string;
  city: string;
  email: string;
  message: string;
  numBrochureShopware: number;
}

export interface ValuePoints {
  year: number;
  points: number;
  target: number;
}

export interface ValuePointsCreditRequest {
  id: number;
  status?: { id: number; name: 'approved' | 'declined' | 'open' };
  creationDate: string;
  decisionDate: null | string;
  commentOfRequester: string;
  approved: boolean;
  finalActionValue: number;
  partner: Pick<Partner, 'id' | 'name'>;
  creditableAction: ValuePointsCreditableAction;
}

export interface ValuePointsCreditableAction {
  name: string;
  scaleFactor: number;
  unscaledValue: number;
}

export interface MarketingBudget {
  year: number;
  planned: number;
  total: number;
  pointsArchived: number;
  pointsTarget: number;
}

export type MarketingBudgetRequestStatus = 'approved' | 'declined' | 'disbursed' | 'open' | 'invoice_submitted';

export interface MarketingBudgetRequest {
  id: number;
  status: MarketingBudgetRequestStatus;
  creationDate: string;
  activityDate: string;
  decisionDate: null | string;
  numberOfTargetedLeads: number;
  actionTitle: string;
  activity: string;
  descriptionOfActivityOthers: string;
  activityObjective: string;
  partner: Pick<Partner, 'id'>;
  attachments: MarketingBudgetRequestAttachment[];
  invoiceDocument: MarketingBudgetRequestAttachment | null;
  isAlignmentMeetingNeeded: boolean;
  keyMessage: string;
  totalEstimatedExpenses: null | number;
  totalRequestedMarketingFund: number;
}

export interface MarketingBudgetRequestAttachment {
  id: number;
  filename: string;
  type: 'file' | 'invoice';
}
