import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { setDefaultPaymentMean } from '../../../shared/rxjs/payment-operators';
import { FinancialGateway } from '../../gateways/company/financial.gateway';
import {
  Account,
  AutomaticBalance,
  BankAccount,
  Invoice,
  ListResult,
  OnPremiseShop,
  PartnerPayoutListItem,
  PaymentMean,
  QueryFilter,
  SbpException,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade } from './companies.facade';

@Injectable({
  providedIn: 'root',
})
export class FinancialFacade {
  constructor(
    private readonly financialGateway: FinancialGateway,
    private readonly companiesFacade: CompaniesFacade
  ) {}

  /*
   * Bank Accounts & KickbackBankData
   * All requests concerning company accounting
   */
  getBankAccountsList(metaData: RequestMetaData): Observable<ListResult<Account[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.getBankAccountsList(companyId, metaData)));
  }

  getKickbackBankData(): Observable<BankAccount> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.financialGateway.getKickbackBankData(companyId)),
      map((bankDataList: BankAccount[]) => bankDataList[0])
    );
  }

  createKickbackBankData(bankData: BankAccount): Observable<BankAccount> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.createKickbackBankData(companyId, bankData)));
  }

  updateKickbackBankData(bankData: BankAccount): Observable<BankAccount> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.updateKickbackBankData(companyId, bankData)));
  }

  /*
   * AutomaticBalance
   * All requests concerning automatic balance of current company
   */
  getAutomaticBalance(): Observable<AutomaticBalance> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.financialGateway.getAutomaticBalance(companyId)),
      setDefaultPaymentMean()
    );
  }

  updateAutomaticBalance(automaticBalance: AutomaticBalance): Observable<AutomaticBalance> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.financialGateway.updateAutomaticBalance(companyId, automaticBalance)),
      setDefaultPaymentMean()
    );
  }

  deletePaymentMean(paymentMean: PaymentMean): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.deletePaymentMean(companyId, paymentMean)));
  }

  /**
   * All requests concerning payouts for partners and producers
   */
  getPartnerPayoutList(metaData: RequestMetaData): Observable<ListResult<PartnerPayoutListItem[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.getPartnerPayoutList(companyId, metaData)));
  }

  downloadPartnerPayoutList(locale: string, metaData: RequestMetaData): Observable<Blob> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.financialGateway.downloadPartnerPayoutList(companyId, locale, metaData))
      );
  }

  /*
   * Invoices & Reversals
   * All requests concerning invoices & reversals
   */
  getInvoices(requestMetaData: RequestMetaData, filter?: QueryFilter[]): Observable<ListResult<Invoice[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.getInvoices(companyId, requestMetaData, filter)));
  }

  getReversalList(metaData: RequestMetaData): Observable<any> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.getReversalList(companyId, metaData)));
  }

  downloadInvoicesExport(locale: string, metaData: RequestMetaData): Observable<Blob | SbpException> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.financialGateway.downloadInvoicesExport(companyId, locale, metaData))
      );
  }

  chargeInvoice(invoice: Invoice, paymentMean: PaymentMean): Observable<void> {
    return this.financialGateway.chargeInvoice(invoice, paymentMean);
  }

  downloadInvoiceDocument(invoiceId: number): Observable<SbpException | Blob> {
    return this.financialGateway.downloadInvoiceDocument(invoiceId);
  }

  downloadReversalDocument(invoiceId: number, reversalId: number): Observable<SbpException | Blob> {
    return this.financialGateway.downloadReversalDocument(invoiceId, reversalId);
  }

  /*
   * Payments
   * All requests concerning payments, payment-means, and charges
   */
  registerPaymentMean(id: string, paymentType: string, isNewDefault: boolean): Observable<PaymentMean> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.financialGateway.registerPaymentMean(companyId, id, paymentType, isNewDefault)
        )
      );
  }

  getRegistrationRedirectUrl(
    id: string,
    paymentType: string,
    isNewDefault: boolean,
    returnUrl: string,
    language: string
  ): Observable<any> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.financialGateway.getRegistrationRedirectUrl(
            companyId,
            id,
            paymentType,
            isNewDefault,
            returnUrl,
            language
          )
        )
      );
  }

  chargeShop(shop: OnPremiseShop, paymentMeanId: number, amount: number, isNewDefault: boolean): Observable<any> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      take(1),
      switchMap((companyId: number) =>
        this.financialGateway.chargeShop(companyId, shop.id, paymentMeanId, amount, isNewDefault)
      )
    );
  }

  getExtensionSaleTaxRate(): Observable<number> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.getExtensionSaleTaxRate(companyId)));
  }

  getAvailablePaymentTypes(): Observable<string[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.financialGateway.getAvailablePaymentTypes(companyId)));
  }
}
