import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SwIconComponent } from '../../../../../shared/components/sw-icon/sw-icon.component';
import { Workspace, WorkspaceItem } from '../../../../models';

@Component({
  selector: 'navigation-entry',
  templateUrl: './navigation-entry.component.html',
  styleUrl: './navigation-entry.component.less',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, SwIconComponent],
})
export class NavigationEntryComponent {
  @Input() isActive: boolean;
  @Input() item: WorkspaceItem & { color?: string };
  @Input() workspace: Workspace;
  @Input() isWorkspace: boolean;
}
