<div class="node">
  <div class="flex-container flex-row flex-align-center" [style.padding-left.px]="depth">
    <a *ngIf="!isLeaf()" class="expander" (click)="toggle()">
      <account-sw-icon size="12px" [icon]="collapsed ? 'chevron-down-xs' : 'chevron-up-xs'"></account-sw-icon>
    </a>

    <div
      (click)="onNodeClick($event)"
      [class.is_disabled]="isSelectable() && !isSelected() && tree.disabled"
      [class.checkbox-wrapper]="!isLeaf() && isSelectable()">
      <span *ngIf="isSelectable()" class="checkbox">
        <account-sw-icon *ngIf="isSelected()" icon="checkmark-xs" size="12px"></account-sw-icon>
        <account-sw-icon *ngIf="!isSelected() && isPartialSelected()" icon="minus-xs" size="12px"></account-sw-icon>
      </span>

      <span *ngIf="template" class="flex-fill checkbox-label" [class.selected]="isSelected()">
        <tree-value-template-loader [template]="template" [node]="nodeValue"></tree-value-template-loader>
      </span>

      <span *ngIf="!template" class="flex-fill">
        {{ resolveFieldData(nodeValue, nodeValueAttributeName) }}
      </span>
    </div>
  </div>
</div>

<div class="children" *ngIf="!collapsed && nodeValue[childNodesAttributeName]">
  <tree-view-node
    *ngFor="let child of nodeValue[childNodesAttributeName]; let first = first; let odd = odd; let even = even"
    [depth]="depth + 24 + (isSelectable() && depth > 0 ? 20 : 0)"
    [nodeValue]="child"
    [template]="template"
    [nodeValueAttributeName]="nodeValueAttributeName"
    [childNodesAttributeName]="childNodesAttributeName"></tree-view-node>
</div>
