import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

import { LabelComponent } from './label/label.component';

@Component({
  selector: 'account-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, LabelComponent],
})
export class ProgressBarComponent implements OnChanges {
  @Input() value: number;
  @Input() label: string;
  @Input() maxValue = 100;
  @Input() showValue = true;
  @Input() hasArrow = false;
  @Input() arrowReversed = false;
  // max value is only visible if current value is also visible
  @Input() showMaxValue = false;
  @Input() unit = '%';
  @Input() showUnit = true;
  @Input() height: number;
  @Input() txtColor: string;
  @Input() bgColor: string;
  @Input() containerBgColor: string;
  @Input() valuePosition = 'inline'; // must be one of top/bottom or inline
  @Input() valueAlignment = 'center'; // must be one of: left/right or center
  @Input() minMaxValueSeparator = '/';
  @Input() separateMinMaxValuesWithWhitespace = true;
  @Input() separateValueAndUnitWithWhitespace = false;
  // label of the goal to be reached shown on the right of the progressbar
  @Input() reversed = false;
  @Input() reachedGoal: string;
  @Input() stretchGoal: string;

  valueInPercent: number;

  ngOnChanges(): void {
    if (100 === this.maxValue) {
      this.valueInPercent = this.value;
    } else {
      this.valueInPercent = (this.value / this.maxValue) * 100;
    }
  }
}
