<label
  class="selection-box"
  [class.checked]="checked"
  [class.disabled]="disabled"
  (mouseenter)="shopwareTooltip.show()"
  (mouseleave)="shopwareTooltip.hide()">
  <span
    class="selection-box-content"
    (mouseenter)="$event.stopImmediatePropagation()"
    (mouseleave)="$event.stopImmediatePropagation()"
    [matTooltipShowDelay]="1"
    [matTooltip]="''"
    #shopwareTooltip="matTooltip">
    <account-figure *ngIf="icon" size="72px" background="#e4f4ff">
      <account-sw-icon color="#afdbf6" size="28px" icon="plug"></account-sw-icon>
    </account-figure>

    <span *ngIf="iconAssetPath" class="icon-wrapper" [style.background-image]="'url(' + iconAssetPath + ')'"></span>

    <simple-radio-button
      [checked]="checked"
      [disabled]="disabled"
      [name]="name"
      [value]="value"
      (onChange)="change($event)"></simple-radio-button>
    <h2>{{ label }}</h2>

    <small>{{ text }}</small>
  </span>
</label>
