<div *ngIf="undefined !== balance && paymentMean" class="a_row commission-data">
  <div class="a_column-12 a_column-l-6 flex-container flex-column">
    <div class="commission-undisbursed" data-margin-bottom>
      <span class="amount" [ngClass]="amountTypeClass">{{ balance | localeCurrency }}</span>
    </div>
    <p>
      <strong>{{ 'COMMON.INFORMATION' | translate }}:</strong>
      <span>{{ 'COMPANY.COLLECTION_ACCOUNT.INFO_TEXT' | translate: { balance: this.balance | localeCurrency } }}</span>
    </p>
  </div>

  <div class="a_column-12 a_column-l-6 flex-container flex-column">
    <div class="a_control account-info" data-margin-bottom>
      <div [ngSwitch]="paymentMethod">
        <div *ngSwitchCase="'DD.DB'">
          <div class="control_description">
            <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.HEADLINE' | translate }}</strong>
          </div>
          <div *ngIf="paymentMean.id" class="control_content" data-margin-bottom>
            <div class="flex-container flex-row">
              <div>
                <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.IBAN' | translate }}:</strong>
                <br />
                <strong *ngIf="paymentMean.bic">{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.BIC' | translate }}:</strong>
              </div>
              <div class="flex-fill" data-padding-left>
                <span>{{ paymentMean.iban }}</span>
                <br />
                <span *ngIf="paymentMean.bic">{{ paymentMean.bic }}</span>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'VA.DB'">
          <div class="control_description">
            <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.HEADLINE' | translate }}</strong>
          </div>
          <div *ngIf="paymentMean.id" class="control_content" data-margin-bottom>
            <div class="flex-container flex-row">
              <div>
                <strong *ngIf="paymentMean.accountNumber">
                  {{ 'COMPANY.COLLECTION_ACCOUNT.PAYPAL' | translate }}:
                </strong>
                <strong *ngIf="!paymentMean.accountNumber">
                  {{ 'ACCOUNTING.PAYMENT_MEAN.LABEL.PAYPAL' | translate }}
                </strong>
              </div>
              <div class="flex-fill" data-padding-left>
                <span>{{ paymentMean.accountNumber }}</span>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'CC.DB'">
          <div class="control_description">
            <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.HEADLINE' | translate }}</strong>
          </div>
          <div *ngIf="paymentMean.id" class="control_content" data-margin-bottom>
            <div class="flex-container flex-row">
              <div>
                <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.CREDIT_CARD' | translate }}:</strong>
                <br />
              </div>
              <div class="flex-fill" data-padding-left>
                <span>{{ paymentMean.accountNumber }}</span>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'DD.BLZ'">
          <div class="control_description">
            <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.HEADLINE' | translate }}</strong>
          </div>
          <div *ngIf="paymentMean.id" class="control_content" data-margin-bottom>
            <div class="flex-container flex-row">
              <div>
                <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.ACCOUNT_NUMBER' | translate }}:</strong>
                <br />
                <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.BANK_NUMBER' | translate }}:</strong>
              </div>
              <div class="flex-fill" data-padding-left>
                <span>{{ paymentMean.accountNumber }}</span>
                <br />
                <span>{{ paymentMean.bankNumber }}</span>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchDefault>
          <div class="control_description">
            <strong>{{ 'COMPANY.COLLECTION_ACCOUNT.NO_ACCOUNT' | translate }}</strong>
          </div>
        </div>
      </div>
      <div class="control_content">
        <span [matTooltip]="!hasWritePermissions ? ('CORE.NO_ACCESS.HEADING.DEFAULT' | translate) : null">
          <button
            type="button"
            class="a_button is_small"
            [disabled]="!hasWritePermissions"
            [class.is_disabled]="!hasWritePermissions"
            (click)="goToBilling()">
            {{ 'COMPANY.COLLECTION_ACCOUNT.UPDATE_BUTTON' | translate }}
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
