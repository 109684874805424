import { InjectionToken, Injector } from '@angular/core';

import { ModalRef } from './modal-ref';

export const MODAL_DATA = new InjectionToken<any>('modalData');

export class ModalInjector implements Injector {
  constructor(
    private readonly parentInjector: Injector,
    private readonly modalRef: ModalRef,
    private readonly data: any
  ) {}

  get(token: any, notFoundValue?: any): any {
    if (token === ModalRef) {
      return this.modalRef;
    }

    if (token === MODAL_DATA) {
      return this.data;
    }

    return this.parentInjector.get<any>(token, notFoundValue);
  }
}
