import { Injectable } from '@angular/core';

import { ScriptFile } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class LoadJsScriptService {
  loadScript(scriptData: ScriptFile): PromiseLike<{ script: string; loaded: boolean; status: string }> {
    return new Promise((resolve, _) => {
      if (!scriptData.loaded) {
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        if (scriptData.src) {
          script.src = scriptData.src;
        }
        if (scriptData.text) {
          script.text = scriptData.text;
        }
        if (scriptData.attributes) {
          scriptData.attributes.forEach((attribute) => {
            for (const key in attribute) {
              if (Object.prototype.hasOwnProperty.call(attribute, key)) {
                script.setAttribute(key, attribute[key]);
              }
            }
          });
        }

        if (script.readyState) {
          const readyStateListener = script.addEventListener('readystatechange', () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.removeEventListener('readystatechange', readyStateListener);
              scriptData.loaded = true;
              resolve({ script: scriptData.name, loaded: true, status: 'Loaded' });
            }
          });
        } else {
          script.addEventListener('load', () => {
            scriptData.loaded = true;
            resolve({ script: scriptData.name, loaded: true, status: 'Loaded' });
          });
        }
        script.addEventListener('error', () => {
          resolve({ script: scriptData.name, loaded: false, status: 'Loaded' });
        });
        document.querySelectorAll('head')[0].appendChild(script);
      } else {
        resolve({ script: scriptData.name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
