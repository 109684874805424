import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollingService {
  onScroll = new EventEmitter<void>();
  onScrollFinished = new EventEmitter<void>();

  private scrolling = false;

  setScrolling(isScrolling: boolean): void {
    if (!this.scrolling && isScrolling) {
      this.onScroll.emit();
    }
    if (this.scrolling && !isScrolling) {
      this.onScrollFinished.emit();
    }
    this.scrolling = isScrolling;
  }
}
