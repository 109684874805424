import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CloudShop,
  CompanyToCreate,
  CustomerUserAccountRegistration,
  ListResult,
  OnPremiseShop,
  PackagesToken,
  PartnerCustomer,
  PartnerCustomerMembershipStatus,
  PluginLicense,
  ProductAcceleratorLicense,
  ProductLicense,
  QueryFilter,
  QueryFilterGroup,
  Shop,
  SubshopLicense,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PartnerCustomersGateway {
  constructor(private readonly apiService: ApiService) {}

  getPartnerCustomersList(
    partnerId: number,
    metaData: RequestMetaData,
    filter?: Record<string, any>
  ): Observable<ListResult<PartnerCustomer[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }

    return this.apiService.getList(`/partners/${partnerId}/customers`, params);
  }

  getPartnerCustomer(partnerId: number, customerId: number): Observable<PartnerCustomer> {
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}`);
  }

  inviteAdminUserToCustomer(
    partnerId: number,
    customerId: number,
    registrationData: CustomerUserAccountRegistration
  ): Observable<PartnerCustomer> {
    return this.apiService.post(`/partners/${partnerId}/customers/${customerId}/invite`, registrationData);
  }

  getPartnerCustomerOnPremiseShops(
    partnerId: number,
    customerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any>
  ): Observable<ListResult<OnPremiseShop[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }

    return this.apiService.getList(`/partners/${partnerId}/customers/${customerId}/onPremiseShops`, params);
  }

  getPartnerCustomerShops(
    partnerId: number,
    customerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any>
  ): Observable<ListResult<Shop[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }

    return this.apiService.getList(`/partners/${partnerId}/customers/${customerId}/shops`, params);
  }

  getPartnerCustomerCloudShops(
    partnerId: number,
    customerId: number,
    filter: Record<string, any> = {}
  ): Observable<CloudShop[]> {
    let params = new HttpParams();
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/cloudshops`, params);
  }

  getPartnerCustomerOnPremiseShop(partnerId: number, customerId: number, shopId: number): Observable<OnPremiseShop> {
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/onPremiseShops/${shopId}`);
  }

  getPartnerCustomerShopPluginLicense(
    partnerId: number,
    customerId: number,
    shopId: number,
    licenseId: number
  ): Observable<PluginLicense> {
    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/pluginlicenses/${licenseId}`
    );
  }

  getPartnerCustomerShopPluginLicensePublishedBinaryVersions(
    partnerId: number,
    customerId: number,
    shopId: number,
    licenseId: number,
    pluginId: number
  ): Observable<string[]> {
    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/pluginlicenses/${licenseId}/plugin/${pluginId}/binaries/versions`
    );
  }

  getPartnerCustomerShopPluginLicensesList(
    partnerId: number,
    customerId: number,
    shopId: number,
    requestMetaData: RequestMetaData,
    filter: Record<string, any> | QueryFilter[] | QueryFilterGroup[] = null
  ): Observable<PluginLicense[]> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/shops/${shopId}/pluginlicenses`, params);
  }

  getPartnerCustomerSubshopPluginLicensesList(
    partnerId: number,
    customerId: number,
    shopId: number,
    requestMetaData: RequestMetaData
  ): Observable<SubshopLicense[]> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);

    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/subshoplicenses`,
      params
    );
  }

  getPartnerCustomerProductAcceleratorLicenses(
    partnerId: number,
    customerId: number,
    shopId: number
  ): Observable<ProductAcceleratorLicense[]> {
    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/productacceleratorlicenses`
    );
  }

  getPartnerCustomerShopProductLicense(
    partnerId: number,
    customerId: number,
    shopId: number
  ): Observable<ProductLicense> {
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/shops/${shopId}/productlicense`);
  }

  getPartnerCustomerShopAcceleratorLicense(
    partnerId: number,
    customerId: number,
    shopId: number,
    acceleratorId: number
  ): Observable<ProductAcceleratorLicense> {
    return this.apiService.get(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/productacceleratorlicenses/${acceleratorId}`
    );
  }

  addCustomerForPartner(partnerId: number, company: CompanyToCreate): Observable<PartnerCustomer> {
    return this.apiService.post(`/partners/${partnerId}/customers`, company.masterData);
  }

  getCustomerMembershipStatus(partnerId: number, customerId: number): Observable<PartnerCustomerMembershipStatus> {
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/membershipStatus`);
  }

  getPackagesComposerToken(partnerId: number, customerId: number, shopId: number): Observable<PackagesToken> {
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/shops/${shopId}/packagestoken`);
  }

  generatePackagesComposerToken(partnerId: number, customerId: number, shopId: number): Observable<PackagesToken> {
    return this.apiService.post(`/partners/${partnerId}/customers/${customerId}/shops/${shopId}/packagestoken`);
  }

  verifyPackagesComposerToken(partnerId: number, customerId: number, shopId: number, token: string): Observable<void> {
    return this.apiService.post(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/packagestoken/${token}`
    );
  }

  revokePackagesComposerToken(partnerId: number, customerId: number, shopId: number, token: string): Observable<void> {
    return this.apiService.delete(
      `/partners/${partnerId}/customers/${customerId}/shops/${shopId}/packagestoken/${token}`
    );
  }
}
