import { Action, createReducer, on } from '@ngrx/store';

import { CompanyAllocations } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './allocations.actions';

export interface AllocationsState extends AsyncState {
  allocations: CompanyAllocations | undefined;
}

const initialProducerState: AllocationsState = {
  loading: false,
  loaded: false,
  error: undefined,
  allocations: undefined,
};

const allocationsReducerInternal = createReducer(
  initialProducerState,

  on(actions.getCompanyAllocations, (state: AllocationsState) => ({
    ...state,
    loading: true,
  })),

  on(actions.noCompanyAllocationsExists, (state: AllocationsState) => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined as undefined,
    allocations: undefined as undefined,
  })),

  on(actions.getCompanyAllocationsSuccess, actions.getCompanyAllocationsFail, (state: AllocationsState) => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  on(actions.getCompanyAllocationsSuccess, (state: AllocationsState, { payload }) => ({
    ...state,
    allocations: payload,
    error: undefined,
  })),

  on(actions.refreshCompanyAllocations, (state: AllocationsState) => ({
    ...state,
    loaded: false,
    error: undefined as undefined,
  })),

  on(actions.getCompanyAllocationsFail, (state: AllocationsState, { payload }) => ({
    ...state,
    error: payload,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: AllocationsState | undefined, action: Action): AllocationsState {
  return allocationsReducerInternal(state, action);
}
