<ng-container>
  <navigation-header
    [isExpanded]="!isCollapsed"
    (onChangeSelectedMembership)="changeSelectedMembership($event)"
    (onGoToPortal)="goToPortal()"
    (onToggleNavigation)="toggleNavigation()"></navigation-header>

  <navigation-workspaces-selector
    [isExpanded]="!isCollapsed"
    [workspaces]="workspaces$ | async"
    [selected]="activeWorkspace$ | async"
    [disabled]="!!(activeWorkspace$ | async)"
    (workspaceSelected)="selectWorkspace($event)"></navigation-workspaces-selector>

  <ng-container *ngIf="activeWorkspace$ | async; else showWorkspaces">
    <navigation-workspace
      [workspace]="activeWorkspace$ | async"
      [workspaceItems]="activeWorkspaceItems$ | async"
      [activeWorkspaceItem]="activeWorkspaceItem$ | async"></navigation-workspace>
  </ng-container>

  <ng-template #showWorkspaces>
    <div class="navigation-workspaces">
      <ng-container *ngFor="let workspace of workspaces$ | async; trackBy: trackByWorkspace">
        <navigation-entry
          *ngIf="!workspace.featureFlag || (workspace.featureFlag | featureFlag)"
          [item]="workspace"
          [isWorkspace]="true"></navigation-entry>
      </ng-container>
    </div>
  </ng-template>

  <navigation-expander (onToggleNavigation)="toggleNavigation()" data-hidden="s,m,l"></navigation-expander>

  <navigation-menu-overlay
    *ngIf="isOverlayMenuOpen"
    [activeWorkspace]="activeWorkspace$ | async"
    [languages]="languages"
    [selectedLanguage]="selectedLanguage$ | async"
    (onLanguageChange)="changeLanguage($event)"
    (onLogout)="logout()"
    (onCloseOverlay)="toggleOverlayMenu()"
    (onShowChangelog)="openChangelogModal(true)"></navigation-menu-overlay>

  <navigation-user-info
    *ngIf="activeUser$ | async as user"
    [firstName]="user.personalData.firstName"
    [lastName]="user.personalData.lastName"
    [email]="user.email"
    [avatarUrl]="user.avatarUrl"
    (onClick)="toggleOverlayMenu()"
    (onGoToSettings)="goToUserAccountSettings()"></navigation-user-info>
</ng-container>
