import { Component, EventEmitter, HostListener, Inject, OnInit } from '@angular/core';

import { MODAL_DATA, ModalComponent, ModalRef } from '../../modal';
import { HubspotFormComponent } from '../hubspot-form.component';

interface ModalData {
  formId: string;
  heading: string;
  onSubmitted: EventEmitter<any>;
}

@Component({
  selector: 'account-hubspot-form-modal',
  templateUrl: './hubspot-form-modal.component.html',
  styleUrl: './hubspot-form-modal.component.less',
  standalone: true,
  imports: [ModalComponent, HubspotFormComponent],
})
export class HubspotFormModalComponent implements OnInit {
  formId: string;
  heading: string;
  onSubmitted: EventEmitter<any>;

  constructor(
    @Inject(MODAL_DATA) private readonly modalData: ModalData,
    private readonly modalRef: ModalRef
  ) {}

  ngOnInit(): void {
    this.getModalData();
  }

  getModalData(): void {
    this.formId = this.modalData.formId;
    this.heading = this.modalData.heading;
    this.onSubmitted = this.modalData.onSubmitted;
  }

  closeModal(): void {
    this.modalRef.close();
  }

  @HostListener('window:message', ['$event'])
  onMessage($event: any): void {
    if ($event.data.type === 'hsFormCallback' && $event.data.eventName === 'onFormSubmitted') {
      this.modalRef.close();
    }
  }
}
