import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SupportPermissions } from '../../../models';
import { ApiService } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class SupportPermissionGateway {
  constructor(private readonly apiService: ApiService) {}

  getSupportPermissions(companyId: number): Observable<SupportPermissions> {
    return this.apiService.get(`/companies/${companyId}/supportpermissions`);
  }

  getSupportPermissionForShop(companyId: number, shopId: number): Observable<SupportPermissions> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/supportpermissions`);
  }
}
