import { LanguagesFacade } from '@account/core/facades';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { formatDistanceToNow } from 'date-fns';
import * as locale from 'date-fns/locale';

import { SwIconComponent } from '../../../../shared/components/sw-icon/sw-icon.component';
import { PluginNamePipe } from '../../../../shared/pipes';
import { NotificationItem } from '../../../models/app/notification.model';
import { Language } from '../../../models/common/language.model';
import { LocalStorageService } from '../../../services/app';
import { convertSbpDate } from '../../../utils/sbp-date-converter.utils';

@Component({
  selector: 'account-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.less',
  standalone: true,
  imports: [TranslateModule, SwIconComponent, CommonModule],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: NotificationItem;
  @Output() readonly onDelete = new EventEmitter();
  @Output() readonly onClick = new EventEmitter();

  showBubble: boolean;
  creationDate: string;

  constructor(
    private readonly pluginNamePipe: PluginNamePipe,
    private readonly languagesFacade: LanguagesFacade,
    private readonly localStorageService: LocalStorageService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const lastOpened = new Date(+this.localStorageService.getItem('notification.last-opened'));
    this.showBubble = lastOpened < convertSbpDate(this.notification.creationDate);

    this.convertPayload();
    this.languagesFacade.getSelectedLanguage().subscribe((lang: Language) => {
      this.creationDate = formatDistanceToNow(convertSbpDate(this.notification.creationDate), {
        addSuffix: true,
        locale: lang.key === 'de' ? locale.de : locale.enUS,
      });
    });
  }

  convertPayload(): void {
    if (Object.prototype.hasOwnProperty.call(this.notification.payload, 'plugin')) {
      const plugin = this.notification.payload.plugin;
      this.notification.payload.pluginName = String(this.pluginNamePipe.transform(plugin));
    }
    for (const key in this.notification.payload) {
      if (
        Object.prototype.hasOwnProperty.call(this.notification.payload, key) &&
        typeof this.notification.payload[key] === 'string'
      ) {
        const translatePrefix = 'TRANSLATE:';
        if (this.notification.payload[key].startsWith(translatePrefix)) {
          this.notification.payload[key] = this.translateService.instant(
            this.notification.payload[key].slice(translatePrefix.length)
          );
        }
      }
    }
  }

  deleteNotification(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onDelete.emit();
  }

  clickNotification(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onClick.emit(this.notification);
  }
}
