import { createAction } from '@ngrx/store';

import * as fromBasketActions from './basket/basket.actions';
import * as fromNavigationActions from './navigation/navigation.actions';
import * as fromSessionActions from './session/session.actions';
import * as fromUserAccountActions from './useraccount/useraccount-meta.actions';

export { actions as companyActions } from './company';

export const sessionActions = fromSessionActions;
export const userAccountActions = fromUserAccountActions;
export const navigationActions = fromNavigationActions;
export const basketActions = fromBasketActions;

const logout = createAction('[Root] Logout');

export const rootActions = {
  logout: logout,
};
