import { InjectionToken, Injector } from '@angular/core';

import { ComponentOverlayRef } from './component-overlay-ref';

export const OVERLAY_DATA = new InjectionToken<any>('overlayData');

export class ComponentOverlayInjector implements Injector {
  constructor(
    private readonly parentInjector: Injector,
    private readonly ratingRef: ComponentOverlayRef,
    private readonly data: any
  ) {}

  get(token: any, notFoundValue?: any): any {
    if (token === ComponentOverlayRef) {
      return this.ratingRef;
    }

    if (token === OVERLAY_DATA) {
      return this.data;
    }

    return this.parentInjector.get<any>(token, notFoundValue);
  }
}
