import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Document } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class DocumentsGateway {
  constructor(private readonly apiService: ApiService) {}

  getDocuments(type: string): Observable<Document[]> {
    return this.apiService.get(`/documents/${type}/versions`);
  }

  getDocumentByVersion(type: string, version: string): Observable<Document> {
    return this.apiService.get(`/documents/${type}/versions/${version}`);
  }
}
