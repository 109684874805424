import { rootSelectors, sessionSelectors } from '@account/core/store/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMapTo } from 'rxjs/operators';

import { RootState } from '../../store/root.state';

@Injectable({
  providedIn: 'root',
})
export class SessionFacade {
  constructor(private readonly store: Store<RootState>) {}

  isDataLoaded(): Observable<boolean> {
    return this.store.select(rootSelectors.isDataLoaded);
  }

  waitUntilDataLoaded(): Observable<boolean> {
    return this.isDataLoaded().pipe(filter((isLoaded: boolean) => isLoaded));
  }

  getCompanyId(): Observable<number> {
    return this.store.select(sessionSelectors.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(this.store.select(sessionSelectors.getCompanyId))
    );
  }

  getUserAccountId(): Observable<number | undefined> {
    return this.store.select(sessionSelectors.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(this.store.select(sessionSelectors.getUserAccountId))
    );
  }
}
