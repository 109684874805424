import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { SwIconComponent } from '../sw-icon';

/**
 * The star component displays a single star icon supporting multiple states.
 */
@Component({
  selector: 'account-star',
  templateUrl: './star.component.html',
  styleUrl: './star.component.less',
  imports: [CommonModule, SwIconComponent],
  standalone: true,
})
export class StarComponent {
  @Input() size: string;

  /**
   * Defines if the star is filled or not.
   */
  @Input() filled = true;

  /**
   * Defines if the star is disabled.
   */
  @Input() disabled = false;

  @Input() useNewIconKit = false;
}
