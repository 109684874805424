import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { SbpTableContainerIntl } from '../table-container-intl.service';

@Component({
  selector: 'sbp-table-paginator',
  templateUrl: './paginator.html',
  styleUrl: './paginator.less',
  standalone: true,
  imports: [SwIconComponent, FormsModule, NgIf],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SbpTablePaginator {
  @Output() readonly onTotalChange = new EventEmitter<number>();
  @Output() readonly onChange = new EventEmitter<void>();
  @Output() readonly onReload = new EventEmitter<void>();

  entriesPerPage = 5;

  @Input() total: number;
  @Input() offset: number;
  @Input() showRefresh = true;
  @Input() contentLeft = false;

  private _limit: number;

  constructor(public intl: SbpTableContainerIntl) {}

  @Input()
  set limit(limit: number) {
    this._limit = limit;
    this.entriesPerPage = this._limit;
  }

  get limit(): number {
    return this._limit;
  }

  getCurrentPage(): number {
    if (this.limit === 0) {
      return 0;
    }
    return Math.floor(this.offset / this.limit) + 1;
  }

  getPageCount(): number {
    if (this.limit === 0) {
      return 0;
    }
    if (this.total === 0) {
      return 1;
    }
    return Math.ceil(this.total / this.limit);
  }

  hasPreviousPage(): boolean {
    return this.offset > 0;
  }

  loadPreviousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    this.offset -= this.limit;
    if (this.offset < 0) {
      this.offset = 0;
    }
    this.onChange.emit();
  }

  hasNextPage(): boolean {
    return this.offset + this.limit < this.total;
  }

  loadNextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    this.offset += this.limit;
    if (this.offset >= this.total) {
      this.offset = this.total - this.limit;
    }
    this.onChange.emit();
  }

  reloadList(): void {
    this.onReload.emit();
  }

  changeItemsPerPage(entriesPerPage: number): void {
    this.offset = 0;
    this.limit = +entriesPerPage;
    this.onChange.emit();
    this.onTotalChange.emit(this.limit);
  }
}
