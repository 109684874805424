import { createAction, props } from '@ngrx/store';

import { CompanyMembership, SbpException } from '../../../models';

// payload is the id of the UserAccount
export const getMemberships = createAction('[UserAccount][Membership] get list', props<{ payload: number }>());
export const getMembershipsSuccess = createAction(
  '[UserAccount][Membership] get list success',
  props<{ payload: CompanyMembership[] }>()
);
export const getMembershipsFail = createAction(
  '[UserAccount][Membership] get list fail',
  props<{ payload: SbpException }>()
);

// payload is the id of the Membership
export const selectMembership = createAction('[UserAccount][Membership] select', props<{ payload: number }>());
export const selectMembershipSuccess = createAction('[UserAccount][Membership] select success');
export const selectMembershipFail = createAction(
  '[UserAccount][Membership] select fail',
  props<{ payload: SbpException }>()
);

// payload is the is the id of the Membership
export const terminateMembership = createAction('[UserAccount][Membership] terminate', props<{ payload: number }>());
// payload is the id of the Membership to be terminated
export const terminateMembershipSuccess = createAction(
  '[UserAccount][Membership] terminate success',
  props<{ payload: number }>()
);
export const terminateMembershipFail = createAction(
  '[UserAccount][Membership] terminate fail',
  props<{ payload: SbpException }>()
);

export const refreshMemberships = createAction('[UserAccount][Membership] refresh');
