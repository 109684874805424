import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalesGateway } from '../../gateways/common';
import { Locale } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class LocalesFacade {
  constructor(private readonly localesGateway: LocalesGateway) {}

  getLocales(): Observable<Locale[]> {
    return this.localesGateway.getLocales();
  }
}
