<div class="booking-selection">
  <div class="selected-items">
    <ng-content></ng-content>
  </div>
  <div class="gtc-confirmation">
    <account-checkbox
      [label]="'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.BOOKING_MODAL.GTC_CONFIRMATION.LABEL' | translate"
      [labelAsHTML]="true"
      [checked]="false"
      (change)="toggleGtc($event.target.checked)"></account-checkbox>
    <div class="legal-notice">
      <ng-container *ngIf="cartFooter"><sup>*1</sup></ng-container>
      <ng-container *ngIf="!cartFooter">*</ng-container>
      {{ 'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.SHARED.TAX_NOTICE.LABEL' | translate }}
    </div>
    <ng-content select="cart-footer"></ng-content>
  </div>
</div>
<div class="booking-info">
  <div class="cart-info">
    <ng-content select="cart-info"></ng-content>
  </div>
  <div class="booking-account">
    <div class="booking-account-info">
      <div class="booking-account-info-label">
        {{ 'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.BOOKING_MODAL.INFO.BOOKING_SHOP.LABEL' | translate }}
      </div>
      <account-overflow-tooltip [content]="accountName" [useMaxWidth]="true"></account-overflow-tooltip>
    </div>
    <div class="booking-account-balance">
      <div class="booking-account-balance-label">
        {{ 'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.BOOKING_MODAL.INFO.ACCOUNT_BALANCE.LABEL' | translate }}
      </div>
      <strong>{{ accountBalance | localeCurrency }}</strong>
    </div>
    <div
      class="outstanding-balance"
      *ngIf="outstandingBalance > 0"
      [innerHTML]="
        'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.BOOKING_MODAL.INFO.OUTSTANDING_BALANCE_TEXT'
          | translate: { outstandingBalance: outstandingBalance | localeCurrency, shopId: bookingShop.id }
          | safe: 'html'
      "></div>
  </div>
  <div class="booking-confirmation">
    <div
      class="confirmation-info"
      [innerHTML]="
        'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.BOOKING_MODAL.INFO.CONFIRMATION_TEXT'
          | translate: { domain: 'domain' }
      "></div>
    <button
      class="a_button is_small is_primary"
      [class.is_disabled]="!gtcChecked || disabled"
      [disabled]="!gtcChecked || disabled"
      (click)="onClose.emit()">
      {{ 'SHOPS.SUCCESSOR.PLUGIN_MIGRATION.ASSISTANT.BOOKING_MODAL.ACTION.BOOK.LABEL' | translate }}
    </button>
  </div>
</div>
