import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CardComponent } from '../../card';
import { _ModalComponent, ModalComponent } from '../../modal';
import { ModalRef } from '../../modal/modal-ref';
import { PaginationComponent } from '../pagination/pagination.component';
import { WizardComponent } from '../wizard.component';

@Directive({
  selector: 'modal-wizard-footer',
  standalone: true,
})
export class ModalWizardFooterDirective {}

@Component({
  selector: 'account-modal-wizard',
  templateUrl: './modal-wizard.component.html',
  styleUrl: './modal-wizard.component.less',
  imports: [CommonModule, PaginationComponent, TranslateModule, CardComponent, ModalComponent],
  standalone: true,
})
export class ModalWizardComponent extends WizardComponent implements AfterViewInit {
  @Input() override heading: string;
  @Input() fullScreen = false;
  @Input() minHeight = 'inherit';
  @Input() maxWidth = '700px';
  @Input() override contentIsScrollable = true;
  @Input() focusInput = true;
  @Input() closable = true;
  @Input() closeOnAbort = true;
  @Input() closableOnBackdropClick = false;
  @Input() closeableOnEscape = true;
  @Input() loading = false;
  @Input() loadingText = '';
  @Input() hidePagination = false;

  @ViewChild(_ModalComponent) modal: _ModalComponent;

  private scrollableBody: any;

  constructor(
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    private readonly modalRef: ModalRef
  ) {
    super(elementRef, changeDetectorRef);
  }

  ngAfterViewInit(): void {
    this.scrollableBody = this.modal.elementRef.nativeElement.querySelector('.card_body');
    this.onStart.subscribe(() => {
      if (this.contentIsScrollable) {
        this.scrollableBody.scrollTo(0, 0);
      }
    });
  }

  override next(): void {
    super.next();
    if (this.focusInput && this.formGroup && this.formGroup.pristine) {
      this.modal.resetInputFocus = true;
    }
  }

  override previous(): void {
    super.previous();
    if (this.focusInput && this.formGroup && this.formGroup.pristine) {
      this.modal.resetInputFocus = true;
    }
  }

  override abort(): void {
    super.abort();
    if (this.closeOnAbort) {
      this.modalRef.close();
    }
  }

  protected override goToNextStep(): void {
    super.goToNextStep();
    if (this.contentIsScrollable) {
      this.scrollableBody.scrollTo(0, 0);
    }
  }

  protected override goToPreviousStep(): void {
    super.goToPreviousStep();
    if (this.contentIsScrollable) {
      this.scrollableBody.scrollTo(0, 0);
    }
  }
}
