import { CountriesFacade } from '@account/core/facades';
import { Country, CreateCompanyWithBillingCountry } from '@account/core/models';
import { ModalComponent, ModalRef } from '@account/shared/components';
import { StringValidators } from '@account/shared/validators';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { ObjectComparators } from '../../../../shared/object.comparators';

@Component({
  selector: 'account-company-creation-modal',
  templateUrl: './company-creation-modal.component.html',
  styleUrl: './company-creation-modal.component.less',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, AsyncPipe, TranslateModule, ModalComponent],
})
export class CompanyCreationModalComponent implements OnInit {
  form: UntypedFormGroup;
  countries$: Observable<Country[]>;

  compareByName = ObjectComparators.compareByName;

  constructor(
    private readonly countriesFacade: CountriesFacade,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modalRef: ModalRef
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.countries$ = this.countriesFacade.getCountries().pipe(share());
  }

  createCompany(): void {
    const companyToCreate: CreateCompanyWithBillingCountry = this.form.getRawValue();
    this.modalRef.close(companyToCreate);
  }

  createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), StringValidators.notTrimmed()]],
      billingCountry: [null, [Validators.required]],
    });
  }

  close(): void {
    this.modalRef.close();
  }
}
