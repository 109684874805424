import { PartnersGateway } from '@account/core/gateways';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { Partner, SbpException } from '../../../models';
import { RootState } from '../../root.state';
import * as supportPermissionActions from './../support-permissions/support-permissions.actions';
import { actions } from './partner.actions';
import { selectLoaded } from './partner.selectors';
import { PartnerState } from './partner.state';

@Injectable({
  providedIn: 'root',
})
export class PartnerEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState & PartnerState>,
    private readonly partnersGateway: PartnersGateway
  ) {}

  getPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getPartner),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.partnersGateway.getPartner(companyId).pipe(
          map((partner: Partner) => actions.getPartnerSuccess({ payload: partner })),
          catchError((error: SbpException) => {
            this.store.dispatch(actions.getPartnerFail({ payload: error }));
            return throwError(error);
          })
        )
      )
    )
  );

  resetPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.resetPartner),
      switchMap(() => this.store.select(selectLoaded)),
      filter((loaded: boolean) => !loaded),
      map(() => supportPermissionActions.resetSupportPermissions())
    )
  );

  // updatePartner$ = createEffect(() =>
  //   this.actions$
  //     .pipe(
  //       ofType(actions.updatePartner),
  //       map(action => action.payload),
  //       switchMap((partner: Partner) =>
  //         this.partnersGateway.updatePartner(partner)
  //           .pipe(
  //             map((partner: Partner) => actions.updatePartnerSuccess({payload: partner})),
  //             catchError((error: SbpException) => {
  //               this.store.dispatch(actions.updatePartnerFail({payload: error}));
  //               return throwError(error);
  //             })
  //           )
  //       )
  //     )
  // );
}
