import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { uniqueHash } from '../../util';
import { SwIconComponent } from '../sw-icon';

@Component({
  selector: 'account-tile-select',
  templateUrl: './tile-select.component.html',
  styleUrl: './tile-select.component.less',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TileSelectComponent),
    },
  ],
  imports: [NgIf, NgFor, NgTemplateOutlet, TranslateModule, SwIconComponent],
})
export class TileSelectComponent implements ControlValueAccessor {
  @Input({ required: true }) options: any[];
  @Input() buttonLabel: string;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  protected selection: any;
  protected disabled = false;
  protected inputName = uniqueHash();

  writeValue(selection: any): void {
    this.selection = selection;
  }

  onChange(isSelected: boolean, selection: any): void {
    if (!isSelected || this.disabled) {
      return;
    }

    this.selection = selection;
    this.propagateChange(this.selection);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any): void => {};
}
