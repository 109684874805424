import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'account-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.less',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule],
})
export class SearchComponent implements OnInit {
  @Input() placeholder: string;
  @Input() debounceTime = 300;

  @Output() readonly onSearch = new EventEmitter<string>();

  searchControl = new UntypedFormControl('');

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        map((searchTerm: string) => searchTerm.trim()),
        distinctUntilChanged()
      )
      .subscribe((searchTerm: string) => {
        this.onSearch.emit(searchTerm);
      });
  }
}
