<account-modal
  paddingBody="padding_none"
  [footerContentLeft]="true"
  [heading]="'CHANGELOG.HEADING' | translate: { workspace: '' + this.heading | translate }"
  [closableOnBackdropClick]="true"
  (modalClose)="onCloseModal()"
  #modal="modal">
  <modal-body>
    <div>
      <sbp-table [dataSource]="dataSource">
        <sbp-table-expander
          [expandedRowElement]="expandedRowElement"
          (toggleRowExpansion)="toggleExpansion($event)"></sbp-table-expander>

        <ng-container sbpColumnDef="entryHead">
          <sbp-cell *sbpCellDef="let entry">
            <span class="entry-headline">
              {{ 'COMMON.VERSION' | translate }}: {{ entry.versionNumber }}
              <ng-container *ngIf="isCurrentEntry(entry.versionNumber)">
                ({{ 'COMMON.CURRENT' | translate }})
              </ng-container>
            </span>
            <span>({{ 'CHANGELOG.RELEASE_DAY' | translate }} {{ entry.releaseDate | localeDate }})</span>
          </sbp-cell>
        </ng-container>

        <ng-container sbpColumnDef="expansion">
          <sbp-cell *sbpCellDef="let entry" class="changelog-row-element">
            <strong class="entries-headline">
              {{ 'CHANGELOG.ENTRY_HEADLINE' | translate }} {{ entry.element.versionNumber }}
            </strong>
            <ul class="entry-list">
              <account-changelog-entry
                *ngFor="let entryElement of entry.element.entries"
                [entry]="entryElement"></account-changelog-entry>
            </ul>
          </sbp-cell>
        </ng-container>

        <sbp-row
          [class.uneven]="index % 4 === 0"
          *sbpRowDef="let row; columns: displayedColumns; let index = index"
          class="header-row"></sbp-row>
        <sbp-row
          *sbpRowDef="let row; columns: displayedExpansionColumns; when: isExpansionRow"
          class="sbp-expansion-row"
          [@detailExpand]="expandedRowElement === row.element ? 'expanded' : 'collapsed'"></sbp-row>
      </sbp-table>
    </div>
  </modal-body>
  <modal-footer>
    <sbp-table-paginator
      [contentLeft]="true"
      [showRefresh]="false"
      [total]="changelogs.length"
      [limit]="limit"
      [offset]="offset"></sbp-table-paginator>
  </modal-footer>
</account-modal>
