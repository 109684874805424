import { Injectable, Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({
  name: 'fileSize',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class FileSizePipe implements PipeTransform {
  private readonly units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes = 0, precision = 2): string {
    if (!Number.isFinite(bytes)) {
      return '?';
    }
    let unit = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    if (unit === 0) {
      precision = 0;
    }
    return `${bytes.toFixed(+precision)} ${this.units[unit]}`;
  }
}
