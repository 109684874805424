import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import { DropdownEntryDirective } from './dropdown-entry.directive';

@Directive({
  selector: 'dropdown-active',
  standalone: true,
})
export class DropdownActiveDirective {}

@Component({
  selector: 'account-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class DropdownComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() hasArrow = false;
  @Input() smallPadding = false;
  @Output() readonly onOpenChanged = new EventEmitter<boolean>();

  @HostBinding('class.disabled') @Input() disabled = false;

  open: boolean;
  showActive: boolean;
  showEntries: boolean;

  private selfClick = false;
  private itemClick = false;
  private documentClickListener: Function;

  @ContentChild(DropdownActiveDirective) private readonly active: DropdownActiveDirective;
  @ContentChild(DropdownEntryDirective, { static: true }) private readonly entries: DropdownEntryDirective;

  constructor(
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.open = false;
    this.showActive = false;
    this.showEntries = false;
  }

  ngAfterContentInit(): void {
    this.showActive = undefined !== this.active;
    this.showEntries = undefined !== this.entries;
  }

  ngOnDestroy(): void {
    this.unbindDocumentClickListener();
  }

  onOpen(): void {
    this.bindDocumentClickListener();
    this.open = !this.open;
    this.onOpenChanged.emit(true);
    this.selfClick = true;
  }

  close(): void {
    this.open = false;
    this.onOpenChanged.emit(false);
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.close();
          this.unbindDocumentClickListener();
        }

        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
