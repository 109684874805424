import { HttpParams } from '@angular/common/http';

import { QueryFilter, QueryFilterGroup } from '../models';
import { RequestMetaData } from '../models/api/request-meta-data.model';

export class HttpParamsBuilder {
  static createMetaData(limit = 5, offset = 0, orderBy = 'id', orderSequence = 'desc'): RequestMetaData {
    return {
      limit: limit,
      offset: offset,
      orderBy: orderBy,
      orderSequence: orderSequence,
      search: '',
    };
  }

  static applyMetaData(httpParams: HttpParams, metaData: RequestMetaData): HttpParams {
    httpParams = httpParams
      .set('limit', metaData.limit.toString())
      .set('offset', metaData.offset.toString())
      .set('orderBy', metaData.orderBy.toString())
      .set('orderSequence', metaData.orderSequence.toString())
      .set('search', metaData.search.toString());

    return httpParams;
  }

  static applyFilters(
    httpParams: HttpParams,
    filters: Record<string, any> | QueryFilter[] | QueryFilterGroup[],
    createFilterObject = false
  ): HttpParams {
    if (Object.entries(filters).length === 0) {
      return httpParams;
    }
    if (!createFilterObject) {
      const keyValuePairs = HttpParamsBuilder.filtersToKeyValuePairs(filters);
      for (const key in keyValuePairs) {
        if (Object.prototype.hasOwnProperty.call(keyValuePairs, key)) {
          httpParams = httpParams.set(key.toString(), keyValuePairs[key].toString());
        }
      }
    } else {
      httpParams = httpParams.set(
        'filter',
        HttpParamsBuilder.filtersToSerializedObjects(filters as QueryFilter[] | QueryFilterGroup[])
      );
    }

    return httpParams;
  }

  static filtersToKeyValuePairs(
    filters: Record<string, any> | QueryFilter[] | QueryFilterGroup[]
  ): Record<string, any> {
    let keyValuePairs = {};
    if (filters instanceof Array && filters.length > 0) {
      if (
        Object.prototype.hasOwnProperty.call(filters[0], 'active') &&
        Object.prototype.hasOwnProperty.call(filters[0], 'items')
      ) {
        for (const filter of filters) {
          const group = filter as QueryFilterGroup;
          if (group.active) {
            const queryFilter = (group.items as QueryFilter[])[0];
            if (
              Object.prototype.hasOwnProperty.call(queryFilter, 'property') &&
              Object.prototype.hasOwnProperty.call(queryFilter, 'value')
            ) {
              keyValuePairs = Object.assign(
                {},
                keyValuePairs,
                this.queryFiltersToKeyValuePairs(group.items as QueryFilter[])
              );
            } else {
              keyValuePairs = Object.assign({}, keyValuePairs, filters);
            }
          }
        }
      } else {
        keyValuePairs = Object.assign({}, keyValuePairs, this.queryFiltersToKeyValuePairs(filters as QueryFilter[]));
      }
    } else {
      keyValuePairs = filters;
    }

    return keyValuePairs;
  }

  private static queryFiltersToKeyValuePairs(filter: QueryFilter[]): Record<string, any> {
    let keyValuePairs: Record<string, any> = {};
    for (const item of filter) {
      if (
        Object.prototype.hasOwnProperty.call(item, 'property') &&
        Object.prototype.hasOwnProperty.call(item, 'value')
      ) {
        keyValuePairs[item.property] = item.value;
      } else {
        keyValuePairs = Object.assign(keyValuePairs, item);
      }
    }

    return keyValuePairs;
  }

  private static filtersToSerializedObjects(filters: QueryFilter[] | QueryFilterGroup[]): string {
    const filtersToSerialize: QueryFilter[] = [];
    for (const filter of filters) {
      if (
        Object.prototype.hasOwnProperty.call(filter, 'active') &&
        Object.prototype.hasOwnProperty.call(filter, 'items')
      ) {
        const group = filter as QueryFilterGroup;
        if (group.active) {
          const items = group.items as QueryFilter[];
          for (const filterItem of items) {
            filtersToSerialize.push(filterItem);
          }
        }
      } else {
        const filterItem = <QueryFilter>filter;
        filtersToSerialize.push(filterItem);
      }
    }

    return JSON.stringify(filtersToSerialize);
  }
}
