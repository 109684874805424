import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { SbpColumnDef, SbpDataSource, SbpTable } from '../table';
import { SbpCell, SbpCellDef, SbpColumnDef as SbpColumnDef_1, SbpHeaderCell, SbpHeaderCellDef } from '../table/cell';

@Component({
  selector: 'sbp-table-selector',
  templateUrl: './selector.html',
  styleUrl: './selector.less',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'selector-column cdk-visually-hidden',
    '[attr.ariaHidden]': 'true',
  },
  standalone: true,
  imports: [SbpColumnDef_1, SbpHeaderCellDef, SbpHeaderCell, CheckboxComponent, SbpCellDef, SbpCell],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SbpTableSelector<T> implements OnInit, OnDestroy {
  @Input() selection: SelectionModel<T>;

  @ViewChild(SbpColumnDef, { static: true }) columnDef: SbpColumnDef;

  private readonly _name = 'selector';
  private data: any[] = [];
  private readonly subscription: Subscription;

  constructor(@Optional() public table: SbpTable<T>) {}

  ngOnInit(): void {
    this.columnDef.name = this._name;
    if (this.table) {
      this.table.addColumnDef(this.columnDef);
      (this.table.dataSource as SbpDataSource<T>).connect().subscribe((data: T[]) => {
        this.data = data;
        this.selection.clear();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.table) {
      this.table.removeColumnDef(this.columnDef);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.filter((element: T) => !this.isExpansionRow(1, element)).length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      const data = this.data.filter((element: T) => !this.isExpansionRow(1, element));
      this.selection.select(...data);
    }
  }

  isExpansionRow(index: number, row: Record<string, any>): boolean {
    return Object.prototype.hasOwnProperty.call(row, 'sbpExpansionRow');
  }
}
