<ng-container *ngIf="entry.type === 'feature'">
  <li>
    <div class="entry-row">
      <badge [value]="'CHANGELOG.FEATURE' | translate" badgeColor="success"></badge>
      <p class="entry-text">
        {{ shownText }}
        <ng-container *ngIf="entry.feedback">
          <br />
          ({{ 'CHANGELOG.FEEDBACK_FEATURES' | translate }})
        </ng-container>
      </p>
    </div>
  </li>
</ng-container>
<ng-container *ngIf="entry.type === 'improvement'">
  <li>
    <div class="entry-row">
      <badge [value]="'CHANGELOG.IMPROVEMENT' | translate" badgeColor="success"></badge>
      <p class="entry-text">
        {{ shownText }}
        <ng-container *ngIf="entry.feedback">
          <br />
          ({{ 'CHANGELOG.FEEDBACK_FEATURES' | translate }})
        </ng-container>
      </p>
    </div>
  </li>
</ng-container>
<ng-container *ngIf="entry.type === 'bugfix'">
  <li>
    <div class="entry-row">
      <badge [value]="'CHANGELOG.BUGFIX' | translate" badgeColor="info"></badge>
      <p class="entry-text bugfix-entry-text">
        {{ shownText }}
        <ng-container *ngIf="entry.feedback">
          <br />
          ({{ 'CHANGELOG.FEEDBACK_BUGFIX' | translate }})
        </ng-container>
      </p>
    </div>
  </li>
</ng-container>
