import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[capsLockToggled]', standalone: true })
export class CapsLockToggledDirective {
  @Output() readonly capsLockToggled: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  // `null` indicates that the Caps Lock state has not been checked yet
  private isCapsLockActive: null | boolean = null;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.updateCapsLockState(event);
    this.emitCapsLockToggled();
  }

  private updateCapsLockState(event: KeyboardEvent): void {
    if (this.isCapsLockActive === null) {
      this.isCapsLockActive = event.getModifierState('CapsLock');
    } else if (event.key === 'CapsLock') {
      this.isCapsLockActive = event.getModifierState('CapsLock');
    }
  }

  private emitCapsLockToggled(): void {
    this.capsLockToggled.emit(this.isCapsLockActive);
  }
}
