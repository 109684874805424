<div class="attachment-icon">
  <account-sw-icon icon="paperclip" size="16px"></account-sw-icon>
</div>
<div class="attachment-title">
  {{ name ? name : 'Attachment' }}
</div>
<div class="attachment-actions">
  <div class="action action-view" *ngIf="isAttachmentImage()" (click)="onOpenPreview($event)">
    <account-sw-icon icon="eye" size="16px"></account-sw-icon>
  </div>
  <div class="action action-download" *ngIf="downloadable" (click)="onDownload.emit()">
    <account-sw-icon icon="cloud-download" size="16px"></account-sw-icon>
  </div>
  <div class="action action-download" *ngIf="deletable" (click)="onDelete.emit()">
    <account-sw-icon icon="trash" size="16px"></account-sw-icon>
  </div>
</div>
