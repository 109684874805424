import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { Language } from '../../models';
import { LocalStorageService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class LanguagesFacade {
  private readonly availableLanguages = [{ key: 'de' }, { key: 'en' }];

  constructor(
    private readonly translate: TranslateService,
    private readonly localStorageService: LocalStorageService
  ) {}

  get selectedLanguage(): Language {
    return { key: this.translate.currentLang };
  }

  getSelectedLanguage(): Observable<Language> {
    return this.translate.onLangChange.pipe(
      map((languageChange: LangChangeEvent) => ({ key: languageChange.lang })),
      startWith(this.selectedLanguage),
      shareReplay(1)
    );
  }

  selectLanguage(language: Language): void {
    this.translate.use(language.key);
    this.localStorageService.setItem('lang', language.key);
  }

  getAvailableLanguages(): Language[] {
    return this.availableLanguages;
  }

  initializeLanguage(): void {
    const languageKeys = this.availableLanguages.map((language) => language.key);
    this.translate.addLangs(languageKeys);

    let lang = this.getLanguageFromUrl();
    if (!lang || lang === '') {
      lang = this.localStorageService.getItem('lang');

      if (!lang || lang === '') {
        lang = this.translate.getBrowserLang();
      }
    }

    let foundLanguage = languageKeys.find((languageKey: string) => languageKey === lang);
    if (!foundLanguage) {
      foundLanguage = 'de';
    }

    this.selectLanguage({ key: foundLanguage });
  }

  private getLanguageFromUrl(): string {
    const currentURL = window.location.href;
    try {
      const url = new URL(currentURL);
      return url.searchParams.get('lang');
    } catch {
      // Browser does not support url
    }
    return null;
  }
}
