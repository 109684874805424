import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReleaseRequest, ReleaseRequestAttachment, ReleaseRequestForCreation, SbpException } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PluginReleaseRequestGateway {
  constructor(private readonly apiService: ApiService) {}

  getReleaseRequestsForPlugin(pluginId: number): Observable<ReleaseRequest[]> {
    return this.apiService.get(`/plugins/${pluginId}/releaserequests`);
  }

  getPositionInReleaseRequestQueue(pluginId: number): Observable<number> {
    return this.apiService
      .get(`/plugins/${pluginId}/releaserequests/queue`)
      .pipe(map((data: { releaseRequestQueuePosition: number }) => data.releaseRequestQueuePosition));
  }

  createReleaseRequest(pluginId: number, releaseRequest: ReleaseRequestForCreation): Observable<ReleaseRequest> {
    return this.apiService.post(`/plugins/${pluginId}/releaserequests`, releaseRequest);
  }

  uploadReleaseRequestAttachment(pluginId: number, file: File): Observable<ReleaseRequestAttachment> {
    return this.apiService.upload(`/plugins/${pluginId}/releaserequests/attachment`, file);
  }

  downloadReleaseRequestAttachment(remoteLink: string): Observable<Blob | SbpException> {
    return this.apiService.downloadFromExternal(remoteLink);
  }
}
