import { createSelector } from '@ngrx/store';

import { Company, UserAccount } from '../models';
import * as fromBasketSelector from './basket/basket.selectors';
import { selectors as companyRootSelectors } from './company';
import * as fromNavigationSelectors from './navigation/navigation.selectors';
import * as fromSessionSelectors from './session/session.selectors';
import {
  invitationsSelectors as fromInvitationsSelectors,
  membershipsSelectors as fromMembershipsSelectors,
  permissionsSelectors as fromPermissionsSelectors,
  userAccountSelectors as fromUserAccountSelectors,
} from './useraccount/useraccount-meta.selectors';

export const sessionSelectors = fromSessionSelectors;

export const navigationSelectors = fromNavigationSelectors;

export const basketSelectors = fromBasketSelector;

const isCompanyLoaded = createSelector(
  companyRootSelectors.allocations.isLoaded,
  companyRootSelectors.company.isLoaded,
  companyRootSelectors.signedGTC.isLoaded,
  fromSessionSelectors.isAllowedToBypassGTCConfirmation,
  (
    allocationsLoaded: boolean,
    companyLoaded: boolean,
    signedGTCLoaded: boolean,
    isAllowedToBypassGTCConfirmation: boolean
  ) => allocationsLoaded && companyLoaded && (signedGTCLoaded || isAllowedToBypassGTCConfirmation)
);

export const companySelectors = {
  isLoaded: isCompanyLoaded,
  ...companyRootSelectors,
  getPartnerId: companyRootSelectors.allocations.getPartnerId,
  getProducerId: companyRootSelectors.allocations.getProducerId,
};

const isUserAccountLoaded = createSelector(
  fromInvitationsSelectors.isLoaded,
  fromMembershipsSelectors.isLoaded,
  fromUserAccountSelectors.isLoaded,
  (invitationsLoaded: boolean, membershipsLoaded, userAccountLoaded: boolean) =>
    invitationsLoaded && membershipsLoaded && userAccountLoaded
);

export const userAccountSelectors = {
  isLoading: fromUserAccountSelectors.isLoading,
  isLoaded: isUserAccountLoaded,
  getError: fromUserAccountSelectors.getError,
  hasError: fromUserAccountSelectors.hasError,
  getAvatarUrl: fromUserAccountSelectors.getAvatarUrl,
  getUserAccount: fromUserAccountSelectors.getUserAccount,
  getPermissions: fromPermissionsSelectors.getPermissions,
  isBanned: fromUserAccountSelectors.isBanned,
  invitations: {
    isLoading: fromInvitationsSelectors.isLoading,
    isLoaded: fromInvitationsSelectors.isLoaded,
    getError: fromInvitationsSelectors.getError,
    hasError: fromInvitationsSelectors.hasError,
    getInvitations: fromInvitationsSelectors.getInvitations,
    isInvitationCheckCompleted: fromInvitationsSelectors.isCheckCompleted,
  },
  memberships: {
    isLoading: fromMembershipsSelectors.isLoading,
    isLoaded: fromMembershipsSelectors.isLoaded,
    getError: fromMembershipsSelectors.getError,
    getWriteError: fromMembershipsSelectors.getWriteError,
    hasError: fromMembershipsSelectors.hasError,
    getMemberships: fromMembershipsSelectors.getMemberships,
    isSelectionInProgress: fromMembershipsSelectors.isSelectionInProgress,
  },
};

const isDataLoaded = createSelector(
  isCompanyLoaded,
  isUserAccountLoaded,
  (companyLoaded: boolean, userAccountLoaded: boolean) => userAccountLoaded && companyLoaded
);

const isLoggedIn = createSelector(
  fromSessionSelectors.isLoggedIn,
  fromSessionSelectors.getCompanyId,
  fromSessionSelectors.getUserAccountId,
  (sessionLoggedIn: boolean, company: number, userAccount: number) => sessionLoggedIn && (!!company || !!userAccount)
);

export const isBanned = createSelector(
  companyRootSelectors.company.isBanned,
  companyRootSelectors.company.isSoftBanned,
  fromUserAccountSelectors.isBanned,
  (companyIsBanned: boolean, companyIsSoftBanned, userAccountIsBanned) =>
    companyIsBanned || companyIsSoftBanned || userAccountIsBanned
);

export const isSoftBanned = createSelector(
  companyRootSelectors.company.isSoftBanned,
  (companyIsSoftBanned) => companyIsSoftBanned
);

const getActiveUserInfo = createSelector(
  companyRootSelectors.company.getCompany,
  fromUserAccountSelectors.getUserAccount,
  (company: Company, userAccount: UserAccount) => ({
    avatarUrl: userAccount.avatarUrl,
    companyName: company ? company.name : undefined,
    customerNumber: company ? company.masterData.customerNumber : undefined,
    firstName: userAccount.personalData.firstName,
    lastName: userAccount.personalData.lastName,
    email: userAccount.email,
    legacyLogin: false,
  })
);

const isLatestGTCSigned = createSelector(
  sessionSelectors.isAllowedToBypassGTCConfirmation,
  companySelectors.signedGTC.hasSignedLatestGTC,
  (canBypassGTCConfirmation: boolean, hasSelectedCompanySignedGTC: boolean) =>
    canBypassGTCConfirmation ? true : hasSelectedCompanySignedGTC
);

export const rootSelectors = {
  isLoggingOut: fromSessionSelectors.isLoggingOut,
  isLoggedIn: isLoggedIn,
  isDataLoaded: isDataLoaded,
  isBanned: isBanned,
  isSoftBanned: isSoftBanned,
  getSelectedCompany: companySelectors.company.getCompany,
  hasSelectedCompanySignedLatestGTC: isLatestGTCSigned,
  getActiveUserInfo: getActiveUserInfo,
};
