import { NgFor, NgIf } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';

import { HelptextComponent } from '../helptext/helptext.component';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'account-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.less',
  standalone: true,
  imports: [NgFor, NgIf, HelptextComponent],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngAfterContentInit(): void {
    this.setDefaultActivatedTab();
  }

  setDefaultActivatedTab(): void {
    if (this.tabs.filter((tab) => tab.active).length === 0) {
      this.tabs.first.active = true;
    }
  }

  activateTab(tab: TabComponent): void {
    this.tabs.forEach((currentTab) => {
      currentTab.active = false;
    });
    tab.active = true;
  }
}
