import {
  CompaniesFacade,
  SessionFacade,
  UserAccountMembershipsFacade,
  UserAccountsFacade,
} from '@account/core/facades';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatestWith, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { OverflowTooltipComponent } from '../../../../shared/components/overflow-tooltip/overflow-tooltip.component';
import { SwIconComponent } from '../../../../shared/components/sw-icon/sw-icon.component';
import { CreateCompanyService } from '../../../../shared/services/create-company.service';
import { Company, CompanyMembership } from '../../../models';

@Component({
  selector: 'navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrl: './navigation-header.component.less',
  standalone: true,
  imports: [CommonModule, TranslateModule, SwIconComponent, OverflowTooltipComponent],
})
export class NavigationHeaderComponent implements OnInit {
  @Input() isExpanded: boolean;
  @Output() readonly onGoToPortal = new EventEmitter<unknown>();
  @Output() readonly onToggleNavigation = new EventEmitter<unknown>();
  @Output() readonly onChangeSelectedMembership = new EventEmitter<CompanyMembership>();

  open = false;

  activeUserContext$: Observable<{
    company: null | Company;
    memberships: CompanyMembership[];
    hasMultipleCompanies: boolean;
  }>;

  private selfClick = false;
  private itemClick = false;
  private documentClickListener: Function;

  constructor(
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sessionFacade: SessionFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly userAccountsFacade: UserAccountsFacade,
    private readonly userAccountMembershipsFacade: UserAccountMembershipsFacade,
    private readonly createCompanyService: CreateCompanyService
  ) {}

  ngOnInit(): void {
    const company$ = this.companiesFacade
      .hasCompany()
      .pipe(switchMap((hasCompany: boolean) => (hasCompany ? this.companiesFacade.getCompany() : of(null))));

    const memberships$ = this.userAccountMembershipsFacade
      .getMemberships()
      .pipe(
        map((memberships: CompanyMembership[]) =>
          memberships
            .filter((membership: CompanyMembership) => membership.active)
            .sort((a: CompanyMembership, b: CompanyMembership) =>
              a.company.name.toLowerCase() < b.company.name.toLowerCase() ? -1 : 1
            )
        )
      );

    this.activeUserContext$ = this.sessionFacade.waitUntilDataLoaded().pipe(
      switchMap(() => company$),
      combineLatestWith(memberships$),
      map(([company, memberships]: [Company, CompanyMembership[]]) => ({
        company: company,
        memberships: memberships,
        hasMultipleCompanies: memberships.length > 1,
      }))
    );
  }

  goToPortal(): void {
    this.onGoToPortal.emit();
  }

  toggleNavigation(): void {
    this.onToggleNavigation.emit();
  }

  changeSelectedMembership(membership: CompanyMembership): void {
    this.onChangeSelectedMembership.emit(membership);
  }

  toggle(): void {
    this.bindDocumentClickListener();
    this.open = !this.open;
    this.selfClick = true;
  }

  openCompanyCreationModal(): void {
    this.createCompanyService.openCompanyCreationModal();
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.open = false;
          this.unbindDocumentClickListener();
        }
        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
