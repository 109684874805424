import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResult, PartnerCommission, PartnerCommissionAction, QueryFilter } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PartnerCommissionGateway {
  constructor(private readonly apiService: ApiService) {}

  getPartnerCommissionList(
    partnerId: number,
    requestMetaData: RequestMetaData,
    filter?: QueryFilter[]
  ): Observable<ListResult<PartnerCommission[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), requestMetaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }

    return this.apiService.getList(`/partners/${partnerId}/commissions`, params);
  }

  createPartnerCommission(partnerId: number, partnerCommission: PartnerCommission): Observable<PartnerCommission> {
    return this.apiService.post(`/partners/${partnerId}/commissions`, partnerCommission);
  }

  getPartnerCommissionActions(): Observable<PartnerCommissionAction[]> {
    return this.apiService.get('/partners/commissions/actions');
  }
}
