import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerStatusName',
  standalone: true,
})
export class PartnerStatusNamePipe implements PipeTransform {
  transform(technicalPartnerStatusName: string): string {
    switch (technicalPartnerStatusName) {
      case 'reseller':
        return 'Reseller';
      case 'bronze':
        return 'Bronze';
      case 'silver':
        return 'Silver';
      case 'gold':
        return 'Gold';
      case 'platinum':
        return 'Platinum';
      case 'stpSilver':
        return 'STP Silver';
      case 'stpGold':
        return 'STP Gold';
      case 'stpPlatinum':
        return 'STP Platinum';
      default:
        return technicalPartnerStatusName;
    }
  }
}
