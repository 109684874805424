import { createSelector } from '@ngrx/store';

import { RootState } from '../root.state';
import { SessionState } from './session.reducer';

export const sessionState = (state: RootState): SessionState => state.session;

export const isLoaded = createSelector(sessionState, (state: SessionState) => state.loaded);

export const isLoading = createSelector(sessionState, (state: SessionState) => state.loading);

export const getError = createSelector(sessionState, (state: SessionState) => state.error);

export const isLoggedIn = createSelector(
  sessionState,
  (state: SessionState) => state.loaded && (!!state.companyId || !!state.userAccountId)
);

export const isLoggingOut = createSelector(sessionState, (state: SessionState) => state.logoutInProgress);

export const getCompanyId = createSelector(sessionState, (state: SessionState) => state.companyId);

export const getUserAccountId = createSelector(sessionState, (state: SessionState) => state.userAccountId);

export const isAllowedToBypassGTCConfirmation = createSelector(
  sessionState,
  (state: SessionState) => state.bypassGTCConfirmation
);
