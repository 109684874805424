<account-modal heading="Shopware Docs" maxWidth="800px" paddingBody="padding_none" (modalClose)="close()">
  <modal-body class="wrapper">
    <ng-container *ngIf="article && loaded; else emptyContent">
      <div class="article">
        <div class="article-intro">
          <h1 class="general-title" *ngIf="article.requestedLocalization.requestedVersion.title">
            {{ article.requestedLocalization.requestedVersion.title }}
          </h1>
          <span class="general-desc" *ngIf="article.requestedLocalization.requestedVersion.lastChangeDate">
            {{ 'COMMON.WIKI.LATEST_CHANGE' | translate }} {{ creationDate }}
          </span>
          <a class="article-language" (click)="switchLanguage()">
            <ng-container *ngIf="selectedLanguage.key === 'en'; else showEnglishLink">
              {{ 'COMMON.WIKI.VIEW_GERMAN' | translate }}
            </ng-container>
            <ng-template #showEnglishLink>
              {{ 'COMMON.WIKI.VIEW_ENGLISH' | translate }}
            </ng-template>
          </a>
        </div>

        <div *ngIf="content" class="article-content blog-content" [innerHTML]="content | safe: 'html'"></div>
      </div>
    </ng-container>

    <ng-template #emptyContent>
      <div class="article-content content-empty">
        <img src="assets/images/error/page-not-found.svg" alt="Not found" />
        <div class="empty-headline">
          {{ 'COMMON.WIKI.NOT_FOUND' | translate }}
        </div>
      </div>
    </ng-template>

    <div class="article-footer">
      <img class="intro-logo" src="assets/images/shared/shopware-signet.svg" alt="Shopware Docs" />
      <a
        *ngIf="article && article.categories.length"
        class="wiki-reference"
        [href]="getLocalizedSEOUrl(article)"
        target="_blank"
        rel="noreferrer">
        <account-sw-icon icon="file-text" size="16px"></account-sw-icon>
        <span data-hidden="s">{{ 'COMMON.WIKI.LINK_EXTERNAL' | translate }}</span>
        <account-sw-icon icon="long-arrow-right" size="16px"></account-sw-icon>
      </a>
    </div>
  </modal-body>
</account-modal>
