import { createAction, props } from '@ngrx/store';

import { CompanyMembershipInvitation, SbpException } from '../../../models';

// payload is the id of the UserAccount
export const getInvitations = createAction('[UserAccount][Invitation] get list', props<{ payload: number }>());
export const getInvitationsSuccess = createAction(
  '[UserAccount][Invitation] get list success',
  props<{ payload: CompanyMembershipInvitation[] }>()
);
export const getInvitationsFail = createAction(
  '[UserAccount][Invitation] get list fail',
  props<{ payload: SbpException }>()
);

export const acceptInvitation = createAction(
  '[UserAccount][Invitation] accept',
  props<{ payload: CompanyMembershipInvitation }>()
);
export const acceptInvitationSuccess = createAction(
  '[UserAccount][Invitation] accept success',
  props<{ payload: CompanyMembershipInvitation }>()
);
export const acceptInvitationFail = createAction(
  '[UserAccount][Invitation] accept fail',
  props<{ payload: SbpException }>()
);

export const declineInvitation = createAction(
  '[UserAccount][Invitation] decline',
  props<{ payload: CompanyMembershipInvitation }>()
);
export const declineInvitationSuccess = createAction(
  '[UserAccount][Invitation] decline success',
  props<{ payload: CompanyMembershipInvitation }>()
);
export const declineInvitationFail = createAction(
  '[UserAccount][Invitation] decline fail',
  props<{ payload: SbpException }>()
);

export const decideLater = createAction(
  '[UserAccount][Invitation] decide later',
  props<{ payload: CompanyMembershipInvitation }>()
);

// TODO: remove... invitation accepting or declining invitations should be handled on useraccounts portal page
export const invitationCheckCompleted = createAction('[UserAccount][Invitation] check completed');
