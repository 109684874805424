import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, of } from 'rxjs';

import { DropdownComponent } from '../../dropdown';
import { SwIconComponent } from '../../sw-icon';
import { SbpCell, SbpCellDef, SbpColumnDef, SbpHeaderCell, SbpHeaderCellDef, SbpTable } from '../table';
import { TableAction } from './table-action';

@Component({
  selector: 'sbp-table-actions',
  templateUrl: './actions.html',
  styleUrl: './actions.less',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'actions-column cdk-visually-hidden',
    '[attr.ariaHidden]': 'true',
  },
  standalone: true,
  imports: [
    SbpColumnDef,
    SbpHeaderCellDef,
    SbpHeaderCell,
    NgIf,
    MatTooltipModule,
    SwIconComponent,
    DropdownComponent,
    NgFor,
    SbpCellDef,
    SbpCell,
    AsyncPipe,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SbpTableActions<T> implements OnInit, OnDestroy {
  @Input() selection: SelectionModel<T>;
  @Input() gridActions: TableAction[];
  @Input() rowActions: TableAction[];

  @ViewChild(SbpColumnDef, { static: true }) columnDef: SbpColumnDef;

  private readonly _name = 'actions';

  constructor(@Optional() public table: SbpTable<T>) {}

  ngOnInit(): void {
    this.columnDef.name = this._name;
    if (this.table) {
      this.table.addColumnDef(this.columnDef);
    }
  }

  ngOnDestroy(): void {
    if (this.table) {
      this.table.removeColumnDef(this.columnDef);
    }
  }

  onRowAction(action: TableAction, entry: T): void {
    if (this.isActionDisabled(action, entry)) {
      return;
    }

    action.callback(entry);
  }

  onGridAction(action: TableAction): void {
    if (action.disabled) {
      return;
    }

    action.callback(this.selection.selected);
  }

  isActionDisabled(action: TableAction, entry?: T): boolean {
    if (typeof action.disabled === 'function') {
      if (entry) {
        return action.disabled(entry) as boolean;
      }
      return action.disabled() as boolean;
    }
    return action.disabled;
  }

  isActionNotVisible(action: TableAction, entry?: T): boolean {
    if (typeof action.notVisible === 'function') {
      if (entry) {
        return action.notVisible(entry) as boolean;
      }
      return action.notVisible() as boolean;
    }
    return action.notVisible;
  }

  trackByAction(index: number, action: TableAction, entry: T): string {
    return this.getActionIcon(action.icon, entry);
  }

  getActionLabel$(
    label: string | Observable<string> | ((entry: T) => string) | ((entry: T) => Observable<string>),
    entry: T
  ): Observable<string | null> {
    if (!label) {
      return of(null);
    }
    if (this.renderActionAttributeAsString(label)) {
      return of(label) as Observable<string>;
    }
    if (this.renderActionAttributeAsObservableString(label)) {
      return label as Observable<string>;
    }
    if (this.renderActionAttributeAsCallback(label)) {
      const callback = label as ((_: any) => string) | ((_: any) => Observable<string>);
      const dynamicLabel: string | Observable<string> = callback(entry);
      if (this.renderActionAttributeAsString(dynamicLabel)) {
        return of(dynamicLabel) as Observable<string>;
      }
      if (this.renderActionAttributeAsObservableString(dynamicLabel)) {
        return dynamicLabel as Observable<string>;
      }
    }

    return of(null);
  }

  getActionIcon(icon: string | ((_: any) => string), entry?: any): string {
    if (this.renderActionAttributeAsString(icon)) {
      return icon as string;
    }
    if (this.renderActionAttributeAsCallback(icon)) {
      if (!entry) {
        throw new Error('A model for callback function must be provided');
      }
      const callback = icon as (_: any) => string;
      return callback(entry);
    }
    return '';
  }

  private renderActionAttributeAsString(attr: string | Observable<string> | Function): boolean {
    return 'string' === typeof attr;
  }

  private renderActionAttributeAsObservableString(attr: string | Observable<string> | Function): boolean {
    return attr instanceof Observable;
  }

  private renderActionAttributeAsCallback(attr: string | Observable<string> | Function): boolean {
    return attr instanceof Function;
  }
}
