import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { InputType } from '../../../../core/types/input-types.type';
import { CapsLockToggledDirective } from '../../../directives/caps-lock-toggled.directive';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';

type EyeState = 'eye-slash' | 'eye';

@Component({
  selector: 'account-toggleable-password',
  templateUrl: './toggleable-password.component.html',
  styleUrl: './toggleable-password.component.less',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, SwIconComponent, CapsLockToggledDirective, NgIf, TranslateModule],
})
export class ToggleablePasswordComponent {
  @Input() control: UntypedFormControl;
  @Output() readonly showHelp = new EventEmitter<void>();
  @Output() readonly blurEmitter = new EventEmitter<void>();

  inputType: InputType = 'password';
  eyeState: EyeState = 'eye-slash';
  isCapsLockOn: boolean;

  togglePasswordShown(): void {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
    this.eyeState = this.eyeState === 'eye-slash' ? 'eye' : 'eye-slash';
  }

  emitShowHelp(): void {
    this.showHelp.emit();
  }

  emitBlur(): void {
    this.blurEmitter.emit();
  }
}
