import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../../models';
import { GraphModel } from '../../models/common/graph.model';
import { ApiService } from '../../services/http';

@Injectable({
  providedIn: 'root',
})
export class StatisticsGateway {
  constructor(private readonly apiService: ApiService) {}

  getShopwareVersionDistributionForPlugin(pluginId: number): Observable<ListResult<GraphModel[]>> {
    return this.apiService.get(`/statistics/shopwareversiondistribution/${pluginId}`);
  }

  getBinaryDownloadCount(pluginId: number, binaryId: number): Observable<number> {
    return this.apiService
      .get(`/statistics/plugins/${pluginId}/binaries/${binaryId}/countdownloaded`)
      .pipe(map((result: { success: string; downloaded: number }) => result.downloaded));
  }
}
