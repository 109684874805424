import { createReducer, on } from '@ngrx/store';

import { actions } from './partner.actions';
import { PartnerState } from './partner.state';

const initialState: PartnerState = {
  loading: false,
  loaded: false,
  error: undefined,
  partner: undefined,
  iconUrl: undefined,
};

export const reducer = createReducer(
  initialState,

  on(actions.resetPartner, () => ({
    ...initialState,
  })),

  on(actions.getPartner, actions.updatePartner, actions.refreshPartner, (state: PartnerState) => ({
    ...state,
    loading: true,
  })),

  on(
    actions.getPartnerSuccess,
    actions.getPartnerFail,
    actions.updatePartnerSuccess,
    actions.updatePartnerFail,
    (state: PartnerState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.getPartnerSuccess, actions.updatePartnerSuccess, (state: PartnerState, { payload }) => ({
    ...state,
    partner: payload,
    error: undefined,
  })),

  on(actions.getPartnerFail, actions.updatePartnerFail, (state: PartnerState, { payload }) => ({
    ...state,
    error: payload,
  })),

  on(actions.updatePartnerAvatarSuccess, (state: PartnerState, { payload }) => ({
    ...state,
    partner: {
      ...state.partner,
      iconUrl: payload,
    },
    error: undefined,
  })),

  on(actions.refreshPartner, (state: PartnerState) => ({
    ...state,
    loaded: false,
    error: undefined,
  }))
);
