import { provideEffects } from '@ngrx/effects';

import { StoreConfig } from '../../store-config.model';
import { ProducerEffects } from './producer.effects';
import { reducer } from './producer.reducer';
import { ProducerState } from './producer.state';

export { actions as producerActions } from './producer.actions';
export * as producerSelectors from './producer.selectors';
export { ProducerState } from './producer.state';

const providers = [provideEffects(ProducerEffects)];

export const producerStoreConfig: StoreConfig<ProducerState> = {
  reducer: reducer,
  providers: providers,
};
