<ng-container [ngSwitch]="type">
  <ng-template ngSwitchCase="success">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      [icon]="icon ? icon : 'checkmark-xs'"
      class="success"
      [size]="large ? '16px' : '10px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>

  <ng-template ngSwitchCase="gray_success">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      [icon]="icon ? icon : 'checkmark-xs'"
      class="success"
      [size]="large ? '16px' : '10px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>

  <ng-template ngSwitchCase="warning">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      [icon]="icon ? icon : 'exclamation-s'"
      class="warning"
      [size]="large ? '14px' : '10px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>

  <ng-template ngSwitchCase="info">
    <img
      [class.icon-position-top]="iconPositionTop"
      src="assets/images/general/status/info-s.svg"
      class="info"
      [matTooltip]="toolTip"
      [width]="large ? '14px' : '10px'"
      [height]="large ? '14px' : '10px'"
      alt="info" />
  </ng-template>

  <ng-template ngSwitchCase="gray_info">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      v
      [icon]="icon ? icon : 'questionmark-s'"
      class="gray_info"
      [size]="large ? '14px' : '10px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>

  <ng-template ngSwitchCase="gray_error">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      [icon]="icon ? icon : 'times-xs'"
      class="gray_error"
      [size]="large ? '12px' : '8px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>

  <ng-template ngSwitchCase="error">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      [icon]="icon ? icon : 'times-xs'"
      class="error"
      [size]="large ? '12px' : '8px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>

  <ng-template ngSwitchCase="loading">
    <div class="hourglass">
      <svg
        fill="var(--sw-paragraph)"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 125"
        style="enable-background: new 0 0 100 100"
        xml:space="preserve">
        <path
          d="M70.6348,34.5861c3.7077-3.7078,5.7907-8.7366,5.7907-13.9801v-7.6747h5.9435V5H17.631v7.9313h5.9435v7.6747  c0,5.2435,2.083,10.2723,5.7907,13.9801l13.3729,13.3729c1.1273,1.1273,1.1273,2.9549,0,4.0822L29.3652,65.4139  c-3.7077,3.7077-5.7907,8.7365-5.7907,13.98v7.6745H17.631V95h64.738v-7.9315h-5.9435V79.394c0-5.2435-2.083-10.2723-5.7907-13.98  L57.2619,52.0411c-1.1273-1.1273-1.1273-2.9549,0-4.0822L70.6348,34.5861z" />
      </svg>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="gray_minus">
    <account-sw-icon
      [class.icon-position-top]="iconPositionTop"
      class="minus"
      [icon]="icon ? icon : 'minus-xs'"
      [size]="large ? '14px' : '10px'"
      [matTooltip]="toolTip"></account-sw-icon>
  </ng-template>
</ng-container>

<div class="text" [style.font-size]="fontSize">
  <ng-content></ng-content>
</div>
