import { CompanyAllocations, PartnerStatus, PartnerStatusName } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyMembershipRolePermission } from '../../models';
import { Permission } from '../../types/acl.type';
import { AbstractAclFacade } from './abstract-acl.facade';

@Injectable({
  providedIn: 'root',
})
export class AclFacade extends AbstractAclFacade {
  isAccessGrantedForPartnerStatus(allowedPartnerStatus: PartnerStatusName[]): Observable<boolean> {
    return this.companiesFacade.getAllocations().pipe(
      map((allocations: CompanyAllocations) => {
        if (!allocations.isPartner) {
          return false;
        }
        const partnerStatusNames: Set<PartnerStatusName> = new Set(
          allocations.partnerStatusList.map((status: PartnerStatus) => status.name)
        );
        return (
          allowedPartnerStatus.filter((allowedStatus: PartnerStatusName) => partnerStatusNames.has(allowedStatus))
            .length > 0
        );
      })
    );
  }

  isAccessGrantedForPermission(allowedPermissions: Permission[]): Observable<boolean> {
    // if no restrictions given access is granted
    if (undefined === allowedPermissions) {
      return of(true);
    }
    return this.userAccountsFacade
      .getPermissionsOfSelectedCompany()
      .pipe(
        map((permissions: CompanyMembershipRolePermission[]) => AclFacade.isPermitted(permissions, allowedPermissions))
      );
  }

  private static isPermitted(
    permissions: CompanyMembershipRolePermission[],
    permissionNamesToCheck: Permission[]
  ): boolean {
    for (const permission of permissions) {
      for (const permissionName of permissionNamesToCheck) {
        if (permissionName.toLowerCase() === permission.name.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }
}
