import { AbstractControl, ValidationErrors } from '@angular/forms';

export class FinanceValidators {
  static bic(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const value: string = control.value;
      if (null !== value && -1 === [8, 11].indexOf(value.length)) {
        return { invalidLength: true };
      }
    }

    return null;
  }

  static iban(control: AbstractControl): ValidationErrors | null {
    if (control) {
      // TODO: validate iban checksum
      // if (...) {
      //   return { invalidChecksum: true };
      // }
      const value: string = control.value;
      // IBAN length is between 15 and 32 characters, starting with alpha-2 ISO country code, followed by 2 digits checksum
      if (!new RegExp('[A-Z]{2}\\d{2}[A-Z0-9]{11,28}$').test(value)) {
        return { invalidFormat: true };
      }
    }

    return null;
  }
}
