import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Directive, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';

import { AbstractListItemComponent } from '../abstract-list-item.component';
import { CollapsibleContainerComponent } from '../shared/collapsible-container/collapsible-container.component';

@Directive({
  selector: 'splitted-list-item-left',
  standalone: true,
})
export class SplittedListItemLeftDirective {}

@Directive({
  selector: 'splitted-list-item-right',
  standalone: true,
})
export class SplittedListItemRightDirective {}

@Directive({
  selector: 'splitted-list-item-left-collapsible',
  standalone: true,
})
export class SplittedListItemLeftCollapsibleDirective {}

@Directive({
  selector: 'splitted-list-item-right-collapsible',
  standalone: true,
})
export class SplittedListItemRightCollapsibleDirective {}

@Component({
  selector: 'splitted-list-item',
  templateUrl: './splitted-list-item.component.html',
  styleUrl: './splitted-list-item.component.less',
  providers: [{ provide: AbstractListItemComponent, useExisting: forwardRef(() => SplittedListItemComponent) }],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, CollapsibleContainerComponent, NgTemplateOutlet],
})
export class SplittedListItemComponent extends AbstractListItemComponent {
  @Input() collapsible = false;
  @Output() readonly onCollapse = new EventEmitter<boolean>();

  collapsed = true;

  constructor() {
    super();
  }

  toggleCollapsible(): void {
    this.collapsed = !this.collapsed;
    this.onCollapse.emit(this.collapsed);
  }
}
