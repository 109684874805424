<div *ngIf="showHelpTexts" class="control_help" @fade>
  <account-status *ngIf="isVisible('characterLength')" [type]="getTypeForFulfilledRequirement('characterLength')">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.MIN_LENGTH' | translate }}
  </account-status>
  <account-status *ngIf="isVisible('hasNumber')" [type]="getTypeForFulfilledRequirement('hasNumber')">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.MIN_NUMBER' | translate }}
  </account-status>
  <account-status
    *ngIf="isVisible('hasLowerAndUppercaseLetter')"
    [type]="getTypeForFulfilledRequirement('hasLowerAndUppercaseLetter')">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.MIN_UPPER_LOWER' | translate }}
  </account-status>
  <account-status *ngIf="isVisible('hasSpecialChar')" [type]="getTypeForFulfilledRequirement('hasSpecialChar')">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.MIN_SPECIAL_CHAR' | translate }}
  </account-status>
  <account-status
    *ngIf="isVisible('isNotEqualToOldPassword')"
    [type]="getTypeForFulfilledRequirement('isNotEqualToOldPassword')">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.NOT_EQUAL_TO_OLD_PASSWORD' | translate }}
  </account-status>
</div>
