import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AvatarComponent, RealName } from '../avatar/avatar.component';

export interface AvatarInfo {
  srcUrl?: string;
  initials?: string;
  email?: string;
  realName?: RealName;
}

@Component({
  selector: 'account-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrl: './avatar-group.component.less',
  standalone: true,
  imports: [MatTooltipModule, NgFor, NgIf, AvatarComponent],
})
export class AvatarGroupComponent {
  @Input() height = '30px';
  @Input() showTooltip = false;

  displayedAvatars: AvatarInfo[] = [];
  total = 0;
  tooltip: string;

  private _avatarInfos: AvatarInfo[] = [];

  @Input() set avatarInfos(avatarInfos: AvatarInfo[]) {
    const currentInitials = this._avatarInfos.map((info) => this.getInitialsFromAvatarInfo(info));
    const updatedInitials = new Set(avatarInfos.map((info) => this.getInitialsFromAvatarInfo(info)));
    if (
      this._avatarInfos.length !== avatarInfos.length ||
      false === currentInitials.every((current) => updatedInitials.has(current))
    ) {
      this._avatarInfos = avatarInfos;
      this.total = avatarInfos.length;
      if (avatarInfos.length <= 5) {
        this.displayedAvatars = avatarInfos;
      } else {
        this.displayedAvatars = avatarInfos.slice(0, 4);
        this.displayedAvatars.push({ initials: `+${this.total - 4}` });
      }
      this.tooltip = this.getTooltipForGroup(avatarInfos);
    }
  }

  get avatarInfos(): AvatarInfo[] {
    return this._avatarInfos;
  }

  showUnknownAvatar(info: AvatarInfo): boolean {
    if (!info) {
      return true;
    }
    if (!info.srcUrl) {
      return false;
    }
    if (!info.realName) {
      return false;
    }
    return info.realName.firstName.trim() === '' && info.realName.lastName.trim() === '';
  }

  getTooltipForGroup(infos: AvatarInfo[]): string {
    if (infos.length === 1) {
      return this.getTooltipDataForInfo(infos[0]);
    }
    return infos.map((info: AvatarInfo) => `• ${this.getTooltipDataForInfo(info)}`).join('\n');
  }

  getTooltipDataForInfo(info: AvatarInfo): string {
    if (info.realName && '' !== info.realName.firstName && '' !== info.realName.lastName) {
      return `${info.realName.firstName} ${info.realName.lastName}`;
    }

    return info.email;
  }

  private getInitialsFromAvatarInfo(info: AvatarInfo): string {
    if (info.realName && '' !== info.realName.firstName && '' !== info.realName.lastName) {
      return info.realName.firstName.charAt(0) + info.realName.lastName.charAt(0);
    }
    if (info.initials) {
      return info.initials;
    }
    return '?';
  }
}
