import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContractCancellationGateway } from '../../gateways/licenses';
import { ContractCancellation } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ContractCancellationFacade {
  constructor(private readonly contractCancellationGateway: ContractCancellationGateway) {}

  cancelContract(commercialContractId: number): Observable<ContractCancellation> {
    return this.contractCancellationGateway.cancelContract(commercialContractId);
  }
}
