import { NgIf } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';

import { SafePipe } from '../../../pipes/safe.pipe';
import { AbstractModalComponent } from '../abstract-modal.component';
import { ModalBodyDirective, ModalComponent } from '../modal.component';
import { MODAL_DATA } from '../modal-injector';
import { ModalRef } from '../modal-ref';

@Component({
  selector: 'account-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrl: './../modal.component.less',
  standalone: true,
  imports: [ModalComponent, NgIf, ModalBodyDirective, SafePipe],
})
export class InfoModalComponent extends AbstractModalComponent implements OnInit {
  constructor(elementRef: ElementRef, modalRef: ModalRef, @Inject(MODAL_DATA) modalData: any) {
    super(elementRef, modalRef, modalData);
  }

  close(): void {
    this.modalRef.close(false);
  }
}
