import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SwIconComponent } from '../sw-icon/sw-icon.component';

@Component({
  selector: 'account-helptext',
  templateUrl: './helptext.component.html',
  styleUrl: './helptext.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SwIconComponent, MatTooltipModule],
})
export class HelptextComponent {
  @Input() content: string;
  @Input() toolTipClass: string;
}
