<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="34px"
  height="34px"
  viewBox="0 0 34 34"
  version="1.1">
  <title>Shopware</title>
  <g>
    <path
      d="M26.926772,30.0704562 C26.9875207,30.1161711 27.0237245,30.1888855 27.0234177,30.2652816 C27.0231109,30.3413709 26.9862935,30.4137784 26.9252379,30.4591865 C24.0010161,32.6234359 20.5291355,33.7672297 16.8851336,33.7672297 C12.3743887,33.7672297 8.13425177,32.0113467 4.9452515,28.82296 C1.75625123,25.6345734 6.13623296e-05,21.3944364 6.13623296e-05,16.8839984 C6.13623296e-05,12.374174 1.75625123,8.13434381 4.9452515,4.94534354 C8.13425177,1.75634328 12.3746955,0.000153405824 16.8851336,0.000153405824 C21.0583856,0.000153405824 25.0641185,1.53543889 28.1644502,4.32312952 C28.2442212,4.39461664 28.2672321,4.50844376 28.2221308,4.60539624 C28.1782567,4.70020104 28.0760884,4.75788163 27.9699316,4.74376829 C26.7669231,4.57870362 25.4681894,4.49494404 24.1093206,4.49494404 C19.1917435,4.49494404 15.1988968,5.59148887 12.5624643,7.66676286 C10.2113666,9.5171439 8.96877945,12.0774871 8.96877945,15.0710484 C8.96877945,18.4085455 10.2460363,21.0471256 12.8735713,23.1383538 C15.1927605,24.9838259 18.2817402,26.1819254 21.0071481,27.2385847 C23.2968834,28.1268044 25.4595987,28.9653206 26.926772,30.0704562 Z M33.6964176,15.3238611 C33.7433598,15.8610883 33.7669843,16.3860431 33.7669843,16.8836916 C33.7669843,19.0031464 33.3782539,21.0738182 32.6115316,23.0383332 C32.5860662,23.1033773 32.5336015,23.154308 32.4679438,23.1782393 C32.441558,23.1877505 32.4139449,23.1923527 32.3857182,23.1923527 C32.3446055,23.1923527 32.3037995,23.1819211 32.2679026,23.1619783 C30.260434,22.048252 28.0734805,21.2327467 25.9586278,20.4439339 C21.8544084,18.9135574 18.3107339,17.5918128 18.3107339,14.6918292 C18.3107339,13.6566467 18.7436451,12.7751768 19.563139,12.1422244 C20.5093462,11.411399 21.9258955,11.0407705 23.7738221,11.0407705 C27.2364984,11.0407705 30.0861649,12.2333474 33.6095899,15.157876 C33.6592934,15.1989888 33.690895,15.2597375 33.6964176,15.3238611 Z"></path>
  </g>
</svg>
