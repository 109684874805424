<div *ngIf="undefined !== undisbursedBalance && bankAccount" class="a_row commission-data">
  <div class="a_column-12 a_column-l-6">
    <div class="commission-undisbursed">
      <h3>
        {{ 'COMMISSIONS_AND_REVENUES.UNDISBURSED' | translate }}:
        <span class="amount" [ngClass]="amountTypeClass">{{ undisbursedBalance | localeCurrency }}</span>
      </h3>
    </div>
    <p>
      <strong>{{ 'COMMISSIONS_AND_REVENUES.PAYOUT_INFO.HEADLINE' | translate }}:</strong>
      <span>{{ 'COMMISSIONS_AND_REVENUES.PAYOUT_INFO.TEXT' | translate }}</span>
    </p>
  </div>

  <div class="a_column-12 a_column-l-6">
    <div class="account-info" data-margin-bottom>
      <h3>{{ 'ACCOUNTING.BANK_ACCOUNT.STATUS.ASSIGNED' | translate }}</h3>
      <div *ngIf="bankAccount.type && bankAccount.type.name === 'paypal'">
        <p [innerHTML]="'ACCOUNTING.PAYPAL_ACCOUNT.PAYPAL_ROLLOUT' | translate | safe: 'html'"></p>
        <div class="flex-container flex-row">
          <div>
            <strong>{{ 'ACCOUNTING.PAYPAL_ACCOUNT.LABEL.MAIL' | translate }}:</strong>
            <br />
          </div>
          <div class="flex-fill" data-padding-left>
            <span>{{ bankAccount.mail }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!bankAccount.type || bankAccount.type.name === 'direct_debit'">
        <div class="flex-container flex-row">
          <div>
            <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.ACCOUNT_HOLDER' | translate }}:</strong>
            <br />
            <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.BANK_NAME' | translate }}:</strong>
            <br />
            <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.IBAN' | translate }}:</strong>
            <br />
            <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.BIC' | translate }}:</strong>
          </div>
          <div class="flex-fill" data-padding-left>
            <span>{{ bankAccount.accountHolder }}</span>
            <br />
            <span>{{ bankAccount.bankName }}</span>
            <br />
            <span>{{ bankAccount.iban }}</span>
            <br />
            <span>{{ bankAccount.bic }}</span>
          </div>
        </div>
        <span [matTooltip]="!hasPermission ? ('CORE.NO_ACCESS.HEADING.DEFAULT' | translate) : null">
          <button
            class="a_button is_small"
            type="button"
            (click)="openBankDataModal()"
            [disabled]="!hasPermission"
            [class.is_disabled]="!hasPermission">
            <span *ngIf="bankAccount.id; else newData">{{ 'ACCOUNTING.BANK_ACCOUNT.STATUS.UPDATE' | translate }}</span>
            <ng-template #newData>{{ 'ACCOUNTING.BANK_ACCOUNT.STATUS.CREATE' | translate }}</ng-template>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
