import { createSelector } from '@ngrx/store';

import { selectPartnerState } from '../partner-feature.selector';
import { PartnerState } from './partner.state';

// const partnerState = createSelector(
//   selectPartnerState,
//   (state: PartnerFeatureState): PartnerState => state.partner
// );

export const selectLoading = createSelector(selectPartnerState, (state: PartnerState) => state.loading);

export const selectLoaded = createSelector(selectPartnerState, (state: PartnerState) => state.loaded);

export const selectPartner = createSelector(selectPartnerState, (state: PartnerState) => state.partner);

export const selectError = createSelector(selectPartnerState, (state: PartnerState) => state.error);
