import {
  ModalComponent,
  ModalRef,
  SbpDataSource,
  SbpTableExpander,
  SbpTablePaginator,
  TableComponent,
  tableExpandAnimation,
} from '@account/shared/components';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MODAL_DATA } from '../../../shared/components/modal/modal-injector';
import { LocaleDatePipe } from '../../../shared/pipes';
import { Changelog, Workspace } from '../../models';
import { ChangelogEntryComponent } from './changelog-entry/changelog-entry.component';
import { CHANGELOG_PARTNER, CHANGELOG_PRODUCER, CHANGELOG_SHOPS } from './changelogs';

@Component({
  selector: 'account-changelog-modal',
  templateUrl: './changelog-modal.component.html',
  styleUrl: './changelog-modal.component.less',
  animations: [tableExpandAnimation],
  standalone: true,
  imports: [
    CommonModule,
    ChangelogEntryComponent,
    TranslateModule,
    ModalComponent,
    TableComponent,
    LocaleDatePipe,
    SbpTableExpander,
  ],
})
export class ChangelogModalComponent implements OnInit, AfterViewInit {
  numberOfShownChangelogs = 5;

  changelogs: Changelog[];
  displayedColumns = ['expander', 'entryHead'];
  displayedExpansionColumns = ['expansion'];
  dataSource = new SbpDataSource<Changelog>();
  expandedRowElement: Changelog = null;
  offset = 0;
  limit = 5;

  shownChangelogs: Changelog[];
  heading: string;

  @ViewChild(SbpTablePaginator, { static: true }) paginator: SbpTablePaginator;

  constructor(
    private readonly modalRef: ModalRef,
    @Inject(MODAL_DATA) private readonly modalData: Workspace
  ) {}

  ngOnInit(): void {
    switch (this.modalData.name) {
      case 'producer':
        this.changelogs = CHANGELOG_PRODUCER;
        this.heading = 'NAVIGATION.WORKSPACE.PLUGIN_MANAGEMENT';
        break;
      case 'partner':
        this.changelogs = CHANGELOG_PARTNER;
        this.heading = 'NAVIGATION.WORKSPACE.PARTNER_MANAGEMENT';
        break;
      case 'shops':
        this.changelogs = CHANGELOG_SHOPS;
        this.heading = 'NAVIGATION.WORKSPACE.SHOP_MANAGEMENT';
        break;
      default:
        this.changelogs = CHANGELOG_PRODUCER;
        this.heading = 'NAVIGATION.WORKSPACE.PLUGIN_MANAGEMENT';
        break;
    }
    this.shownChangelogs = this.changelogs.slice(0, this.numberOfShownChangelogs);
    this.expandedRowElement = this.shownChangelogs[0];
    this.dataSource.data = this.shownChangelogs;
  }

  onCloseModal(): void {
    this.modalRef.close();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.onChange.subscribe(() => {
        this.limit = this.paginator.limit;
        this.offset = this.paginator.offset;
        this.shownChangelogs = this.changelogs.slice(this.offset, this.offset + this.limit);
        this.dataSource.data = this.shownChangelogs;
      });
    }
  }

  toggleExpansion(row: Changelog): void {
    if (this.expandedRowElement === row) {
      this.expandedRowElement = null;
      return;
    }

    this.expandedRowElement = row;
  }

  isExpansionRow(index: number, row: Changelog): boolean {
    return Object.prototype.hasOwnProperty.call(row, 'sbpExpansionRow');
  }

  isCurrentEntry(versionNumber: string): boolean {
    return versionNumber === this.changelogs[0].versionNumber;
  }
}
